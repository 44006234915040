import React from 'react';
import {
    DateRow,
    DisplayIdRow,
    LinkRow,
    PriceRow,
    TextRow,
    TypeRow,
    BatchFlagRow, MinLoader,
} from 'components';
import { makeCapitalize, PermissionList, RolePermission, Images, Colors } from 'utils';
import { FixDecimalToTree } from '../../components/table/tableRows';

export const ACTION_TYPE = 'GET_ACTIVE_PAYROLL';

export const activePayrollHead = () => {
    return [
        { name: '', title: 'Timesheet ID', custom: false, noSearch: true },
        { name: 'timesheetDate', title: 'Date', iconType: 'date', noSearch: true },
        { name: '', title: 'Appt ID', custom: false, noSearch: true },
        { name: '', title: 'CPT Code', custom: false, noSearch: true },
        { name: '', title: 'Staff name', custom: false, noSearch: true },
        {
            name: 'type', title: 'Type', disabled: true,
            filterList: [
                { name: 'All', id: 'All' },
                { name: 'Hourly', id: 'HOURLY' },
                { name: 'Salary', id: 'SALARY' },
                { name: 'Fixed', id: 'FIXED' },
                { name: 'Mileage', id: 'MILEAGE' },
            ], noSearch: true,
        },
        { name: '', title: 'Paycode', custom: false, noSearch: true },
        { name: '', title: 'Rate', custom: false, noSearch: true },
        { name: '', title: 'Qty', custom: false, noSearch: true },
        { name: '', title: 'Total AMT', custom: false, noSearch: true },
    ];
};


export const activePayrollBody = () => {
    return [
        { rowText: (item) => <DisplayIdRow id={item?.displayId} /> },
        { rowText: (item) => <DateRow date={item?.timesheetDate} /> },
        {
            rowText: (item) =>
                <LinkRow
                    name={item?.apptDisplayId ? item.apptDisplayId : 'N/A'}
                    href={item?.apptDisplayId ? `/schedule` : null}
                    checkPermission={!!item?.apptDisplayId}
                    textWidth={8}
                />, notClickable: true,

        },
        { rowText: (item) => <TextRow name={item?.cptCode ? item?.cptCode : 'N/A'} /> },
        {
            rowText: (item) => <LinkRow
                name={item?.staffName ? item.staffName : 'N/A'}
                href={RolePermission([PermissionList.STAFF_READ.code]) ? `/staff/${item?.staffId}` : ''}
                checkPermission={item?.staffId ? RolePermission([PermissionList.STAFF_READ.code]) : null}
                textWidth={8}
            />, notClickable: true,
        },

        { rowText: (item) => <TypeRow text={item?.type ? item?.type : 'N/A'} /> },
        { rowText: (item) => <TextRow name={item?.payCode ? item?.payCode : 'N/A'} /> },
        { rowText: (item) => <PriceRow info={item?.amount} /> },
        { rowText: (item) => <FixDecimalToTree number={item?.qty} /> },
        { rowText: (item) => <PriceRow info={item?.totalAmount} /> },
    ];
};

export const informationHead = [
    { name: 'rateType', title: 'Rate Type', custom: false, noSearch: true },
    { name: 'rate', title: 'Rate', custom: false, noSearch: true },
    { name: 'hours', title: 'Hours', custom: false, noSearch: true },
    { name: 'amount', title: 'Amount', custom: false, noSearch: true },
];

export const informationBody = [
    {
        rowText: (item) => <TextRow
            name={item?.payCode?.payCodeTypeId?.type ? makeCapitalize(item?.payCode?.payCodeTypeId?.type) : 'Fixed'}
            textWidth={10} />,
    },
    {
        rowText: (item) => <PriceRow
            info={!!item?.amount && !!item?.hours ? item.amount / item.hours : item?.payCode?.rate} textWidth={10} />,
    },
    {
        rowText: (item) => <TextRow name={item?.regularHours === 'NaNhNaNm' ? 'N/A' : item?.regularHours || item?.hours}
                                    textWidth={10} />,
    },
    { rowText: (item) => <PriceRow info={item.totalAmount ? item?.totalAmount : 0} textWidth={10} /> },
];


export const PROCESSED_ACTION_TYPE = 'GET_PROCESSED_PAYROLL';


export const processedHead = [
    { name: '', title: 'Batch ID', custom: false, width: '15%' },
    { name: '', title: 'Processor', custom: false, width: '15%' },
    { name: '', title: 'Start Date', custom: false, width: '13%' },
    { name: '', title: 'End Date', custom: false, width: '13%' },
    { name: '', title: 'Processed Date', custom: false, width: '15%' },
    { name: '', title: 'Total Pay', custom: false, width: '10%' },
    { name: '', title: 'Batch CSV', custom: false, width: '15%' },
];

export const processedBody = (i, handleDownload, currentId, loadDownload) => [
    { value: <BatchFlagRow flag={i?.flag} id={i?.displayId} notShowResolve={true} />, width: '15%' },
    {
        value: <LinkRow
            name={i?.processorName ? i?.processorName : 'N/A'}
            href={
                RolePermission([PermissionList.STAFF_READ.code]) ? `/staff/${i?.processorId}` : ''
            }
            checkPermission={i?.processorId ? RolePermission([PermissionList.STAFF_READ.code]) : null}
        />, width: '15%',
    },
    { value: <DateRow date={i?.startDate} />, width: '13%' },
    { value: <DateRow date={i?.endDate} />, width: '13%' },
    { value: <DateRow date={i?.processedDate} />, width: '15%' },
    { value: <PriceRow info={i?.totalPay} textWidth={10} />, width: '10%' },
    {
        value: <div className="action-btn">
            <button className="download-1500" style={{ height: 24 }} onClick={(e) => handleDownload(e, i?.id)}>
                <p>Download</p>
                {currentId === i?.id && loadDownload ?
                    <MinLoader margin={'0'} color={Colors.ThemeBlue} position={'relative'} />
                    :
                    <img src={Images.download} alt="" />
                }
            </button>
        </div>, width: '15%',
    },
];


export const processorDetailBody = (item, handleOpenResolve) => [
    {
        value: <BatchFlagRow
            handleResolve={(e) => handleOpenResolve(e, item)}
            resolve={item?.resolve}
            notShowFlag={true}
            flag={item?.flag}
            id={item?.displayId}
        />, width: '15%',
    },
    {
        value: <DateRow date={item?.timesheetDate} />, width: '10%',
    },
    {
        value: <LinkRow
            name={item?.apptDisplayId ? item?.apptDisplayId : 'N/A'}
            href={`/schedule`}
            checkPermission={item?.apptDisplayId ? true : null}
            textWidth={8}
        />, width: '10%', notClickable: true
    },
    {
        value: <LinkRow
            name={item?.staffName ? item.staffName : 'N/A'}
            href={RolePermission([PermissionList.STAFF_READ.code]) ? `/staff/${item?.staffId}` : ''}
            checkPermission={item?.staffId ? RolePermission([PermissionList.STAFF_READ.code]) : null}
            textWidth={8}
        />, width: '10%', notClickable: true
    },
    { value: <TypeRow text={item?.type} />, width: '10%' },
    { value: <TextRow name={item?.payCode ? item.payCode : 'N/A'} />, width: '10%' },
    { value: <TextRow name={item?.cptCode ? item?.cptCode : 'N/A'} />, width: '10%' },
    { value: <PriceRow info={item?.amount} />, width: '10%' },
    { value: <TextRow name={item?.qty ? item?.qty : 'N/A'} />, width: '5%' },
    { value: <PriceRow info={item?.totalAmount} />, width: '10%' },
];

export const processorDetailHeight = [
    { title: 'Timesheet ID', width: '15%' },
    { title: 'Date', width: '10%' },
    { title: 'Appt ID', width: '10%' },
    { title: 'Staff Name', width: '10%' },
    { title: 'Type', width: '10%' },
    { title: 'Paycode', width: '10%' },
    { title: 'CPT Code', width: '10%' },
    { title: 'Rate', width: '10%' },
    { title: 'Qty', width: '5%' },
    { title: 'Total AMT', width: '10%' },
];



export const PAYROLL_ENUMS = {
    PROCESSED: 'PROCESSED',
    ACTIVE: 'ACTIVE',
}

export const activePayrollNotYet = {
    title: 'No active timesheets available',
    subTitle: 'It looks like there are currently no active timesheets. Once timesheets are created, they will appear here.',
    image: Images.noCalendar,
}

export const processedPayrollNotYet = {
    title: 'No processed timesheets available',
    subTitle: 'It looks like there are currently no active processed.',
    image: Images.noCalendar,
}
import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { FindLoad, Images, PermissionList, RolePermission, formatAMPM } from 'utils';
import { Items } from '../items';
import { scheduleCommonStyle } from './styles';
import {
    SimpleTooltip,
    Loader,
    NoItemText, TextRow,
} from 'components';
import { getBorderColorAndText, getCurrentText, getServiceAppmtDetails } from '../constants';
import {
    billStatuses,
    BREAK,
    DRIVE,
    getDisplayFromFullType,
    PAID,
    SERVICE,
    SICK_TIME,
    STAFF,
    UNPAID,
} from '../../constants';
import { SignatureNoteModal } from './signatureNoteModal';
import { useDispatch, useSelector } from 'react-redux';
import { noteActions } from '../../../../store';

export const ScheduleDetailsCard = ({ openCloseRecur, handleEdit, appointmentById, closeDetail }) => {
    const dispatch = useDispatch();
    const classes = scheduleCommonStyle();
    const [item, setItem] = useState(appointmentById ? appointmentById : null);
    const defItem = item;
    const { color: statusColor, text } = getBorderColorAndText(defItem?.status, defItem?.type);
    const apptType = getDisplayFromFullType(defItem?.type);
    const _isServiceAppmt = defItem?.type === 'SERVICE';
    const loader = FindLoad('GET_APPOINTMENT_BY_ID');
    const serviceAppmtDetails = getServiceAppmtDetails(defItem);
    const checkEditor =
        defItem?.type === 'SERVICE' ? defItem?.status !== 'CANCELLED' && item?.billStatus !== billStatuses?.BILLED : defItem?.status !== 'CANCELLED' && defItem?.status !== 'COMPLETED';
    const createdTime = `${moment(defItem?.startTime).format('hh:mm A')} - ${moment(defItem?.endTime).format('hh:mm A')}`;
    const checkEditPermission =
        item?.type === SERVICE ? RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_SERVICE_CREATE?.code, PermissionList.APPT_CONNECTED_CREATE?.code]) :
            item?.type === STAFF ? RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_STAFF_CREATE?.code]) :
                item?.type === BREAK ? RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_BREAK_CREATE?.code]) :
                    item?.type === DRIVE ? RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_DRIVE_CREATE?.code]) :
                        item?.type === PAID ? RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_PTO_CREATE?.code]) :
                            item?.type === UNPAID ? RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_UNPAID_CREATE?.code]) :
                                item?.type === SICK_TIME ? RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_SICK_TIME_CREATE?.code]) :
                                    false;

    useEffect(() => {
        if (appointmentById) {
            setItem(appointmentById);
        }
        if (appointmentById?.type === SERVICE && appointmentById?.requireNote) {
            dispatch(noteActions.getGlobalNotes(appointmentById?.id, 'Appt'));
            setItem(appointmentById);
        }
    }, [appointmentById]);


    const handleEditItem = () => {
        const params = {
            ...defItem,
        };
        handleEdit(params);
    };

    if (!!loader.length) {
        return (
            <div className={classes.infoWrapper}>
                <Loader height={'40vh'} circleSize={50} />
            </div>
        );
    }

    return (
        <Fragment>
            <div className={classes.infoWrapper}>
                <div className={classes.titleWrapper}>
                    <p>{apptType}</p>

                    {defItem ? (
                        <div className={classes.recurAndEditBoxStyle}>
                            {!defItem?.template && item?.status !== 'CANCELLED' &&
                                RolePermission([PermissionList.APPT_RECURE?.code]) &&
                                (
                                    <SimpleTooltip
                                        title={<p>{'Recur Event'}</p>}
                                        placement="top-end"
                                    >
                                        <button
                                            className={classes.recurButnStyle}
                                            onClick={() => {
                                                closeDetail();
                                                openCloseRecur(defItem);
                                            }}
                                        >
                                            <img src={Images.recurrance} alt="icon" />
                                        </button>
                                    </SimpleTooltip>
                                )}
                            {(checkEditor) && checkEditPermission && (
                                <SimpleTooltip title={<p>{'Edit'}</p>} placement="top-end">
                                    <button
                                        style={
                                            RolePermission([PermissionList.LOCKED_APPT_EDIT?.code]) ? {} :
                                                item?.locked ? { opacity: 0.5 } : {}
                                        }
                                        disabled={RolePermission([PermissionList.LOCKED_APPT_EDIT?.code]) ? false : item?.locked}
                                        className={classes.editButnStyle}
                                        onClick={handleEditItem}
                                    >
                                        <img src={Images.edit} alt="icon" />
                                    </button>
                                </SimpleTooltip>
                            )}
                        </div>
                    ) : (
                       ''
                    )}
                </div>
                {defItem &&
                    <p className={classes.displayId}>
                        <span style={{ fontWeight: 600 }}>ID:</span>
                        <span style={{ marginLeft: '2px' }}>{defItem?.displayId}</span>
                    </p>
                }
                <div className={classes.infoDate}>
                    {defItem && (
                        <div className={classes.dateAndStatusBoxStyle}>
                     <span>
                        {moment.utc(defItem.startDate).format('MMM DD, YYYY')}
                         <span style={{ marginLeft: '16px' }}>
                             {`${formatAMPM(defItem?.startTime)} - ${formatAMPM(defItem?.endTime)}`}
                         </span>
                     </span>
                            <div className={classes.statusBoxStyle}>
                                <i
                                    className={classes.statusMarkStyle}
                                    style={{ backgroundColor: statusColor }}
                                />
                                <p className={classes.eventStatusStyle}>
                                    {text}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
                <div className={classes.itemsWrap}>
                    {serviceAppmtDetails.map((item, index) => (
                        <Items key={index} text={item.detailText} subText={item.detail} />
                    ))}
                </div>
                <div className={classes.infoFooter}>
                    {_isServiceAppmt ? (
                            <div>
                                {defItem?.signatureDate &&
                                    <>
                                        <p className={classes.signatureTitle}>Signature </p>
                                        <div className={classes.signatureWrapper}>
                                            <div className={classes.signatureCardWrapper}>
                                                <div className={classes.imageWrapper}>
                                                    <img src={Images.hours} alt="icon" />
                                                </div>
                                                <div className={classes.textWrapper}>
                                                    <p>Signature Time</p>
                                                    <div className={classes.timeInput}>
                                                        <p className={classes.textStyle}>
                                                            {defItem?.signatureDate ?
                                                                moment.utc(defItem.signatureDate).format('YYYY-MM-DD h:mm a')
                                                                :
                                                                'Not Set'
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ marginLeft: '16px' }} className={classes.signatureCardWrapper}>
                                                <div className={classes.imageWrapper}>
                                                    <img src={Images.location} alt="icon" />
                                                </div>
                                                <div className={classes.textWrapper}>
                                                    <p>Signature Location</p>
                                                    <p style={{ color: 'red' }} className={classes.textStyle}>
                                                        <TextRow
                                                            textWidth={9}
                                                            name={defItem?.signatureLocation?.formattedAddress ? defItem?.signatureLocation?.formattedAddress : 'Not Set'}
                                                        />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                <SignatureNoteModal item={defItem} closeDetail={closeDetail} />
                            </div>
                        )
                        :
                        <SignatureNoteModal item={defItem} closeDetail={closeDetail} />
                    }
                </div>
            </div>

        </Fragment>
    );
};

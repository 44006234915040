import React from 'react';
import { DateRow, IconAndTitle, TextRow, TypeRow, StatusRow } from 'components';
import { Images } from 'utils';
import { getValueDisplay } from '../../components/card/constants';

export const clientHead = [
    { name: 'code', title: 'Code', searchKey: 'CODE', width: '100px' },
    { name: 'firstName', title: 'Full Name',
         // searchKey: 'NAME'
    },
    {
        name: 'gender', title: 'Gender', disabled: true,
        filterList: [
            { name: 'All', id: 'All' },
            { name: 'Male', id: 'MALE' },
            { name: 'Female', id: 'FEMALE' },
            { name: 'Other', id: 'OTHER' },
        ],
        width: '100px',
    },
    { name: 'birthday', title: 'DOB', icon: 'date', disabled: true, custom: false, width: '120px' },
    { name: 'enrollmentDate', title: 'Enroll. Date', disabled: true, custom: false, width: '135px' },
    { name: 'enrollment', title: 'Funding Source', custom: false, smallSize: 6 },
    { name: 'contractDates', title: 'Contract Dates', custom: false, smallSize: 10, disabled: true },
    {
        name: 'status', title: 'Status', disabled: true, width: '140px',
        filterList: [
            { name: 'All', id: 'All' },
            { name: 'Active', id: 'ACTIVE' },
            { name: 'FBA', id: 'FBS' },
            { name: 'On Hold', id: 'ON_HOLD' },
            { name: 'Waitlist', id: 'WAIT_LIST' },
            { name: 'Terminated', id: 'TERMINATED' },
            { name: 'Graduated', id: 'GRADUATED' },
        ],
    },
];

const renderStatus = (item) => {
    return getValueDisplay(item?.status, 'Status');
};

export const renderBorderColor = (item) => {

    const color =
        item?.status === 'ACTIVE' ? '#6FD231' :
            item?.status === 'FBS' ? '#ADD8E6' :
                item?.status === 'FBA' ? '#ADD8E6' :
                    item?.status === 'ON_HOLD' ? '#FFCA33' :
                        item?.status === 'WAIT_LIST' ? '#FE7B43' :
                            item?.status === 'TERMINATED' ? '#FE7070' :
                                item?.status === 'GRADUATED' ? '#51439E' : 'grey';

    return {
        borderLeft: `8px solid ${color}`,
        borderRadius: '8px',
    };


};

export const clientBody = [
    {
        rowText: (item) =>     <IconAndTitle
        icon={Images.clients}
        title={item?.code ? item?.code : 'Not Set'}
        textWidth={7}
    />
    },
    {
        rowText: (item) => (
            <TextRow name={`${item?.firstName} ${item?.lastName}`} textWidth={7} />
        
        ),
    },
    { rowText: (item) => item?.gender ? <TypeRow text={item?.gender} /> : 'Not Set' },
    { rowText: (item) => <DateRow date={item?.birthday} /> },
    { rowText: (item) => <DateRow date={item?.registrationDate ? item.registrationDate : ''} /> },
    { rowText: (item) => <TextRow name={item?.enrollment ? item.enrollment : 'Not Set'} textWidth={10} /> },
    {
        rowText: (item) => (item?.contractDates?.from && item?.contractDates?.to) ?
                <div className={'flex'}>
                    <DateRow date={item?.contractDates?.from} /> - <DateRow date={item?.contractDates?.to} />
                </div> : 'Not Set',
    },
    { rowText: (item) => <StatusRow status={item?.status} /> },
];

export const ACTION_TYPE = 'GET_CLIENTS';

export const clientNotYet = {
    title: 'No Clients Added Yet',
    subTitle: 'Start by adding your first client to manage their information and services.',
    image: Images.noClientYet,
}

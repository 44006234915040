import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { FindLoad, PermissionList, RolePermission } from 'utils';
import { Loader } from 'components';
import { claimActions, invoiceActions } from 'store';
import { InvoiceDetailInfo } from 'fragments';

export const InvoiceDetails = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const loader = FindLoad('GET_INVOICE_BY_ID');

    useEffect(() => {
        dispatch(invoiceActions.getInvoiceById(params.id));

        if (RolePermission([PermissionList.INVOICE_FILE_READ?.code, PermissionList.INVOICE_FILE_MANAGE?.code])) {
            dispatch(invoiceActions.getInvoiceDocument(params.id));
        }

    }, [params.id]);

    if (!!loader.length) return <Loader />;

    return (
        <>
            <InvoiceDetailInfo />
        </>
    );
};

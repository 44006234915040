
/** Active Payroll */

export const GET_ACTIVE_PAYROLL = "GET_ACTIVE_PAYROLL"
export const GET_ACTIVE_PAYROLL_SUCCESS = "GET_ACTIVE_PAYROLL_SUCCESS"

export const GET_ACTIVE_PROCESS_PAYROLL = "GET_ACTIVE_PROCESS_PAYROLL"
export const GET_ACTIVE_PROCESS_PAYROLL_SUCCESS = "GET_ACTIVE_PROCESS_PAYROLL_SUCCESS"
export const CREATE_PROCESS_PAYROLL = "CREATE_PROCESS_PAYROLL"
export const GET_PAYROLL_BY_ID = "GET_PAYROLL_BY_ID"
export const GET_PAYROLL_BY_ID_SUCCESS = "GET_PAYROLL_BY_ID_SUCCESS"
export const DELETE_PAYROLL = "DELETE_PAYROLL"
export const RESOLVE_PAYROLL = "RESOLVE_PAYROLL"

/** Processed Payroll */

export const GET_PROCESSED_PAYROLL = "GET_PROCESSED_PAYROLL"
export const GET_PROCESSED_PAYROLL_SUCCESS = "GET_PROCESSED_PAYROLL_SUCCESS"


/**Payroll files manage*/

export const UPLOAD_PAYROLL_DOCUMENT = "UPLOAD_PAYROLL_DOCUMENT";
export const EDIT_PAYROLL_DOCUMENT = "EDIT_PAYROLL_DOCUMENT";
export const GET_PAYROLL_DOCUMENT = "GET_PAYROLL_DOCUMENT";
export const GET_PAYROLL_DOCUMENT_SUCCESS = "GET_PAYROLL_DOCUMENT_SUCCESS";
export const DELETE_PAYROLL_DOCUMENT = "DELETE_PAYROLL_DOCUMENT";
import React, { useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { paginationStyle } from './style';
import { Images } from '../../utils';

export const PaginationFillTable = ({ count, handleReturn, page, listLength, hideGoTo }) => {
    const classes = paginationStyle();
    const limitCountNumber = 50;
    const pages = listLength ? Math.ceil(count / limitCountNumber) : 0;
    const pagesList = Array.from({ length: pages }, (_, i) => i + 1);
    const firsCountNumber = page ? (page - 1) * limitCountNumber + 1 : 1;
    const lastCount = page ? page * limitCountNumber > count ? count : page * limitCountNumber : 1;
    const [selectedPage, setSelectedPage] = useState(1);

    return (
        <div className={classes.paginationWrapper}>
            <div>
                <p className={classes.showCountText}>
                    {`${firsCountNumber} - ${lastCount} of ${count}`}
                </p>
            </div>

            <div className="flex-align-center" style={{ gap: 24 }}>
                <Pagination
                    onChange={(event, val) => handleReturn(val)}
                    page={page}
                    count={count && Math.ceil(count / limitCountNumber)}
                    color={'primary'}
                />

                {!hideGoTo &&
                    <div className="flex-align-center" style={{ gap: 14 }}>
                        <button className="reset-btn" onClick={() => {
                            handleReturn(selectedPage);
                            setSelectedPage(selectedPage);
                        }}>
                            <p> Go to page</p>
                        </button>
                        <div className={classes.selectPageBox}>
                            <select
                                defaultValue={page}
                                value={selectedPage}
                                className={classes.selectPage} onChange={(e) => setSelectedPage(+e.target.value)}>
                                {pagesList?.map((i, j) => (
                                    <option key={j} value={i}>{i}</option>
                                ))}
                            </select>

                            <img src={Images.dropDown} alt="icon" />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

import {
   ADD_INVOICE_IN_INVOICE_PAYMENT,
   APPEND_FILES_TO_INVOICE_PAYMENT,
   CREATE_INVOICE_PAYMENT,
   DELETE_INVOICE_PAYMENT,
   EDIT_FILE_NAME_OF_INVOICE_PAYMENT,
   EDIT_INVOICE_PAYMENT,
   EDIT_INVOICE_PAYMENT_STATUS,
   GET_INVOICE_PAYMENTS,
   GET_INVOICE_PAYMENT_BY_ID,
   REMOVE_FILES_FROM_INVOICE_PAYMENT, EDIT_PAYMENT_INVOICE, PAY_IN_FULL_PAYMENT_INVOICE,
} from "./invoicePayment.type";

export const getInvoicePayments = (data, load) => {
   return {
      type: GET_INVOICE_PAYMENTS,
      payload: { data, load },
   };
};

export const getInvoicePaymentById = (id) => {
   return {
      type: GET_INVOICE_PAYMENT_BY_ID,
      payload: { id },
   };
};

export const createInvoicePayment = (body) => {
   return {
      type: CREATE_INVOICE_PAYMENT,
      payload: { body },
   };
};

export const editInvoicePayment = (id, body) => {
   return {
      type: EDIT_INVOICE_PAYMENT,
      payload: { id, body },
   };
};

export const deleteInvoicePayment = (id) => {
   return {
      type: DELETE_INVOICE_PAYMENT,
      payload: { id },
   };
};

export const editInvoicePaymentStatus = (id, status, details) => {
   return {
      type: EDIT_INVOICE_PAYMENT_STATUS,
      payload: { id, status, details },
   };
};

export const addInvoiceInInvoicePayment = (id, body) => {
   return {
      type: ADD_INVOICE_IN_INVOICE_PAYMENT,
      payload: { id, body },
   };
};

export const appendFilesToInvoicePayment = (id, body) => {
   return {
      type: APPEND_FILES_TO_INVOICE_PAYMENT,
      payload: { id, body },
   };
};

export const removeFilesFromInvoicePayment = (id, docId) => {
   return {
      type: REMOVE_FILES_FROM_INVOICE_PAYMENT,
      payload: { id, docId },
   };
};

export const editFileNameOfInvoicePayment = (id, docId, fileName) => {
   return {
      type: EDIT_FILE_NAME_OF_INVOICE_PAYMENT,
      payload: { id, docId, fileName },
   };
};




export const editPaymentInvoice = (id, body) => {
   return {
      type: EDIT_PAYMENT_INVOICE,
      payload: { id, body },
   };
};

export const payInFullInvoice = (id, body) => {
   return {
      type: PAY_IN_FULL_PAYMENT_INVOICE,
      payload: { id, body },
   };
};

import { Images, PermissionList, RolePermission } from 'utils';
import { DateRow, LinkRow, TextRow } from '../table';

// Notes Table config

export const noteHead = (permissions) => {
    return [
        { name: 'date', title: 'Created Date', icon: 'date', disabled: true, custom: false, noSearch: true, },
        { name: 'user', rowName: 'firstName', title: 'Creator Name', custom: false, noSearch: true },
        { name: 'subject', title: 'Subject', custom: false, noSearch: true },
        { name: 'description', title: 'Body', custom: false, noSearch: true },
        RolePermission([...permissions]) &&
        { name: 'action', title: 'Action', custom: false, disabled: true, noSearch: true, width: '200px' },
    ];
};

export const noteBody = (permissions) => {
    return [
        { rowText: (item) => <DateRow date={item?.created} /> },
        {
            rowText: (item) => <LinkRow
                name={item?.creatorFullName ? item?.creatorFullName : 'N/A'}
                href={RolePermission([PermissionList.STAFF_READ.code]) ? `/staff/${item?.creatorId}` : ''}
                checkPermission={item?.creatorFullName ? RolePermission([PermissionList.STAFF_READ.code]) : null}
                textWidth={8}
            />,
            notClickable: true,
        },
        { rowText: (item) => <TextRow name={item?.subject} /> },
        { rowText: (item) => <TextRow name={item?.text} wrapperStyle={{ maxWidth: '250px' }} /> },
        RolePermission([...permissions]) &&
        {
            button: (cb) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <button onClick={() => cb('editNote')} className="reset-btn" style={{ padding: 0 }}>
                        <img src={Images.edit} alt="edit" />
                    </button>
                    <button onClick={() => cb('deleteNote')} className="reset-btn" style={{ padding: 0 }}>
                        <img
                            style={{ cursor: 'pointer' }}
                            src={Images.remove}
                            alt="remove"

                        />
                    </button>
                </div>
            ),
            notClickable: true,
        },
    ];
};

export const ACTION_TYPE = 'GET_GLOBAL_NOTES';

export const noteNotYet = {
    title: 'No Notes Yet',
    subTitle: 'Keep important details here by adding a note.',
    image: Images.noNote,
}

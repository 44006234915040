import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clientActions, invoiceActions } from 'store';
import { BillFiltersSelectors, CreateChancel, FullTable, SinglePagination } from 'components';
import { FindLoad, PermissionList, RolePermission, useModal } from 'utils';
import { GENERATE_ACTION_TYPE, generateInvoiceBody, generateInvoiceHead } from './constants';

export const GenerateInvoice = () => {
    const [filters, setFilters] = React.useState({});
    const history = useHistory();
    const dispatch = useDispatch();
    const generateInvoiceLoader = FindLoad('GENERATE_INVOICE');
    const notInvoicedBills = useSelector((state) => state.invoice.billForGenerate);
    const [selectedList, setSelectedList] = useState([]);
    const { close } = useModal();

    useEffect(() => {
        const sendInfo = {
            ...filters,
        };
        delete sendInfo.page;
        sendInfo.skip = filters?.skip ? filters?.skip : 0;
        sendInfo.limit = 50;
        if (filters?.firstDate) {
            sendInfo.from = filters?.firstDate;
            delete sendInfo.firstDate;
        } else {
            delete sendInfo.from;
        }
        if (filters?.lastDate) {
            sendInfo.to = filters?.lastDate;
            delete sendInfo.lastDate;
        } else {
            delete sendInfo.to;
        }
        dispatch(invoiceActions.getInvoiceForGenerate(sendInfo));
    }, [filters]);

    const handleCheck = (ev, item, name) => {
        ev.stopPropagation();
        ev.preventDefault();
        if (name === 'all') {
            let allList = notInvoicedBills?.bills?.length === selectedList?.length ? [] : notInvoicedBills?.bills?.map((i) => i?.id);
            setSelectedList(allList);
        } else {
            const list = [...selectedList];
            const uniqSet = new Set(list);
            if (!uniqSet.has(item?.id)) {
                list.push(item?.id);
            } else {
                list.indexOf(item?.id) !== -1 && list.splice(list.indexOf(item?.id), 1);
            }
            setSelectedList(list);
        }
    };

    const changePage = (number) => {
        const params = {
            ...filters,
        };
        params.page = number;
        setFilters(params);
    };

    return (
        <>
            <div className='generate-modal'>
              <div>
                <div className='space-between' style={{marginBottom: 24}}>
                    <BillFiltersSelectors filters={filters} setFilters={setFilters} />
                </div>
                <FullTable
                    head={generateInvoiceHead(handleCheck, notInvoicedBills?.bills, selectedList)}
                    body={generateInvoiceBody(handleCheck, notInvoicedBills?.bills, selectedList)}
                    loadingType={GENERATE_ACTION_TYPE}
                    list={notInvoicedBills?.bills}
                    noText={'invoices'}
                    generateTable={true}
                    height={'medium'}
                />
              </div>
                <div className='space-between'>
                    {!!notInvoicedBills?.bills?.length ?
                        <SinglePagination page={filters?.page} count={notInvoicedBills?.count} changePage={changePage} />
                        :
                        <div/>
                    }
                    {RolePermission([PermissionList.INVOICE_CREATE?.code]) &&
                        <CreateChancel
                            classes='generate-or-cancel-btn-style'
                            loader={!!generateInvoiceLoader.length}
                            create={'Generate Invoices'}
                            chancel={'Cancel'}
                            onCreate={() =>
                                dispatch(invoiceActions.generateInvoice({ bills: selectedList, }),)
                            }
                            onClose={() => {
                                close();
                                history.push('/invoices')
                            }}
                            disabled={!selectedList?.length}
                        />
                    }
                </div>
            </div>
        </>
    );
};

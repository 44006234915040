import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { CreateChancel, Input } from 'components';
import { FindLoad, FindSuccessItem, useModal } from 'utils';
import { claimActions, httpRequestsOnSuccessActions } from 'store';

const ACTION_TYPE = 'EDIT_CLAIM';
export const EditClaim = ({ claim }) => {
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccessItem(ACTION_TYPE);
    const { close } = useModal();
    const { handleSubmit, watch, control, setError, clearErrors, reset } = useForm();

    useEffect(() => {
        if (claim) {
            reset({
                additionalClaimInfo: claim.additionalClaimInfo,
                resubmissionCode: claim.resubmissionCode,
                originalRefNumber: claim.originalRefNumber,
            });
        }
    }, [claim]);

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    const handleCreate = (date) => {
        if (date) {
            dispatch(claimActions.editClaim(claim?.id, date));
        }
    };

    return (
        <div style={{ width: 400 }}>
            <p className="modal-header-title">Edit Claim</p>
            <form onSubmit={handleSubmit(handleCreate)}>
                <div className="modal-body-wrapper">
                    <Input
                        name="additionalClaimInfo"
                        label="Reason (box 19)"
                        variant="outlined"
                        control={control}
                        maxLength={60}
                        rules={{
                            maxLength: {
                                value: 60,
                                message: 'Max length is 60 digits.',
                            },
                        }}
                    />
                    <Input
                        name="resubmissionCode"
                        label="Resubmission Code (Box 22)"
                        variant="outlined"
                        control={control}
                        maxLength={12}
                        rules={{
                            maxLength: {
                                value: 12,
                                message: 'Max length is 12 digits.',
                            },
                        }}
                    />
                    <Input
                        name="originalRefNumber"
                        label="Original Ref. No (Box 22)"
                        variant="outlined"
                        control={control}
                        maxLength={24}
                        rules={{
                            maxLength: {
                                value: 24,
                                message: 'Max length is 24 digits.',
                            },
                        }}
                    />
                </div>
                <CreateChancel
                    type="submit"
                    loader={!!loader.length}
                    create={'Save'}
                    chancel={'Cancel'}
                    onClose={close}
                    buttonWidth="100%"
                />
            </form>

        </div>
    );
};
import { Images, states } from 'utils';

export const enumValues = {

    // FS_STATUS: ["Active", "Inactive"],
    STAFF_STATUS: ['Active', 'Inactive'],
    CLIENT_STATUS: ['Active', 'FBS', 'On Hold', 'Waitlist', 'Terminated', 'Graduated'],

    // enum types

    FUNDING_SOURCE_TYPES: [
        { label: 'Private Insurance', id: 'PRIVATE' },
        { label: 'Public Insurance', id: 'PUBLIC' },
        { label: 'School', id: 'SCHOOL' },
    ],

    FUNDING_MODIFIER_TYPES: ['Direct', 'Indirect'],

    FUNDING_MODIFIER_SERVICE_TYPES: [
        { title: 'Direct', id: 'DIRECT' },
        { title: 'Indirect', id: 'INDIRECT' },
    ],
    // PAYMENT_TYPES: ["Check", "Ach"],
    CREATE_PAYMENT_TYPES: ['Check', 'Ach', 'Cash'],
    // end

    // enum statuses
    CLIENT_STATUSES: ['Active', 'FBA', 'On Hold', 'Waitlist', 'Terminated', 'Graduated'],


    STATUSES: ['Active', 'Inactive', 'On Hold', 'Terminated'],
    BILLING_STATUSES: ['Open', 'Close'],
    BILLING_TRANSACTION_TYPES: [
        'Payer Paid',
        'Client Resp',
        'Client Paid',
        'Partial Paid',
    ],
    BILLING_CLAIM_STATUSES: ['Claimed', 'Not Claimed', 'Complete'],
    BILLING_INVOICE_STATUSES: ['Invoiced', 'Not Invoiced', 'Complete'],
    CLAIM_STATUSES: ['Pending', 'Closed', 'Submitted', 'Posted'],
    INVOICE_STATUSES: ['Pending', 'Closed', 'Submitted', 'Posted'],
    PAYMENT_STATUSES: ['Open', 'Closed', 'Voided'],
    // end

    // enums for Staff
    RESIDENCIES: ['US', 'citizen', 'pcitizen', 'work', 'visa'],

    GENDER_OPTIONS: [
        { label: 'Male', id: 'MALE' },
        { label: 'Female', id: 'FEMALE' },
        { label: 'Other', id: 'OTHER' },
    ],
    EMPLOYMENT_TYPES: ['Full-time', 'Part-time', 'Intern', 'Contractor'],
    // end

    // other enums
    PAYMENT_TYPES: ['Hourly', 'Salary'],
    TIME_TYPES: ['Daily', 'Weekly', 'Consecutive'],
    CREDENTIAL_TYPES: ['Degree', 'Clearance', 'License'],
    // end
};

export const RenderStatusEnum = (type) => {
    switch (type) {
        case 'Active':
            return 'ACTIVE';
        case 'Inactive':
            return 'INACTIVE';
        case 'FBA':
            return 'FBA';
        case 'On_Hold':
            return 'ON_HOLD';
        case 'Waitlist':
            return 'WAIT_LIST';
        case 'Terminated':
            return 'TERMINATED';
        case 'Graduated':
            return 'GRADUATED';

        default:
            return '';
    }
};

export const FLAGGED_VALUES = {
    INCOMPLETE_UNIT: 'INCOMPLETE_UNIT',
    REMOVED_FROM_CLAIM: 'REMOVED_FROM_CLAIM',
    UPDATE_FLAG: 'UPDATE_FLAG',
    NOT_INCOMPLETE_UNIT : 'NOT_INCOMPLETE_UNIT',
};
export const FLAGGED_ENUMS = [
    { id: 'All', label: 'All' },
    { id: FLAGGED_VALUES.INCOMPLETE_UNIT, label: 'Partial Units', img: Images.flag },
    { id: FLAGGED_VALUES.UPDATE_FLAG, label: 'Appt. Edit', img: Images.yellowFlag },
    { id: FLAGGED_VALUES.REMOVED_FROM_CLAIM, label: 'Prev. Claim', img: Images.blueFlag },
];

export const APPT_FLAGGED_ENUMS = [
    { id: 'All', label: 'All' },
    { id: FLAGGED_VALUES.INCOMPLETE_UNIT, label: 'Partial Units', img: Images.flag },
    { id: FLAGGED_VALUES.REMOVED_FROM_CLAIM, label: 'Prev. Claim', img: Images.blueFlag },
];

export const PAYROLL_FLAGGED_ENUMS = [
    { id: 'All', label: 'All' },
    { id: FLAGGED_VALUES.NOT_INCOMPLETE_UNIT, label: 'Not Partial' },
    { id: FLAGGED_VALUES.INCOMPLETE_UNIT, label: 'Partial Units', img: Images.yellowFlag  },
];

export const FLAGGED = 'FLAGGED';
export const UNFLAGGED = 'UNFLAGGED';
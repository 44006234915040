import { Images } from '../../../../utils';

export function getFilteredClaimPayments(claimPmts = [], selFunder, selStatus) {
   const makeLowC = (value = "") => value?.toLowerCase();

   return claimPmts.filter((claimPmt) => {
      const funderName = claimPmt?.fundingSource?.name;
      const status = claimPmt?.status;

      if (selFunder === "All" && selStatus === "All") return true;

      if (selFunder !== "All" && selStatus === "All")
         return makeLowC(funderName) === makeLowC(selFunder);

      if (selFunder === "All" && selStatus !== "All")
         return makeLowC(status) === makeLowC(selStatus);

      if (selFunder !== "All" && selStatus !== "All")
         return (
            makeLowC(funderName) === makeLowC(selFunder) &&
            makeLowC(status) === makeLowC(selStatus)
         );

      return false;
   });
}


export const claimEnums = {
   PENDING: 'PENDING',
   OPEN: 'OPEN',
   CLOSED: 'CLOSED',
   APPEAL: 'APPEAL',
   COMPLETED: 'COMPLETED',
   COMPLETE: 'COMPLETE',
   PAID: 'PAID',
}

export const claimPaymentNotYet = {
   title: 'No Claim Payments',
   subTitle: 'There are no claim payments.',
   image: Images.noPendingClaim,
}

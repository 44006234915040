import { makeStyles } from '@material-ui/core/styles';
import { Backgrounds, Colors, Shadow } from 'utils';

const drawerWidth = 100;
export const navBarStyles = makeStyles((theme) => ({
    online: {
        width: '12px',
        height: '12px',
        borderRadius: '50px',
        border: '1.5px solid #FFF',
        background: '#12B76A',
        position: 'absolute',
        bottom: '0',
        right: '0',
    },
    root: {
        display: 'flex',
    },
    transitionClose: {
        marginLeft: '50px'
    },

    transitionOpen: {
        marginLeft: '165px',
        '@media (min-width: 1919px)': {
            marginLeft: '203px',
        },

        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    drawerBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    appBar: {
        background: `${Colors.BackgroundWhite} 0% 0% no-repeat padding-box`,
        boxShadow: Shadow.menuShadow,
        opacity: 1,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        '& > div': {
            width: '100%',
            minHeight: '63px',
            paddingLeft: '40px',
            paddingRight: '36px',
            '@media (max-width: 1280px)': {
                paddingLeft: '32px',
                paddingRight: '24px',
            },
        },
    },

    appBarShift: {
        marginLeft: drawerWidth,
        width: '100%',
        transition: theme.transitions.create(['width', 'margin', 'padding-left'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        // '& > div': {
        //     paddingLeft: '16px',
        //     '@media (max-width: 1280px)': {
        //         paddingLeft: '247px',
        //     },
        // },
    },
    expandCollapseButtonBox:{
        position: 'fixed',
        top: '50px',
        zIndex:1202,
    },
    IconButtonStyle: {
        height: '32px',
        width: '32px',
        color: Colors.BackgroundWhite,
        border: `2px solid white`,
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        background: `${Colors.BackgroundWhite} 0% 0% no-repeat padding-box`,
        boxShadow: Shadow.menuShadow,
        opacity: '1',
        width: '184px',
        zIndex: 1201,
        overflowX: 'hidden',
        border: 'none',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '@media (min-width: 1919px)': {
            width: '220px',
        },
    },
    drawerClose: {
        zIndex: 1201,
        background: `${Colors.BackgroundWhite} 0% 0% no-repeat padding-box`,
        boxShadow: Shadow.menuShadow,
        opacity: '1',
        width: '64px',
        border: 'none',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
    },
    Toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    openToolbar: {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: '170px',
        '@media (min-width: 1919px)': {
            marginLeft: '206px',
        },
    },
    closeToolbar: {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: '50px',
    },
    content: {
        flexGrow: 1,
        padding: '88px 24px 24px 24px',
        paddingBottom: '40px',
        height: '100vh',
        background: `${Colors.BackgroundSecondary} 0% 0% no-repeat padding-box`,

        // '@media (min-width: 1919px)': {
        //     padding: '104px 32px 24px 32px',
        // },
    },

    headerContent: {
        display: 'flex',
        justifyContent: 'flex-end',
    },

    menuItems: {
        marginTop: '24px',
        paddingTop: 0,
        borderBottom: '1px solid #F2F4F8',
        '& .MuiListItemText-root': {
            margin: '0',
        },
        '& a': {
            textDecoration: 'none',
        },
    },

    boxWrapper: {
        display: 'flex',
        alignItems: 'center',
    },

    userInfo: {
        position: 'relative',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',

        '& img': {
            width: '40px',
            height: '40px',
            border: '1px solid white',
            borderRadius: '24px',
        },
    },

    userInfoText: {
        width: '150px',
        fontSize: '14px',
        lineHeight: '19px',
        color: Colors.TextSecondary,
        opacity: '1',
        marginLeft: '12px',
        fontFamily: 'Open Sans, sans-serif',
    },

    logOutInfo: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '54px',
        '& img': {
            width: '18px',
            height: '18px',
        },
    },

    logOut: {
        textAlign: 'right',
        font: 'normal normal normal 12px/17px',
        letterSpacing: '0',
        color: '#FFFFFF',
        opacity: '1',
        paddingLeft: '8px',
    },

    userActionsBoxStyle: {
        display: 'flex',
        alignItems: 'center',
    },

    myProfileDrawerStyle: {
        '& div.MuiBackdrop-root': {
            opacity: '0 !important',
        },
        '& div.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight.MuiPaper-elevation16':
            {
                top: '20px',
                right: '22px',
                width: '395px',
                height: 'auto',
                overflowY: 'unset',
            },
    },
    companyInfoBox: {
        height: '64px',
        minHeight: '64px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 10px 0 16px',
        borderBottom: '1px solid #EDEEF3',
        '& p': {
            marginLeft: '8px',
            color: '#172B4D',
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: '600',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'initial',
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            lineHeight: '21px',
        },
    },

    companyBox: {
        minWidth: '28px',
        width: '28px',
        height: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        background: '#347AF0',
    },

    companyLogo: {
        width: '32px',
        minWidth: '32px',
        height: '32px',
        borderRadius: '4px',

        '& img': {
            width: '32px',
            minWidth: '32px',
            height: '32px',
            borderRadius: '4px',
            objectFit: 'cover',
            border: '1px solid #E4E7EC',
        },
    },

    borderTop: {
        height: '1px',
        background: ' rgba(235, 242, 253, 0.80)',
    },
    companyInfoWrapper: {
        display: 'flex',
        alignItems: 'center',
    },

    helpCenterBox: {
        padding: '8px 10px',
    },
    helpCenterButton: {
        borderRadius: '4px',
        background: '#F2F4F8',
        width: '100%',
        padding: '4px 8px',
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        border: 'none',
        '& div': {
            width: '28px',
            height: '28px',
            borderRadius: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'white',
        },
        '& p': {
            color: '#0A1B39',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: 'normal',
        },
    },
    getMenuTitle: {
        color: '#172B4D',
        fontSize: '16px',
        fontWeight: '600',
    },

}));

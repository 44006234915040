import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import { paginationStyle } from './style';


export const SinglePagination = ({ changePage, page, count  }) => {
    const classes = paginationStyle();
    const limitCountNumber = 50;

    return (
        <div className={classes.singlePaginationWrapper}>
            <Pagination
                onChange={(event, val) => changePage(val)}
                page={page}
                count={ count && Math.ceil(count / limitCountNumber)}
                color={'primary'}
            />
        </div>
    )
}
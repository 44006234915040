import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TableWrapper, FullTable } from 'components';
import { RolePermission, PermissionList, useModal } from 'utils';
import { CreateFundingSource } from 'fragments';
import { fundingSourceActions } from 'store';
import { ACTION_TYPE, fsBody, fsHead, fsNotYet } from './constants';

export const FundingSource = () => {
    const fundingSourceList = useSelector((state) => state.fundingSource.fundingSourceList);
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const { open } = useModal();

    const getFsSources = () => {
        const filteredInfo = {
            ...info,
        };
        filteredInfo.skip = info?.skip ? info?.skip : 0;
        filteredInfo.limit = 50;

        if (info?.status === 'ALL') {
            delete filteredInfo.status;
        } else if (info?.status) {
            filteredInfo.status = info?.status;
        }
        delete filteredInfo.page;
        delete filteredInfo.activeTab;
        delete filteredInfo.name;
        dispatch(fundingSourceActions.getFundingSource(filteredInfo));

        return filteredInfo;
    };

    useEffect(() => {
        getFsSources();
    }, [info]);


    const handleNavigate = (id) => {
        if (RolePermission([PermissionList.FS_READ?.code])) {
            history.push(`/fundingSource/${id}`);
        }
    };

    return (
        <TableWrapper
            buttonsTab={true}
            buttonsTabAddButton={RolePermission([PermissionList.FS_CREATE?.code])}
            addButtonText={'Add Funding Source'}
            handleOpenClose={() => open(<CreateFundingSource closeToSuccess={getFsSources} />)}
            csvType={'fundingSource'}
        >
            <FullTable
                head={fsHead}
                body={fsBody}
                loadingType={ACTION_TYPE}
                list={fundingSourceList?.funders}
                listCount={fundingSourceList?.count}
                handleClick={handleNavigate}
                notYet={fsNotYet}
            />
        </TableWrapper>
    );
};

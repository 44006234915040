import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { claimActions, httpRequestsOnSuccessActions } from 'store';
import { FullTable, MinLoader } from 'components';
import { ACTION_TYPE, pendingClaimHead, pendingClaimBody, pendingClaimNotYet } from './constants';
import {
    Colors,
    FindLoad,
    FindSuccessItem,
    FLAGGED,
    FLAGGED_VALUES,
    PermissionList,
    RolePermission,
    useModal,
} from 'utils';
import { ClaimFilters } from './claimFilters';

const SUBMIT_CLAIM_TYPE = 'SUBMIT_CLAIM';
const GENERATE_ACTION = 'GENERATE_CLAIM';

export const PendingClaims = () => {
    const { close } = useModal();
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const claimsData = useSelector((state) => state.claim.claims);
    const [selectedList, setSelectedList] = useState([]);
    const loader = FindLoad(SUBMIT_CLAIM_TYPE);
    const success = FindSuccessItem(SUBMIT_CLAIM_TYPE);
    const filteredList = claimsData?.claims?.filter((i) => (!i?.incompleteUnitFlag && !i?.updateFlag));
    const generateClaimSuccess = FindSuccessItem(GENERATE_ACTION);
    const renderParams = () => {
        const sendInfo = {
            ...info,
        };
        delete sendInfo.page;
        sendInfo.skip = info?.skip ? info?.skip : 0;
        sendInfo.limit = 50;
        if (info?.firstDate) {
            sendInfo.from = info?.firstDate;
            delete sendInfo.firstDate;
        } else {
            delete sendInfo.from;
        }
        if (info?.lastDate) {
            sendInfo.to = info?.lastDate;
            delete sendInfo.lastDate;
        } else {
            delete sendInfo.to;
        }
        return sendInfo;
    };

    const getPendingClaim = (loading) => {
        dispatch(claimActions.getPendingClaims({ ...renderParams(), loading }));
    };

    useEffect(() => {
        getPendingClaim();
    }, [info]);

    useEffect(() => {
        if (generateClaimSuccess) {
            getPendingClaim('noLoad');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(GENERATE_ACTION));
            close()
        }
        if (success?.type === SUBMIT_CLAIM_TYPE) {
            getPendingClaim('noLoad');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(SUBMIT_CLAIM_TYPE));
            setSelectedList([]);
        }
    }, [generateClaimSuccess, success]);


    const handleCheck = (ev, item, name) => {
        if (name === 'all') {
            let allList = filteredList?.length === selectedList?.length ? [] : filteredList?.map((i) => i?.id);
            setSelectedList(allList);
        } else {
            const list = [...selectedList];
            if (ev.target.checked) {
                list.push(item?.id);
            } else {
                list.indexOf(item?.id) !== -1 && list.splice(list.indexOf(item?.id), 1);
            }
            setSelectedList(list);
        }
    };

    const submitClaims = () => {
        dispatch(claimActions.submitClaim(selectedList));
    };

    return (
        <div>
            <ClaimFilters type="pending" />
            {!!selectedList?.length &&
                RolePermission([PermissionList.CLAIMS_UPDATE?.code]) &&
                <div className="submit-claim-box">
                    <p className="submit-claim-text">{
                        `All ${selectedList?.length} pending claims on this page are selected.`
                    }
                    </p>
                    <button className="submit-claim-button" onClick={submitClaims}>
                        {loader?.length ?
                            <MinLoader margin={'0'} color={Colors.BackgroundBlue} position={'relative'} />
                            :
                            'Submit Selected Claims'
                        }
                    </button>
                </div>
            }
            <div className="pending-claim-box">
                <FullTable
                    head={pendingClaimHead(handleCheck, filteredList, selectedList)}
                    body={pendingClaimBody(handleCheck, claimsData?.claims, selectedList, history)}
                    loadingType={ACTION_TYPE}
                    list={claimsData?.claims}
                    listCount={claimsData?.count}
                    handleClick={(id) =>
                        history.push(`/claim/${id}`, {
                            from: 'pending',
                        })
                    }
                    notYet={pendingClaimNotYet}
                    activeRowId={''}
                />
            </div>
        </div>
    );
};

import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    DateRow,
    MinLoader,
    ModalContentWrapper,
    NoItemText,
    PriceRow,
    SimpleModal,
    SimpleTooltip, TextRow, PriceInput, CustomDelete, DateRowStartEnd, UnitsRow, LinkRow, NoteDrawer,
} from 'components';
import { claimPaymentActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { FindLoad, FindSuccess, Images, PermissionList, RolePermission, useModal } from 'utils';
import { claimPostStyles } from './styles';
import { AppealInputs } from './appealInputs';
import { Drawer } from '@material-ui/core';
import { claimEnums } from '../../constants';
import { headerList } from './constants';

const styleNoBorder = {
    width: '10%',
    borderBottom: `1px solid #FE7070`,
    marginBottom: '-1px',
};

const EDIT_CLAIM_ACTION_TYPE = 'EDIT_CLAIM_PAYMENT_INFO';
const NOT_GREATER = 'NOT_GREATER';
const PAY_IN_FULL_CLAIM = 'PAY_IN_FULL_CLAIM';
const PAY_IN_FULL_CLAIM_BILLING = 'PAY_IN_FULL_CLAIM_BILLING';
const APPEAL_CLAIM_PAYMENT = 'APPEAL_CLAIM_PAYMENT';
export const ClaimPaymentBody = ({ item, activeRow, handleEdit, setActiveRow }) => {
    const history = useHistory();
    const info = history?.location?.state;
    const tab = info?.tabType ? info?.tabType : 'OPEN';
    const classes = claimPostStyles();
    const dispatch = useDispatch();
    const [selectedId, setSelectedId] = useState('');
    const [inputs, setInputs] = useState({});
    const [error, setError] = useState('');
    const [openAppeal, setOpenAppeal] = useState(false);
    const currentInput = useRef();
    const success = FindSuccess(EDIT_CLAIM_ACTION_TYPE);
    const loafSave = FindLoad(EDIT_CLAIM_ACTION_TYPE);
    const [activeState, setActiveState] = useState('OPEN');
    const { open } = useModal();
    const [openNotes, setOpenNotes] = useState(false);

    const style = {
        width: '10%',
        borderBottom: `1px solid ${activeRow === item?.id ? '#347AF0' : '#D8E5FF'}`,
        marginBottom: '-1px',
    };

    useEffect(() => {
        if (info?.tabType) {
            setActiveState(info?.tabType);
            if (activeState !== info?.tabType) {
                setSelectedId('');
                setInputs({});
                setError('');
                setActiveRow('');
            }
        }
    }, [info?.tabType]);

    useEffect(() => {
        if (success?.length) {
            handleEdit('');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(EDIT_CLAIM_ACTION_TYPE));
        }
    }, [success]);

    const handleChange = (e, key, item) => {
        dispatch(httpRequestsOnErrorsActions.removeError(NOT_GREATER));
        const allObj = [...inputs];
        const current = allObj[key];
        const value = e.target.value;
        const convertedValue = parseFloat(value.replace(/[$,]/g, ''));
        const deductible = e?.target?.name === 'deductible' ? convertedValue : current?.deductible;
        const copay = e?.target?.name === 'copay' ? convertedValue : current?.copay;
        const coIns = e?.target?.name === 'coIns' ? convertedValue : current?.coIns;
        const paidAMT = e?.target?.name === 'paidAMT' ? convertedValue : current?.paidAMT;

        // if (e.target.name === 'paymentRef') {
        //     allObj[key][e?.target?.name] = e.target.value;
        //     setInputs(allObj);
        // } else {
        if (item?.allowedAMT >= (deductible + copay + coIns + paidAMT)) {
            setError('');
            allObj[key][e?.target?.name] = convertedValue;
            setInputs(allObj);
        } else {
            setError('notAllowed');
        }
        // }
    };


    const openAppealFn = (id) => {
        setSelectedId(id);
        setOpenAppeal(true);
    };

    const payInFullClaim = (id, item) => {
        const body = {
            id: id,
            status: tab,
        };
        // if (currentRef?.value) {
        //     body.paymentRef = { paymentRef: currentRef?.value };
        // } else if (item?.paymentRef) {
        //     body.paymentRef = { paymentRef: item?.paymentRef };
        // }
        setSelectedId(id);
        dispatch(claimPaymentActions.payInFullClaim(body, tab));
    };

    const payInFullClaimBill = (id, billId, item) => {
        // const current = inputs?.length && inputs?.find((i) => i.id === item?.id);
        const body = {
            id: id,
            billId: billId,
            status: tab,
        };
        // if (current?.paymentRef) {
        //     body.paymentRef = { paymentRef: current?.paymentRef };
        // } else if (item?.paymentRef) {
        //     body.paymentRef = { paymentRef: item?.paymentRef };
        // }
        setSelectedId(billId);
        dispatch(claimPaymentActions.payInFullClaimBill(body));
    };

    const handleClose = () => {
        handleEdit('');
        setError('');
        setInputs({});
    };

    const handleSave = (id) => {
        const body = {
            status: tab,
            id: id,
            body: {
                'claimBills': [
                    ...inputs,
                ],
            },
        };
        if (error !== 'notAllowed') {
            dispatch(claimPaymentActions.editClaimPaymentInfo(body));
        } else {
            dispatch(httpRequestsOnErrorsActions.appendError(NOT_GREATER));
        }
    };

    const handleEditFn = async (id) => {
        await handleEdit(id);
        const claimBills = [];
        await item?.bills.forEach((i) => {
            claimBills.push({
                    'id': i?.id,
                    'allowedAMT': i?.allowedAMT,
                    'deductible': i?.deductible,
                    'copay': i?.copay,
                    'coIns': i?.coIns,
                    'paidAMT': i?.payorPaid,
                },
            );
        });
        setInputs(claimBills);
        currentInput?.current?.focus();
    };

    const checkAppealOrPay = item?.status === claimEnums.APPEAL ?
        RolePermission([PermissionList.CLAIM_PMT_APPEAL_PAY?.code])
        :
        RolePermission([PermissionList.CLAIM_PMT_PAY?.code]);

    return (
        <div className={classes.claimPaymentBodyWrapper}>
            <div className={classes.claimPaymentBodyHead}>
                <div className={classes.claimPaymentBodyHeadItem} style={{ width: '10%' }}>
                    <LinkRow
                        name={item?.displayId ? item?.displayId : 'N/A'}
                        href={RolePermission([PermissionList.CLAIMS_READ?.code]) ? `/billedClaim/${item?.id}` : ''}
                        checkPermission={item?.id ? RolePermission([PermissionList.CLAIMS_READ?.code]) : null}
                    />
                </div>
                <div className={classes.claimPaymentBodyHeadItem} style={{ width: '20%' }}>
                    <DateRowStartEnd start={item?.dos?.early} end={item?.dos?.latest} />
                </div>
                <div className={classes.claimPaymentBodyHeadItem} style={{ width: '20%' }}>
                    {item?.funderName ? item.funderName : 'N/A'}
                </div>
                <div className={classes.claimPaymentBodyHeadItem} style={{ width: '20%' }}>
                    {item?.clientFullName ? item.clientFullName : 'N/A'}
                </div>
                <div className={classes.claimPaymentBodyHeadNoPaddings} style={{ width: '30%' }}>
                    <div className={classes.claimPaymentBodyHeadItemButtons} style={{ justifyContent: 'flex-end' }}>

                        <button className={classes.editButton} onClick={() => setOpenNotes(true)}>
                            <img src={Images.note} alt="icon" />
                        </button>

                        {activeRow === item?.id ?
                            <div className={classes.buttonsWrapper}>
                                <button
                                    onClick={() => handleSave(item?.id)}
                                    className="save-btn-box"
                                >
                                    {loafSave?.length ?
                                        <MinLoader margin={'0'} color={'#344054'} position="relative" />
                                        :
                                        'Save'
                                    }
                                </button>
                                <button onClick={handleClose} className="posting-close-btn">
                                    <img src={Images.remove} alt="icon" />
                                </button>
                            </div>
                            :
                            (checkAppealOrPay &&
                                <button
                                    onClick={() => handleEditFn(item?.id)}
                                    className={classes.editButton}
                                >
                                    <img src={Images.edit} alt="edit" />
                                </button>
                            )
                        }
                        {
                            item?.status === claimEnums.CLOSED ?
                                <div className={classes.closed}>
                                   Closed
                                </div>
                                :
                                (item?.status === claimEnums.COMPLETED || item?.status === claimEnums.PAID) ?
                                    <div className={classes.paidStatusText}>
                                        <p>Paid</p>
                                    </div>
                                    :
                                    ((item?.status === claimEnums.APPEAL || item?.status === claimEnums.OPEN) && checkAppealOrPay &&
                                        <button
                                            className={classes.payInFull}
                                            onClick={() => open(
                                                <CustomDelete
                                                    text="Are you sure you want to pay in full this claim?"
                                                    info="Pay in full"
                                                    handleDel={() => payInFullClaim(item?.id, item)}
                                                    handleClose={() => close()}
                                                    actionType={PAY_IN_FULL_CLAIM}
                                                    color={'blue'}
                                                    innerText={'Confirm'}
                                                />,
                                            )}
                                        >
                                            Pay in Full
                                        </button>
                                    )
                        }

                        {item?.status === claimEnums.OPEN && RolePermission([PermissionList.CLAIM_PMT_SEND_APPEAL?.code]) ?
                            <button onClick={() => openAppealFn(item?.id)} className={classes.appeal}>
                                Appeal
                            </button>
                            : ''
                        }

                        {item?.status === claimEnums.APPEAL &&
                            <SimpleTooltip
                                title={<p>{item?.appealDetails}</p>}
                                placement="top-end"
                            >
                                <img src={Images.aboutInformation} alt="icon" />
                            </SimpleTooltip>
                        }
                    </div>
                </div>
            </div>

            <div className={classes.bodyItemWrapper}>
                <div className={classes.bodyItemHead}>
                    {headerList?.map((i, j) => i && (
                        <div key={j} style={{ width: i?.width }} className={classes.bodyItemHeadRow}>
                            <TextRow name={i?.name} />
                        </div>
                    ))}
                </div>


                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {item?.bills?.length ?
                        item?.bills?.map((i, j) => {
                            return (
                                <div key={j} className={classes.bodyItemBody}>
                                    <div style={{ width: '8%' }} className={classes.bodyItemBodyRow}>
                                        <DateRow date={i?.dos} />
                                    </div>
                                    <div style={{ width: '10%' }} className={classes.bodyItemBodyRow}>
                                        <TextRow
                                            name={`${i?.cptCode} (${i?.chargeRate?.name ? i?.chargeRate?.name : 'N/A'})`}
                                        />
                                    </div>
                                    <div style={{ width: '5%' }} className={classes.bodyItemBodyRow}>
                                        <UnitsRow info={i?.totalUnits} />
                                    </div>
                                    <div style={{ marginBottom: '-1px', width: '10%' }}
                                         className={classes.bodyItemBodyRow}>
                                        <p className={classes.totalBilled} style={{ width: '100%' }}>
                                            <PriceRow info={i?.totalBilled} />
                                        </p>
                                    </div>
                                    <div style={{ ...style }} className={classes.bodyItemBodyRow}>
                                        <PriceInput
                                            ref={currentInput}
                                            placeholder={inputs[j]?.allowedAMT === 0 ? '0' : '0'}
                                            name={'allowedAMT'}
                                            handleChange={(e) => handleChange(e, j, i)}
                                            value={activeRow === item?.id ? inputs[j]?.allowedAMT === 0 ? 0 : inputs[j]?.allowedAMT : i?.allowedAMT ? i?.allowedAMT : 0}
                                            disabled={activeRow !== item?.id}
                                        />
                                    </div>
                                    <div style={error === 'notAllowed' ? { ...styleNoBorder } : { ...style }}
                                         className={classes.bodyItemBodyRow}>
                                        <PriceInput
                                            ref={currentInput}
                                            placeholder={inputs[j]?.deductible === 0 ? '0' : ''}
                                            name={'deductible'}
                                            handleChange={(e) => handleChange(e, j, i)}
                                            value={activeRow === item?.id ? inputs[j]?.deductible === 0 ? 0 : inputs[j]?.deductible : i?.deductible ? i?.deductible : 0}
                                            disabled={activeRow !== item?.id}
                                        />
                                    </div>
                                    <div style={error === 'notAllowed' ? { ...styleNoBorder } : { ...style }}
                                         className={classes.bodyItemBodyRow}>
                                        <PriceInput
                                            ref={currentInput}
                                            placeholder={inputs[j]?.copay === 0 ? '0' : ''}
                                            name={'copay'}
                                            handleChange={(e) => handleChange(e, j, i)}
                                            value={activeRow === item?.id ? inputs[j]?.copay === 0 ? 0 : inputs[j]?.copay : i?.copay ? i?.copay : 0}
                                            disabled={activeRow !== item?.id}
                                        />
                                    </div>
                                    <div style={error === 'notAllowed' ? { ...styleNoBorder } : { ...style }}
                                         className={classes.bodyItemBodyRow}>
                                        <PriceInput
                                            ref={currentInput}
                                            placeholder={inputs[j]?.coIns === 0 ? '0' : ''}
                                            name={'coIns'}
                                            handleChange={(e) => handleChange(e, j, i)}
                                            value={
                                                activeRow === item?.id ? inputs[j]?.coIns === 0 ? 0 :
                                                    inputs[j]?.coIns : i?.coIns ? i?.coIns : 0
                                            }
                                            disabled={activeRow !== item?.id}
                                        />
                                    </div>
                                    <div style={error === 'notAllowed' ? { ...styleNoBorder } : { ...style }}
                                         className={classes.bodyItemBodyRow}>
                                        <PriceInput
                                            ref={currentInput}
                                            placeholder={inputs[j]?.paidAMT === 0 ? '0' : ''}
                                            name={'paidAMT'}
                                            handleChange={(e) => handleChange(e, j, i)}
                                            value={
                                                activeRow === item?.id ? inputs[j]?.paidAMT === 0 ? 0
                                                    : inputs[j]?.paidAMT : i?.payorPaid ? i?.payorPaid : 0
                                            }
                                            disabled={activeRow !== item?.id}
                                        />
                                    </div>
                                    <div style={{ width: '7%' }} className={classes.bodyItemBodyRow}>
                                        <PriceRow info={i?.payorBalance}/>
                                    </div>
                                    <div style={{ width: '8%' }} className={classes.bodyItemBodyRow}>
                                        {i?.claimStatus === claimEnums.COMPLETE ?
                                            <div className={classes.paidStatus}>
                                                <img src={Images.darkGrayDone} alt="mark" />
                                                <p>Paid</p>
                                            </div>
                                            :
                                            (checkAppealOrPay &&
                                                <button className={classes.payInFullBill}
                                                        onClick={() => open(<CustomDelete
                                                                text="Are you sure you want to pay in full this bill?"
                                                                info="Pay in full"
                                                                handleDel={() => payInFullClaimBill(item?.id, i?.id, i)}
                                                                handleClose={() => close()}
                                                                actionType={PAY_IN_FULL_CLAIM_BILLING}
                                                                color={'blue'}
                                                                innerText={'Confirm'}
                                                            />,
                                                        )}
                                                >
                                                    Pay in Full
                                                </button>
                                            )
                                        }
                                    </div>
                                </div>
                            );
                        })
                        :
                        <NoItemText text="No bills yet" />
                    }
                </div>
            </div>

            <SimpleModal
                openDefault={openAppeal}
                handleOpenClose={() => setOpenAppeal((prevState) => !prevState)}
                content={
                    <ModalContentWrapper
                        textAlign={'start'}
                        wrapperClassName={classes.closeClaimWrapperStyle}
                        onClose={() => setOpenAppeal(false)}
                        titleContent={'Appeal this claim?'}
                        subtitleContent={
                            'Please provide a reason for appealing this claim.'
                        }
                    >
                        <AppealInputs
                            closeModal={() => {
                                setOpenAppeal(false);
                                setSelectedId('');
                            }}
                            claimId={selectedId}
                            tab={tab}
                        />
                    </ModalContentWrapper>
                }
                secondary={true}
            />

            <Drawer
                className={'note-drawer-modal'}
                anchor={'right'}
                open={openNotes}
                onClose={() => setOpenNotes(false)}
            >
                <NoteDrawer
                    handleClose={() => setOpenNotes(false)}
                    currentId={item?.id}
                    onModel={'Claim'}
                    text={'Claim'}
                    title={'Claim'}
                />
            </Drawer>
        </div>
    );
};
import { makeStyles } from '@material-ui/core/styles';
import { Backgrounds, Colors } from 'utils';

export const scheduleModalsStyle = makeStyles(() => ({
    editTemplate: {
        display:'flex',
        alignItems:'center',
        gap:'6px',
        cursor: 'pointer',

        '& span': {
            color: '#347AF0',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '19px',
            textDecoration: 'underline',
            whiteSpace: 'nowrap',
        }
    },
    characters: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '4px',
        '& p': {
            color: '#4B5C68',
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '17px',
        },
    },
    actionBtnBox: {
        marginTop: '40px',
    },
    recurModalWrapper: {
        padding:'0 4px',
        width: '620px',
        overflowX: 'hidden',
    },
    stepperBtns: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',

        '& button': {
            border: 'none',
            background: 'transparent',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '22px',
            color: '#4B5C68',
            marginBottom: '16px',
            position: 'relative',
            width: '140px',
            minWidth: '140px',
            '& img': {
                width: '40px',
                height: '40px',
            },

            '& p': {
                position: 'absolute',
                whiteSpace: 'nowrap',
                marginTop: '16px',
            },
        },
    },
    lineBox: {
        width: '100%',
        borderBottom: '2px dotted rgba(81, 86, 109, 0.50)',
        marginBottom: '24px',
    },
    serviceModalBox: {
        marginTop: '24px',
    },
    multipleServiceNote: {
        width: '100%',
        marginRight: '32px',
        display: 'flex',
        alignItems: 'flex-start',
        borderRadius: '4px',
        background: '#F8FAFB',
        textAlign: 'start',
        padding: '8px 10px',
        '& p': {
            marginLeft: '12px',
            color: '#4B5C68',
            lineHeight: '20px',
        },
        '& b': {
            fontWeight: 600,
        },
    },
    stepBox: {
        marginTop: '40px',
        height: '80px',
    },
    modalWrapper: {
        width: '480px',
        height: 'auto',
        background: Backgrounds.whiteModal,
        borderRadius: '8px',
    },
    subTitle: {
        fontSize: '16px',
        color: Colors.TextSecondary,
    },
    typesWrapper: { marginTop: '40px' },
    typesItem: {
        height: '48px',
        background: Backgrounds.blue,
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 16px',
        marginBottom: '16px',
        cursor: 'pointer',
        '& p': {
            fontSize: '16px',
            fontWeight: '600',
            color: 'white',
        },
    },
    breakWrapper: { marginTop: '24px' },
    timeInputs: { display: 'flex' },
    startTime: {
        marginRight: '16px',
        width: '100%',
        '& .MuiFormLabel-root': {
            fontSize: '16px',
            color: `${Colors.TextPrimary}`,
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: `${Colors.ThemeRed}`,
        },
    },
    bigModal: {
        width: '920px',
        padding: '32px',
        borderRadius: '8px',
        backgroundColor: 'white',
        position: 'relative',
        '@media (max-width: 1400px)': {
            width: '896px',
        },
    },
    serciveModall: {
        display: 'flex',
        textAlign: 'center',
        flexDirection: 'column',
        width: '100%',
    },
    signatureStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: '16px',
        '& p': {
            fontSize: '14px',
            fontWeight: '600',
            color: Colors.TextSecondary,
            width: '135px',
            textAlign: 'start',
            marginLeft: '4px',
            marginBottom: '2px',
        },
    },
    serviceModalWrapper: {
        display: 'flex',
        width: '100%',
    },

    serviceInputsWrapper: {
        width: '100%',
        marginRight: '38px',
        '@media (max-width: 1280px)': {
            marginRight: '32px',
        },
    },
    infoModalHead: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& p': {
            fontSize: '18px',
            fontWeight: 'bold',
            color: Colors.TextSecondary,
        },
        '& button': {
            border: 'none',
            outline: 'none',
            background: 'transparent',
        },
        '& button:first-of-type': {
            marginRight: '16px',
        },
    },
    modalDate: {
        fontSize: '14px',
        fontWeight: '600',
        color: Colors.TextSecondary,
        '& span': {
            marginLeft: '16px',
            marginTop: '8px',
        },
    },
    infoModalBody: { marginTop: '32px' },
    recurBody: {
        marginTop: '40px',
        textAlign: 'start',
    },
    dayWeekMounth: {
        textAlign: 'start',
        borderRadius: '8px',
        background: '#FFF',
        boxShadow: '0px 0px 6px 0px rgba(52, 122, 240, 0.20)',
        // background: Backgrounds.whiteModal,
        // boxShadow: '0px 0px 6px #347AF03D',
        // borderRadius: '4px',
        padding: '16px',
        height: 'auto',
        margin: '24px 0 24px 0',

    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    occurance: {
        height: '36px',
        background: '#EBF2FD 0% 0% no-repeat padding-box',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 16px',
        marginBottom: '24px',
        '& p': {
            fontSize: '14px',
            color: Colors.TextSecondary,
        },
        '& span': {
            fontSize: '14px',
            color: Colors.TextSecondary,
            fontWeight: 'bold',
            marginLeft: '8px',
        },
    },

    warningWrapper: {
        padding: '10px 16px',
        background: '#FFF6EA 0% 0% no-repeat padding-box',
        borderRadius: '8px',
        marginBottom: '12px',
        display: 'flex',
        alignItems: 'center',

        '& p': {
            marginLeft: '8px',
            color: Colors.TextPrimary,
            fontSize: '14px',
        },
    },
    loaderWrapper: {
        width: '100%',
        height: '598px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    warning: {
        display: 'flex',
        alignItems: 'center',
        margin: '16px 0 0',
        background: 'rgba(255, 163, 48, 0.1)',
        borderRadius: '4px',
        padding: '8px',
        width: '100%',
        textAlign: 'start',
        '& img': {
            marginRight: '8px',
        },
        '& p': {
            fontWeight: '400',
            fontSize: '16px',
            color: '#51566D',
            lineHeight: '24px',
        },
    },

    informationWrapper: {
        borderRadius: '4px',
        background: 'rgba(235, 242, 253, 0.50)',
        width: '100%',
        height: 'auto',
        padding: '16px',
        marginBottom: '16px',
    },
    titleTextWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '16px',
    },
    informationTitle: {
        color: '#4B5C68',
        fontSize: '14px',
        fontWeight: 600,
        marginRight: '8px',
    },
    informationBody: {
        display: 'flex',
        flexWrap: 'wrap',
        '& p': {
            color: 'rgba(75, 92, 104, 0.70)',
            fontSize: '14px',
            fontWeight: '400',
            textAlign: 'start',
            lineHeight: '22px',
            marginTop: '-4px',
        },
    },

    lockUnlockWrapper: {
        width: '450px',
        maxHeight: '80vh',
    },
    confirmTitle: {
        color: '#4B5C68',
        fontSize: '32px',
        fontWeight: '700',
        lineHeight: '43px',
        marginBottom: '16px',
    },
    confirmSubTitle: {
        color: '#4B5C68',
        fontSize: '16px',
        lineHeight: '24px',
        marginBottom: '40px',
    },
    noteModalSubtitle: {
        color: '#4B5C68',
        fontSize: '16px',
        fontWeight: '400',
        margin: '16px 0 40px',
    },
    noteModalArea: {
        width: '600px',
        height: '400px',
        borderRadius: '4px',
        border: '1px solid #347AF0',
        background: '#FFF',
        padding: '16px',
        resize: 'none',
        marginBottom: '40px',
    },
    flexEnd: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '10px',
    },
    noteModalBox: {
        padding: '6px 24px 24px',
    },
    noteModalTitle: {
        color: '#4B5C68',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '32px',
        fontFamily: 'Open Sans, sans-serif',
    },
    nodeModalBody: {
        height: '100%',
        borderRadius: '8px',
        marginTop: '24px',
        display: 'flex',

    },
    noteLetSide: {
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '468px',
        padding: '16px',
        width: '100%',
        wordWrap: 'break-word',
    },

    noteInformationTitle: {
        color: '#4B5C68',
        fontSize: '16px',
        fontWeight: '600',
        margin: '16px 0 24px 0',
    },

    noteInformationSubject: {
        color: '#4B5C68',
        textAlign: 'center',
        lineHeight: '24px',
    },

    noteTitleInput: {
        color: '#4B5C68',
        fontSize: '24px',
        fontWeight: '700',
        border: 'none',
        outline: 'none',
        width: '100%',
        height: '33px',
        marginBottom: '16px',
        background: 'transparent',
    },

    noteSubjectBox: {
        width: '100%',
        height: '100%',
        borderTop: '1px solid #A3B2BD',
        padding: '16px 0',
    },

    noteSubjectArea: {
        height: '267px',
        width: '100%',
        background: 'transparent',
        border: 'none',
        resize: 'none',
        outline: 'none',
        color: '#4B5C68',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '21px',
    },

    noteImage: {
        width: '80px',
        height: '80px',
    },

    noteBox: {
        borderRadius: '8px',
        background: '#EBF2FD',
        width: '350px',
        height: 'auto',
        marginBottom: '8px',
        padding: '16px',
        cursor: 'pointer',
    },
    noteTitle: {
        color: '#4B5C68',
        fontSize: '16px',
        fontWeight: 600,
        marginBottom: '4px',
    },
    noteSubject: {
        color: '#4B5C68',
        fontSize: '14px',
        fontWeight: 400,
        marginBottom: '12px',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        lineClamp: 3,
        '-webkit-box-orient': 'vertical',
        lineHeight: 'normal',
    },
    noteInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& p': {
            color: '#4B5C68',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: 'normal',
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
        },
        '& b': {
            fontWeight: 600,
        },
        '& span': {
            color: '#4B5C68',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: 'normal',

        },
        '& div': {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
        },
    },
    userNotes: {
        minWidth: '353px',
        width: '353px',
        height: '470px',
        overflow: 'auto',
        marginRight: '16px',
    },
    selectedNoteTitle: {
        color: '#4B5C68',
        fontSize: '24px',
        fontWeight: '600',
        width: '400px',
        wordWrap: 'break-word',
    },

    noteInformationBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        width: '100%',
        padding: '16px 0',
        marginBottom: '16px',
        borderBottom: '1px solid #A3B2BD',
    },
    noteInformation: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '& p': {
            color: '#4B5C68',
            fontSize: '14px',
        },
        '& span': {
            // textDecoration: 'underline',
        },
        '& img': {
            marginRight: '8px',
        },
    },
    noteSubjectText: {
        color: '#4B5C68',
        fontSize: '14px',
        lineHeight: '21px',
        width: '100%',
        height:'300px',
        overflow:'auto',
        wordWrap: 'break-word',
    },
    reverse: {
        display: 'flex',
        flexDirection: 'column-reverse',
    },
    recurTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: Colors.TextSecondary,
        marginBottom: '8px',
    },
    dateInputs: {
        display: 'flex',
        width: '100%',
        margin: '8px 0 24px 0',
    },
    endDate: {
        marginLeft: '16px',
        width: '100%',
    },
    confirmModalInfoBox: {
        borderRadius: '8px',
        background: '#F8FAFB',
        padding: '16px',
        marginBottom: '16px',
        '& p': {
            color: '#4B5C68',
            fontSize: '16px',
            marginBottom: '8px',
        },
    },

    confirmModalWornBox: {
        borderRadius: '8px',
        background: 'rgba(255, 163, 48, 0.1)',
        padding: '16px',
        marginBottom: '16px',
        '& p': {
            color: '#4B5C68',
            fontSize: '16px',
            marginBottom: '8px',
        },
    },

    editConfirmModalWornBox: {
        maxHeight:'200px',
        overflow:'auto',
        borderRadius: '8px',
        background: 'rgba(255, 163, 48, 0.1)',
        padding: '16px',
        marginBottom: '16px',
        '& p': {
            color: '#4B5C68',
            fontSize: '16px',
            marginBottom: '8px',
        },
    },

    confirmModalWornBoxInfo:{
        display:'flex',
        alignItems:'flex-start',
        gap:'8px',
        marginBottom:'8px',

        '& img':{
            width:'16px',
            height:'16px'
        },

        '& p':{
            lineHeight:'21px',
        }
    },

    confirmModalWornBoxDates:{
        fontWeight: 600
    },

    confirmModalErrBox: {
        borderRadius: '8px',
        background: 'rgba(254, 112, 112, 0.2)',
        padding: '16px',
        marginBottom: '16px',
        '& p': {
            color: '#4B5C68',
            fontSize: '16px',
            marginBottom: '8px',
            fontWeight: '400',
            lineHeight: '21px',
        },
    },

    cancellingOptionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: '40px 0 0',
    },

    informationBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',

        '& button': {
            // marginLeft: '32px',
            display: 'flex',
            alignItems: 'center',
            color: '#FE7070',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '19px',
            border: 'none',
            background: 'transparent',
            padding: 0,

            '& p': {
                marginLeft: '8px',
                whiteSpace: 'nowrap',
            },
        },
    },

    cancellingTitle: {
        color: '#4B5C68',
        fontSize: '16px',
        fontWeight: '600',
        margin: '0 0 16px',
    },
}));

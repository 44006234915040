/** Create Appointment */
export const CREATE_APPOINTMENT = "CREATE_APPOINTMENT";

/** Edit Appointment */
export const EDIT_APPOINTMENT = "EDIT_APPOINTMENT";
export const EDIT_APPOINTMENT_POSITION = "EDIT_APPOINTMENT_POSITION";

/** Get Appointments */
export const GET_APPOINTMENT = "GET_APPOINTMENT";
export const GET_APPOINTMENT_SUCCESS = "GET_APPOINTMENT_SUCCESS";

export const GET_APPOINTMENT_BY_ID = "GET_APPOINTMENT_BY_ID";
export const GET_APPOINTMENT_BY_ID_SUCCESS = "GET_APPOINTMENT_BY_ID_SUCCESS";

/** Delete Appointment */
export const DELETE_APPOINTMENT = "DELETE_APPOINTMENT";
export const DELETE_APPOINTMENT_SUCCESS = "DELETE_APPOINTMENT_SUCCESS";

/** Appointment Status */
export const SET_APPOINTMENT_STATUS = "SET_APPOINTMENT_STATUS";
export const SET_APPOINTMENT_STATUS_SUCCESS = "SET_APPOINTMENT_STATUS_SUCCESS";
export const REMOVE_APPT_FROM_BILL = "REMOVE_APPT_FROM_BILL";
export const UN_RENDER_APPT = "UN_RENDER_APPT";

/** Appointment Repeat */
export const APPOINTMENT_REPEAT = "APPOINTMENT_REPEAT";
export const APPOINTMENT_FROM_TEMPLATE = "APPOINTMENT_FROM_TEMPLATE";

export const APPEND_SIGNATURE_TO_APPMT = "APPEND_SIGNATURE_TO_APPMT";

export const SAVE_CURRENT_LOCATION = "SAVE_CURRENT_LOCATION";

export const GET_CLIENT_STAFFS = "GET_CLIENT_STAFFS";
export const GET_CLIENT_STAFFS_SUCCESS = "GET_CLIENT_STAFFS_SUCCESS";

export const REMOVE_CLIENT_STAFFS = "REMOVE_CLIENT_STAFFS";

export const CHANGE_APPOINTMENT_LOCK_UNLOCK = "CHANGE_APPOINTMENT_LOCK_UNLOCK";
export const MANAGE_NOTES = "MANAGE_NOTES";
export const CONNECT_RECONNECT_TEMPLATE = "CONNECT_RECONNECT_TEMPLATE";
export const CANCEL_APPT = "CANCEL_APPT";
export const DELETE_ALL_APPT = "DELETE_ALL_APPT";
export const GET_APPT_STAFFS = "GET_APPT_STAFFS";
export const GET_APPT_STAFFS_SUCCESS = "GET_APPT_STAFFS_SUCCESS";


import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { authHeaderStyles } from './styles';
import {
    ActiveInactiveStatusReverse,
    Colors,
    Images,
    makeCapitalize,
    useWidth,
    getDisplayDependsOnWidth, RolePermission, PermissionList, useModal,
} from 'utils';
import moment from 'moment';
import { AddButtonLight, TextRow, CustomDelete, NoItemText, SimpleTooltip, UploadModal } from 'components';
import { clientActions } from 'store';
import { DiagnosticCode } from '../../../clientModals';
import { PastAuthorizations } from './pastAuthorizations';
import { AuthFiles } from '../authFiles';

export const AuthHeader = ({ info, setToggleModal, empoloyment, type, setAuthIndex }) => {
    const classes = authHeaderStyles();
    const width = useWidth();
    const { open, close } = useModal();
    const params = useParams();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    const handleDelete = (item) => {
        const body = {
            ...info,
        };
        body.diagnosticCodes = info?.diagnosticCodes?.filter((i) => i !== item);
        dispatch(clientActions.editClientsAuthorizations(body, info.id, params.id));
    };

    return (
        <div className={classes.AuthHeader}>
            <div className={classes.AuthHeaderTop}>
                <div className={classes.AuthHeaderTopLeft}>
                    <p className={classes.AuthHeaderTopLeftTitle}>
                        {empoloyment ? makeCapitalize(info?.title?.name) : `# ${info?.authId}`}
                    </p>
                    {empoloyment ? (
                        <p className={classes.AuthHeaderTopLeftText}>
                            {info?.startDate &&
                                `${moment.utc(info?.startDate).format('MM/DD/YYYY')} - ${info?.endDate === null ? 'Present' : moment.utc(info?.endDate).format('MM/DD/YYYY')}`
                            }
                        </p>
                    ) : (
                        <p className={classes.AuthHeaderTopLeftText}>
                            {info?.startDate &&
                                `${moment.utc(info?.startDate).format('MM/DD/YYYY')} - ${moment.utc(info?.endDate).format('MM/DD/YYYY')}`
                            }
                        </p>
                    )}
                    {type === 'staff' && !empoloyment && (
                        <div className={classes.activeInactive}>
                            <p> {info?.active ? 'Active' : 'Inactive'}</p>
                            <div
                                className={classes.circle}
                                style={info?.active ?
                                    { background: Colors.ThemeGreen }
                                    :
                                    { background: Colors.ThemeRed }
                                }
                            />
                        </div>
                    )}
                </div>
                {!!empoloyment ? (
                    <div
                        className={classes.employmentEditBoxStyle}
                        onClick={() => setToggleModal((prevState) => !prevState)}
                    >
                        <img src={Images.edit} alt="edit" />
                        <span className={classes.editTextStyle}>Edit</span>
                    </div>
                ) : (

                    RolePermission([PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) &&
                    <div className={classes.AuthHeaderTopRight}>

                        <SimpleTooltip title={<p>{'Past Authorizations'}</p>} placement="top-start">
                            <div
                                className={classes.fileListStyle}
                                onClick={() => open(<PastAuthorizations authIndex={info} setAuthIndex={setAuthIndex} />)}
                            >
                                <img src={Images.timePast} alt="file" />
                            </div>
                        </SimpleTooltip>

                        <SimpleTooltip title={<p>{'Files'}</p>} placement="top-start">
                            <div className={classes.fileListStyle}
                                onClick={() => open(
                                    <AuthFiles currentId={info?.id}  />
                               )}
                            >
                                <img alt="file" src={Images.documentUpload} />
                            </div>
                        </SimpleTooltip>

                        {info?.status !== 'INACTIVE' &&
                            <SimpleTooltip title={<p>{'Edit Authorization'}</p>} placement="top-start">
                                <div
                                    className={classes.editIconStyle}
                                    onClick={() => {
                                        // setDelEdit(true);
                                        setToggleModal((prevState) => !prevState);
                                    }}
                                >
                                    <img src={Images.edit} alt="edit" />
                                </div>
                            </SimpleTooltip>
                        }
                        {/* <div
                     className={classes.removeIconStyle}
                     onClick={() => {
                        setDelEdit(false);
                        setToggleModal((prevState) => !prevState);
                     }}
                  >
                     <img src={Images.remove} alt="delete" />
                  </div> */}
                    </div>
                )}
            </div>
            <div className={classes.AuthHeaderBottom}>
                <div className={classes.AuthHeaderBottomBox}>
                    <p className={classes.AuthHeaderBottomBoxTitle}>
                        {' '}
                        {empoloyment ? 'Supervisor:' : 'Funding Source:'}{' '}
                    </p>
                    <p className={classes.AuthHeaderBottomBoxText}>
                        <TextRow name={empoloyment ? info?.supervisor?.firstName : info?.funderId?.name}  />
                        {/*{empoloyment ?*/}
                        {/*     getDisplayDependsOnWidth(makeCapitalize(info?.supervisor?.firstName),*/}
                        {/*        width,*/}
                        {/*        5,*/}
                        {/*        1620,*/}
                        {/*    )*/}
                        {/*    : getDisplayDependsOnWidth(*/}
                        {/*        makeCapitalize(info?.funderId?.name),*/}
                        {/*        width, 10, 1620,*/}
                        {/*    )}*/}
                    </p>
                </div>
                <div className={classes.AuthHeaderBottomBox}>
                    <p className={classes.AuthHeaderBottomBoxTitle}>
                        {empoloyment ? 'Department:' : 'Status:'}
                    </p>
                    <p className={`${classes.AuthHeaderBottomBoxText} ${!empoloyment ? 'statusStyle' : ''} ${info?.status}`}>
                        {empoloyment
                            ? getDisplayDependsOnWidth(makeCapitalize(info?.departmentId?.name), width)
                            : ActiveInactiveStatusReverse(info?.status)}
                    </p>
                </div>
                <div className={classes.AuthHeaderBottomBox}>
                    <p className={classes.AuthHeaderBottomBoxTextLocation}>
                        <span
                            style={{ fontWeight: 600 }}> {empoloyment ? 'Employment Type: ' : 'Service Location: '}</span>
                        {info?.location}
                    </p>
                </div>
            </div>


            <div className={classes.diagnosticCodesWrapper}>
                <Accordion>
                    <AccordionSummary
                        onClick={() => setShow(!show)}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className={classes.addCodeBox}>
                            <div className={classes.downUp}>
                                <p>Diagnostic Codes</p>
                                <img src={Images.dropDown} alt="icon"
                                     style={show ? { transform: 'rotate(-180deg)' } : {}} />
                            </div>

                            {RolePermission([PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) &&
                                <AddButtonLight
                                    addButnLightClassName={classes.addAuthServiceButnStyle}
                                    addButnLightInnerText={'Add Code'}
                                    onAddButnLightClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        open(<DiagnosticCode info={info} params={params} />);
                                    }}
                                />
                            }
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.diagnosticCodes}>
                            {info?.diagnosticCodes?.length ?
                                info?.diagnosticCodes?.map((i, j) => (
                                    <div className={classes.itemBox} key={j}>
                                        <TextRow name={i} />
                                        {RolePermission([PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) &&
                                            <div className={classes.itemButtonsBox}>
                                                <button onClick={() => open(
                                                    <DiagnosticCode
                                                        item={i}
                                                        info={info}
                                                        params={params}
                                                    />)}
                                                >
                                                    <img src={Images.edit} alt="edit" />
                                                </button>
                                                <button onClick={() => open(<CustomDelete
                                                    text={`Are you sure you want Delete Diagnostic Code`}
                                                    info="Delete Diagnostic Code?"
                                                    handleDel={() => handleDelete(i)}
                                                    handleClose={() => close()}
                                                    actionType={'EDIT_CLIENT_AUTHORIZATION'}
                                                />)}>
                                                    <img src={Images.remove} alt="edit" />
                                                </button>
                                            </div>
                                        }
                                    </div>
                                ))
                                :
                                <div style={{ paddingBottom: '20px' }}>
                                    <NoItemText text={'No diagnostic codes yet'} />
                                </div>
                            }
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
};

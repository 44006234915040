/**Get Invoices */
export const GET_INVOICES = "GET_INVOICES";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";

export const GET_INVOICE_TRANSACTION = "GET_INVOICE_TRANSACTION";
export const GET_INVOICE_TRANSACTION_SUCCESS = "GET_INVOICE_TRANSACTION_SUCCESS";
export const REMOVE_INVOICE_TRANSACTION = "REMOVE_INVOICE_TRANSACTION";
export const CLOSE_INVOICE = "CLOSE_INVOICE";


/**Get Invoice by Id */
export const GET_INVOICE_BY_ID = "GET_INVOICE_BY_ID";
export const GET_INVOICE_BY_ID_SUCCESS = "GET_INVOICE_BY_ID_SUCCESS";

/**generate Invoice */
export const GENERATE_INVOICE = "GENERATE_INVOICE";

/**edit Invoice */
export const EDIT_INVOICE = "EDIT_INVOICE";

/**delete Invoice */
export const DELETE_INVOICE = "DELETE_INVOICE";

/**Get Invoices for generate  */
export const GET_INVOICES_FOR_GENERATE = "GET_INVOICES_FOR_GENERATE";
export const GET_INVOICES_FOR_GENERATE_SUCCESS = "GET_INVOICES_FOR_GENERATE_SUCCESS";

/**Invoice files manage*/

export const UPLOAD_INVOICE_DOCUMENT = "UPLOAD_INVOICE_DOCUMENT";
export const EDIT_INVOICE_DOCUMENT = "EDIT_INVOICE_DOCUMENT";
export const GET_INVOICE_DOCUMENT = "GET_INVOICE_DOCUMENT";
export const GET_INVOICE_DOCUMENT_SUCCESS = "GET_INVOICE_DOCUMENT_SUCCESS";
export const DELETE_INVOICE_DOCUMENT = "DELETE_INVOICE_DOCUMENT";
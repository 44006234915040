import React from 'react';
import { Checkbox } from '@material-ui/core';
import { Images, PermissionList, RolePermission } from 'utils';
import {
    DateRow,
    DisplayIdAnFlagRow,
    DisplayIdRow,
    DownloadLink,
    LinkRow,
    PriceRow,
    SimpleTooltip,
    TextRow, TypeRow,
} from 'components';
import { FixDecimalToTree } from '../../../components/table/tableRows';

export const processPayrollHead = [
    { name: '', title: 'Timesheet ID', noSearch: true, custom: false },
    { name: '', title: 'Timesheet Date', noSearch: true, custom: false },
    { name: '', title: 'Staff', noSearch: true, custom: false },
    { name: '', title: 'Appt ID', noSearch: true, custom: false },
    { name: '', title: 'Type', noSearch: true, custom: false },
    { name: '', title: 'Rate', noSearch: true, custom: false },
    { name: '', title: 'Qty', noSearch: true, custom: false },
    { name: '', title: 'Total AMT', noSearch: true, custom: false },
];


export const processPayrollBody = [
    { rowText: (item) => <DisplayIdRow id={item?.displayId} /> },
    { rowText: (item) => <DateRow date={item?.timesheetDate} /> },
    { rowText: (item) => <TextRow name={item?.staffName} /> },
    { rowText: (item) => <LinkRow href={`/schedule`} name={item?.apptDisplayId} checkPermission={true} /> },
    { rowText: (item) => <TypeRow text={item?.type} /> },
    { rowText: (item) => <PriceRow info={item?.amount} /> },
    { rowText: (item) => <FixDecimalToTree number={item?.qty} /> },
    { rowText: (item) => <PriceRow info={item?.totalAmount} /> },
];

export const ACTION_TYPE = 'GET_ACTIVE_PROCESS_PAYROLL';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AddButton, CheckboxesTags, CustomDelete, Loader, MinLoader, NoItemsYet } from 'components';
import {
    Colors,
    FindErrorItem,
    FindLoad, FindSuccess,
    Images,
    PermissionList, RolePermission, useModal,
} from 'utils';
import { adminActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { systemItemStyles } from 'fragments/system/core';
import { Paper } from '@material-ui/core';
import { popperUseStyles } from '../../../management/core/style';
import { staffServiceNotYet } from './constants';

const credentialBtn = {
    maxWidth: '154px',
    width: '100%',
    padding: 0,
};
const ACTION_TYPE = 'CREATE_STAFF_SERVICE';
const GET_SERVICE_ACTION_TYPE = 'GET_STAFF_SERVICE';

export const StaffService = ({ staffGeneral, info }) => {
    const { allServices, staffServices } = useSelector((state) => ({
        allServices: state.admins.allServices,
        staffServices: state.admins.staffServices,
    }));
    const classes = systemItemStyles();
    const popperClasses = popperUseStyles();
    const params = useParams();
    const dispatch = useDispatch();
    const createSuccess = FindSuccess(ACTION_TYPE);
    const backError = FindErrorItem(ACTION_TYPE);
    const loadCreate = FindLoad(ACTION_TYPE);
    const getLoader = FindLoad(GET_SERVICE_ACTION_TYPE);
    const [list, setList] = useState([]);
    const [error, setError] = useState('');
    const [show, setShow] = useState(false);
    const { open } = useModal();
    const _isNotClinician = staffGeneral?.clinical === false;

    useEffect(() => {
        dispatch(adminActions.getAllServices());
        dispatch(adminActions.getStaffService(params.id));
    }, []);

    useEffect(() => {
        if (!!createSuccess.length) {
            setShow(false);
            setList([]);
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [createSuccess.length]);

    const handleChange = (values) => {
        setList(values);
        if (!!backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        }
        error && setError('');
    };

    const handleSubmit = () => {
        if (list?.length > 0) {
            let filteredList = [];
            list?.forEach((i) => filteredList.push(i?.id));
            dispatch(adminActions.createStaffService(params.id, filteredList));
            filteredList = [];
        } else {
            setError(!list?.length ? 'serviceType' : '');
        }
    };

    const filteredList = allServices?.length &&
        allServices.filter(function(array_el) {
            return (
                staffServices?.filter(function(anotherOne_el) {
                    return anotherOne_el?.serviceId === array_el.id;
                }).length === 0
            );
        });

    if (getLoader?.length) {
        return <Loader />;
    }

    return (
        <div>
            <div style={{ marginBottom: 0 }}>
                <div style={{ marginBottom: 16, width: '100%', position: 'relative' }}>
                    <div className="space-between"
                         style={{ marginBottom: 24, alignItems: 'flex-start', height: '36px' }}>
                        <p className={classes.title}>Services</p>
                        {show === false &&
                            RolePermission([PermissionList.STAFF_SERVICE_MANAGE?.code]) &&
                            <AddButton
                                styles={credentialBtn}
                                handleClick={() => setShow(!show)}
                                text="Add Service"
                                disabled={_isNotClinician}
                            />
                        }
                    </div>
                    {RolePermission([PermissionList.STAFF_SERVICE_MANAGE?.code]) && show &&
                        <div className={classes.tablePermissionsBodyStyleWrapper}>
                            <div className={classes.tablePermissionsBodyStyle}>
                                <CheckboxesTags
                                    textRow={false}
                                    styles={{ width: '100%' }}
                                    disabled={loadCreate?.length}
                                    name="serviceType"
                                    handleChange={handleChange}
                                    permissionsList={filteredList?.length ? filteredList : []}
                                    value={list}
                                    renderValue={(e) =>
                                        `${e?.funderId?.name ? `${e?.funderId?.name} - ` : 'N/A -'}
                                          ${e?.serviceTypeId?.name ? `${e?.serviceTypeId?.name} - ` : 'N/A -'} 
                                           ${e?.serviceTypeId?.displayCode ? `${e?.serviceTypeId?.displayCode}` : 'N/A'}`
                                    }
                                    label={'Services*'}
                                    placeholder={'Services'}
                                    uiType={'modalCheckboxSelect'}
                                    notShowError={true}
                                    CustomPaperComponent={(props) => {
                                        return (
                                            <Paper
                                                {...props}
                                                className={popperClasses.paper}
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                {props.children}
                                                <div className={popperClasses.footer}>
                                                    <div />
                                                    <button
                                                        style={{ backgroundColor: list?.length ? '#347AF0' : 'rgba(52, 122, 240, 0.50)' }}
                                                        disabled={!list?.length}
                                                        className={popperClasses.affPermissionsBtn}
                                                        onMouseDown={handleSubmit}>
                                                        {loadCreate?.length ?
                                                            <MinLoader margin={'0'} color={Colors.TextWhite} />
                                                            :
                                                            'Add Services'
                                                        }
                                                    </button>
                                                </div>
                                            </Paper>
                                        );
                                    }
                                    }
                                />
                            </div>
                        </div>
                    }
                </div>

                {info?.length ?
                    <div className={classes.serviceBodyItems}>
                        {info.map((item, index) => {
                            return (
                                <div className={classes.item} key={index} style={{ width: '100%' }}>

                                    <div className={classes.serviceInfoBox}
                                         style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <p>{item?.fs ? `${item?.fs} - ` : 'N/A -'}</p>
                                        <span>{item?.serviceType ? `${item?.serviceType} - ` : 'N/A -'}</span>
                                        <span>{item?.displayCode ? item?.displayCode : 'N/A '}</span>
                                    </div>

                                    {RolePermission([PermissionList.STAFF_SERVICE_MANAGE?.code]) &&
                                        <div className={classes.icons}>
                                            <img
                                                src={Images.remove}
                                                alt="delete"
                                                onClick={() =>
                                                    open(<CustomDelete
                                                        text={`Are you sure you want remove service?`}
                                                        info="Remove Service?"
                                                        handleDel={() => dispatch(adminActions.deleteStaffService(params?.id, item?.serviceId))}
                                                        actionType={'DELETE_STAFF_SERVICE'}
                                                    />)
                                                }
                                            />
                                        </div>
                                    }
                                </div>
                            );
                        })}
                    </div>
                    :
                    <div style={{ position: 'relative' }}>
                        <NoItemsYet
                            text={staffServiceNotYet.title}
                            subTitle={staffServiceNotYet.subTitle}
                            image={staffServiceNotYet.image}
                        />
                    </div>
                }
            </div>
        </div>
    );
};

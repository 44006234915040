import {
  createProcessPayroll,
  deletePayroll, deletePayrollDocument, editPayrollDocument,
  getActivePayroll, getActiveProcessPayroll,
  getPayrollById, getPayrollDocument,
  getProcessedPayroll, resolvePayroll, uploadPayrollDocument,
} from './staffPayroll.action';
export { staffPayrollReducer } from './staffPayroll.reducer';
export { watchStaffPayroll } from './staffPayroll.saga';

export const staffPayrollActions = {
  /** Active Payroll */
  getActivePayroll,
  getActiveProcessPayroll,
  createProcessPayroll,
  getPayrollById,
  deletePayroll,
  resolvePayroll,
  /** End */

  /** Processed Payroll */
  getProcessedPayroll,
  /** End */

  /** Payroll Documents */
  uploadPayrollDocument,
  editPayrollDocument,
  getPayrollDocument,
  deletePayrollDocument,
  /** End */

}


import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileUploader } from 'react-drag-drop-files';
import { LinearProgress } from '@material-ui/core';
import { systemItemStyles } from './styles';
import {
    ErrorText,
    FindErrorItem,
    FindLoad,
    FindSuccessItem,
    getPhoneErrorText,
    hooksForErrors,
    Images,
} from 'utils';
import { Address, CreateChancel, Loader, MaskInput, ValidationInput } from 'components';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, systemActions, UploadServices } from 'store';

const ACTION_TYPE = 'GET_COMPANY';
export const Company = () => {
    const classes = systemItemStyles();
    const company = useSelector((state) => state.system.company);
    const CREATE_EDIT_TYPE = company?.id ? 'EDIT_COMPANY' : 'CREATE_COMPANY';
    const DELETE_TYPE = 'DELETE_COMPANY';
    const [inputs, setInputs] = useState({});
    const [error, setError] = useState();
    const dispatch = useDispatch();
    const backError = FindErrorItem(CREATE_EDIT_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const createLoader = FindLoad(CREATE_EDIT_TYPE);
    // const deleteLoader = FindLoad(DELETE_TYPE);
    const success = FindSuccessItem(CREATE_EDIT_TYPE);
    const deleteSuccess = FindSuccessItem(DELETE_TYPE);
    const phoneErrorMsg = getPhoneErrorText(inputs.phoneNumber);
    const phoneErrorText = hooksForErrors.getPhoneError(error, backError, phoneErrorMsg);
    const [enteredAddress, setEnteredAddress] = useState('');
    const [currentFile, setCurrentFile] = useState(null);
    const [uploadLoader, setUploadLoader] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const checkEditState = company?.id ? showEdit : true;
    const disabled = company?.id ? !showEdit : false;

    useEffect(() => {
        dispatch(systemActions.getCompany());
    }, [dispatch]);

    useEffect(() => {
        if (company?.id) {
            setInputs({
                name: company?.name,
                npi: company?.npi,
                phone: company?.phone,
                taxId: company?.taxId,
            });
            if (company?.address) {
                setEnteredAddress({ address: company?.address?.formattedAddress });
            }
            if (company?.logo) {
                setCurrentFile(company?.logo);
            }
        }
    }, [company]);


    useEffect(() => {
        if (success?.type === CREATE_EDIT_TYPE) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(CREATE_EDIT_TYPE));
            setShowEdit(false);
        }
        if (deleteSuccess?.type === DELETE_TYPE) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(DELETE_TYPE));
            setInputs({});
            setEnteredAddress('');
            setCurrentFile(null);
        }
    }, [success, deleteSuccess]);

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        error === e.target.name && setError('');
    };

    const handleAddressChange = (selectedAddress) => {
        setEnteredAddress(selectedAddress);
        error === 'enteredAddress' && setError('');
        if (error === 'unableError') {
            setError('');
            dispatch(httpRequestsOnErrorsActions.removeError(backError.type));
        }
    };

    const handleUpload = async (e, k) => {
        error === 'currentFile' && setError('');
        if (e) {
            let formData = new FormData();
            formData.append('files', e);
            try {
                setUploadLoader(true);
                const res = await UploadServices.UploadImage(formData);
                const pos = e.type.slice(e.type.search('/'));
                const fileType = pos.substring(1);

                const info = {
                    file: {
                        ...res?.data,
                        format: fileType,
                    },
                };
                setCurrentFile(info);
                setUploadLoader(false);
            } catch (e) {
                setUploadLoader(false);
            }
        }
    };

    const handleSubmit = () => {
        if (inputs?.name && enteredAddress?.address && inputs?.phone && inputs?.npi && inputs?.taxId) {
            const params = {
                ...inputs,
                address: { address: enteredAddress?.address },
            };

            if (currentFile) {
                params.file = currentFile?.file;
            } else {
                delete params.file;
            }
            params.phone = parseInt(inputs?.phone.replace(/[^0-9]/g, '')) + '';
            if (company?.id) {
                dispatch(systemActions.editCompany(company?.id, params));
            } else {
                dispatch(systemActions.createCompany(params));
            }
        } else {
            setError(
                !inputs?.name ? 'name' :
                    !enteredAddress?.address ? 'enteredAddress' :
                        !inputs?.phone ? 'phone' :
                            !inputs?.npi ? 'npi' :
                                !inputs?.taxId ? 'taxId' :
                                    '',
            );
        }
    };

    const handleCancel = () => {
        setShowEdit(false);
        setError('');
        if (company) {
            setInputs({
                name: company?.name,
                npi: company?.npi,
                phone: company?.phone,
                taxId: company?.taxId,
            });
            if (company?.address) {
                setEnteredAddress({ address: company?.address?.formattedAddress });
            }
            if (company?.logo) {
                setCurrentFile(company?.logo);
            }
        } else {
            setInputs({});
            setEnteredAddress('');
            setCurrentFile('');
        }
    };

    // const handleDelete = () => {
    //     dispatch(systemActions.deleteCompany(company?.id));
    // };

    if (loader?.length) {
        return <Loader />;
    }

    return (
        <div className={classes.companyBox}>
            <div className={classes.systemHeaderStyles} style={{ height: 30 }}>
                <p className={classes.title} style={{ margin: 0 }}>Company Profile</p>
                {company?.id &&
                    <div className={classes.actionButtonsBox}>
                        {company?.id && !checkEditState &&
                            <button onClick={() => setShowEdit(true)}>
                                <img src={Images.edit} alt="edit" />
                            </button>
                        }
                        {/*<button onClick={handleDelete}>*/}
                        {/*    {deleteLoader?.length ?*/}
                        {/*        <MinLoader margin={'0'} color={Colors.ThemeRed} position={'relative'} />*/}
                        {/*        :*/}
                        {/*        <img src={Images.remove} alt="remove" />*/}
                        {/*    }*/}
                        {/*</button>*/}
                    </div>
                }
            </div>

            <div className={classes.companyBodyBox}>
                <ValidationInput
                    disabled={disabled}
                    variant={'outlined'}
                    onChange={handleChange}
                    value={inputs?.name}
                    type={'text'}
                    label={'Company Name*'}
                    name="name"
                    typeError={error === 'name' ? ErrorText.field : ''}
                />
                <div style={{ marginBottom: '20px', opacity: disabled ? '0.7' : '1' }}>
                    <FileUploader
                        types={['png', 'jpeg', 'jpg', 'image/svg+xml', 'svg']}
                        handleChange={handleUpload} name="file"
                        disabled={disabled}
                    >
                        <div
                            style={{ height: '213px' }}
                            className="drag-drop-component">
                            <div className={classes.uploadedImage}>
                                <img
                                    className={classes.currentLogo}
                                    src={
                                        currentFile?.file?.url ? currentFile?.file?.url :
                                            currentFile?.url ? currentFile?.url : Images.uploadImage
                                    } alt="icon"
                                />
                                {company?.id && currentFile &&
                                    <button
                                        disabled={disabled}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setCurrentFile('');
                                        }}>
                                        <img src={Images.closeBlackVector} alt={'icon'} />
                                    </button>
                                }
                            </div>

                            <label className="custom-file-upload">
                                <input
                                    onClick={(event) => (event.target.value = null)}
                                    onChange={handleUpload}
                                    type="file"
                                    id="file"
                                />
                                <i className="fa fa-cloud-upload" />
                                <span className="upload">
                                <p>Drag and Drop or <span style={{ color: '#438AFE' }}>Choose file</span> to upload</p>
                                    <p style={{textAlign:'center'}}>JPG, PNG and SVG</p>
                            </span>
                            </label>
                        </div>
                    </FileUploader>
                    <div style={{ marginTop: '16px' }}>
                        {uploadLoader && (
                            <div className="item-loading" style={{ marginTop: '0' }}>
                                <div className="images-info">
                                    <img src={Images.document} alt="icon" />
                                </div>
                                <LinearProgress style={{ background: '#347AF0' }} />
                            </div>
                        )}
                    </div>
                </div>

                <Address
                    oneInput={true}
                    noUnit={true}
                    placeholder={'Company Address*'}
                    setAddress={handleAddressChange}
                    defaultValue={company?.address}
                    errMessage={error === 'enteredAddress' ? ErrorText.field :
                        backError?.type === ACTION_TYPE ? backError?.error :
                            ''
                    }
                    disabled={disabled}
                />
                <MaskInput
                    disables={disabled}
                    name="phone"
                    label="Phone Number*"
                    value={inputs?.phone}
                    handleChange={handleChange}
                    error={
                        error === 'phone' ? ErrorText.field :
                            phoneErrorText
                    }
                />
                <ValidationInput
                    disabled={disabled}
                    variant={'outlined'}
                    name={'npi'}
                    type={'text'}
                    label={'NPI Number*'}
                    typeError={error === 'npi' ? ErrorText.field : ''}
                    value={inputs?.npi}
                    onChange={handleChange}
                />
                <ValidationInput
                    disabled={disabled}
                    variant={'outlined'}
                    name={'taxId'}
                    type={'text'}
                    label={'Tax ID*'}
                    typeError={error === 'taxId' ? ErrorText.field : ''}
                    value={inputs?.taxId}
                    onChange={handleChange}
                />
            </div>

            {checkEditState &&
                <CreateChancel
                    loader={!!createLoader.length}
                    create={'Save'}
                    chancel={'Cancel'}
                    onCreate={handleSubmit}
                    onClose={handleCancel}
                    buttonWidth="292px"
                />
            }
        </div>
    );
};
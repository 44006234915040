import axios from 'axios';

export const invoiceService = {
    getInvoicesService: (data) => axios.get('/invoice', { auth: true, params: { ...data } }),

    getInvoiceByIdService: (id) => axios.get(`/invoice/${id}`, { auth: true }),

    generateInvoiceService: (body) => axios.post('/invoice/generate', body, { auth: true }),

    editInvoiceService: (id, body) => axios.patch(`/invoice/${id}`, body, { auth: true }),

    deleteInvoiceService: (id) => axios.delete(`/invoice/${id}`, { auth: true }),

    getInvoiceTxnsService: (ids) => axios.get(`/txn/invoice`, { auth: true, params: { billIds: ids } }),

    closeInvoiceService: (id, comment) => axios.post(`/invoice/${id}/close`, null, {
        auth: true,
        params: { closeReason: comment },
    }),

    /**Get Invoices for generate  */
    getInvoiceForGenerateService: (params) => axios.get(`/bill/invoice`, { auth: true, params: { ...params } }),

    /** Invoice Documents */

    uploadDocumentService: (file, id) => axios.post(`/invoice/${id}/documents`, { ...file }, { auth: true }),

    editDocumentService: ({
                              id,
                              dockId,
                              file,
                          }) => axios.patch(`/invoice/${id}/documents/${dockId}`, { ...file }, { auth: true }),

    getDocumentService: (id) => axios.get(`/invoice/${id}/documents`, { auth: true }),

    deleteDocumentService: (clientId, id) => axios.delete(`/invoice/${clientId}/documents/${id}`, { auth: true }),

    /** End */
};

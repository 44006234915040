import {
   getInvoices,
   getInvoiceById,
   generateInvoice,
   editInvoice,
   deleteInvoice,
   getInvoiceForGenerate,
   uploadInvoiceDocument,
   editInvoiceDocument,
   getInvoiceDocument,
   deleteInvoiceDocument, getInvoiceTransactions, closeInvoice,
} from './invoice.action';

export { invoiceReducer } from "./invoice.reducer";
export { watchInvoice } from "./invoice.saga";

export const invoiceActions = {
   getInvoices,
   getInvoiceById,
   generateInvoice,
   editInvoice,
   deleteInvoice,
   getInvoiceTransactions,
   closeInvoice,

   /**Get Invoices for generate  */
   getInvoiceForGenerate,

   /** Invoice Documents */
   uploadInvoiceDocument,
   editInvoiceDocument,
   getInvoiceDocument,
   deleteInvoiceDocument,
};
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { FindLoad, PermissionList, RolePermission } from 'utils';
import { Loader } from "components";
import { claimActions } from 'store';
import { ClaimDetailInfo } from 'fragments';

const ACTION_TYPE = 'GET_CLAIM_BY_ID'
export const ClaimDetails = () => {
   const params = useParams();
   const dispatch = useDispatch();
   const loader = FindLoad(ACTION_TYPE);

   useEffect(() => {
       if(params?.id) {
           dispatch(claimActions.getClaimById(params.id));

           if(RolePermission([PermissionList.CLAIM_FILE_READ?.code, PermissionList.CLAIM_FILE_MANAGE?.code])) {
               dispatch(claimActions.getClaimDocument(params.id))
           }

       }
   }, [params.id]);

   if (!!loader.length) return <Loader />;

   return (
      <>
          <ClaimDetailInfo/>
      </>
   );
};

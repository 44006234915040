import axios from 'axios';

export const claimService = {
    getClaimsService: (data) => axios.get('/claim', { auth: true, params: { ...data } }),

    getSubmittedClaimsService: (data) => axios.get('/claim/submitted', { auth: true, params: { ...data } }),

    getClaimByIdService: (id) => axios.get(`/claim/${id}`, { auth: true }),

    generateClaimService: (group, merge) => axios.post(`/claim/generate`, group, {
        auth: true,
        params: { merge: merge },
    }),

    editClaimService: (id, body) => axios.patch(`/claim/${id}/update`, body, { auth: true }),

    /**Claim Status Actions */
    closeClaimService: (id, details) => axios.post(`/claim/${id}/close?details=${details}`, {}, { auth: true }),

    submitClaimService: (id) => axios.patch(`/claim/submit`, { 'claimIds': id }, { auth: true }),

    refreshClaimService: (id) => axios.get(`/claim/${id}/draft`, { responseType: 'arraybuffer', auth: true }),

    resubmitClaimService: (id) => axios.patch(`/claim/${id}/refresh`, null, { auth: true }),

    removeFromClaimService: (id, reason) => axios.delete(`/claim/appt/${id}`, {
        auth: true,
        params: { reason: reason },
    }),

    /**Bill Details */
    billClaimPmtService: (id) => axios.get(`/txn/claim/${id}`, { auth: true }),

    billInvoicePmtService: (ids) => axios.get(`/txn/invoice`, { auth: true, params: { billIds: ids } }),

    /** Claim Documents */

    uploadClaimDocumentService: (file, id) => axios.post(`/claim/${id}/documents`, { ...file }, { auth: true }),

    editClaimDocumentService: ({
                                   id,
                                   dockId,
                                   file,
                               }) => axios.patch(`/claim/${id}/documents/${dockId}`, { ...file }, { auth: true }),

    getClaimDocumentService: (id) => axios.get(`/claim/${id}/documents`, { auth: true }),

    deleteClaimDocumentService: (clientId, id) => axios.delete(`/claim/${clientId}/documents/${id}`, { auth: true }),

    /** End */

};

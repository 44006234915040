import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { receivableBillTableStyle } from './styles';
import {  claimActions } from 'store';
import { CustomDelByNote, FullTable, ModalFullTable, SimpleModal } from 'components';
import {
    billApptBody,
    billApptHead,
    billTotalBody,
    billTotalHead,
    claimPaymentTableBody,
    claimPaymentTableHead,
    invoicePaymentTableBody,
    invoicePaymentTableHead,
} from './constants';

const activeButtonStyle = {
    fontWeight: 700,
    borderBottom: '4px solid #FFCA33',
};

const REMOVE_ACTION_TYPE ='REMOVE_APPT_FROM_CLAIM'
export const ReceivableBillTable = ({ currentId }) => {
    const { billClaimPmt, invoiceClaimPmt, claimDetails } = useSelector((state) => ({
        billClaimPmt: state.claim.billClaimPmt,
        invoiceClaimPmt: state.claim.invoiceClaimPmt,
        claimDetails: state.claim.claimById
    }));
    const classes = receivableBillTableStyle();
    const [activeButton, setActiveButton] = useState('claim');
    const [removeAppt, setRemoveAppt] = useState(null);
    const dispatch = useDispatch();
    const billById = claimDetails?.bills?.find((i) => i?.id === currentId)

    useEffect(() => {
        dispatch(claimActions.bilClaimPmtTr(billById?.id));

        dispatch(claimActions.bilInvoicePmt([billById?.id]));
    }, []);

    const handleChange = (type) => {
        setActiveButton(type);
    };

    const removeFromClaim = (item) => {
        setRemoveAppt(item);
    };

    return (
        <div style={{ width: '1200px', height: '80vh' }}>
            <div className={classes.recBillContainerStyle}>
                <h6 className={classes.recBillTitleStyle}>Bill Details</h6>
                <div>
                    <FullTable
                        head={billTotalHead}
                        body={billTotalBody}
                        list={[{
                            billedRate: billById?.billedRate,
                            totalBilled: billById?.totalBilled,
                            totalUnits: billById?.totalUnits,
                            payorTotal: billById?.payorTotal,
                            payorPaid: billById?.payorPaid,
                            payorBalance: billById?.payorBalance,
                            clientTotal: billById?.clientTotal,
                            clientPaid: billById?.clientPaid,
                            clientBalance: billById?.clientBalance,
                        }]}
                        noText={'receivables'}
                        generateTable={true}
                        height={'auto'}
                    />
                </div>

                <div style={{ marginTop: -100, position: 'relative' }}>
                    <p className={classes.billTotals}>Other Details</p>
                    <div className={classes.buttonsTabWrapper}>
                        <button onClick={() => handleChange('claim')}
                                style={activeButton === 'claim' ? { ...activeButtonStyle } : {}}>
                            Claim Payment Transaction
                        </button>
                        {/*<button onClick={() => handleChange('invoice')}*/}
                        {/*        style={activeButton === 'invoice' ? { ...activeButtonStyle } : {}}>*/}
                        {/*    Invoice Payment Transaction*/}
                        {/*</button>*/}
                        <button onClick={() => handleChange('appointments')}
                                style={activeButton === 'appointments' ? { ...activeButtonStyle } : {}}>
                            Appointments
                        </button>
                    </div>


                    {activeButton === 'claim' &&
                        <ModalFullTable
                            head={claimPaymentTableHead}
                            body={claimPaymentTableBody}
                            loadingType={'GET_BILL_CLAIM_PAYMENT_TRANSACTIONS'}
                            list={billClaimPmt?.txns}
                            noText={'claim payment transactions'}
                            activeRowId={''}
                            height={'auto'}
                        />
                    }
                    {/*{activeButton === 'invoice' &&*/}
                    {/*    <ModalFullTable*/}
                    {/*        head={invoicePaymentTableHead}*/}
                    {/*        body={invoicePaymentTableBody}*/}
                    {/*        list={invoiceClaimPmt?.txns}*/}
                    {/*        loadingType={'GET_BILL_INVOICE_PAYMENT'}*/}
                    {/*        noText={'invoice payment transaction'}*/}
                    {/*        activeRowId={''}*/}
                    {/*        height={'auto'}*/}
                    {/*    />*/}
                    {/*}*/}
                    {activeButton === 'appointments' &&
                        <ModalFullTable
                            head={billApptHead(claimDetails)}
                            body={billApptBody(classes, removeFromClaim, claimDetails)}
                            list={billById?.appts}
                            noText={'appointments'}
                            activeRowId={''}
                            height={'auto'}
                        />
                    }
                </div>
            </div>

            <SimpleModal
                openDefault={removeAppt?.id}
                handleOpenClose={() => setRemoveAppt(null)}
                content={

                <div style={{padding: 24, background:'white'}}>
                    <CustomDelByNote
                        info="Remove Appointment from Claim"
                        text="Are you sure you want to remove this appointment from the claim? Please provide a reason for removing this appointment."
                        handleDel={(comment) => {
                            dispatch(claimActions.removeFromClaim(removeAppt?.id, comment, claimDetails?.id))
                        }}
                        innerText={'Remove'}
                        inputLabel={'Reason for removing*'}
                        actionType={REMOVE_ACTION_TYPE}
                        body={
                            <div>
                                <div className="deleting-client-info-box" style={{ alignItems: 'flex-start' }}>
                                    <p style={{ fontWeight: 600 }}>Claim ID:</p>
                                    <p style={{ fontWeight: 400 }}>{claimDetails?.displayId}</p>
                                </div>
                            </div>
                        }
                    />
                </div>
                }
                secondary={true}
            />
        </div>
    );
};

export const NoItemsYet = ({ text, styles, containerStyles, image, subTitle, height }) => {
    return (
        <>
            {image ?
                <div className="no-items-by-image-box" style={{ ...containerStyles, height }}   >
                    <img src={image} alt="No Items" />
                    <p>{text}</p>
                    <span>{subTitle}</span>
                </div>
                :
                <div className="no-items-box" style={{ ...containerStyles }}>
                    <p style={{ ...styles }}>{text}</p>
                </div>
            }
        </>
    );
};
import React from 'react';
import { useSelector } from 'react-redux';
import { FullTable } from 'components';
import { invoiceAppointmentsBody, invoiceAppointmentsHead, } from './constants';

export const InvoiceAppointments = () => {
    const invoiceById = useSelector((state) => state.invoice.invoiceById);

    return (
        <div>
            <FullTable
                head={invoiceAppointmentsHead}
                body={invoiceAppointmentsBody}
                list={invoiceById?.appts}
                noText={'appointments'}
                activeRowId={''}
            />
        </div>
    );
};
import { useState } from 'react';
import axios from 'axios';

const token = localStorage.getItem('access-token');

export const DownloadFile = () => {
    const [loadDownload, setLoadDownload] = useState(false);

    const handleDownload = async (file) => {
        const fileName = file?.originalName ? file?.originalName : '';
        setLoadDownload(true);
        try {
            const response = await axios({
                url: `/files/download`,
                method: 'POST',
                responseType: 'blob',
                data: file,
            });
            setLoadDownload(false);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (e) {
            setLoadDownload(false);
        }
    };


    const download1500Form = async (file, id) => {
        setLoadDownload(true);
        axios({
            url: `/claim/${id}/1500Form`,
            method: 'GET',
            responseType: 'blob',
            headers: { 'access-token': `${token}` },
        }).then((response) => {
            setLoadDownload(false);
            const url = window.URL.createObjectURL(new Blob([response?.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `1500Form.pdf`);
            document.body.appendChild(link);
            link.click();
        }).catch(() => {
            setLoadDownload(false);
        });
    };

    const download1500Forms = async (id) => {
        setLoadDownload(true);
        axios({
            url: `/claim/batch/${id}/1500Forms`,
            method: 'GET',
            responseType: 'blob',
            headers: { 'access-token': `${token}` },
        }).then((response) => {
            setLoadDownload(false);
            const url = window.URL.createObjectURL(new Blob([response?.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `1500Form.pdf`);
            document.body.appendChild(link);
            link.click();
        }).catch(() => {
            setLoadDownload(false);
        });
    };
    const downloadBatch = async (id) => {
        setLoadDownload(true);
        axios({
            url: `/reports/payroll/batch/${id}`,
            method: 'GET',
            responseType: 'blob',
            headers: { 'access-token': `${token}` },
        }).then((response) => {
            setLoadDownload(false);
            const url = window.URL.createObjectURL(new Blob([response?.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `processedPayroll.csv`);
            document.body.appendChild(link);
            link.click();
        }).catch(() => {
            setLoadDownload(false);
        });
    };


    return {
        loadDownload,
        handleDownload,
        download1500Form,
        download1500Forms,
        downloadBatch
    };
};
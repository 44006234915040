import {
    getPendingClaims,
    getClaimById,
    generateClaim,
    closeClaim,
    submitClaim,
    bilClaimPmtTr,
    bilInvoicePmt,
    getSubmittedClaims,
    submitClaimById,
    refreshClaim,
    resubmitClaim,
    editClaim,
    removeFromClaim,
    uploadClaimDocument, editClaimDocument, getClaimDocument, deleteClaimDocument,
} from './claim.action';

export {claimReducer} from "./claim.reducer";
export {watchClaim} from "./claim.saga";

export const claimActions = {
    getPendingClaims,
    getSubmittedClaims,
    getClaimById,
    generateClaim,
    editClaim,
    /**Claim Status Actions */
    submitClaim,
    submitClaimById,
    closeClaim,
    refreshClaim,
    resubmitClaim,
    removeFromClaim,
    /**Bill Details */
    bilClaimPmtTr,
    bilInvoicePmt,
    /** Claim Documents */
    uploadClaimDocument,
    editClaimDocument,
    getClaimDocument,
    deleteClaimDocument,
};
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CreateChancel, ValidationInput } from 'components';
import { ErrorText, FindLoad, FindSuccessItem, isNotEmpty, useModal } from 'utils';
import { httpRequestsOnSuccessActions, systemActions } from 'store';

const ACTION_TYPE = 'EDIT_PLACE_BY_ID_GLOBAL';
export const EditPlace = ({ info }) => {
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccessItem(ACTION_TYPE);
    const [inputs, setInputs] = useState(info ? { ...info } : {});
    const [error, setError] = useState('');
    const { close } = useModal();

    useEffect(() => {
        if (success) {
            close();
            setInputs({});
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        error === e.target.name && setError('');
    };

    const handleSubmit = () => {
        const dataIsValid = isNotEmpty(inputs.name) && isNotEmpty(inputs.code);
        if (dataIsValid) {
            const data = {
                name: inputs.name,
                code: inputs.code,
            };
            inputs.modifier ? data.modifier = inputs.modifier : delete data.modifier;
            dispatch(systemActions.editPlaceByIdGlobal(data, info?.id));
        } else {
            setError(
                !inputs.name ? 'name' :
                    !inputs.code ? 'code' :
                        '');
        }
    };

    return (
        <div style={{ width: 400 }}>
            <p className="modal-header-title">Edit Place of Service</p>
            <div className="modal-body-wrapper">
                <ValidationInput
                    label={'Name*'}
                    onChange={handleChange}
                    value={inputs.name}
                    variant={'outlined'}
                    name={'name'}
                    type={'text'}
                    placeholder={'Name*'}
                    typeError={error === 'name' ? `Name ${ErrorText.isRequired}` : ''}
                    Length={50}
                />
                <ValidationInput
                    label={'Code*'}
                    onChange={handleChange}
                    value={inputs.code}
                    variant={'outlined'}
                    name={'code'}
                    type={'number'}
                    placeholder={'Code*'}
                    typeError={error === 'code' ? `Code ${ErrorText.isRequired}` : ''}
                    Length={10}
                />
                <ValidationInput
                    label={'Modifier'}
                    onChange={handleChange}
                    value={inputs.modifier}
                    variant={'outlined'}
                    name={'modifier'}
                    type={'text'}
                    placeholder={'Modifier'}
                    Length={5}
                />
            </div>
            <CreateChancel
                loader={!!loader.length}
                buttonWidth="100%"
                create="Save"
                chancel="Cancel"
                onClose={close}
                onCreate={handleSubmit}
            />
        </div>
    );
};
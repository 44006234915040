import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import { Images } from '../../../utils';

const getNestedError = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

export const DateInputForm = forwardRef(({
                                         label,
                                         labelHeader,
                                         placeholder,
                                         rules,
                                         control,
                                         name,
                                         type = 'text',
                                         maxLength,
                                         minLength,
                                         startAdornment,
                                         endAdornment,
                                         className,
                                         onClickIconStart,
                                         onClickIconEnd,
                                         showPassword,
                                         disabled,
                                         min,
                                         max,
                                         value
                                     }, ref) => {

    const ExampleCustomInput = forwardRef(({ onClick, className }, ref) => {
            return (
                <button type={'button'} className={`${className} space-between`} onClick={onClick} ref={ref}>
                    {value ? <p style={{ color: '#51566D' }}>{value}</p> :
                        <p style={{ color: 'rgba(75, 92, 104, 0.5)' }}>MM/DD/YYYY</p>}
                    <img src={Images.calendarToday} alt={'as'} />
                </button>
            );
        },
    );

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field, formState: { errors } }) => {
                const error = getNestedError(errors, name);
                console.log(rules,'rules');
                return (
                    <div className="date-input-box">
                        <div style={disabled ? { opacity: '0.8'} : {}}>
                            <p style={
                                disabled ? {color:'rgba(0, 0, 0, 0.26)' } :
                                  error ? { color: '#f44336' } :
                                    field?.value ? { color: '#347AF0' } : {}
                            } className="date-input-title">{label}</p>
                            <DatePicker
                                showYearDropdown
                                className={`date-picker-box ${disabled ? 'date-picker-disabled' : error ? 'date-picker-error' : ''}`}
                                isClearable={!!field?.value}
                                showIcon
                                disabled={disabled}
                                onChange={(date) => {
                                    if (date) {
                                        const converted = moment(date);
                                        converted.hour(10);
                                        const momentConverted = moment(converted).format('YYYY-MM-DD');
                                        field.onChange(momentConverted);
                                    } else {
                                        field.onChange(null);
                                    }
                                }}
                                selected={field?.value}
                                customInput={
                                    <ExampleCustomInput
                                        className="example-custom-input"
                                        value={field?.value}
                                    />
                                }
                                minDate={min}
                                maxDate={max}
                                popperProps={{ strategy: "fixed" }}
                            />
                            <p className="error-message">{error?.message}</p>
                        </div>
                    </div>);
            }
            }
        />
    );
});
DateInputForm.displayName = 'DateInputForm';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { Images } from 'utils';
import { Popper } from '@material-ui/core';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { enGB } from 'date-fns/locale';

export const EndDateSelector = ({ filters, setFilters }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const today = moment();
    const isSunday = today.day() === 0;
    const lastSunday = moment().day(0);
    const [state, setState] = useState([{
        startDate: '',
        endDate: '',
        key: 'selection',
    },
    ]);

    useEffect(() => {
        const startOfWeek = isSunday ? today.startOf('week').add(1, 'day') : today.startOf('week').subtract(6, 'days');
        const endOfWeek = startOfWeek.clone().add(6, 'days');
        setState([{
            startDate: startOfWeek.toDate(),
            endDate: endOfWeek.toDate(),
            key: 'selection',
        }]);
        const params = {
            ...filters,
        };
        params.startDate = startOfWeek.toDate();
        params.endDate = endOfWeek.toDate();
        setFilters(params);
    }, []);

    const handleDateChange = (item) => {
        const startDay = moment(item?.selection?.startDate);
        let endDay = moment(item?.selection?.endDate);
        const weekStart = startDay.startOf('isoWeek');

        if (endDay.day() !== 0) {
            endDay = endDay.day(0);
        }

        setState([{
            startDate: weekStart.format(),
            endDate: endDay.format(),
            key: 'selection',
        }]);

        const params = {
            ...filters,
        };
        params.startDate = weekStart.format();
        params.endDate = endDay.format();
        setFilters({ ...params });
    };

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const customLocale = {
        ...enGB,
        options: {
            ...enGB.options,
            weekStartsOn: 1,
        },
    };

    const isNotSunday = (date) => date.getDay() !== 0;

    return (
        <div className="date-selector-box">
            <p className="date-selector-label">Date Rage</p>
            <button className="date-range-selector" aria-describedby={id} type="button"
                    onClick={handleClick}>
                <p>
                    {filters?.startDate ? moment(filters?.startDate).format('MM/DD/YYYY') : 'Start Date'}
                </p>
                <img src={Images.arrowRight} alt="icon" />
                <p>
                    {filters?.endDate ? moment(filters?.endDate).format('MM/DD/YYYY') : 'End Date'}
                </p>
                <img src={Images.calendarToday} alt="icon" />
            </button>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                className={'custom-date-selector-box'}
                placement="bottom-start"
                style={{ position: 'absolute', zIndex: 9999 }}
            >
                <DateRangePicker
                    showDateDisplay={false}
                    ranges={state}
                    onChange={handleDateChange}
                    locale={customLocale}
                    disabledDay={isNotSunday}
                    maxDate={!isSunday ? lastSunday.toDate() : lastSunday.add(6, 'days').toDate()} // Set maxDate to last Sunday if today is not Sunday
                />
            </Popper>
            {open && <div className="beck-drop" onClick={() => setAnchorEl(null)} />}
        </div>
    );
};

import React, { useState, useEffect } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { inputsStyle } from './styles';
import axios from 'axios';
import { Colors, CountryList, isNotEmpty, useGlobalStyles } from 'utils';
import { ErrMessage, SelectInput, ValidationInput } from 'components';
import PlaceIcon from '@material-ui/icons/Place';

export const AddressInput = ({
                                 handleSelectValue,
                                 info,
                                 disabled,
                                 flex,
                                 oneInput,
                                 errorBoolean,
                                 auth,
                                 all,
                                 type,
                                 handleGetAll,
                                 onTrigger,
                                 enteredValue,
                                 noUnit,
                                 label,
                                 notRequired,
                             }) => {
    const classes = inputsStyle();
    const globalInputs = useGlobalStyles();
    const [address, setAddress] = useState('');
    const [error, setError] = useState('');
    const [fullAddress, setFullAddress] = useState('');
    const [loading, setLoading] = useState(false);
    const [unit, setUnit] = useState('');
    const [inputs, setInputs] = useState(info ? (type === 'another' ? { ...info } : { ...info?.address }) : {});
    const Country = inputs.country ? inputs.country : fullAddress.country ? fullAddress.country : all ? all.country : '';
    const City = inputs.city ? inputs.city : fullAddress.city ? fullAddress.city : all ? all.city : '';
    const States = inputs.state ? inputs.state : fullAddress.state ? fullAddress.state : all ? all.state : '';
    const Zip = inputs.zip ? inputs.zip : fullAddress.zip ? fullAddress.zip : all ? all.zip : '';
    const Street = fullAddress ? fullAddress.street : address ? address : all ? all.street : '';
    const fullAddressCompleted = `${Street}${City}${States}${Zip}${Country}`;
    let authPlaceHolder = info ? info.location : 'Service Location*';
    const placeholder = auth ? authPlaceHolder : info?.address?.formattedAddress ? info.address?.formattedAddress : label ? label : `Physical Address${notRequired ? '' : '*'}`;


    useEffect(() => {
        onTrigger({
            address: `${Street} ${City} ${States} ${Zip} ${Country}`,
            unitNumber: unit,
        });
    }, [fullAddressCompleted]);


    useEffect(() => {
        if (enteredValue && !address) {
            setAddress(enteredValue?.formattedAddress ? enteredValue?.formattedAddress : enteredValue?.address);
        }
    }, [enteredValue]);

    useEffect(() => {
        if (enteredValue && !unit) {
            setUnit(enteredValue?.unitNumber);
        }
    }, [enteredValue]);

    const handleChangeAddress = (value, name) => {
        handleSelectValue({
            address: address,
            unitNumber: unit,
        });

        if (name !== 'other' && isNotEmpty(value)) {
            setAddress(value);
        }
    };

    const handleSelect = async (value, ev) => {
        setAddress(value);
        setLoading(true);
        await axios
            .post(`/address`, { address: value })
            .then(function(response) {
                handleSelectValue({
                    address: response.data.formattedAddress,
                    unitNumber: unit,
                });
                setInputs({ ...response.data });
                setFullAddress(response.data);
                setLoading(false);
                handleGetAll && handleGetAll(response.data);
            })
            .catch(function(error) {
            });
    };

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        error === e.target.name && setError(''),
            handleSelectValue({
                address: fullAddressCompleted,
                unitNumber: unit,
            });
    };


    const handleChangeUnit = (e) => {
        setUnit(e.target.value);
        handleSelectValue({
            address: fullAddressCompleted,
            unitNumber: e.target.value,
        });
    };

    return (
        <div style={{ display: flex ? flex : 'flex', width: '100%' }}>
            <>
                <PlacesAutocomplete
                    value={address}
                    onChange={handleChangeAddress}
                    onSelect={(ev) => handleSelect(ev)}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className={!oneInput ? globalInputs.simpleInput : globalInputs.simpleInputFull}>
                            <input
                                style={disabled ? {
                                    opacity: '0.7',
                                    borderColor: 'rgba(0, 0, 0, 0.26)',
                                    background: 'transparent',
                                } : {}}
                                className={!!errorBoolean ? classes.searchAddressError : classes.searchAddress}
                                {...getInputProps({
                                    placeholder: placeholder,
                                    disabled: disabled,
                                    onBlur: (e) => {
                                        handleChangeAddress(e.target.value);
                                    },
                                })}
                            />
                            <ErrMessage text={errorBoolean} />
                            <div>
                                {loading && <div style={{ position: 'absolute' }}>Loading...</div>}
                                <div className={classes.valuesContainer}>
                                    {suggestions.map((suggestion, index) => {
                                        const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                        const style = suggestion.active ? {
                                            margin: '15px',
                                            cursor: 'pointer',

                                        } : { margin: '15px', cursor: 'pointer' };
                                        return (
                                            <div
                                                key={index}
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        textAlign: 'start',
                                                    }}>
                                                    <PlaceIcon style={{ color: Colors.ThemeBlue }} />
                                                    <span
                                                        className={classes.searchfAddressDescriptionText}>{suggestion.description}</span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
                {oneInput && !noUnit &&
                    <ValidationInput
                        variant={'outlined'}
                        name={'unit'}
                        type={'text'}
                        label={'Unit/Suite #'}
                        onChange={handleChangeUnit}
                        value={unit}
                        disabled={disabled}
                    />
                }
            </>
            {!oneInput && (
                <>
                    <SelectInput
                        style={globalInputs.simpleInput}
                        name={'country'}
                        label={`Country${notRequired ? '' : '*'}`}
                        handleBlur={(e) => {
                            handleChangeAddress(e, 'other');
                        }}
                        handleSelect={handleChange}
                        loader={loading}
                        value={Country}
                        list={CountryList}
                        disabled={disabled}
                    />
                    <ValidationInput
                        style={globalInputs.simpleInput}
                        variant={'outlined'}
                        name={'city'}
                        type={'name'}
                        label={`City${notRequired ? '' : '*'}`}
                        handleSendAddress={(e) => {
                            handleChangeAddress(e, 'other');
                        }}
                        onChange={handleChange}
                        value={City}
                        loader={loading}
                        disabled={disabled}
                    />
                    <ValidationInput
                        style={globalInputs.simpleInput}
                        variant={'outlined'}
                        name={'state'}
                        type={'name'}
                        label={'State'}
                        handleSendAddress={(e) => {
                            handleChangeAddress(e, 'other');
                        }}
                        onChange={handleChange}
                        value={States}
                        loader={loading}
                        disabled={disabled}
                    />
                    <ValidationInput
                        variant={'outlined'}
                        name={'zip'}
                        type={'text'}
                        label={'Zip Code'}
                        handleSendAddress={(e) => {
                            handleChangeAddress(e, 'other');
                        }}
                        onChange={handleChange}
                        value={Zip}
                        loader={loading}
                        disabled={disabled}
                    />
                    {!noUnit &&
                        <ValidationInput
                            variant={'outlined'}
                            name={'unit'}
                            type={'text'}
                            label={'Unit/Suite #'}
                            onChange={handleChangeUnit}
                            value={unit}
                            disabled={disabled}
                        />
                    }
                </>
            )}
        </div>
    );
};

import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { leftBarCommonStyle } from './styles';
import { CustomAccordion } from './customAccordion';
import { DrawerContext } from 'utils';

export const CustomListItem = ({ item, accordion }) => {
    const classes = leftBarCommonStyle();
    const location = useLocation();
    const originalPath = location?.pathname?.split('/')[1];
    const { open } = useContext(DrawerContext);

    if (accordion) {
        return (
            <CustomAccordion item={item} />
        );
    }

    return (
        <Link to={item.path}>
            <div className={classes.linkPathWrapper}>
                <div className={item.path === `/${originalPath}` ? classes.activeBorder : classes.passiveBorder} />
                <div
                    style={{ width: open ? '100%' : 'auto', borderRadius: open ? '4px 0px 0px 4px' : '4px' }}
                    className={`${classes.linkWrapper} ${item.path === `/${originalPath}` ? classes.linkWrapperActive : classes.linkWrapperPassive}`}
                >
                    <div className={classes.linkImageWrapper}>
                        <img src={item.path === `/${originalPath}` ? item?.activeIcon : item?.icon} alt="icon" />
                    </div>
                    {open &&
                        <p>{item?.name}</p>
                    }
                </div>
            </div>
        </Link>
    );
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { Drawer } from '@material-ui/core';
import { FindLoad, FindSuccess, Images, PermissionList, RolePermission, useModal } from 'utils';
import {
    AddModalButton, CustomDelByNote,
    CustomDelete,
    CustomizedSwitch,
    DownloadLink,
    ModalContentWrapper,
    SimpleModal,
    NoteDrawer,
} from 'components';
import { scheduleCommonStyle } from './styles';
import { billStatuses, SERVICE } from '../../constants';
import { appointmentActions, httpRequestsOnSuccessActions } from 'store';
import { SignatureUploader } from './signatureUploader';
import { DetailItem } from '../detailItem';
import { apptEnums } from '../constants';

export const SignatureNoteModal = ({ item, closeDetail }) => {
    const { open, close } = useModal();
    const classes = scheduleCommonStyle();
    const defItem = item;
    const [isRequired, setIsRequired] = useState({
        requireSignature: item?.requireSignature ? item?.requireSignature : false,
        requireNote: item?.requireNote ? item?.requireNote : false,
    });
    const dispatch = useDispatch();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const _isServiceAppmt = item?.type === 'SERVICE';
    const _isNotRendered = item?.status === 'PENDING';
    const _isPending = item?.status === 'PENDING';
    const appendSignatureSuccess = FindSuccess('APPEND_SIGNATURE_TO_APPMT');
    const RENDER_COMPLETE_ACTION_TYPE = 'SET_APPOINTMENT_STATUS_COMPLETE';
    const CANCEL_ACTION_TYPE = 'SET_APPOINTMENT_STATUS_CANCEL';
    const [openNotes, setOpenNotes] = useState(false);
    const [currentAppt, setCurrentAppt] = useState();
    const checkSignature = item?.status === 'CANCELLED' ? item?.signature?._id : true;
    const [showLinks, setShowLinks] = useState(false);
    const user = useSelector((state) => state.auth.userInfo);
    const userInfo = user || JSON.parse(localStorage.getItem('wellUserInfo'));
    const checkRenderComplete = item?.staff?.id === userInfo?.id ? true : RolePermission([PermissionList.APPT_RENDER_COMPLETE?.code]);
    const checkCancel = item?.staff?.id === userInfo?.id ? true : RolePermission([PermissionList.APPT_CANCEL?.code]);
    const notes = useSelector((state) => state.note.notes);
    const loadNotes = FindLoad('GET_GLOBAL_NOTES');

    useEffect(() => {
        if (item) {
            setIsRequired({
                requireSignature: item?.requireSignature ? item?.requireSignature : false,
                requireNote: item?.requireNote ? item?.requireNote : false,
            });
        }
    }, [item]);

    useEffect(() => {
        if (item && currentAppt) {
            setCurrentAppt(item);
        }
    }, [item]);

    useEffect(() => {
        if (!!appendSignatureSuccess?.length) {
            setModalIsOpen(false);
            dispatch(
                httpRequestsOnSuccessActions.removeSuccess('APPEND_SIGNATURE_TO_APPMT'),
            );
        }
    }, [appendSignatureSuccess]);

    function handleStatusChange(type, eventType) {
        if (eventType === SERVICE) {
            if (isRequired?.requireSignature && !item?.signature?._id) {
                setModalIsOpen(true);
            } else if (isRequired?.requireNote && !notes?.length) {
                setOpenNotes(true);
                setCurrentAppt(item);
            } else {
                if(closeDetail) {
                    closeDetail();
                }
                open(
                    <CustomDelete
                        text={`Are you sure you want to render this appointment?`}
                        info={`Render This Appointment?`}
                        innerText={`Render Appointment`}
                        handleDel={() => dispatch(appointmentActions.setAppointmentStatus(item?.id, type, ''))}
                        handleClose={() => close()}
                        actionType={RENDER_COMPLETE_ACTION_TYPE}
                        color={'blue'}
                    />,
                );
            }
        } else {
            if(closeDetail) {
                closeDetail();
            }
            open(
                <CustomDelete
                    text={`Are you sure you want to complete this appointment?`}
                    info={`Complete This Appointment?`}
                    innerText={`Complete Appointment`}
                    handleDel={() => dispatch(appointmentActions.setAppointmentStatus(item?.id, type, ''))}
                    handleClose={() => close()}
                    actionType={RENDER_COMPLETE_ACTION_TYPE}
                    color={'blue'}
                />,
            );

        }
    }

    const unRenderAppt = () => {
        if(closeDetail) {
            closeDetail();
        }
        open(
            <CustomDelete
                info={'Un-render Appointment'}
                text={'Are you sure you want to un-render this appointment? Billing  or processed timesheets will not be affected. Active timesheets will be deleted.'}
                innerText={'Un-Render'}
                handleDel={() => dispatch(appointmentActions.unRenderAppt(item?.id))}
                handleClose={() => close()}
                actionType={'UN_RENDER_APPT'}
                color={'blue'}
            />,
        );
    };

    const removeFromBill = () => {
        if(closeDetail) {
            closeDetail();
        }
        open(
            <CustomDelByNote
                info="Remove Appointment from Claim"
                text="Are you sure you want to remove this appointment from the claim?"
                handleDel={(comment) => {
                    dispatch(appointmentActions.removeApptFromBill(item?.id, comment));
                }}
                innerText={'Remove'}
                inputLabel={'Reason for removing*'}
                actionType={'REMOVE_APPT_FROM_BILL'}
            />,
        );
    };

    const handleOpenNotes = (item) => {
        setCurrentAppt(item);
        setOpenNotes(!openNotes);
    };

    return (
        <>
            {item?.type === SERVICE && checkSignature &&
                <div className="signature-process">
                    <p className="schedule-details-title">Signature Details</p>
                    {item?.signatureDate &&
                        <div className="signature-date-time-wrapper">
                            <DetailItem icon={Images.time}
                                        value={moment.utc(item?.signatureDate).format('YYYY-MM-DD h:mm a')}
                            />
                            <div className="location">
                                <DetailItem icon={Images.locationGray}
                                            value={
                                                item?.signatureLocation?.formattedAddress ?
                                                    item?.signatureLocation?.formattedAddress
                                                    :
                                                    'Not Set'
                                            }
                                />
                            </div>
                        </div>
                    }

                    <div>
                        <div className={classes.signatureActionsBoxStyleWrapper}>
                            <div className={classes.signatureActionsBoxStyle}>
                                <div>
                                    {RolePermission([PermissionList.APPT_SIGNATURE_TOGGLE_MANAGE?.code]) &&
                                        _isNotRendered && (
                                            <div className={classes.signatureBoxStyle} style={{opacity:'0.5'}}>
                                                <p className={classes.signatureTextStyle}>
                                                    Require Signature
                                                </p>
                                                <CustomizedSwitch
                                                    disabled={true}
                                                    checked={isRequired?.requireSignature}
                                                    // handleClick={() => handleChangeService('requireSignature')}
                                                />
                                            </div>
                                        )}
                                    {!_isNotRendered && defItem?.signature && (
                                        <div className={classes.signatureCsvWrapper}>
                                            <DownloadLink
                                                linkClassName={classes.downloadSignatureStyle}
                                                linkInnerText={defItem?.signature?.originalName ? defItem?.signature?.originalName : 'Signature.csv'}
                                                file={defItem?.signature}
                                                small={true}
                                            />
                                            <a className={classes.linkBtn} href={defItem?.signature?.url}
                                               target={'_blank'}>
                                                <img src={Images.show} alt="icon" />
                                            </a>
                                        </div>

                                    )}
                                </div>
                                {defItem?.status !== 'CANCELLED' && (
                                    <button
                                        type="button"
                                        className={classes.openModalButnStyle}
                                        onClick={() => setModalIsOpen(true)}
                                    >
                                        <p> Upload Signature</p>
                                        <img src={Images.cloudUpload} alt="icon" />
                                    </button>
                                )}
                            </div>

                            {_isNotRendered && defItem?.signature && (
                                <div className={classes.signatureCsvWrapper}>
                                    <DownloadLink
                                        linkInnerText={defItem?.signature?.originalName ? defItem?.signature?.originalName : 'Signature.csv'}
                                        file={defItem?.signature}
                                        linkClassName={classes.downloadSignatureStyle}
                                        small={true}
                                    />
                                    <a className={classes.linkBtn} href={defItem?.signature?.url} target={'_blank'}>
                                        <img src={Images.show} alt="icon" />
                                    </a>
                                </div>
                            )}

                            <div className="signature-require-and-download-wrapper">
                                {RolePermission([PermissionList.APPT_NOTE_TOGGLE_MANAGE?.code]) &&
                                    _isNotRendered && (
                                        <div className={classes.signatureBoxStyle} style={{opacity:'0.5'}}>
                                            <p className={classes.signatureTextStyle}>
                                                Require Notes
                                            </p>
                                            <CustomizedSwitch
                                                disabled={true}
                                                checked={isRequired?.requireNote}
                                                // handleClick={() => handleChangeService('requireNote')}
                                            />
                                        </div>
                                    )}
                                <div className="upload-download-wrapper">
                                    {item?.status !== 'CANCELLED' && (
                                        <button
                                            type="button"
                                            className={classes.openModalButnStyle}
                                            onClick={() => handleOpenNotes(item)}
                                        >
                                            <p> Manage Notes </p>
                                            <img src={Images.note} alt="icon" />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>

                        <SimpleModal
                            handleOpenClose={() => setModalIsOpen((prevState) => !prevState)}
                            openDefault={modalIsOpen}
                            content={
                                <ModalContentWrapper
                                    wrapperClassName={classes.signatureModalWrapperStyle}
                                    onClose={() => setModalIsOpen(false)}
                                    titleContent={'Upload Signature'}
                                    subtitleContent={
                                        'To complete a service appointment, please upload a signature.'
                                    }
                                >
                                    <SignatureUploader
                                        handleClose={() => setModalIsOpen(false)}
                                        appt={defItem}
                                    />
                                </ModalContentWrapper>
                            }
                            secondary={true}
                        />
                    </div>
                </div>
            }

            {!!item?.links?.length &&
                <div className="signature-process">
                    <button className="link-details-btn" onClick={() => setShowLinks(!showLinks)}>
                        <p className="schedule-details-title" style={{ margin: 0 }}>Attached Links</p>
                        <img
                            style={showLinks ? { transform: 'rotate(180deg)' } : {}}
                            src={Images.dropDown}
                            alt="icon"
                        />
                    </button>

                    <div className="signature-process-links">
                        {showLinks && item?.links?.map((i, j) => (
                            <a key={j}
                               target={'_blank'}
                               href={i?.url}
                               className="schedule-link-wrapper">
                                <img src={Images.externalLink} alt="icon" />
                                <p> {i?.title}</p>
                            </a>
                        ))}
                    </div>
                </div>
            }

            <div>
                {(_isNotRendered || _isPending) ? (
                        <div className={classes.statusActionsBoxStyle}>
                            {checkCancel &&
                                <AddModalButton
                                    buttonClassName={classes.chancelStatusButnStyle}
                                    handleClick={() => open(
                                        <CustomDelete
                                            text={`Are you sure you want to cancel this appointment? This action cannot be undone.`}
                                            info="Cancel Appointment?"
                                            innerText={'Cancel Appointment'}
                                            handleDel={() =>
                                                dispatch(appointmentActions.setAppointmentStatus(item?.id, 'cancel', ''))
                                            }
                                            handleClose={() => close()}
                                            actionType={CANCEL_ACTION_TYPE}
                                            color={'blue'}
                                        />,
                                    )}
                                    text="Cancel Appointment"
                                />
                            }
                            {checkRenderComplete &&
                                <AddModalButton
                                    buttonClassName={classes.changeStatusButnStyle}
                                    text={_isServiceAppmt ? 'Render Appointment' : 'Complete Appointment'}
                                    handleClick={() => handleStatusChange(_isServiceAppmt ? 'render' : 'complete', defItem?.type)}
                                    disabled={!!loadNotes?.length}
                                />
                            }
                        </div>
                    )
                    :
                    _isServiceAppmt && (
                        // item?.billStatus === billStatuses?.NOT_BILLED || item?.billStatus === billStatuses?.BILLED
                        <div className={classes.statusActionsBoxStyle}>
                            {RolePermission([PermissionList.APPT_UNRENDER?.code]) &&
                                 item?.billStatus === billStatuses?.NOT_BILLED && item?.status !== apptEnums.CANCELLED &&
                                <AddModalButton
                                    btnStyles={{width: '250px'}}
                                    buttonClassName={classes.changeStatusButnStyle}
                                    text={'Un-render Appointment'}
                                    handleClick={() => unRenderAppt()}
                                />
                            }
                            {RolePermission([PermissionList.REMOVE_CLAIM_APPT?.code]) && item?.billStatus === billStatuses?.BILLED &&
                                item?.status !== apptEnums.CANCELLED &&
                                <AddModalButton
                                    buttonClassName={classes.changeStatusButnStyle}
                                    text={'Remove from Claim'}
                                    handleClick={() => removeFromBill()}
                                />
                            }
                        </div>
                    )
                }
            </div>

            <Drawer
                className={'note-drawer-modal'}
                anchor={'right'}
                open={openNotes}
                onClose={() => setOpenNotes(false)}
            >
                <NoteDrawer
                    handleClose={() => setOpenNotes(false)}
                    currentId={item?.id}
                    onModel={'Appt'}
                    text={'appointment'}
                    title={'Session'}
                />
            </Drawer>
        </>
    );
};
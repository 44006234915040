import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FundingSourceItem } from 'fragments/fundingSource';
import { fundingSourceActions } from 'store';
import { FindLoad, PermissionList, RolePermission } from 'utils';
import { Loader } from 'components';

export const FundingSourceSingle = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const loader = FindLoad('GET_FUNDING_SOURCE_BY_ID');

    useEffect(() => {
        if (RolePermission([PermissionList.FS_READ?.code, PermissionList.FS_CREATE?.code])) {
            dispatch(fundingSourceActions.getFundingSourceById(params.id));
        }
        if (RolePermission([PermissionList.FS_FILE_READ?.code, PermissionList.FS_FILE_MANAGE?.code])) {
            dispatch(fundingSourceActions.getFsDocument(params.id));
        }
    }, []);

    if (loader.length) {
        return <div style={{ height: '85vh' }}><Loader /></div>;
    }

    return (
        <FundingSourceItem />
    );
};

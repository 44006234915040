import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
// import { generateClaimStyle } from './styles';
import { CreateChancel, FullTable, SinglePagination } from 'components';
import { FindLoad, FindSuccessItem, getSkipCount, PermissionList, RolePermission, SendPageSave, useModal } from 'utils';
import { adminActions, claimActions, httpRequestsOnSuccessActions, staffPayrollActions, systemActions } from 'store';
import { ACTION_TYPE, processPayrollHead, processPayrollBody } from './constants';
import { generateClaimStyle } from '../../../fragments/billing/claims/generateClaim.js/styles';
import { ProcessModalFilter } from './processModalFilter';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';


const GENERATE_ACTION_TYPE = 'CREATE_PROCESS_PAYROLL';
export const ProcessModal = ({}) => {
    const { activeProcessPayroll } = useSelector((state) => ({
        activeProcessPayroll: state?.staffPayroll?.activeProcessPayroll,
    }));
    const { close } = useModal();
    const classes = generateClaimStyle();
    const dispatch = useDispatch();
    const loader = FindLoad(GENERATE_ACTION_TYPE);
    const success = FindSuccessItem(GENERATE_ACTION_TYPE);
    const history = useHistory();
    const today = moment();
    const isSunday = today.day() === 0;
    const startOfWeek = isSunday ? today.startOf('week').add(1, 'day') : today.startOf('week').subtract(6, 'days');
    const endOfWeek = startOfWeek.clone().add(6, 'days');
    const [filters, setFilters] = useState({
        page: 1,
        startDate: startOfWeek.toDate(),
        endDate: endOfWeek.toDate(),
    });

    const renderParams = () => {
        const filteredInfo = {
            ...filters,
        };
        filteredInfo.skip = filters?.page ? getSkipCount(filters?.page,  50) : 0;
        filteredInfo.limit = 50;
        delete filteredInfo.page;
        delete filteredInfo.activeTab;

        if (filters?.staffs?.length) {
            filteredInfo.staffs = filters?.staffs?.map((i) => i.id);
        } else {
            delete filteredInfo.staffs;
        }

        if(filters?.startDate){
            filteredInfo.startDate = moment(filters?.startDate).format('YYYY-MM-DD');
        }else{
            delete filteredInfo.startDate;
        }
        if(filters?.endDate){
            filteredInfo.endDate = moment(filters?.endDate).format('YYYY-MM-DD');
        }else{

        }
        return filteredInfo;
    };

    useEffect(() => {
        dispatch(staffPayrollActions.getActiveProcessPayroll(renderParams()));
    }, [filters]);

    useEffect(() => {
        if (success) {
            history.push('/activePayroll');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(GENERATE_ACTION_TYPE));
            close();
        }
    }, [success]);

    const changePage = (number) => {
        const params = {
            ...filters,
        };
        params.page = number;
        setFilters(params);
    };

    return (
        <div className="generate-modal">
            <div>
                <div className={classes.generateClaimActionsStyle} style={{ margin: 0 }}>
                    <ProcessModalFilter filters={filters} setFilters={setFilters} />
                </div>
                <div className="matching-box">
                    <p>Timesheets to Process</p>
                    <div>
                        {`${activeProcessPayroll?.count ? activeProcessPayroll?.count : 0} matching`}
                    </div>
                </div>
                <FullTable
                    head={processPayrollHead}
                    body={processPayrollBody}
                    loadingType={ACTION_TYPE}
                    list={activeProcessPayroll?.timesheets}
                    noText={'appointments'}
                    generateTable={true}
                    height={'medium'}
                />
            </div>
            <div className={classes.notClaimedBillsFooterStyle}>
                <SinglePagination page={filters?.page} count={activeProcessPayroll?.count} changePage={changePage} />
                {RolePermission([PermissionList.TIMESHEET_PROCESS?.code]) &&
                    <CreateChancel
                        disabled={!activeProcessPayroll?.timesheets?.length}
                        classes={classes.generateOrCancelButnStyle}
                        loader={!!loader?.length}
                        create={'Process Payroll'}
                        chancel={'Cancel'}
                        onCreate={() => {
                            const sendParams = {
                                ...renderParams(),
                            };
                            delete sendParams.limit;
                            delete sendParams.skip;
                            delete sendParams.page;
                            dispatch(staffPayrollActions.createProcessPayroll(sendParams));
                        }
                        }
                        onClose={() => close()}
                    />
                }
            </div>
        </div>
    );
};

import {
    CREATE_PROCESS_PAYROLL, DELETE_PAYROLL, DELETE_PAYROLL_DOCUMENT, EDIT_PAYROLL_DOCUMENT,
    GET_ACTIVE_PAYROLL, GET_ACTIVE_PROCESS_PAYROLL,
    GET_PAYROLL_BY_ID, GET_PAYROLL_DOCUMENT,
    GET_PROCESSED_PAYROLL, RESOLVE_PAYROLL, UPLOAD_PAYROLL_DOCUMENT,
} from './staffPayroll.types';

/** Active Payroll */

export const getActivePayroll = (params, load) => {
    return {
        type: GET_ACTIVE_PAYROLL,
        payload: { params, load },
    };
};
export const getActiveProcessPayroll = (params) => {
    return {
        type: GET_ACTIVE_PROCESS_PAYROLL,
        payload: { params },
    };
};

export const createProcessPayroll = ( params) => {
    return {
        type: CREATE_PROCESS_PAYROLL,
        payload: { params },
    };
};
export const getPayrollById = (id) => {
    return {
        type: GET_PAYROLL_BY_ID,
        payload: { id },
    };
};
export const deletePayroll = (id) => {
    return {
        type: DELETE_PAYROLL,
        payload: { id },
    };
};

export const resolvePayroll = (id, params) => {
    return {
        type: RESOLVE_PAYROLL,
        payload: { id, params },
    };
};

/** End */

/** Processed Payroll */

export const getProcessedPayroll = (params, load) => {
    return {
        type: GET_PROCESSED_PAYROLL,
        payload: { params, load },
    };
};

/** End */

/** Payroll Documents */
export const uploadPayrollDocument = (file, id) => {
    return {
        type: UPLOAD_PAYROLL_DOCUMENT,
        payload: {file, id}
    }
}

export const editPayrollDocument = (id, dockId, file) => {
    return {
        type: EDIT_PAYROLL_DOCUMENT,
        payload: {id, dockId, file}
    }
}

export const getPayrollDocument = (id) => {
    return {
        type: GET_PAYROLL_DOCUMENT,
        payload: {id}
    }
}

export const deletePayrollDocument = (payrollId, id) => {
    return {
        type: DELETE_PAYROLL_DOCUMENT,
        payload: {payrollId, id}
    }
}

/** End */
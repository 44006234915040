import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CreateChancel, UserTextArea } from 'components';
import { ErrorText, FindLoad, FindSuccessItem, useModal } from 'utils';
import { modalsStyle } from '../../../components/modal/styles';
import { httpRequestsOnSuccessActions, staffPayrollActions } from 'store';

const RESOLVE_PAYROLL = 'RESOLVE_PAYROLL';
export const Resolve = ({ id, displayId, afterSuccess }) => {
    const dispatch = useDispatch();
    const classes = modalsStyle();
    const loader = FindLoad(RESOLVE_PAYROLL);
    const success = FindSuccessItem(RESOLVE_PAYROLL);
    const [comment, setComment] = React.useState('');
    const [error, setError] = React.useState('');
    const { close } = useModal();

    useEffect(() => {
        if(success){
            afterSuccess()
            close()
            dispatch(httpRequestsOnSuccessActions.removeSuccess(RESOLVE_PAYROLL))
        }
    }, [success]);

    const handleResolve = () => {
        if (comment) {
            dispatch(staffPayrollActions.resolvePayroll(id, { reason: comment }));
        } else {
            setError('resolveComment');
        }
    };

    const handleChange = (event) => {
        if(error) setError('')
        setComment(event.target.value);
    };

    return (
        <div style={{ width: 380 }}>
            <p className="modal-header-title">Confirm Resolution</p>
            <p className="modal-header-sub" style={{ margin: '12px 0 24px' }}>
                Are you sure you want to mark this issue as resolved? This action will
                remove the flag and update the appointment status.
            </p>
            <div className="deleting-client-info-box" style={{ alignItems: 'flex-start' }}>
                <p style={{ fontWeight: 400, whiteSpace: 'nowrap' }}>Appointment ID:</p>
                <a target={'_blank'} href={`/schedule`}>{displayId ? displayId : 'N/A'}</a>
            </div>

            <div className="modal-body-wrapper">
                <UserTextArea
                    margin={'0'}
                    className={classes.commentTextAreaStyle}
                    id={'resolveComment'}
                    name={'resolveComment'}
                    label={'Add your note here*'}
                    value={comment}
                    onChange={handleChange}
                    hasText={!!comment}
                    typeError={error === 'resolveComment' && `Note ${ErrorText.isRequired}`}
                />
            </div>
            <CreateChancel
                buttonWidth={'100%'}
                loader={!!loader.length}
                create={'Resolve'}
                chancel={'Cancel'}
                onCreate={handleResolve}
                onClose={close}
            />
        </div>
    );
};
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    httpRequestsOnErrorsActions,
    httpRequestsOnSuccessActions,
    httpRequestsOnLoadActions,
} from 'store';
import { claimService } from './claim.service';
import {
    CLOSE_CLAIM,
    GENERATE_CLAIM,
    GET_CLAIMS,
    GET_CLAIMS_SUCCESS,
    GET_CLAIM_BY_ID,
    GET_CLAIM_BY_ID_SUCCESS,
    SUBMIT_CLAIM,
    GET_BILL_INVOICE_PAYMENT,
    GET_BILL_INVOICE_PAYMENT_SUCCESS,
    GET_BILL_CLAIM_PAYMENT_TRANSACTIONS,
    GET_BILL_CLAIM_PAYMENT_TRANSACTIONS_SUCCESS,
    GET_SUBMITTED_CLAIMS,
    GET_SUBMITTED_CLAIMS_SUCCESS,
    SUBMIT_CLAIM_BY_ID,
    REFRESH_CLAIM,
    RESUBMIT_CLAIM,
    EDIT_CLAIM,
    REMOVE_APPT_FROM_CLAIM,
    REFRESH_CLAIM_SUCCESS,
    UPLOAD_CLAIM_DOCUMENT, EDIT_CLAIM_DOCUMENT, GET_CLAIM_DOCUMENT, DELETE_CLAIM_DOCUMENT, GET_CLAIM_DOCUMENT_SUCCESS,
} from './claim.type';

function* getClaims(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    try {
        const res = yield call(claimService.getClaimsService, action?.payload?.data);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_CLAIMS_SUCCESS,
            payload: { claims: res.data },
        });
    } catch (err) {
        yield put({
            type: GET_CLAIMS_SUCCESS,
            payload: { claims: [] },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* getSubmittedClaims(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    try {
        const res = yield call(claimService.getSubmittedClaimsService, action?.payload?.data);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));

        yield put({
            type: GET_SUBMITTED_CLAIMS_SUCCESS,
            payload: res?.data,
        });
    } catch (err) {
        yield put({
            type: GET_SUBMITTED_CLAIMS_SUCCESS,
            payload: { claims: [] },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* getClaimById(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    if (action.payload.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    try {
        const res = yield call(claimService.getClaimByIdService, action?.payload?.id);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_CLAIM_BY_ID_SUCCESS,
            payload: { claimById: res.data },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* generateClaim(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(claimService.generateClaimService, action?.payload?.group, action?.payload?.merge);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editClaim(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(claimService.editClaimService, action?.payload?.id, action?.payload?.body);
        const res = yield call(claimService.getClaimByIdService, action?.payload?.id);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_CLAIM_BY_ID_SUCCESS,
            payload: { claimById: res.data },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/**Claim Status Actions */
function* closeClaim(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(
            claimService.closeClaimService,
            action.payload.id,
            action.payload.details,
        );
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_CLAIM_BY_ID,
            payload: { id: action.payload.id },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* submitClaim(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(claimService.submitClaimService, action?.payload?.id);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* submitClaimById(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(claimService.submitClaimService, action?.payload?.id);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));

        yield put({
            type: GET_CLAIM_BY_ID,
            payload: { id: action?.payload?.id, load: 'noLoad' },
        });

    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* refreshClaim(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(claimService.refreshClaimService, action?.payload?.id);
        yield put({
            type: REFRESH_CLAIM_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* resubmitClaim(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(claimService.resubmitClaimService, action?.payload?.id);
        const res = yield call(claimService.getClaimByIdService, action?.payload?.id);
        yield put({
            type: GET_CLAIM_BY_ID_SUCCESS,
            payload: { claimById: res.data },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* removeFromClaim(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(claimService.removeFromClaimService, action?.payload?.id, action?.payload?.reason);
        const res = yield call(claimService.getClaimByIdService, action?.payload?.claimId);
        yield put({
            type: GET_CLAIM_BY_ID_SUCCESS,
            payload: { claimById: res.data },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

/** End */
function* billClaimPmtTr(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        const res = yield call(claimService.billClaimPmtService, action.payload.id);
        yield put({
            type: GET_BILL_CLAIM_PAYMENT_TRANSACTIONS_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* billInvoicePmt(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        const res = yield call(claimService.billInvoicePmtService, action?.payload?.ids);
        yield put({
            type: GET_BILL_INVOICE_PAYMENT_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

/** Claim Documents */
function* uploadClaimDocument(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(claimService.uploadClaimDocumentService, action.payload.file, action.payload.id);
        yield put({
            type: GET_CLAIM_DOCUMENT,
            payload: { id: action?.payload?.id, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editClaimDocument(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(claimService.editClaimDocumentService, action?.payload);
        yield put({
            type: GET_CLAIM_DOCUMENT,
            payload: { id: action?.payload?.id, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* getClaimDocument(action) {
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(claimService.getClaimDocumentService, action.payload.id);
        yield put({
            type: GET_CLAIM_DOCUMENT_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* deleteClaimDocument(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(claimService.deleteClaimDocumentService, action.payload.claimId, action.payload.id);
        yield put({
            type: GET_CLAIM_DOCUMENT,
            payload: { id: action?.payload?.claimId, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** End */

export const watchClaim = function* watchClaimSaga() {
    yield takeLatest(GET_CLAIMS, getClaims);
    yield takeLatest(GET_SUBMITTED_CLAIMS, getSubmittedClaims);
    yield takeLatest(GET_CLAIM_BY_ID, getClaimById);
    yield takeLatest(GENERATE_CLAIM, generateClaim);
    yield takeLatest(EDIT_CLAIM, editClaim);
    /**Claim Status Actions */
    yield takeLatest(CLOSE_CLAIM, closeClaim);
    yield takeLatest(SUBMIT_CLAIM, submitClaim);
    yield takeLatest(SUBMIT_CLAIM_BY_ID, submitClaimById);
    yield takeLatest(REFRESH_CLAIM, refreshClaim);
    yield takeLatest(RESUBMIT_CLAIM, resubmitClaim);
    yield takeLatest(REMOVE_APPT_FROM_CLAIM, removeFromClaim);
    /**Bill Details */
    yield takeLatest(GET_BILL_CLAIM_PAYMENT_TRANSACTIONS, billClaimPmtTr);
    yield takeLatest(GET_BILL_INVOICE_PAYMENT, billInvoicePmt);
    /** Claim Documents */
    yield takeLatest(UPLOAD_CLAIM_DOCUMENT, uploadClaimDocument);
    yield takeLatest(EDIT_CLAIM_DOCUMENT, editClaimDocument);
    yield takeLatest(GET_CLAIM_DOCUMENT, getClaimDocument);
    yield takeLatest(DELETE_CLAIM_DOCUMENT, deleteClaimDocument);
    /** End */
};

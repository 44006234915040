import React, {useCallback, useState} from "react";
import {useHistory} from "react-router-dom";
import {Paper, Table, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {FindLoad, SendPageSave, useGlobalStyles, SavePage,} from "utils";
import {Loader, TableNoInfo,} from "components";
import {tableStyle} from "../table/styles";
import {PaginationFillTable} from '../pagination';
import {SearchAndFilerFull} from "./searchAndFilerFull";

export const ModalFullTable = ({
                              head,
                              body,
                              entries,
                              list,
                              loadingType,
                              handleClick,
                              noText,
                              margin,
                              listCount,
                              handleClickButton,
                              activeRowId,
                          }) => {

    const classes = tableStyle();
    const globalStyle = useGlobalStyles();
    const [currentWidth, setCurrentWidth] = useState(0);
    const [page, setPage] = useState(1);
    const history = useHistory()
    const info = history?.location?.state
    const loader = FindLoad(loadingType)

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const changePage = (number) => {
        setPage(number);
        SendPageSave(number, info, history)
    };

    const handleName = (item, name) => {
        handleClickButton(item, name)
    }

    const renderItems = (i, item) => {
        if (item?.button) {
            return item?.button((name) => handleName(i, name), i)
        } else {
            return item?.rowText(i)
        }
    }

    const handle = (e) => {
        if (e.target.value === 'All') {
            const cleanList = {
                ...info
            }
            delete cleanList[e.target.name]
            SavePage(history, info, {...cleanList})
        } else {
            if (!e.target.value) {
                const filteredList = {
                    ...info
                }
                delete filteredList[e.target.name]
                SavePage(history, info, {...filteredList})
            } else {
                const newObj = {
                    ...info
                }
                newObj[e.target.name] = e.target.value
                SavePage(history, info, {...newObj})
            }
        }
    }

    const setName = (name) => {
        const anotherInfo = {...info}
        if (name) {
            anotherInfo.sortType = name
        } else {
            delete anotherInfo.sortType
        }
        SavePage(history, info, {...anotherInfo})
    }

    if (loader?.length) return <Loader height={'300px'} />

    return (
        <div style={{width: '100%'}}>
            <div style={margin ? {margin: 0} : {}} className={globalStyle.tableWrapper}>
                <TableContainer className={globalStyle.tableContainer} style={{height:'auto'}} component={Paper}>
                    <Table
                        stickyHeader
                        className={globalStyle.table}
                        size="small"
                        aria-label="a dense table"
                    >
                        <TableHead className={classes.modalTableHead}>
                            <TableRow>
                                {head ? head.map((i, j) => i && (
                                    <TableCell key={j} style={i?.width ? { width: i.width } : {}}>
                                        <SearchAndFilerFull
                                            setSelectedFilter={handle}
                                            setSelectedName={setName}
                                            selectedFilter={info?.type ? info?.type : 'All'}
                                            item={i}
                                        />
                                    </TableCell>
                                )) : ''}
                            </TableRow>
                        </TableHead>

                        <tbody>
                        {list?.length ? list?.map((i, j) => (
                                <React.Fragment key={j}>
                                    <TableRow className={classes.modalTableRow} style={{height:'36px'}}>
                                        {body?.map((item, ke) => {
                                            if(item) {
                                                return (
                                                    <TableCell
                                                        ref={curr}
                                                        onClick={() => item?.notClickable !== true && handleClick && handleClick(i?.id || i?._id)}
                                                        style={{ color: '#51566D', height: '36px' }} key={ke}
                                                    >
                                                        {renderItems(i, item, currentWidth) || 'Not Set'}
                                                    </TableCell>
                                                )
                                            }
                                        })}
                                    </TableRow>
                                </React.Fragment>
                            ))
                            :
                            <TableNoInfo
                                text={`No invoice payment transaction yet`}
                                flexCenter={true}
                                styles={{ height: '200px' }}
                            />
                        }
                        </tbody>
                    </Table>

                    {listCount > 0 ?
                        <div style={{height: '76px'}}>
                            <PaginationFillTable
                                listLength={list?.length}
                                entries={entries}
                                page={page}
                                handleReturn={(number) => changePage(number)}
                                count={listCount}
                            />
                        </div>
                        : ''
                    }
                </TableContainer>
            </div>
        </div>
    )
}
import React from 'react';
import { GenerateClaimFragment } from 'fragments';

export const GenerateClaim = () => {

    return (
        <>
            <GenerateClaimFragment />
        </>
    );
};

import React, { useEffect, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import PlaceIcon from '@material-ui/icons/Place';
import { Colors, useGlobalStyles } from 'utils';
import { AddressService } from 'store';
import { inputsStyle } from './styles';
import { ErrMessage } from '../messages';
import { ValidationInput } from './validationInput';
import { InputMinLoader } from './inputMiniLoader';

export const Address = ({ disabled, placeholder, noUnit, oneInput, errMessage, setAddress, defaultValue }) => {
    const classes = inputsStyle();
    const globalInputs = useGlobalStyles();
    const [error, setError] = useState('');
    const [load, setLoad] = useState(false);
    const [inputs, setInputs] = useState();

    useEffect(() => {
        if (defaultValue && !inputs) {
            const params = {};
            defaultValue?.unitNumber ? params.unitNumber = defaultValue?.unitNumber : delete params.unitNumber;
            defaultValue?.formattedAddress ? params.address = defaultValue?.formattedAddress : delete params.address;
            defaultValue?.city ? params.city = defaultValue?.city : delete params.city;
            defaultValue?.state ? params.state = defaultValue?.state : delete params.state;
            defaultValue?.zip ? params.zip = defaultValue?.zip : delete params.zip;
            defaultValue?.country ? params.country = defaultValue?.country : delete params.country;
            defaultValue?.lat ? params.lat = defaultValue?.lat : delete params.lat;
            defaultValue?.lng ? params.lng = defaultValue?.lng : delete params.lng;
            setInputs(params);
        }
    }, [defaultValue]);

    useEffect(() => {
        if (setAddress) {
            setAddress({
                address: inputs?.address,
                unitNumber: inputs?.unitNumber,
            });
        }
    }, [inputs]);

    const handleSelect = async (value) => {
        setLoad(true);
        AddressService.getAddress(value).then((response) => {
            const { data } = response;
            const params = {};
            inputs?.unitNumber ? params.unitNumber = inputs?.unitNumber : delete params.unitNumber;
            data?.formattedAddress ? params.address = data?.formattedAddress : delete params.address;
            data?.city ? params.city = data?.city : delete params.city;
            data?.state ? params.state = data?.state : delete params.state;
            data?.zip ? params.zip = data?.zip : delete params.zip;
            data?.country ? params.country = data?.country : delete params.country;
            data?.lat ? params.lat = data?.lat : delete params.lat;
            data?.lng ? params.lng = data?.lng : delete params.lng;
            setInputs(params);
        }).finally(() => {
            setLoad(false);
        });
    };

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        error === e.target.name && setError('');
    };

    const handleChangeAddress = (value) => {
        setInputs((prevState) => ({ ...prevState, ['address']: value }));
    };

    return (
        <div className="address-wrapper">
            <>
                <PlacesAutocomplete
                    value={inputs?.address}
                    onChange={handleChangeAddress}
                    onSelect={(ev) => handleSelect(ev)}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className={!oneInput ? globalInputs.simpleInput : globalInputs.simpleInputFull}>
                            <p
                                style={disabled && !inputs?.address? { color: 'rgba(0, 0, 0, 0.38)' } : {}}
                                className={
                                    inputs?.address ? 'places-autocomplete-label-active' : 'places-autocomplete-label'}>{placeholder}</p>
                            <input
                                style={disabled ? {
                                    opacity: '0.7',
                                    borderColor: 'rgba(0, 0, 0, 0.26)',
                                    background: 'transparent',
                                } : {}}
                                className={!!errMessage ? classes.searchAddressError : classes.searchAddress}
                                {...getInputProps({
                                    // placeholder: placeholder,
                                    disabled: disabled,
                                    onBlur: (e) => {
                                        handleChangeAddress(e.target.value);
                                    },
                                })}
                            />
                            {load &&
                                <InputMinLoader style={{marginTop:'14px'}} />
                            }

                            <ErrMessage text={errMessage} />

                            <div>
                                {loading && <div style={{ position: 'absolute' }}>Loading...</div>}
                                <div className={classes.valuesContainer}>
                                    {suggestions.map((suggestion, index) => {
                                        const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                        const style = suggestion.active ? {
                                            margin: '15px',
                                            cursor: 'pointer',

                                        } : { margin: '15px', cursor: 'pointer' };
                                        return (
                                            <div
                                                key={index}
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}>
                                                <div
                                                    className="suggestion-box"
                                                >
                                                    <PlaceIcon style={{ color: Colors.ThemeBlue }} />
                                                    <span
                                                        className={classes.searchfAddressDescriptionText}>{suggestion.description}</span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
            </>

            {!noUnit &&
                <ValidationInput
                    variant={'outlined'}
                    name={'unitNumber'}
                    type={'text'}
                    label={'Unit/Suite #'}
                    onChange={handleChange}
                    value={inputs?.unitNumber}
                    disabled={disabled}
                />
            }

            {/*{!oneInput && (*/}
            {/*    <>*/}
            {/*        <ValidationInput*/}
            {/*            style={globalInputs.simpleInput}*/}
            {/*            variant={'outlined'}*/}
            {/*            name={'city'}*/}
            {/*            type={'name'}*/}
            {/*            label={`City`}*/}
            {/*            onChange={handleChange}*/}
            {/*            value={inputs?.city}*/}
            {/*            disabled={disabled}*/}
            {/*        />*/}
            {/*        <ValidationInput*/}
            {/*            style={globalInputs.simpleInput}*/}
            {/*            variant={'outlined'}*/}
            {/*            name={'state'}*/}
            {/*            type={'name'}*/}
            {/*            label={'State'}*/}
            {/*            onChange={handleChange}*/}
            {/*            value={inputs?.state}*/}
            {/*            disabled={disabled}*/}
            {/*        />*/}
            {/*        <ValidationInput*/}
            {/*            variant={'outlined'}*/}
            {/*            name={'zip'}*/}
            {/*            type={'text'}*/}
            {/*            label={'Zip Code'}*/}
            {/*            onChange={handleChange}*/}
            {/*            value={inputs?.zip}*/}
            {/*            disabled={disabled}*/}
            {/*        />*/}
            {/*    </>*/}
            {/*)}*/}

        </div>
    );
};
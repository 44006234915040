import { call, put, takeLatest } from "redux-saga/effects";
import {
   httpRequestsOnErrorsActions,
   httpRequestsOnSuccessActions,
   httpRequestsOnLoadActions,
} from "store";
import { invoiceService } from "./invoice.service";
import {
   DELETE_INVOICE,
   EDIT_INVOICE,
   GENERATE_INVOICE,
   GET_INVOICES,
   GET_INVOICES_SUCCESS,
   GET_INVOICE_BY_ID,
   GET_INVOICE_BY_ID_SUCCESS,
   GET_INVOICES_FOR_GENERATE,
   GET_INVOICES_FOR_GENERATE_SUCCESS,
   UPLOAD_INVOICE_DOCUMENT,
   EDIT_INVOICE_DOCUMENT,
   GET_INVOICE_DOCUMENT,
   DELETE_INVOICE_DOCUMENT,
   GET_INVOICE_DOCUMENT_SUCCESS,
   GET_INVOICE_TRANSACTION, GET_INVOICE_TRANSACTION_SUCCESS, CLOSE_INVOICE,
} from './invoice.type';


function* getInvoices(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   try {
      const res = yield call(invoiceService.getInvoicesService, action?.payload?.data);
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put({
         type: GET_INVOICES_SUCCESS,
         payload: { invoices: res.data },
      });
   } catch (err) {
      yield put({
         type: GET_INVOICES_SUCCESS,
         payload: { invoices: [] },
         // payload: { invoices: { invoices: [], count: 0 } },
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      if (err?.data?.message === "Internal server error") {
         yield put(
            httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message)
         );
      }
   }
}

function* getInvoiceById(action) {
   if(action?.payload?.load !== 'noLoad'){
      yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   }
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   try {
      const res = yield call(invoiceService.getInvoiceByIdService, action.payload.id);
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put({
         type: GET_INVOICE_BY_ID_SUCCESS,
         payload: { invoiceById: res.data },
      });
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      if (err?.data?.message === "Internal server error") {
         yield put(
            httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message)
         );
      }
   }
}

function* generateInvoice(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(invoiceService.generateInvoiceService, action.payload.body);
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
      // window.location.replace("/invoices");
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* editInvoice(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(
         invoiceService.editInvoiceService,
         action.payload.id,
         action.payload.body
      );
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
      yield put({
         type: GET_INVOICE_BY_ID,
         payload: { id: action.payload.id },
      });
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* deleteInvoice(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(invoiceService.deleteInvoiceService, action.payload.id);
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
      yield put({
         type: GET_INVOICE_BY_ID,
         payload: { id: action.payload.id },
      });
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* getInvoiceTxns(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   try {
      const res = yield call(invoiceService.getInvoiceTxnsService, action?.payload?.ids);
      yield put({
         type: GET_INVOICE_TRANSACTION_SUCCESS,
         payload: res.data,
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* closeInvoice(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(invoiceService.closeInvoiceService, action?.payload?.id, action?.payload?.comment);
      yield put({
         type: GET_INVOICE_BY_ID,
         payload: { id: action?.payload?.id, load: 'noLoad'},
      });
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

/**Get Invoices for generate  */

function* getInvoiceGenerate(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      const res = yield call(invoiceService.getInvoiceForGenerateService, action?.payload?.params);
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
      yield put({
         type: GET_INVOICES_FOR_GENERATE_SUCCESS,
         payload: res.data,
      });
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

/** Invoice Documents */

function* uploadInvoiceDocument(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(invoiceService.uploadDocumentService, action.payload.file, action.payload.id);
      yield put({
         type: GET_INVOICE_DOCUMENT,
         payload: { id: action?.payload?.id, load: 'noLoad' },
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* editInvoiceDocument(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(invoiceService.editDocumentService, action?.payload);
      yield put({
         type: GET_INVOICE_DOCUMENT,
         payload: { id: action?.payload?.id, load: 'noLoad' },
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* getInvoiceDocument(action) {
   if (action?.payload?.load !== 'noLoad') {
      yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   }
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   try {
      const res = yield call(invoiceService.getDocumentService, action.payload.id);
      yield put({
         type: GET_INVOICE_DOCUMENT_SUCCESS,
         payload: res?.data,
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* deleteInvoiceDocument(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(invoiceService.deleteDocumentService, action.payload.invoiceId, action.payload.id);
      yield put({
         type: GET_INVOICE_DOCUMENT,
         payload: { id: action?.payload?.invoiceId, load: 'noLoad' },
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
      yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
   } catch (err) {
      yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

/** End */
export const watchInvoice = function* watchInvoiceSaga() {
   yield takeLatest(GET_INVOICES, getInvoices);
   yield takeLatest(GET_INVOICE_BY_ID, getInvoiceById);
   yield takeLatest(GENERATE_INVOICE, generateInvoice);
   yield takeLatest(EDIT_INVOICE, editInvoice);
   yield takeLatest(DELETE_INVOICE, deleteInvoice);
   yield takeLatest(GET_INVOICE_TRANSACTION, getInvoiceTxns);
   yield takeLatest(CLOSE_INVOICE, closeInvoice);

   /**Get Invoices for generate  */
   yield takeLatest(GET_INVOICES_FOR_GENERATE, getInvoiceGenerate);
  
   /** Invoice Documents */
   yield takeLatest(UPLOAD_INVOICE_DOCUMENT, uploadInvoiceDocument);
   yield takeLatest(EDIT_INVOICE_DOCUMENT, editInvoiceDocument);
   yield takeLatest(GET_INVOICE_DOCUMENT, getInvoiceDocument);
   yield takeLatest(DELETE_INVOICE_DOCUMENT, deleteInvoiceDocument);
   /** End */
};

import moment from 'moment';
import { FullTable } from 'components';
import React from 'react';
import { moreApptBody, moreApptHead } from './constants';

export const MoreAppts = ({ appts, day, handleOpenDetail }) => {
    return(
        <div className='more-appts-modal'>
            <div className='space-between more-appt-title'>
                <p>Events List</p>
                <p>{moment(day).format('MMM D, dddd')}</p>
            </div>
            <FullTable
                head={moreApptHead}
                body={moreApptBody}
                list={appts}
                noText={'appointments'}
                generateTable={true}
                height={'auto'}
                handleClick={(e) => handleOpenDetail(e)}
            />
        </div>
    )
}
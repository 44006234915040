import React from "react";
import { useHistory } from 'react-router-dom';
import { helpCenterCardInfo } from "./fragments/constants";
import { HelpCenterCard } from "./fragments";

export const HelpCenterPage = () => {
  const history = useHistory();

  return (
    <div className='help-center-page gt-walsheim'>
      <div className="HelpCenterPage-box">
        <h2 className="title">
          Welcome to Our Help Center!
        </h2>
        {/*<Icons.halfLogo1Svg className="HelpCenterPage-HalfLogo1Svg" />*/}
      </div>

      <div className="card-list">
        {helpCenterCardInfo.map(({ title, description, Icon,path }, index) => (
          <HelpCenterCard
            onClick={()=> history.push(path)}
            key={index}
            Icon={Icon}
            title={title}
            description={description}
          />
        ))}
      </div>

    </div>
  );
};


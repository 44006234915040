import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RadioButton, DateRow, StatusRow, TextRow, CustomizedSwitch, MinLoader } from 'components';
import { Colors, FindLoad, useModal } from 'utils';
import { authHeaderStyles } from './styles';
import { clientActions } from 'store';

const checkboxStyle = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
};

const ACTION_TYPE = 'EDIT_CLIENT_AUTHORIZATION';
export const PastAuthorizations = ({ authIndex, setAuthIndex }) => {
    const dispatch = useDispatch();
    const classes = authHeaderStyles();
    const clientsAuthorizations = useSelector((state) => state.client.clientsAuthorizations);
    const client = useSelector((state) => state.client.clientItemInfo);
    const [selected, setSelected] = useState(authIndex?.id ? authIndex?.id : '');
    const { close } = useModal();
    const [currentId, setCurrentId] = useState(false);
    const loader = FindLoad(ACTION_TYPE);

    const change = (item) => {
        setSelected(item?.id);
        setAuthIndex(item);
        close();
    };

    const changeVisible = (i) => {
        setCurrentId(i?.id);
        const data = {
            authId: authIndex.authId,
            startDate: authIndex.startDate,
            endDate: authIndex.endDate,
            location: authIndex?.address,
            enrollmentId: authIndex?.id,
            status: authIndex?.status,
            canUsed: !i?.canUsed,
        };
        dispatch(clientActions.editClientsAuthorizations(data, i?.id, client?.id));
    };

    const renderItemBody = (i, k) => {
        return (
            <div className={classes.pastAuthorizationsTableBodyItem} key={k}>
                <div className={classes.item} style={{ width: '25%' }}>
                    <RadioButton
                        styles={checkboxStyle}
                        value={selected}
                        onChange={() => change(i)}
                        radioData={[{ value: i?.id }]}
                    />
                    <TextRow name={`#${i?.authId}`} />
                </div>
                <div className={classes.item} style={{ width: '35%' }}>
                    <DateRow date={i?.startDate} /> - <DateRow date={i?.endDate} />
                </div>
                <div className={classes.item} style={{ width: '25%' }}>
                    <StatusRow status={i?.status} />
                </div>
                <div className={classes.item} style={{ width: '15%' }}>
                    {loader?.length && currentId === i?.id ?
                        <MinLoader margin={'0'} color={Colors.ThemeBlue} position={'relative'} />
                        :
                        <CustomizedSwitch
                            checked={i?.canUsed}
                            handleClick={() => changeVisible(i)}
                        />
                    }
                </div>
            </div>
        );
    };

    return (
        <div style={{ width: 700 }}>
            <p className="modal-header-title">Past Authorizations</p>
            <div className={classes.pastAuthorizationsBody}>
                <div className={classes.pastAuthorizationsTableHead}>
                    <div style={{ width: '25%' }}>Number</div>
                    <div style={{ width: '35%' }}>Date Range</div>
                    <div style={{ width: '25%' }}>Status</div>
                    <div style={{ width: '15%' }}>Appt Visible</div>
                </div>
                <div className={classes.pastAuthorizationsTableBody}>
                    {clientsAuthorizations?.map((i, k) => i.status === 'ACTIVE' && (
                        renderItemBody(i, k)
                    ))}
                    {clientsAuthorizations?.map((i, k) => i.status === 'INACTIVE' && (
                        renderItemBody(i, k)
                    ))}
                </div>
            </div>
        </div>
    );
};
import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { generateClaimStyle } from './styles';
import { AddModalButton, CloseButton, SimpleTooltip } from 'components';
import { claimActions, httpRequestsOnSuccessActions } from 'store';
import { FindLoad, FindSuccessItem, Images } from 'utils';

const REQUEST_TYPE = 'RESUBMIT_CLAIM'

export const SubmitBillModal = ({handleClose}) => {
    const classes = generateClaimStyle()
    const dispatch = useDispatch()
    const [disable, setDisable] = useState(true)
    const loader = useRef(null);
    const loadResubmit = FindLoad(REQUEST_TYPE);
    const resubmitSuccess = FindSuccessItem(REQUEST_TYPE);
    const form = useSelector((state) => state.claim.claim1500Form);
    const claimById = useSelector((state) => state.claim.claimById);
    const [pdfUrl, setPdfUrl] = useState('');

    useEffect(() => {
        if (form) {
            const file = new Blob([form], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            setPdfUrl(fileURL);
        }
    }, [form]);

    useEffect(() => {
        if(resubmitSuccess){
            handleClose()
            dispatch(httpRequestsOnSuccessActions.removeSuccess(REQUEST_TYPE))
        }
    }, [resubmitSuccess]);

    const handleSubmitBill = () => {
            dispatch(claimActions.resubmitClaim(claimById?.id))
    }

    useEffect(() => {
        let options = {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        };
        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
            observer.observe(loader.current);
        }
    }, []);

    const handleObserver = entities => {
        const target = entities[0];
        if (target.isIntersecting && disable) {
            setDisable(false)
        }
    };

    return (
        <div>
            <div className='form-paper-wrapper'>
                <p className={classes.refreshTitle}> Refresh Claim Data</p>
                <div style={{ height: '940px', overflow: 'scroll' }}>
                    <object
                        style={{ overflow: 'hidden' }}
                        data={pdfUrl}
                        width="100%" height="100%"
                    >
                    </object>

                    <div className="loading" ref={loader} />
                </div>
            </div>
            <div className={classes.paperFooter}>
                <div style={{ display: 'flex', gap: 32 }}>
                    <button onClick={handleClose} className={classes.requestCorrection}>
                        Cancel
                    </button>
                    <AddModalButton
                        disabled={disable}
                        loader={!!loadResubmit?.length}
                        styles={{ width: 260, backgroundColor: '#0C66E4', opacity: disable ? 0.5 : 1 }}
                        handleClick={handleSubmitBill}
                        text={'Save'}
                    />
                    <SimpleTooltip title={
                        <p>{'This is a draft. Reloading the page or pressing ‘Cancel’ will discard any unsaved changes. To ensure the most recent data is kept, click ‘Save’ to update the existing content.'}</p>}
                                   placement="top-end">
                        <div className={classes.draftBtnBox}>
                            <img src={Images.whiteInfoIcon} alt="" />
                            <p>Draft</p>
                        </div>
                    </SimpleTooltip>
                </div>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { staffPayrollActions } from 'store';
import { DownloadFile, FindLoad, FLAGGED_VALUES, Images, SendPageSave, useModal } from 'utils';
import {
    PROCESSED_ACTION_TYPE,
    processedHead,
    processedBody,
    processorDetailBody,
    processorDetailHeight, processedPayrollNotYet,
} from './constants';
import {
    Loader,
    TextRow,
    PaginationFillTable, NoItemsYet,
} from 'components';
import { Filters } from './fragments/filters';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { Resolve } from './fragments/resolve';

export const PassivePayroll = ({}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const processed = useSelector((state) => state?.staffPayroll?.processedPayroll);
    const loader = FindLoad(PROCESSED_ACTION_TYPE);
    const [expanded, setExpanded] = React.useState(false);
    const [page, setPage] = useState(1);
    const { open } = useModal();
    const { loadDownload, downloadBatch } = DownloadFile();
    const [currentId, setCurrentId] = useState(null);

    const renderParams = () => {
        const filteredInfo = {
            ...info,
        };
        filteredInfo.skip = info?.skip ? info?.skip : 0;
        filteredInfo.limit = 50;
        delete filteredInfo.page;
        delete filteredInfo.activeTab;

        if (info?.flag === FLAGGED_VALUES.INCOMPLETE_UNIT) {
            filteredInfo.flag = true;
        } else if (info?.flag === FLAGGED_VALUES.NOT_INCOMPLETE_UNIT) {
            filteredInfo.flag = false;
        } else {
            delete filteredInfo.flag;
        }

        if (info?.staffs?.length) {
            filteredInfo.staffs = info?.staffs?.map((i) => i.id);
        } else {
            delete filteredInfo.staffs;
        }

        if (info?.firstDate) {
            filteredInfo.startDate = info?.firstDate;
            delete filteredInfo.firstDate;
        }else{
            delete filteredInfo.startDate;
        }
        if (info?.lastDate) {
            filteredInfo.endDate = info?.lastDate;
            delete filteredInfo.lastDate;
        }else{
            delete filteredInfo.endDate
        }

        return filteredInfo;
    };

    const getDates = (loading) => {
        dispatch(staffPayrollActions.getProcessedPayroll({ ...renderParams() }, loading));
    };

    useEffect(() => {
        getDates();
    }, [info]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const changePage = (number) => {
        setPage(number);
        SendPageSave(number, info, history);
    };

    const download = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        downloadBatch(id);
        setCurrentId(id);
    };

    const handleOpenResolve = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        open(
            <Resolve
                id={item?.id}
                afterSuccess={() => getDates('noLoad')}
                displayId={item?.apptDisplayId}
            />,
        );
    };

    return (
        <div>
            <Filters noBtn={true} type={'passive'} />
            {loader.length ?
                <Loader />
                :
                <div className="processed-table-wrapper">
                    <div className="table-head-wrapper">
                        {processedHead?.map((i, j) => (
                            <div className="table-head-row" key={j} style={{ width: i?.width }}>
                                <p><TextRow name={i?.title} /></p>
                            </div>
                        ))}
                    </div>
                    {processed?.batches?.length ?
                        <div className="table-body-wrapper">
                            {processed?.batches?.map((i, k) => (
                                <React.Fragment key={k}>
                                    <Accordion
                                        style={{ background: expanded === k ? 'rgba(235, 242, 253, 0.50)' : 'white' }}
                                        className="accordion-wrapper" expanded={expanded === k}
                                        onChange={handleChange(k)}
                                    >
                                        <AccordionSummary
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            {processedBody(i, download, currentId, loadDownload)?.map((value, k) => (
                                                <div className="table-body-row" key={k} style={{ width: value.width }}>
                                                    {value?.value}
                                                </div>
                                            ))}
                                            <div style={{ position: 'absolute', right: 20, top: 15 }}>
                                                <img
                                                    className="down-up-icon"
                                                    style={expanded === k ? { transform: 'rotate(-180deg)' } : {}}
                                                    src={Images.dropDown}
                                                    alt="icon"
                                                />
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="accordion-details-wrapper">
                                                {!!i?.timesheets?.length &&
                                                    <div className="accordion-details-head-wrapper">
                                                        {processorDetailHeight?.map((head, k) => (
                                                            <div className="accordion-details-head-row"
                                                                 style={{ width: head?.width }} key={k}>
                                                                <TextRow name={head?.title} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                                {i?.timesheets?.length ?
                                                    i?.timesheets?.map((item, j) => (
                                                        <div className="accordion-details-body-wrapper" key={j}>
                                                            {processorDetailBody(item, handleOpenResolve)?.map((body, k) => (
                                                                <div className="accordion-details-body-row"
                                                                     onClick={() => !body?.notClickable && history.push(`/processed/${item?.id}`)}
                                                                     style={{ width: body?.width }} key={k}>
                                                                    {body?.value}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))
                                                    :
                                                    <NoItemsYet text={`No payrolls yet`}
                                                                containerStyles={{
                                                                    padding: '20px 0',
                                                                    position: 'relative',
                                                                }}
                                                    />
                                                }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </React.Fragment>
                            ))}
                        </div>
                        :
                        <div style={{ position: 'relative' }}>
                            <NoItemsYet
                                text={processedPayrollNotYet.title}
                                subTitle={processedPayrollNotYet.subTitle}
                                image={processedPayrollNotYet.image}
                            />
                        </div>
                    }
                    {processed?.count > 0 ?
                        <div style={{ height: '76px' }}>
                            <PaginationFillTable
                                listLength={processed?.batches?.length}
                                page={page}
                                handleReturn={(number) => changePage(number)}
                                count={processed?.count}
                            />
                        </div>
                        : ''
                    }
                </div>
            }
        </div>
    );
};
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { CustomDelete, FullTable } from 'components';
import { useModal } from 'utils';
import { adminActions } from 'store';
import { ACTION_TYPE, payCodeBody, payCodeHead } from './constants';
import { staffPaycodeNotYet } from '../constants';

const REQ_ACTION_TYPE = 'TERMINATE_PAY_CODE';

export const StaffEmpPaycodes = ({ payCodes }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const info = history?.location?.state;
    const { open } = useModal();
    const getPayCodes = () => {
        const pushInfo = {
            ...info,
        };
        delete pushInfo.page;
        delete pushInfo.activeTab;
        delete pushInfo.limit;
        delete pushInfo.skip;
        dispatch(adminActions.getPayCode(params?.id, { ...pushInfo }));
    };

    useEffect(() => {
        if (info) {
            getPayCodes();
        }
    }, [info]);

    const handleInactivate = (item) => {
        open(<CustomDelete
            info="Inactivate Paycode"
            text="Are you sure you want to inactivate this paycode?"
            innerText="Inactivate"
            handleDel={() => dispatch(adminActions.terminatePayCode(item?.id, item?.staffId))}
            actionType={REQ_ACTION_TYPE}
            afterSuccess={getPayCodes}
        />);
    };

    return (
        <FullTable
            head={payCodeHead}
            body={payCodeBody(handleInactivate)}
            loadingType={ACTION_TYPE}
            list={payCodes?.filter((i) => i?.payCodeTypeId)}
            notYet={staffPaycodeNotYet}
        />
    );
};

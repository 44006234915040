import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { noteActions } from 'store';
import { MoteDrawerModal } from './noteDrawerModal';

export const NoteDrawer = ({ onModel, currentId, text, title, handleClose }) => {
    const dispatch = useDispatch()
    const history = useHistory();
    const info = history?.location?.state;

    useEffect(() => {
        if(onModel) {
            getNotes();
        }
    }, [info]);

    const getNotes = (loading) => {
        const pushInfo = {
            ...info,
        };
        delete pushInfo.page;
        delete pushInfo.activeTab;
        const load =  loading === 'noLoad' ? 'noLoad' : 'load';
        dispatch(noteActions.getGlobalNotes(currentId, onModel, { ...pushInfo }, load));
    };

    return (
            <MoteDrawerModal
                title={title}
                onModel={onModel}
                modalDate={onModel}
                currentId={currentId}
                getNotes={getNotes}
                text={text}
                handleClose={handleClose}
            />
    );
};
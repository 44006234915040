import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AddButton, AddNotes, UploadModal, SearchHistory, CloseActionBtn, CustomDelByNote } from 'components';
import { Images, PermissionList, RolePermission, useModal } from 'utils';
import { invoiceActions } from 'store';
import { invoiceEnums } from './constants';

const CLOSE_TYPE = 'CLOSE_INVOICE';
export const InvoiceDetailHeader = ({}) => {
    const invoiceById = useSelector((state) => state.invoice.invoiceById);
    const history = useHistory();
    const info = history?.location?.state;
    const dispatch = useDispatch();
    const { open } = useModal();

    return (
        <Fragment>
            <div className="tabs-wrapper">
                <div className="space-between-flex-start" style={{ height: 36 }}>
                    <div className="detail-page-title-box">
                        <p>Invoice ID: </p>
                        <span> {invoiceById?.displayId}</span>
                    </div>
                    <div className="flex-align-start" style={{ gap: 16 }}>
                        {(!info?.activeTab || info?.activeTab === 'Details') &&
                            <div className="flex-align-center" style={{ gap: 16 }}>

                                    <>
                                        {RolePermission([PermissionList.INVOICE_CLOSE?.code]) && invoiceById?.status === invoiceEnums.OPEN &&
                                            <CloseActionBtn
                                                text={'Close Invoice'}
                                                handleClick={() => open(
                                                    <CustomDelByNote
                                                        info="Close This Invoice?"
                                                        text="Please indicate below the reason for closing the invoice."
                                                        handleDel={(comment) => {
                                                            dispatch(invoiceActions.closeInvoice(invoiceById?.id, comment));
                                                        }}
                                                        innerText={'Close'}
                                                        inputLabel={'Add your comment here...'}
                                                        actionType={CLOSE_TYPE}
                                                        areaStyles={{ height: 160 }}
                                                    />,
                                                )}
                                            />
                                        }

                                        {invoiceById?.status === invoiceEnums.CLOSED &&
                                            <p className='closed-status'>Closed</p>
                                        }
                                    </>
                            </div>
                        }

                        {
                            info?.activeTab === 'Notes' && RolePermission([PermissionList.CLAIM_NOTE_MANAGE?.code]) ? (
                                    <AddButton text="Add Note" handleClick={() => open(
                                        <AddNotes
                                            id={invoiceById?.id}
                                            model="Invoice"
                                        />,
                                    )} />
                                ) :
                                info?.activeTab === 'History' && RolePermission([PermissionList.CLAIM_HISTORY_READ?.code]) ? (
                                        <SearchHistory />
                                    ) :
                                    info?.activeTab === 'Files' && RolePermission([PermissionList.CLAIM_FILE_MANAGE?.code]) ?
                                        <AddButton
                                            Icon={true}
                                            customIcon={Images.uploadCloud}
                                            text={'Upload Document'}
                                            handleClick={() => open(
                                                <UploadModal
                                                    currentId={invoiceById?.id}
                                                    onResource={'invoice'}
                                                />,
                                            )}
                                        />
                                        : null
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

import {
    CREATE_GLOBAL_NOTE,
    DELETE_GLOBAL_NOTE,
    EDIT_GLOBAL_NOTE,
    GET_GLOBAL_NOTES,
} from './note.type';

export const getGlobalNotes = (id, onModel, data, load) => {
    return {
        type: GET_GLOBAL_NOTES,
        payload: { id, onModel, data, load },
    };
};

export const createGlobalNote = (body) => {
    return {
        type: CREATE_GLOBAL_NOTE,
        payload: {
            body,
        },
    };
};

export const editGlobalNote = (fId, id, body, onModel) => {
    return {
        type: EDIT_GLOBAL_NOTE,
        payload: { fId, id, body, onModel },
    };
};

export const deleteGlobalNote = (id) => {
    return {
        type: DELETE_GLOBAL_NOTE,
        payload: { id },
    };
};

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    CreateChancel, CustomNumericFormat,
    ErrMessage,
    SelectTypeAutocomplete,
    DateInput, TimeInput,
} from 'components';
import {
    ErrorText,
    FindLoad,
    Images,
    isNotEmpty,
    TimeIsGreat,
    FindErrorItem,
    UrlRegex,
} from 'utils';
import { scheduleModalsStyle } from './styles';
import { adminActions, appointmentActions, httpRequestsOnErrorsActions } from 'store';
import { apptOverlap, renderTimes, staffOverlap } from './constants';
import { DRIVE, scheduleStatuses, UNPAID } from '../../constants';
import { ModalHeader } from './serviceAppt/modalHeader';
import { Recur } from './recur';
import { ScheduleLinks } from './scheduleLinks';

const GET_ALL_PAY_CODES = 'GET_ALL_PAY_CODES';

export const Break = ({ handleOpenClose, type, date, modalDate }) => {
    const { allPaycodes, staffs } = useSelector((state) => ({
        allPaycodes: state.admins.allPaycodes,
        staffs: state.appointment.apptStaffs,
    }));
    const editActionType = modalDate ? 'EDIT_APPOINTMENT' : 'CREATE_APPOINTMENT';
    const classes = scheduleModalsStyle();
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const createModalDate = history?.location?.state;
    const [inputs, setInputs] = useState(createModalDate ? { ...createModalDate } : {});
    const [error, setError] = useState({});
    const backError = FindErrorItem(editActionType);
    const loader = FindLoad(editActionType);
    const [step, setStep] = useState('first');
    const [createMultiple, setCreateMultiple] = useState(false);
    const [links, setLinks] = useState();

    useEffect(() => {
        dispatch(appointmentActions.getApptStaffs());
        if (error) {
            setError('');
            dispatch(httpRequestsOnErrorsActions.removeError(editActionType));
            dispatch(httpRequestsOnErrorsActions.removeError('APPOINTMENT_OVERLAPPING'));
        }
    }, []);

    useEffect(() => {
        if (modalDate) {
            const params = {
                type: modalDate?.type,
                staff: modalDate?.staff?._id,
                staffPayCode: modalDate?.staffPayCode?._id || modalDate?.staffPayCodeId,
                startDate: modalDate?.startDate,
                startTime: modalDate?.startTime,
                endTime: modalDate?.endTime,
            };

            if (modalDate?.miles) {
                params.miles = modalDate.miles;
            }
            if (modalDate?.links?.length) {
                setLinks(modalDate?.links);
            }
            setInputs(params);
        }
        if (!modalDate && date) {
            setInputs({
                startTime: date?.startTime?.slice(11, 16),
                endTime: date?.endTime?.slice(11, 16),
                startDate: date?.startDate,
            });
        }
    }, [modalDate, date]);

    const apptInfo = () => {
        const startDate = moment.utc(inputs?.startDate);
        startDate.set({
            hour: inputs?.startTime?.slice(0, 2),
            minute: inputs?.startTime?.slice(3, 5),
            second: '00',
        });
        const endDate = moment.utc(inputs?.startDate);
        endDate.set({
            hour: inputs?.endTime?.slice(0, 2),
            minute: inputs?.endTime?.slice(3, 5),
            second: '00',
        });

        const apptDate = {
            type: type,
            startDate: inputs?.startDate && moment.utc(inputs?.startDate).format('YYYY-MM-DD'),
            startTime: startDate.format(),
            endTime: endDate.format(),
            staff: inputs?.staff,
            staffPayCode: inputs?.staffPayCode,
            editTemplate: createMultiple,
        };
        if (type === DRIVE) {
            apptDate.miles = inputs.miles ? +inputs.miles : apptDate.miles = 0;
        }
        if (type === UNPAID) {
            delete apptDate.staffPayCode;
        }

        if (links?.length) {
            const newList = links.filter((i) => i?.title && i?.url);
            const list = newList?.map((i) => {
                if (!i?.url.startsWith('http://') && !i?.url.startsWith('https://')) {
                    return { title: i?.title, url: `https://${i?.url}` };
                } else {
                    return { title: i?.title, url: i?.url };
                }
            });
            list?.length ? apptDate.links = list : delete apptDate.links;
        } else {
            delete apptDate.links;
        }

        return apptDate;
    };

    useEffect(() => {
        if (!!backError?.error === apptOverlap || !!backError?.error === staffOverlap) {
            setError(ErrorText.overlappingError('Appointments'));
            dispatch(httpRequestsOnErrorsActions.removeError('APPOINTMENT_OVERLAPPING'));
        }
    }, [backError]);

    useEffect(() => {
        if (createModalDate && createModalDate?.staff) {
            dispatch(adminActions.getAllPayCodes(createModalDate.staff));
        }
    }, [createModalDate]);

    useEffect(() => {
        if (info?.staff) {
            dispatch(adminActions.getAllPayCodes(info?.staff));
        }
    }, [info]);

    useEffect(() => {
        return () => dispatch(adminActions.clearAllPayCodes());
    }, []);

    const handleCloseModal = () => {
        handleOpenClose && handleOpenClose();
        setInputs('');
    };

    const handleChange = (e) => {
        error === e.target.name && setError('');

        if (e.target.name === 'staff') {
            dispatch(adminActions.clearAllPayCodes());
            dispatch(adminActions.getAllPayCodes(e.target.value));
            const newParams = {
                ...inputs,
                staff: e.target.value,
            };
            delete newParams.staffPayCode;
            setInputs(newParams);
        } else {
            setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        }

        if (error === e.target.name || error === ErrorText.timeError || error === ErrorText.overlappingError('Appointments')) {
            setError('');
        }
        if (backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(backError.type));
        }
    };

    const handleChangeMiles = (value) => {
        setInputs((prevState) => ({ ...prevState, ['miles']: value?.value }));
        if (error === 'miles') setError('');
    };

    const handleCreate = () => {
        const timeComparingIsValid = createMultiple ? true : !!inputs.startTime && !!inputs.endTime && TimeIsGreat(inputs.startTime, inputs.endTime);
        const checkStartDate = createMultiple ? true : !!inputs.startDate;
        const staffPayCode = type === UNPAID ? true : isNotEmpty(inputs?.staffPayCode);
        const dataIsValid = isNotEmpty(inputs.staff) && staffPayCode && checkStartDate && timeComparingIsValid;
        const date = apptInfo();
        const linksChecker = links?.length ? links?.find((i) => UrlRegex.test(i?.url)) : true;
        const emptyLink = links?.length ? links?.find((i) => i?.title !== '') : true;

        if (dataIsValid && linksChecker && emptyLink) {
            if (modalDate) {

                const checkActivePayCode = allPaycodes?.find((i) => i?.id === inputs?.staffPayCode);

                if (type === UNPAID ? true : (checkActivePayCode && !checkActivePayCode?.terminationDate)) {

                    if (createMultiple) {
                        setStep('second');
                    } else {
                        dispatch(appointmentActions.editAppointment(date, modalDate?.id));
                    }
                } else {
                    setError('staffPayCode');
                }
            } else {
                if (createMultiple) {
                    setStep('second');
                } else {
                    dispatch(appointmentActions.createAppointment(date));
                }
            }
        } else {
            const errorText =
                createMultiple ?
                    (!inputs.staff ? 'staff' :
                            !inputs.staffPayCode ? 'staffPayCode' :
                                !linksChecker ? 'notValidLinks' :
                                    !emptyLink ? 'emptyTitle' :
                                        null
                    )
                    :
                    (!inputs.staff ? 'staff' :
                        !inputs.staffPayCode ? 'staffPayCode' :
                            !inputs.startDate ? 'startDate' :
                                !inputs.startTime ? 'startTime' :
                                    !inputs.endTime ? 'endTime' :
                                        !timeComparingIsValid ? ErrorText.timeError :
                                            !linksChecker ? 'notValidLinks' :
                                                !emptyLink ? 'emptyTitle' :
                                                    null);

            if (type === DRIVE) {
                setError(errorText ? errorText : '');
            } else if (type === UNPAID) {
                setError(
                    createMultiple ? (!inputs.staff ? 'staff' : null)
                        :
                        !inputs.staff ? 'staff' :
                            !inputs.startDate ? 'startDate' :
                                !inputs.startTime ? 'startTime' :
                                    !inputs.endTime ? 'endTime' :
                                        !timeComparingIsValid ? ErrorText.timeError :
                                            !linksChecker ? 'notValidLinks' :
                                                !emptyLink ? 'emptyTitle' :
                                                    '');
            } else {
                setError(errorText);
            }
        }
    };
    const setMultipleAppts = () => {
        if (createMultiple) {
            setStep('first');
        }
        setCreateMultiple(!createMultiple);
        const newParams = {
            ...inputs,
        };
        if (!modalDate) {
            delete newParams.startDate;
            delete newParams.startTime;
            delete newParams.endTime;
        }
        setInputs(newParams);
    };

    return (
        <>
            <ModalHeader
                modalDate={modalDate}
                multiple={createMultiple}
                step={step}
                setStep={setStep}
                type={type}
                createMultiple={createMultiple}
                setMultipleAppts={setMultipleAppts}
            />
            {step === 'first' ?
                <div className={classes.breakWrapper}>
                    <SelectTypeAutocomplete
                        loadType={'GET_APPT_STAFFS'}
                        title={'Staff Member*'}
                        name={'staff'}
                        handleSelect={handleChange}
                        defaultValue={inputs.staff}
                        list={staffs?.length ? staffs : []}
                        error={error}
                        typeError={error === 'staff' ? `Staff member ${ErrorText.isRequired}` : ''}
                        renderValue={(i) => `${i?.firstName} ${i?.lastName ? i?.lastName : null}`}
                    />
                    {type !== UNPAID &&
                        <SelectTypeAutocomplete
                            disabled={!inputs?.staff}
                            loadType={GET_ALL_PAY_CODES}
                            title={'Staff Paycode*'}
                            name={'staffPayCode'}
                            handleSelect={handleChange}
                            defaultValue={inputs?.staffPayCode}
                            list={allPaycodes?.filter((data) => !data?.terminationDate) || []}
                            error={error}
                            typeError={error === 'staffPayCode' ? `Staff paycode ${ErrorText.isRequired}` : ''}
                            renderValue={(i) => i?.payCodeTypeId?.name}
                        />
                    }

                    {!createMultiple &&
                        <>
                            <DateInput
                                name={'startDate'}
                                label={'Start Date*'}
                                handleChange={handleChange}
                                value={inputs?.startDate ? moment.utc(inputs.startDate).format('MM/DD/YYYY') : inputs.startDate}
                                typeError={error === 'startDate' && `Start date ${ErrorText.isRequired}`}
                            />
                            <div className={classes.timeInputs} style={{ gap: '16px' }}>
                                <TimeInput
                                    label={'Start Time*'}
                                    name={'startTime'}
                                    onChange={handleChange}
                                    typeError={
                                        error === 'startTime' ? `Start time ${ErrorText.isRequired}` :
                                            backError?.error === apptOverlap ? ErrorText.overlappingError('Appointments') :
                                                backError?.error === staffOverlap ? ErrorText.overlappingError('Appointments') :
                                                    ''
                                    }
                                    defaultValue={
                                        inputs?.startTime ? renderTimes(inputs.startTime) || inputs.startTime : null
                                    }
                                />
                                <TimeInput
                                    label={'End Time*'}
                                    name={'endTime'}
                                    onChange={handleChange}
                                    typeError={
                                        error === 'endTime' ? `End time ${ErrorText.isRequired}` :
                                            error === ErrorText.timeError ? ErrorText.timeError
                                                : ''
                                    }
                                    defaultValue={
                                        inputs?.endTime ? renderTimes(inputs.endTime) || inputs.endTime : null
                                    }
                                />
                            </div>
                        </>
                    }
                    {type === DRIVE && (
                        <CustomNumericFormat
                            name={'miles'}
                            label={'Miles'}
                            value={inputs?.miles}
                            error={error === 'miles' ? `Miles ${ErrorText.isRequired}` : ''}
                            handleChangeMiles={handleChangeMiles}
                            thousandSeparator={','}
                            errorName={error}
                        />
                    )}

                    <ScheduleLinks
                        changeLinks={setLinks}
                        item={modalDate}
                        linkError={error}
                        setLinkError={setError}
                    />

                    {backError?.error === 'TB credential expired' &&
                        <ErrMessage
                            styles={{ margin: '0 0 16px' }}
                            text={
                                backError?.error === 'TB credential expired' ? 'TB credential expired' :
                                    ''} />
                    }

                    {modalDate?.status === scheduleStatuses?.RENDER &&
                        <div
                            style={{ margin: '0 0 24px' }}
                            className={classes.warning}
                        >
                            <img src={Images.warning} alt="icon" />
                            <p>Warning! Since this appointment is already complete, processed inputsheets will not be
                                updated</p>
                        </div>
                    }

                    <div>
                        <CreateChancel
                            loader={!!loader.length}
                            create={
                                createMultiple ? 'Continue' :
                                    modalDate ? 'Save' : 'Add'
                            }
                            chancel={'Cancel'}
                            onCreate={handleCreate}
                            onClose={handleCloseModal}
                            buttonWidth="300px"
                        />
                    </div>
                </div>
                :
                <div>
                    <Recur
                        setStep={() => setStep('first')}
                        date={apptInfo()}
                        modalDate={modalDate}
                        multiple={createMultiple}
                        handleClose={handleOpenClose}
                        dateTime={date}
                    />
                </div>
            }
        </>
    );
};

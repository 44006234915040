import {
    DateRow,
    DisplayIdAnFlagRow,
    DisplayIdRow,
    DownloadLink,
    IdRow,
    LinkRow,
    PriceRow, SimpleTooltip,
    TextRow,
    UnitsRow,
} from 'components';
import React from 'react';
import { Images, PermissionList, RolePermission } from 'utils';
import { PENDING_CLAIM } from '../../../constants';
import { ApptFlags } from '../../../../../../../components/table/tableRows';

export const claimPaymentTableHead = [
    { name: 'id', title: 'ID', custom: false, noSearch: true, width: '100px' },
    { name: '', title: 'User name', custom: false, noSearch: true },

    { name: '', title: 'Created Date', custom: false, noSearch: true, width: '120px' },
    { name: '', title: 'Voided Date', custom: false, noSearch: true, width: '120px' },
    { name: '', title: 'Allowed AMT', custom: false, noSearch: true },
    { name: '', title: 'Deductible', custom: false, noSearch: true },
    { name: '', title: 'Coinsurance', custom: false, noSearch: true },
    { name: '', title: 'Copay', custom: false, noSearch: true },
    { name: '', title: 'Paid Amt', custom: false, noSearch: true },
    { name: '', title: 'Status', custom: false, noSearch: true },
];

export const claimPaymentTableBody = [
    { rowText: (item) => <DisplayIdRow id={item?.displayId ? item?.displayId : item?.id} /> },
    {
        rowText: (item) => <TextRow
            name={item?.creator?.firstName ? `${item?.creator?.firstName} ${item?.creator?.lastName}` : 'N/A'} />,
    },
    { rowText: (item) => <DateRow date={item?.createdDate} /> },
    { rowText: (item) => <DateRow date={item?.voidedDate} /> },
    { rowText: (item) => <PriceRow info={item?.allowedAMT} /> },
    { rowText: (item) => <PriceRow info={item?.deductible} /> },
    { rowText: (item) => <PriceRow info={item?.coIns} /> },
    { rowText: (item) => <PriceRow info={item?.copay} /> },
    { rowText: (item) => <PriceRow info={item?.paidAMT} /> },
    {
        rowText: (item) => <p
            className={item?.status === 'ACTIVE' ? 'active-status' : 'inactive-status'}>{item?.status === 'ACTIVE' ? 'Active' : 'Voided'}</p>,
    },
];


export const invoicePaymentTableHead = [
    { name: '', title: 'ID', custom: false, noSearch: true, width: '100px' },
    { name: '', title: 'Payment ID', custom: false, noSearch: true },
    { name: '', title: 'Bill ID', custom: false, noSearch: true },
    { name: '', title: 'Created Date', custom: false, noSearch: true },
    { name: '', title: 'Updated Date', custom: false, noSearch: true },
    { name: '', title: 'Paid Amt', custom: false, noSearch: true },
    { name: '', title: 'Status', custom: false, noSearch: true },
];

export const invoicePaymentTableBody = [
    { rowText: (item) => <DisplayIdRow id={item?.displayId} /> },
    { rowText: (item) => <IdRow id={item?.paymentRef ? item?.paymentRef : 'N/A'} size={4} /> },
    { rowText: (item) => <IdRow id={item?.bill} size={4} /> },
    { rowText: (item) => <DateRow date={item?.createdDate} /> },
    { rowText: (item) => <DateRow date={item?.voidedDate} /> },
    { rowText: (item) => <PriceRow info={item?.paidAMT} /> },
    {
        rowText: (item) => <p
            className={item?.status === 'ACTIVE' ? 'active-status' : 'inactive-status'}>{item?.status === 'ACTIVE' ? 'Active' : 'Voided'}</p>,
    },
];


export const billTotalHead = [
    { name: '', title: 'Billed Rate', custom: false, noSearch: true },
    { name: '', title: 'Billed Amount', custom: false, noSearch: true },
    { name: '', title: 'Total Units', custom: false, noSearch: true },
    { name: '', title: 'Payor Total', custom: false, noSearch: true },
    { name: '', title: 'Payor Paid', custom: false, noSearch: true },
    { name: '', title: 'Payor Balance', custom: false, noSearch: true },
    { name: '', title: 'Client Total', custom: false, noSearch: true },
    { name: '', title: 'Client Paid', custom: false, noSearch: true },
    { name: '', title: 'Client Balance', custom: false, noSearch: true },
];

export const billTotalBody = [
    { rowText: (item) => <PriceRow info={item?.billedRate} /> },
    { rowText: (item) => <PriceRow info={item?.totalBilled} /> },
    { rowText: (item) => <UnitsRow info={item?.totalUnits} /> },
    { rowText: (item) => <PriceRow info={item?.payorTotal} /> },
    { rowText: (item) => <PriceRow info={item?.payorPaid} /> },
    { rowText: (item) => <PriceRow info={item?.payorBalance} /> },
    { rowText: (item) => <PriceRow info={item?.clientTotal} /> },
    { rowText: (item) => <PriceRow info={item?.clientPaid} /> },
    { rowText: (item) => <PriceRow info={item?.clientBalance} /> },
];

export const billApptHead = (claimDetails) => [
    { name: '', title: 'ID', custom: false, noSearch: true },
    { name: '', title: 'DOS', custom: false, noSearch: true },
    { name: '', title: 'Units', custom: false, noSearch: true },
    { name: '', title: 'Signature', custom: false, noSearch: true },
    claimDetails?.status !== PENDING_CLAIM && RolePermission([PermissionList.REMOVE_CLAIM_APPT?.code]) &&
    { name: '', title: ' ', custom: false, noSearch: true, width: '100px' },

];

export const billApptBody = (tableStyle, removeFromClaim, claimDetails) => [
    {
        rowText: (item) =>
            <div className={'flex-align-center'} style={{ gap: 8 }}>

                <ApptFlags id={item?.displayId} incomplete={item?.incompleteUnitFlag} removed={item?.removedFromClaimFlag} updateFlag={item?.updateFlag} link={true} singleAppt={true} />


                {/*<a href="/schedule" style={{ color: '#347AF0' }}>*/}
                {/*    {item?.displayId}*/}
                {/*</a>*/}
                {/*<div className="flex-align-center" style={{gap:10}}>*/}
                {/*    <div style={{ width: 20 }}>*/}
                {/*        {item?.flag &&*/}
                {/*            <SimpleTooltip*/}
                {/*                title={*/}
                {/*                    <p>{'This appointment has incomplete units.'}</p>}*/}
                {/*                placement="bottom"*/}
                {/*                arrow*/}
                {/*            >*/}
                {/*                <img src={Images.flag} alt="flag" />*/}
                {/*            </SimpleTooltip>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*    <div style={{ width: 20 }}>*/}
                {/*        {item?.removedFromClaimFlag &&*/}
                {/*            <SimpleTooltip*/}
                {/*                title={*/}
                {/*                    <p>{'Appointment was removed from claim'}</p>*/}
                {/*                }*/}
                {/*                placement="bottom"*/}
                {/*                arrow*/}
                {/*            >*/}
                {/*                <img src={Images.yellowFlag} alt="flag" />*/}
                {/*            </SimpleTooltip>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>,
    },
    { rowText: (item) => <DateRow date={item?.dos || item?.startTime} /> },
    { rowText: (item) => <UnitsRow info={item?.units} /> },
    {
        rowText: (item) => <div>
            {item?.signature?.id || item?.signature?._id ?
                <div className={tableStyle.linksWrapper}>
                    <div className={tableStyle.linksButtonWrapper}>
                        <a href={item?.signature?.url} target={'_blank'}>
                            <button>View</button>
                            <img src={Images.show} alt="icon" />
                        </a>
                    </div>
                    <div className={tableStyle.linksButtonWrapper}>
                        <DownloadLink
                            linkInnerText={'Download'}
                            file={item?.signature}
                            small={true}
                        />
                    </div>
                </div>
                : 'N/A'}
        </div>,
    },
    RolePermission([PermissionList.REMOVE_CLAIM_APPT?.code]) &&
    {
        button: (fn, item) => claimDetails?.status === 'PENDING' ? <div></div> : (
            <div>
                {item?.removedFromClaimFlag ?
                    <SimpleTooltip
                        title={<p>{'Appointment was removed from claim'}</p>}
                        placement="bottom"
                        arrow
                    >
                        <p style={{ color: '#E90715' }}>Removed</p>
                    </SimpleTooltip>
                    :
                    <button onClick={() => removeFromClaim(item)} className="remove-from-claim">Remove from
                        Claim</button>
                }
            </div>
        ),
    },
];

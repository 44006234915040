import React from 'react';
import { DetailItem } from '../detailItem';
import { makeCapitalize } from 'utils';

export const ServiceItems = ({ item }) => {

    return (
        <div className='detail-information'>
            <div className='full-width'>
                <DetailItem
                    title={'Authorized Service'}
                    value={item?.authorizedService?.authorization?.authId}
                />
                <DetailItem
                    title={'Place of Service'}
                    value={item?.placeService?.name}
                />
                <DetailItem
                    title={'Funding Source'}
                    value={item?.payer?.name}
                />
            </div>
            <div className='full-width' style={{ marginLeft: '8px' }}>
                <DetailItem
                    title={'Staff Paycode'}
                    value={makeCapitalize(item?.staffPayCode)}
                />
                <DetailItem
                    title={'Appointment Creator'}
                    value={item?.creator ? item.creator : 'N/A'}
                />
                <DetailItem
                    title={'Charge Rate'}
                    value={item?.chargeRateName ? item.chargeRateName : 'N/A'}
                />
            </div>
            <div className='full-width' style={{ marginLeft: '8px' }}>
                <DetailItem
                    title={'Client Address'}
                    value={item?.client?.address?.formattedAddress ? item?.client?.address?.formattedAddress : 'N/A'}
                />
                <DetailItem
                    title={'Last Editor'}
                    value={item?.editor ? item.editor : 'N/A'}
                />
                <DetailItem
                    title={'CPT Code'}
                    value={item?.authorizedService?.service?.cptCode ? item?.authorizedService?.service?.cptCode : 'N/A'}
                />
            </div>
        </div>
    );
};
import React, { Fragment, useEffect, useState } from 'react';
import { AddButton, FullTable, Loader, ValidationInput } from 'components';
import { useDispatch } from 'react-redux';
import { ErrorText, FindLoad, FindSuccessItem, isNotEmpty, useModal, } from 'utils';
import { systemItemStyles } from './styles';
import { httpRequestsOnSuccessActions, systemActions } from 'store';
import { EditPlace } from './modals/editPlace';
import { PLACE_OF_ACTION_TYPE, placeOfNotYet, placeOfServiceBody, placeOfServiceHead } from './constants';

const credentialBtn = {
    maxWidth: '209px',
    width: '100%',
    flex: '0 0 209px',
    padding: 0,
};

const ACTION_TYPE = 'CREATE_PLACE_GLOBAL';

export const PlaceOfService = ({ globalJobs }) => {
    const classes = systemItemStyles();
    const dispatch = useDispatch();
    const getLoader = FindLoad('GET_PLACES');
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccessItem(ACTION_TYPE);
    const [inputs, setInputs] = useState({});
    const [error, setError] = useState('');
    const { open } = useModal();

    useEffect(() => {
        dispatch(systemActions.getPlaces());
    }, []);

    useEffect(() => {
        if (success) {
            setInputs({});
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    function handleChange(e) {
        setInputs((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
        error === e.target.name && setError('');
    }

    function handleSubmit() {
        const dataIsValid = isNotEmpty(inputs.name) && isNotEmpty(inputs.code);
        if (dataIsValid) {
            const data = {
                name: inputs.name,
                code: inputs.code,
            };
            inputs.modifier ? data.modifier = inputs.modifier : delete data.modifier;
            dispatch(systemActions.createPlaceGlobal(data));
        } else {
            const dataErrorText = !isNotEmpty(inputs.name)
                ? 'name'
                : !isNotEmpty(inputs.code)
                    ? 'code'
                    : '';
            setError(dataErrorText);
        }
    }

    if (getLoader?.length) {
        return <Loader />;
    }

    return (
        <Fragment>
            <div className={`${classes.flexContainer} ${classes.headerSize}`}>
                <ValidationInput
                    style={classes.credentialInputStyle}
                    onChange={handleChange}
                    value={inputs.name}
                    variant={'outlined'}
                    name={'name'}
                    type={'text'}
                    placeholder={'Name*'}
                    typeError={error === 'name' ? `Name ${ErrorText.isRequired}` : ''}
                    Length={50}
                />
                <ValidationInput
                    style={classes.codInputStyle}
                    onChange={handleChange}
                    value={inputs.code}
                    variant={'outlined'}
                    name={'code'}
                    type={'number'}
                    placeholder={'Code*'}
                    typeError={error === 'code' ? `Code ${ErrorText.isRequired}` : ''}
                    Length={10}
                />
                <ValidationInput
                    style={classes.codInputStyle}
                    onChange={handleChange}
                    value={inputs.modifier}
                    variant={'outlined'}
                    name={'modifier'}
                    type={'text'}
                    placeholder={'Modifier'}
                    Length={5}
                />
                <AddButton
                    type={ACTION_TYPE}
                    styles={credentialBtn}
                    loader={!!loader.length}
                    handleClick={handleSubmit}
                    text="Add Place of Service"
                />
            </div>
            <p className={classes.title}>Place of Services</p>
            <FullTable
                head={placeOfServiceHead}
                body={placeOfServiceBody}
                loadingType={PLACE_OF_ACTION_TYPE}
                list={globalJobs?.length ? globalJobs : []}
                handleClickButton={(item) => open(<EditPlace info={item} />)}
                notYet={placeOfNotYet}
            />
        </Fragment>
    );
};

import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { claimDetailBody, claimDetailHead, getClaimDetails } from './constants';
import { DrawerContext, useModal } from 'utils';
import { DetailMessageBox, FullTable } from 'components';
import { ReceivableBillTable } from './core/common/core';

export const ClaimDetailsTab = () => {
    const claimDetails = useSelector((state) => state.claim.claimById);
    const filteredDetails = getClaimDetails(claimDetails);
    const { open: drawerOpen } = useContext(DrawerContext);
    const claimIsClosed = claimDetails?.status === 'CLOSED';
    const { open } = useModal();

    function getDetailDisplay(detail) {
        if (detail && detail !== 'Undefined Undefined') {
            return detail;
        } else {
            return 'N/A';
        }
    }

    return (
        <div>
            {claimIsClosed && (
                <DetailMessageBox
                    text={`Reason for closing: ${claimDetails?.details}`}
                />
            )}

            <div className='details-wrapper'>
                {!!filteredDetails.length && (
                    <ol className="details-list-box">
                        {filteredDetails.map((item, index) => item?.detail && (
                            <li key={index}
                                style={{ background: item?.background ? item?.background : '#F4F5F7' }}
                            >
                                <p className="detail-title-style"> {item.detailText}</p>
                                <p className="detail-value-style">{getDetailDisplay(item.detail)}</p>
                            </li>
                        ))}
                    </ol>
                )}
            </div>

            <div>
                <p className="details-title-box">Billing Entries</p>
                <FullTable
                    head={claimDetailHead(claimDetails)}
                    body={claimDetailBody(claimDetails)}
                    list={claimDetails?.bills?.length ? claimDetails?.bills : []}
                    handleClick={(e) => open(
                        <ReceivableBillTable currentId={e} />,
                    )}
                    noText={'receivables'}
                    activeRowId={''}
                    generateTable={true}
                    height={'auto'}
                />
            </div>
        </div>
    );
};
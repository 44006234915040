import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import {
    FindLoad,
    SendPageSave,
    useGlobalStyles,
    Images,
    FindSuccess,
    RolePermission,
    PermissionList,
} from 'utils';
import {
    DateRow, DateRowStartEnd,
    Loader,
    MinLoader,
    NoItemsYet,
    PriceInput,
    PriceRow,
    SimpleTooltip,
    TextRow,
} from 'components';
import { tableStyle } from '../../../components/table/styles';
import { PaginationFillTable, SearchAndFilerFull } from 'components';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, invoicePaymentActions } from 'store';
import { manageStatusColor } from '../../../utils/hooks/manageStatus';
import { invoicesNotYet, postingInvoiceEnums } from './constants';

const activeRowStyles = {
    borderBottom: '1px solid #347AF0',
    background: 'white',
};
const editStyles = {
    color: '#51566D',
    display: 'flex',
    alignItems: 'center',
    height: '50px',
    // width: '200px',
};

const EDIT_TYPE = 'EDIT_PAYMENT_INVOICE';

export const InvoicePaymentTable = ({ head, entries, list, loadingType, margin, listCount }) => {
    const classes = tableStyle();
    const globalStyle = useGlobalStyles();
    const [page, setPage] = useState(1);
    const history = useHistory();
    const info = history?.location?.state;
    const loader = FindLoad(loadingType ? loadingType : '');
    const [activeRow, setActiveRow] = useState({});
    const [selectedId, setSelectedId] = useState();
    const dispatch = useDispatch();
    const loadPay = FindLoad(EDIT_TYPE);
    const loadPayInFull = FindLoad('PAY_IN_FULL_PAYMENT_INVOICE');
    const success = FindSuccess(EDIT_TYPE);
    const [searched, setSearched] = useState({ name: '', text: '' });

    useEffect(() => {
        if (success?.length) {
            setActiveRow({});
        }
    }, [success]);

    const changePage = (number) => {
        setPage(number);
        SendPageSave(number, info, history);
    };

    const handleChange = (e, j, item) => {
        if (e.target.name === 'paidAMT') {
            const value = e.target.value;
            const convertedValue = parseFloat(value.replace(/[$,]/g, ''));

            setActiveRow((prevState) => ({ ...prevState, ['paidAMT']: convertedValue }));
        } else {
            setActiveRow((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        }
    };

    const payInFull = (i) => {
        setSelectedId(i);
        const body = {};
        activeRow?.paymentRef ? body.paymentRef = activeRow?.paymentRef : i?.paymentRef ? body.paymentRef = i?.paymentRef : '';
        activeRow?.paymentType ? body.paymentType = activeRow?.paymentType : i?.paymentType ? body.paymentType = i?.paymentType : '';
        activeRow?.paidAMT ? body.paymentAMT = activeRow?.paidAMT : i?.paidAMT ? body.paymentAMT = i?.paidAMT : '';
        dispatch(invoicePaymentActions.payInFullInvoice(i?.id, body));
    };

    const handleSave = () => {
        const body = {
            'paymentAMT': +activeRow?.paidAMT,
        };
        activeRow?.paymentRef ? body.paymentRef = activeRow?.paymentRef : '';
        activeRow?.paymentType ? body.paymentType = activeRow?.paymentType : '';

        if (activeRow?.paidAMT <= activeRow?.totalAMT) {
            dispatch(invoicePaymentActions.editPaymentInvoice(activeRow?.id, body));
        } else {
            dispatch(httpRequestsOnErrorsActions.appendError('INVOICE_AMT_IS_GREAT'));
        }
    };

    const handleCloseInputs = () => {
        setActiveRow({});
        setSelectedId('');
    };

    const navigateById = (id) => {
        history.push(`/invoice/${id}`);
    }

    if (loader.length) return <Loader />;

    return (
        <div style={{ width: '100%' }}>
            <div style={margin ? { margin: 0 } : {}} className={globalStyle.tableWrapper}>


                <TableContainer className={globalStyle.tableContainer} component={Paper}>
                    <Table
                        stickyHeader
                        className={globalStyle.table}
                        size="small"
                        aria-label="a dense table"
                    >
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                {head ? head.map((i, j) => (i &&
                                    <TableCell key={j} style={i?.width ? { width: i.width } : {}}>
                                        <SearchAndFilerFull
                                            handleSearch={(e) => handleChange(e, i?.rowName, i?.searchKey)}
                                            item={i}
                                            searched={searched}
                                            width={i?.width}
                                        />
                                    </TableCell>
                                )) : ''}
                            </TableRow>
                        </TableHead>

                        <tbody>
                        {list?.length ? list?.map((i, j) => (
                                <React.Fragment key={j}>
                                    <TableRow className={`${classes.tableRow}  table-small-paddings ${activeRow?.id === i?.id ? 'active' : ''}`}>
                                        <TableCell onClick={() => navigateById(i?.id)}>
                                            <p>{i?.displayId ? i?.displayId : 'N/A'}</p>
                                        </TableCell>
                                        <TableCell onClick={() => navigateById(i?.id)}>
                                            <DateRow date={i?.invoiceDate} />
                                        </TableCell>
                                        <TableCell onClick={() => navigateById(i?.id)}>
                                            <DateRowStartEnd start={i?.dateRange?.early} end={i?.dateRange?.latest}  />
                                        </TableCell>
                                        <TableCell onClick={() => navigateById(i?.id)}>
                                            <TextRow
                                                name={i?.client?.clientFullName ? i?.client?.clientFullName : 'N/A'}
                                                textWidth={8} />
                                        </TableCell>
                                        <TableCell onClick={() => navigateById(i?.id)}>
                                            <PriceRow info={i?.totalAMT} />
                                        </TableCell>
                                        <TableCell
                                            style={activeRow?.id === i?.id ? { ...activeRowStyles } : {}}
                                        >
                                            <PriceInput
                                                placeholder={activeRow?.paidAMT === '' ? '0' : ''}
                                                name={'paidAMT'}
                                                handleChange={(e) => handleChange(e, j, i)}
                                                value={activeRow?.id === i?.id ? activeRow?.paidAMT === '' ? 0 :
                                                    activeRow?.paidAMT : i?.paidAMT ? i?.paidAMT : 0}
                                                disabled={activeRow?.id !== i?.id}
                                            />

                                        </TableCell>
                                        <TableCell onClick={() => navigateById(i?.id)}>
                                            <PriceRow info={i?.balance} />
                                        </TableCell>
                                        <TableCell
                                            style={activeRow?.id === i?.id ? { ...activeRowStyles } : {}}
                                        >
                                            <input
                                                style={{ height: '40px', position: 'relative' }}
                                                name={'paymentType'}
                                                onChange={handleChange}
                                                disabled={activeRow?.id !== i?.id}
                                                value={activeRow?.id === i?.id ? activeRow?.paymentType : i?.paymentType}
                                                placeholder={'N/A'}
                                            />
                                        </TableCell>
                                        {/*<TableCell*/}
                                        {/*    style={activeRow?.id === i?.id ? { ...activeRowStyles } : {}}*/}
                                        {/*>*/}
                                        {/*    <input*/}
                                        {/*        style={{ height: '40px', position: 'relative' }}*/}
                                        {/*        onChange={(e) => handleChange()}*/}
                                        {/*        name={'paymentRef'}*/}
                                        {/*        value={activeRow?.id === i?.id ? activeRow?.paymentRef : i?.paymentRef}*/}
                                        {/*        disabled={activeRow?.id !== i?.id}*/}
                                        {/*        placeholder={'N/A'}*/}
                                        {/*        type="number"*/}
                                        {/*    />*/}
                                        {/*</TableCell>*/}
                                        <TableCell onClick={() => navigateById(i?.id)}>
                                            {manageStatusColor(i?.status)}
                                        </TableCell>
                                        {RolePermission([PermissionList.INVOICE_PMT_PAY?.code]) &&
                                            <TableCell
                                                style={editStyles}
                                            >
                                                {activeRow?.id === i?.id ?
                                                    <div className={classes.alignCenter}>
                                                        <button className="save-btn-box" onClick={handleSave}>
                                                            {activeRow?.id === i?.id && loadPay?.length ?
                                                                <MinLoader margin={'0'} color={'#344054'}
                                                                           position="relative" />
                                                                :
                                                                'Save'
                                                            }
                                                        </button>
                                                        <button
                                                            style={{ margin: 0 }}
                                                            onClick={handleCloseInputs} className="posting-close-btn">
                                                            <img src={Images.remove} alt="icon" />
                                                        </button>
                                                    </div>
                                                    :
                                                    <button
                                                        style={{ opacity: i?.status === postingInvoiceEnums.COMPLETED ? 0.5 : 1 }}
                                                        disabled={i?.status === postingInvoiceEnums.COMPLETED}
                                                        className={'edit'} onClick={() => setActiveRow(i)}>
                                                        <img src={Images.edit} alt="icon" />
                                                    </button>
                                                }

                                                {(i?.status !== postingInvoiceEnums.COMPLETED &&
                                                    i?.status !== postingInvoiceEnums.PAID
                                                ) ?
                                                    <button onClick={() => payInFull(i)} className={'pay'}>
                                                        {selectedId?.id === i?.id && loadPayInFull?.length ?
                                                            <MinLoader
                                                                margin={'4px 0 0'}
                                                                color={'white'}
                                                                position="relative"
                                                            />
                                                            :
                                                            'Pay in Full'
                                                        }
                                                    </button>

                                                    :

                                                    <SimpleTooltip title={<p>{'This invoice has already been paid.'}</p>}
                                                                   placement="top-start">
                                                        <button style={{ opacity: '0.5' }} className={'pay'}>
                                                            Pay in Full
                                                        </button>
                                                    </SimpleTooltip>
                                                }
                                            </TableCell>
                                        }

                                    </TableRow>
                                </React.Fragment>
                            ))
                            :
                            <NoItemsYet
                                text={invoicesNotYet.title}
                                subTitle={invoicesNotYet.subTitle}
                                image={invoicesNotYet.image}
                            />
                        }
                        </tbody>
                    </Table>

                </TableContainer>
                {listCount > 0 &&
                    <div style={{ height: '76px' }}>
                        <PaginationFillTable
                            listLength={list?.length}
                            entries={entries}
                            page={page}
                            handleReturn={(number) => changePage(number)}
                            count={listCount}
                        />
                    </div>
                }
            </div>
        </div>
    );
};
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AddButton, Loader, NoItemsYet, ValidationInput } from 'components';
import { httpRequestsOnSuccessActions, systemActions } from 'store';
import { ErrorText, FindLoad, FindSuccessItem, Images, isNotEmpty, useModal, } from 'utils';
import { systemItemStyles } from './styles';
import { EditJobTitle } from './modals/editJobTitle';
import { departNotYet, jobTitleNotYet } from './constants';

const credentialBtn = {
    maxWidth: '174px',
    width: '100%',
    flex: '0 0 174px',
    padding: 0,
};

const ACTION_TYPE = 'CREATE_JOB_GLOBAL';

export const JobTitles = ({ globalJobs }) => {
    const classes = systemItemStyles();
    const dispatch = useDispatch();
    const getLoader = FindLoad('GET_JOBS');
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccessItem(ACTION_TYPE);
    const [inputs, setInputs] = useState({});
    const [error, setError] = useState('');
    const { open } = useModal();

    useEffect(() => {
        dispatch(systemActions.getJobs());
    }, []);

    useEffect(() => {
        if (success) {
            setInputs({});
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    function handleChange(e) {
        setInputs((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
        error === e.target.name && setError('');
    }

    function handleSubmit() {
        if (isNotEmpty(inputs.name)) {
            const data = { name: inputs.name };
            dispatch(systemActions.createJobGlobal(data));
        } else {
            setError(!isNotEmpty(inputs.name) ? 'name' : '');
        }
    }

    if (getLoader?.length) {
        return <Loader />;
    }

    return (
        <Fragment>
            <div className={`${classes.flexContainer} ${classes.headerSize}`}>
                <ValidationInput
                    style={classes.credentialInputStyle}
                    onChange={handleChange}
                    value={inputs.name}
                    variant={'outlined'}
                    name={'name'}
                    type={'text'}
                    placeholder={'Job Title*'}
                    typeError={error === 'name' ? `Job title ${ErrorText.isRequired}` : ''}
                    Length={50}
                />
                <AddButton
                    type={ACTION_TYPE}
                    styles={credentialBtn}
                    loader={!!loader.length}
                    handleClick={handleSubmit}
                    text="Add Job Title"
                />
            </div>
            <p className={classes.title}>Job Titles</p>
            {globalJobs?.length ?
                <div className={classes.credentialTable}>
                    {globalJobs?.map((jobItem, index) => {
                        return (
                            <div className={classes.item} key={index}>
                                <p><span>{jobItem?.name}</span></p>
                                <div className={classes.icons}>
                                    <img
                                        src={Images.edit}
                                        alt="edit"
                                        onClick={() => open(<EditJobTitle info={jobItem} />)}
                                    />
                                    {/* <img
                              src={Images.remove}
                              alt="delete"
                              onClick={() =>
                                 removeItem({
                                    id: jobItem?.id,
                                    name: jobItem.name,
                                    type: "editJobTitles",
                                 })
                              }
                           /> */}
                                </div>
                            </div>
                        );
                    })
                    }
                </div>
                :
                <NoItemsYet
                    height={'300px'}
                    text={jobTitleNotYet.title}
                    subTitle={jobTitleNotYet.subTitle}
                    image={jobTitleNotYet.image}
                />
            }
        </Fragment>
    );
};

import { makeStyles } from '@material-ui/core';
import { Backgrounds, Colors } from 'utils';

export const leftBarCommonStyle = makeStyles(() => ({
    linkPathWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        width: '100%',
        marginBottom: '8px',
        cursor: 'pointer',
    },
    activeBorder: {
        width: '4px',
        height: '40px',
        background: Colors.BackgroundBlue,
        borderRadius: '0px 8px 8px 0px',
    },
    passiveBorder: {
        width: '4px',
        height: '40px',
        background: 'white',
        borderRadius: '0px 8px 8px 0px',
    },
    linkImageWrapper: {
        width: '24px',
        minWidth: '24px',
        height: '24px',
        padding: '2px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            width: '24px',
            minWidth: '24px',
            height: '24px',
        },
    },
    linkWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '8px',
        cursor: 'pointer',
        '& p': {
            width: '100%',
            fontSize: '14px',
            lineHeight: 'normal',
            fontWeight: 600,
        },

        '&:hover': {
            background: '#F2F4F8',
        }
    },
    linkWrapperActive: {
        background: '#E7F0FC',
        width: '100%',
        color: Colors.BackgroundBlue,
        fontWeight: 600,
        '&:hover': {
            background: '#E7F0FC',
        }
    },
    linkWrapperPassive: {
        color: '#172B4D',
    },

    listItem: {
        width: '100%',
        height: '36px',
        marginBottom: '8px',
        marginLeft: '6px',
        '&.accordionItem': { marginTop: '0px' },
        paddingLeft: '8px',
        borderRadius: '4px',
        '&.active': {
            borderRadius: '8px',
            background: Backgrounds.lightBlue,
        },
        '&.passive': { width: '40px' },
        '& span': {
            fontSize: '14px',
            fontWeight: 600,
            color: Colors.TextSecondary,
        },
    },


    // linkWrapperActive: {
    //     borderLeft: `4px solid ${Colors.BackgroundBlue}`,
    //     borderRadius: "0px 8px 8px 0px",
    // },
    accordionStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '8px',
    },
    // listItem: {
    //     width: "200px",
    //     height: "36px",
    //     marginTop: "8px",
    //     marginLeft: "12px",
    //     "&.accordionItem": { marginTop: "0px" },
    //     paddingLeft: "8px",
    //     borderRadius: "4px",
    //     "&.active": {
    //         borderRadius: "8px",
    //         background: Backgrounds.lightBlue,
    //     },
    //     "&.passive": { width: "40px" },
    //     "& span": {
    //         fontSize: "14px",
    //         fontWeight: 600,
    //         color: Colors.TextSecondary,
    //     },
    // },
    accordArrowStyle: {
        transform: 'rotate(0deg)',
        transition: 'transform 0.2s linear',
        '&.rotate': { transform: 'rotate(-180deg)' },
    },
    menuItemsStyle: {
        fontSize: '14px',
        fontWeight: 600,
        color: Colors.TextSecondary,
        lineHeight: '21px',
        marginLeft: '8px',
        '&.active span': { color: Colors.BackgroundBlue },
    },

    sectionsListBoxStyle: {
        height: '0px',
        overflow: 'hidden',
        transition: 'all 0.2s linear',
        marginBottom: 4,
        '&.shown': {
            height: 'auto'
        },
        '& > div': {
            width: '100%',
            '& > a': {
                fontSize: '14px',
                fontWeight: 600,
                color: '#172B4D',
                display: 'block',
                marginLeft: '10px',
                padding: '11px 16px 11px 40px',
                borderRadius: '4px',
                '&:hover':{
                    background: '#F2F4F8',
                },
                '&.active': {
                    borderRadius: '4px 0px 0px 4px',
                    background: '#E7F0FC',
                    color:'#0C66E4',
                },

                '& > div': {
                    color: 'inherit',
                    borderRadius: '4px',
                },
            },
        },
        '& .MuiTouchRipple-root':{
            background: 'transparent',
        },
        '& .MuiListItem-gutters': {
            padding: 0,
        },
        '& .MuiListItem-button:hover':{
            background: 'transparent'
        }
    },
}));

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    FindLoad,
    PAYROLL_FLAGGED_ENUMS,
    PermissionList,
    RolePermission,
    SaveParams,
    useModal,
} from 'utils';
import { AddButton, CheckboxesTags, CustomDateSelector, DownloadCsv, SelectTypeAutocomplete } from 'components';
import { TimeSheetModalSelector } from '../../../fragments';
import { adminActions } from 'store';


export const Filters = ({ handleProcess, noBtn, type }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const staff = useSelector((state) => state.admins.adminsList);
    const loader = FindLoad('CREATE_PROCESS_PAYROLL');
    const { open } = useModal();

    useEffect(() => {
        dispatch(adminActions.getAdmins({ status: 'ACTIVE' }));
    }, []);
    const handleChange = (e) => {
        const newFilters = {
            ...pushInfo,
            skip: 0,
            limit: 50,
            page: 1,
        };

        if (e.target.value === 'All') {
            delete newFilters[e.target.name];
        } else {
            newFilters[e.target.name] = e.target.value;
        }
        SaveParams(history, { ...newFilters });
    };

    const handleSelectValue = (list) => {
        const params = {
            ...pushInfo,
            skip: 0,
            limit: 50,
            page: 1,
        };

        list?.length ? params.staffs = list : delete params.staffs;
        SaveParams(history, { ...params });
    };

    return (
        <div className="payroll-filters-wrapper">
            <div className="filter-inputs-align-end">
                <CheckboxesTags
                    handleChange={handleSelectValue}
                    name="staffs"
                    renderValue={(i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}
                    value={pushInfo?.staffs ? pushInfo?.staffs : []}
                    permissionsList={staff?.staffs || []}
                    outLabel={type === 'active' ? 'Staff' : 'Processor'}
                    placeholder={pushInfo?.staffs?.length ? pushInfo?.staffs?.map((i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`).join(', ') : 'All'}
                    uiType={'tableFilter'}
                    notShowError={true}
                />
                {type !== 'active' &&
                    <SelectTypeAutocomplete
                        placeholder={pushInfo?.flag ? '' : 'All'}
                        name={'flag'}
                        outLabel={'Flag'}
                        handleSelect={handleChange}
                        defaultValue={pushInfo?.flag}
                        list={PAYROLL_FLAGGED_ENUMS}
                        renderValue={(i) => i?.label}
                        uiType={'tableFilter'}
                        noError={true}
                    />
                }
                <CustomDateSelector outLabel={'DOS'} />
            </div>

            <div className={'export-csv-box'}>
                <DownloadCsv smallSize={true} fromModal={true} type={type === 'active' ? 'activePayroll' : 'passivePayroll'} />
                {type === 'active' && RolePermission([PermissionList.TIMESHEET_CREATE?.code]) &&
                    <AddButton text="Add Timesheet" handleClick={() => open(<TimeSheetModalSelector />)} />
                }
                {!noBtn && RolePermission([PermissionList.TIMESHEET_PROCESS?.code]) &&
                    <div>
                        <AddButton
                            styles={{ width: '160px' }}
                            Icon={false}
                            loader={loader?.length}
                            type={'CREATE_PROCESS_PAYROLL'}
                            text={'Process Payroll'}
                            handleClick={handleProcess}
                        />
                    </div>
                }
            </div>
        </div>
    );
};
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FullTable } from 'components';
import { useModal } from 'utils';
import { adminActions } from 'store';
import { serviceSingleStyles } from './styles';
import { timesheetBody, timesheetHead, TIMESHEET_ACTION_TYPE, staffTimesheetNotYet } from './constants';
import { CreateActiveTimesheet, MilageTimesheet, TIMESHEET_ENUMS } from '../../modals';

export const StaffTimesheet = ({}) => {
    const { staffTimesheet } = useSelector((state) => ({
        staffTimesheet: state.admins.timesheet,
    }));
    const classes = serviceSingleStyles();
    const dispatch = useDispatch();
    const { open } = useModal();
    const params = useParams();
    const history = useHistory();
    const info = history?.location?.state;

    useEffect(() => {
        if (info) {
            const pushInfo = {
                ...info,
            };
            delete pushInfo.page;
            delete pushInfo.activeTab;
            delete pushInfo.skip;
            delete pushInfo.limit;
            pushInfo.limit = 50;
            pushInfo.skip = info?.skip ? info?.skip : 0;
            dispatch(adminActions.getTimesheet(params.id, { ...pushInfo }));
        }
    }, [info]);

    useEffect(() => {
        dispatch(adminActions.removeTimesheetById());
        return () => dispatch(adminActions.clearAllPayCodes());
    }, []);

    const handleClick = (name, item) => {
        if (item?.type === "MILEAGE") {
            open(<MilageTimesheet info={item}  fromStaff={true} />);
        }else {
            open(
                <CreateActiveTimesheet
                    info={item}
                    type={item?.payCode?._id ? TIMESHEET_ENUMS.PAYCODE : TIMESHEET_ENUMS.FIXED}
                    fromStaff={true}
                />,
            );
        }
    };

    return (
        <div className={classes.timesheetWrapper}>
            <FullTable
                head={timesheetHead}
                body={timesheetBody}
                loadingType={TIMESHEET_ACTION_TYPE}
                handleClick={handleClick}
                list={staffTimesheet?.timesheets}
                listCount={staffTimesheet?.count}
                notYet={staffTimesheetNotYet}
            />
        </div>
    );
};
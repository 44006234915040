export const SERVICE = 'SERVICE';
export const STAFF = 'STAFF';
export const BREAK = 'BREAK';
export const DRIVE = 'DRIVE';
export const PAID = 'PAID';
export const UNPAID = 'UNPAID';
export const SICK_TIME = 'SICK_TIME';

export function getDisplayFromType(givenType = '') {
    switch (givenType) {
        case SERVICE:
            return 'Service';
        case STAFF:
            return 'Staff';
        case BREAK:
            return 'Break';
        case DRIVE:
            return 'Drive';
        case PAID:
            return 'Paid';
        case UNPAID:
            return 'Unpaid';
        case SICK_TIME:
            return 'Sick';
        default:
            return '';
    }
}

export function getDisplayFromFullType(givenType = '') {
    switch (givenType) {
        case SERVICE:
            return 'Service Appointment';
        case STAFF:
            return 'Staff Appointment';
        case BREAK:
            return 'Break';
        case DRIVE:
            return 'Drive Time';
        case PAID:
            return 'Paid Time Off';
        case UNPAID:
            return 'Unpaid Time Off';
        case SICK_TIME:
            return 'Sick Type';
        default:
            return '';
    }
}

export const billStatuses = {
    BILLED: 'BILLED',
    NOT_BILLED: 'NOT_BILLED',
    ON_CLAIM: 'ON_CLAIM',
};

export const scheduleStatuses = {
    COMPLETED: 'COMPLETED',
    RENDER: 'RENDER',
    CANCELLED: 'CANCELLED',
    PENDING: 'PENDING',
};

export function diff_minutes(times) {
    if (times.startTime && times.endTime) {
        let dt1 = new Date(times.startTime);
        let dt2 = new Date(times.endTime);
        let diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= 60;
        return Math.abs(Math.round(diff));
    }
}
import React from 'react';
import { useSelector } from 'react-redux';
import { FilesComponent, Notes, SimpleTabs } from 'components';
import { PermissionList, RolePermission } from 'utils';
import { invoiceDetailTabsLabels } from './constants';
import { InvoiceAppointments } from './invoiceAppointments';
import { HistoryTab } from '../../../history';
import { InvoiceDetailTab } from './invoiceDetailTab';
import { InvoiceDetailHeader } from './invoiceDetailHeader';
import { InvoiceTransactions } from './invoiceTransactions';

export const InvoiceDetailInfo = ( ) => {
    const invoiceById = useSelector((state) => state.invoice.invoiceById);
    const invoiceDocuments = useSelector((state) => state.invoice.invoiceDocuments);

    const invoiceTabContent = [
        {
            tabComponent: <InvoiceDetailTab/>,
            tab: 'Details',
        },
        {
            tabComponent: <InvoiceAppointments/>,
            tab: 'Appointments',
        },
        {
            tabComponent: <InvoiceTransactions/>,
            tab: 'Transactions',
        },
        RolePermission([PermissionList.INVOICE_NOTE_READ?.code, PermissionList.INVOICE_NOTE_MANAGE?.code]) &&
        {
            tabComponent: (
                <Notes permissions={[PermissionList.INVOICE_NOTE_MANAGE?.code]}  onModel={'Invoice'} id={invoiceById?.id} />
            ),
            tab: 'Notes',
        },
        RolePermission([PermissionList.INVOICE_HISTORY_READ?.code]) &&
        {
            tabComponent: <HistoryTab currentId={invoiceById?.id} onModel={'Invoice'} />,
            tab: 'History',
        },
        RolePermission([PermissionList.INVOICE_FILE_MANAGE?.code, PermissionList.INVOICE_FILE_READ?.code]) &&
        {
            tabComponent: (
                <FilesComponent
                    currentId={invoiceById?.id}
                    onResource={'invoice'}
                    documents={invoiceDocuments}
                    loadTable={'GET_INVOICE_DOCUMENT'}
                    permissions={[PermissionList.INVOICE_FILE_MANAGE?.code]}
                />
            ),
            tab: 'Files',
        },
    ];

    return (
        <div className='tab-box-wrapper'>
            <InvoiceDetailHeader />
            <SimpleTabs
                defaultTab={'Details'}
                tabsLabels={invoiceDetailTabsLabels}
                tabsContent={invoiceTabContent}
            />
        </div>
    );
};
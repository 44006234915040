import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import {
    CreateChancel,
    CustomizedSwitch,
    DateInput,
    ErrMessage,
    SelectTypeAutocomplete,
    TimeInput,
    ToolInfo,
} from 'components';
import {
    enumValues,
    ErrorText,
    FindErrorItem,
    FindLoad,
    isNotEmpty,
    PermissionList,
    RolePermission,
    TimeIsGreat,
    UrlRegex,
} from 'utils';
import { scheduleModalsStyle } from './styles';
import { adminActions, appointmentActions, httpRequestsOnErrorsActions, httpRequestsOnLoadActions } from 'store';
import { ModalHeader } from './serviceAppt/modalHeader';
import { Recur } from './recur';
import { scheduleStatuses, SERVICE } from '../../constants';
import { apptOverlap, isDateGreater, renderTimes, renderUnitWarnings, RenderWarnings, staffOverlap } from './constants';
import { SelectStaff } from './selectStaff';
import { ScheduleLinks } from './scheduleLinks';

const GET_AUTHORIZATION = 'GET_AUTHORIZATION';
const GET_AUTH = 'GET_AUTH';
const GET_CLIENTS = 'GET_CLIENTS';
const GET_ALL_PAY_CODES = 'GET_ALL_PAY_CODES';

export const Service = ({ handleOpenClose, date, modalDate }) => {
    const { clientList, places, allPaycodes, clientStaffs } = useSelector((state) => ({
        clientList: state.client.clientList,
        places: state.system.places,
        allPaycodes: state.admins.allPaycodes,
        clientStaffs: state.appointment.clientStaffs,
    }));
    const REQUEST_TYPE = modalDate ? 'EDIT_APPOINTMENT' : 'CREATE_APPOINTMENT';
    const loader = FindLoad(REQUEST_TYPE);
    const backError = FindErrorItem(REQUEST_TYPE);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = scheduleModalsStyle();
    const createModalDate = history?.location?.state;
    const [inputs, setInputs] = useState(modalDate ? {} : createModalDate ? { ...createModalDate } : {});
    const [requires, setRequires] = useState({ requireSignature: false, requireNote: false });
    const [createMultiple, setCreateMultiple] = useState(false);
    const [error, setError] = useState('');
    const [editLoader, setEditLoader] = useState(false);
    const [clientService, setClientService] = useState([]);
    const [clientAuthService, setClientAuthService] = useState([]);
    const [currentStaff, setCurrentStaff] = useState(null);
    const [step, setStep] = useState('first');
    const [links, setLinks] = useState();
    const tb = currentStaff?.verifiableCredentials && currentStaff?.verifiableCredentials?.find((i) => i?.type === 'TB');
    const fbi = currentStaff?.verifiableCredentials && currentStaff?.verifiableCredentials?.find((i) => i?.type === 'DOJ');
    const currentService = clientAuthService?.length && clientAuthService?.find((i) => i?.id === inputs?.authService);
    const {
        uniteSizeCorrect,
        unitSizeCountIsCorrect,
        maxUnit,
        minUnit,
        currentApptUnites,
        unitSize,
    } = renderUnitWarnings(currentService, inputs);
    const checkDefaultCredential = (tb?.checked === true && isDateGreater(tb?.expireDate, inputs?.startDate, !createMultiple)) && fbi?.checked === true;

    useEffect(() => {
        dispatch(httpRequestsOnErrorsActions.removeError(REQUEST_TYPE));
        dispatch(httpRequestsOnErrorsActions.removeError('APPOINTMENT_OVERLAPPING'));
    }, []);

    useEffect(() => {
        if (createModalDate?.client) {
            handleGetClientServ(createModalDate?.client);
        }
        if (createModalDate?.staff) {
            const newInputs = { ...inputs };
            delete newInputs.staff;
            setInputs(newInputs);
        }
    }, [createModalDate]);

    useEffect(() => {
        if (modalDate) {
            const setInfo = {
                ...modalDate,
                client: modalDate.client?.id,
                staff: modalDate.staff?._id,
                placeService: modalDate.placeService?._id,
                authorizedService: modalDate?.authorizedService?.authorization?._id,
                staffPayCode: modalDate.staffPayCode?._id?._id || modalDate.staffPayCode?._id || modalDate?.staffPayCodeId,
                authService: modalDate.authorizedService?._id,
            };

            setRequires({
                requireSignature: modalDate?.requireSignature,
                requireNote: modalDate?.requireNote,
            });
            dispatch(appointmentActions.getStaffClients(modalDate.client?.id, modalDate?.authorizedService?.service?._id, modalDate.authorizedService?._id));

            if (modalDate.staff?._id) {
                dispatch(adminActions.getAllPayCodes(modalDate?.staff?._id));
            }
            if (modalDate?.links) {
                setLinks(modalDate?.links);
            }
            setInputs(setInfo);
        }
        if (!modalDate && date) {
            setInputs({
                startTime: date?.startTime?.slice(11, 16),
                endTime: date?.endTime?.slice(11, 16),
                startDate: date?.startDate,
            });
        }
    }, [modalDate, date]);

    useEffect(() => {
        if (modalDate && clientService) {
            clientStaffs?.forEach((item) => (
                item?.staffs?.find((i) => {
                    if (i?.id === modalDate?.staff?._id) {
                        setCurrentStaff({ chargeRateId: item?.chargeRateId, ...i });
                    }
                })
            ));
        }
    }, [modalDate, clientStaffs]);

    useEffect(() => {
        dispatch(httpRequestsOnErrorsActions.removeError('APPOINTMENT_OVERLAPPING_TOASTER'));
        dispatch(httpRequestsOnErrorsActions.removeError('APPOINTMENT_OVERLAPPING'));
        return () => dispatch(adminActions.clearAllPayCodes());
    }, []);

    useEffect(() => {
        if (backError?.error === apptOverlap || backError?.error === staffOverlap) {
            setError(ErrorText.overlappingError('Appointments'));
            dispatch(httpRequestsOnErrorsActions.removeError('APPOINTMENT_OVERLAPPING'));
        }
    }, [backError]);

    useEffect(async () => {
        if (modalDate) {
            setEditLoader(true);
            await handleGetClientServ(modalDate?.client?.id);
            await axios.get(`/authService/authorizations/${modalDate?.authorizedService?.authorization?._id}`, { auth: true })
                .then((res) => {
                        setClientAuthService(res.data);
                        setEditLoader(false);
                    },
                )
                .catch(() => {
                    setClientAuthService('');
                    setEditLoader(false);
                });
        }
    }, [modalDate]);

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        if (error === e.target.name) {
            setError('');
        }
        if (error === e.target.name || error === ErrorText.timeError || error === ErrorText.overlappingError('Appointments')) {
            setError('');
        }
        if (backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(backError.type));
        }
    };

    const removeStaffs = () => {
        dispatch(appointmentActions.removeStaffClients());
        dispatch(adminActions.clearAllPayCodes());
        setCurrentStaff(null);
    };

    const handleSelect = (ev) => {
        if (ev.target.name !== 'client' && ev.target.name !== 'authorizedService' && ev.target.name !== 'authService') {
            setInputs((prevState) => ({ ...prevState, [ev.target.name]: ev.target.value }));
        }

        if (ev.target.name === 'client') {
            removeStaffs();
            handleGetClientServ(ev.target.value);
            setClientService([]);
            setClientAuthService([]);
            let params = {
                ...inputs,
            };
            params.client = ev.target.value;
            delete params.authService;
            delete params.authorizedService;
            delete params.staffPayCode;
            delete params.serviceType;
            setInputs(params);
        }

        if (ev.target.name === 'authorizedService') {
            removeStaffs();
            const params = {
                ...inputs,
            };
            params.authorizedService = ev.target.value;
            delete params.authService;
            delete params.staff;
            delete params.staffPayCode;
            delete params.serviceType;
            setInputs(params);
            handleGetAuthorization(ev.target.value);
        }
        if (ev?.target?.name === 'authService') {
            removeStaffs();
            const current = clientAuthService?.find((i) => i?.id === ev.target.value);

            const params = {
                ...inputs,
            };

            current?.service?.type ? params.serviceType = current?.service?.type : delete params.serviceType;
            params.authService = ev.target.value;
            delete params.staff;
            delete params.staffPayCode;
            setInputs(params);
            setRequires({
                requireSignature: current?.service?.requireSignature,
                requireNote: current?.service?.requireNotes,
            });

            if (current?.service?._id && ev.target.value) {
                dispatch(appointmentActions.getStaffClients(inputs?.client, current?.service?._id, ev.target.value));
            }
        }
        setError('');
    };

    const selectStaff = (selected) => {
        const params = {
            ...inputs,
        };
        params.staff = selected?.id;
        delete params.staffPayCode;
        setInputs(params);
        setCurrentStaff(selected);
        dispatch(adminActions.getAllPayCodes(selected?.id));
        if (error === 'staff') setError('');
    };

    const handleRemoveStaff = () => {
        const params = {
            ...inputs,
        };
        delete params.staffPayCode;
        delete params.staff;
        setInputs(params);
        setCurrentStaff(null);
    };

    function handleGetClientServ(id) {
        if (id) {
            dispatch(httpRequestsOnLoadActions.appendLoading(GET_AUTHORIZATION));
            setClientService([]);
            axios.get(`/enrollment/authorizations/client/${id}`, { auth: true, params: { canUsed: true } })
                .then((res) => {
                    setClientService(res.data);
                    dispatch(httpRequestsOnLoadActions.removeLoading(GET_AUTHORIZATION));
                })
                .catch(() => {
                    setClientService([]);
                    dispatch(httpRequestsOnLoadActions.removeLoading(GET_AUTHORIZATION));
                });
        } else {
            setClientService([]);
        }
    }

    function handleGetAuthorization(id) {
        if (id) {
            dispatch(httpRequestsOnLoadActions.appendLoading(GET_AUTH));
            setClientAuthService([]);
            axios.get(`/authService/authorizations/${id}`, { auth: true })
                .then((res) => {
                    setClientAuthService(res.data);
                })
                .catch(() => {
                    setClientAuthService([]);
                }).finally(() => {
                dispatch(httpRequestsOnLoadActions.removeLoading(GET_AUTH));
            });
        } else {
            setClientAuthService([]);
        }
    }

    const apptInfo = () => {

        const startDate = moment.utc(inputs?.startDate);
        startDate.set({
            hour: inputs?.startTime?.slice(0, 2),
            minute: inputs?.startTime?.slice(3, 5),
            second: '00',
        });
        const endDate = moment.utc(inputs?.startDate);
        endDate.set({
            hour: inputs?.endTime?.slice(0, 2),
            minute: inputs?.endTime?.slice(3, 5),
            second: '00',
        });

        const data = {
            type: SERVICE,
            client: inputs.client,
            authorizedService: inputs.authService,
            staff: inputs.staff || currentStaff?.id,
            staffPayCode: inputs.staffPayCode,
            placeService: inputs.placeService,
            startDate: inputs.startDate && moment.utc(inputs.startDate).format('YYYY-MM-DD'),
            startTime: startDate.format(),
            endTime: endDate.format(),
            requireSignature: requires?.requireSignature,
            requireNote: requires?.requireNote,
            editTemplate: createMultiple,
            authService: inputs.authService,
            chargeRate: currentStaff?.chargeRateId ? currentStaff?.chargeRateId : modalDate?.chargeRateId,
        };

        if (createMultiple && !modalDate) {
            delete data.startTime;
            delete data.endTime;
            delete data.startDate;
        }

        inputs?.serviceType ? data.serviceType = inputs?.serviceType : delete data.serviceType;

        if (links?.length) {
            let newList = links.filter((i) => {
                if (i?.title && i?.url) {
                    if (!i?.url.startsWith('http://') && !i?.url.startsWith('https://')) {
                        return { title: i?.title, url: `https://${i?.url}` };
                    } else {
                        return { title: i?.title, url: i?.url };
                    }
                }
            });
            newList?.length ? data.links = newList : delete data.links;
        } else {
            delete data.links;
        }
        return data;
    };

    const handleCreate = async () => {
        const timeComparingIsValid = createMultiple ? true :
            !!inputs.startTime && !!inputs.endTime && TimeIsGreat(inputs.startTime, inputs.endTime);

        const checkStartDate = createMultiple ? true : !!inputs.startDate;

        const serviceAppointmentDataIsVlid =
            isNotEmpty(inputs.client) &&
            isNotEmpty(inputs.authorizedService) &&
            isNotEmpty(inputs.authService) &&
            isNotEmpty(inputs.placeService) &&
            isNotEmpty(inputs.staff) &&
            isNotEmpty(inputs?.staffPayCode) &&
            checkStartDate &&
            timeComparingIsValid;

        const linksChecker = links?.length ? links?.find((i) => UrlRegex.test(i?.url)) : true;
        const emptyLink = links?.length ? links?.find((i) => i?.title !== '') : true;
        const checkActivePayCode = allPaycodes?.find((i) => i?.id === inputs?.staffPayCode);

        if (serviceAppointmentDataIsVlid && checkDefaultCredential && currentStaff?.id && linksChecker && emptyLink) {
            const date = apptInfo();

            if (modalDate) {
                if (checkActivePayCode && !checkActivePayCode?.terminationDate) {
                    if (createMultiple) {
                        setStep('second');
                    } else {
                        dispatch(appointmentActions.editAppointment(date, modalDate?.id));
                    }
                } else {
                    setError('staffPayCode');
                }
            } else {
                if (createMultiple) {
                    setStep('second');
                } else {
                    dispatch(appointmentActions.createAppointment(date));
                }
            }
        } else {
            const dataErrorText =
                !isNotEmpty(inputs.client) ? 'client' :
                    !isNotEmpty(inputs.authorizedService) ? 'authorizedService' :
                        !isNotEmpty(inputs.authService) ? 'authService' :
                            !isNotEmpty(inputs.placeService) ? 'placeService' :
                                !currentStaff?.id ? 'staff' :
                                    !isNotEmpty(inputs?.staffPayCode) ? 'staffPayCode' :
                                        !linksChecker ? 'notValidLinks' :
                                            !emptyLink ? 'emptyTitle' :
                                                '';

            const dateTimeError =
                !createMultiple ?
                    !inputs.startDate ? 'startDate' :
                        !inputs.startTime ? 'startTime' :
                            !inputs.endTime ? 'endTime' :
                                !timeComparingIsValid ? ErrorText.timeError :
                                    '' : '';

            setError(dataErrorText ? dataErrorText : dateTimeError);
        }
    };

    const availableUnit = clientAuthService?.length && clientAuthService?.find((i) => i?.authorizationId?._id === inputs?.authorizedService);
    const checkAvailableUnits = inputs?.authService ? availableUnit?.availableUnits < 0 ? -1 : (availableUnit?.availableUnits - currentApptUnites) : '';
    const warnAuthorization = clientService?.length && clientService?.find((i) => i?.id === inputs?.authorizedService);

    const changeRequired = (e, name) => {
        const checkValue = requires[name] !== true;
        setRequires((prevState) => ({ ...prevState, [name]: checkValue }));
    };

    const setMultipleAppts = () => {
        if (createMultiple) {
            setStep('first');
        }
        setCreateMultiple(!createMultiple);
        const newParams = {
            ...inputs,
        };
        if (!modalDate) {
            delete newParams.startDate;
            delete newParams.startTime;
            delete newParams.endTime;
        }
        setInputs(newParams);
    };

    return (
        <div className={classes.serciveModall}>
            {editLoader === true ? (
                <div className={classes.loaderWrapper}>
                    <CircularProgress size={100} color={'primary'} />
                </div>
            ) : (
                <>
                    <ModalHeader
                        modalDate={modalDate}
                        multiple={createMultiple}
                        step={step}
                        setStep={setStep}
                        type={SERVICE}
                        createMultiple={createMultiple}
                        setMultipleAppts={setMultipleAppts}
                    />
                    {step === 'first' ?
                        <div className={classes.serviceModalBox}>
                            <div className={classes.serviceModalWrapper}>
                                <div className={classes.serviceInputsWrapper}>
                                    <SelectTypeAutocomplete
                                        loadType={GET_CLIENTS}
                                        title={'Client*'}
                                        name={'client'}
                                        handleSelect={handleSelect}
                                        defaultValue={inputs.client}
                                        list={clientList?.clients ? clientList?.clients : []}
                                        error={error}
                                        typeError={error === 'client' ? `Client ${ErrorText.isRequired}` : ''}
                                        renderValue={(i) => `${i?.firstName}${i?.lastName}${i?.code ? `(${i?.code})` : null}`}
                                    />
                                    <SelectTypeAutocomplete
                                        disabled={!inputs?.client}
                                        loadType={GET_AUTHORIZATION}
                                        title={'Authorization*'}
                                        name={'authorizedService'}
                                        handleSelect={handleSelect}
                                        defaultValue={inputs.authorizedService}
                                        list={clientService ? clientService : []}
                                        error={error}
                                        typeError={error === 'authorizedService' ? `Authorization ${ErrorText.isRequired}` : ''}
                                        renderValue={(i) => `${i?.funderId?.name} - ${i?.authId} (${i?.status?.toLowerCase()})`}
                                    />
                                    <SelectTypeAutocomplete
                                        loadType={GET_AUTH}
                                        disabled={!inputs?.authorizedService}
                                        title={'Service*'}
                                        name={'authService'}
                                        handleSelect={handleSelect}
                                        defaultValue={inputs?.authService}
                                        list={clientAuthService ? clientAuthService?.filter((i) => i?.service?.active) : []}
                                        error={error}
                                        typeError={error === 'authService' ? `Service ${ErrorText.isRequired}` : ''}
                                        renderValue={(i) => `${i?.service?.cptCode ? i?.service?.cptCode : ''} ${i?.chargeRates?.length ?
                                            `(${i?.chargeRates?.map((e) => i?.chargeRates?.length > 1 ? `${e?.name}` : e?.name).join(', ')})`
                                            : ''}`}
                                    />
                                    <SelectTypeAutocomplete
                                        title={'Type'}
                                        name={'serviceType'}
                                        handleSelect={handleSelect}
                                        defaultValue={inputs?.serviceType}
                                        list={enumValues.FUNDING_MODIFIER_SERVICE_TYPES}
                                        error={error}
                                        typeError={error === 'serviceType' ? `Type ${ErrorText.isRequired}` : ''}
                                        renderValue={(i) => i?.title}
                                    />
                                    <SelectTypeAutocomplete
                                        title={'Place of Service*'}
                                        name={'placeService'}
                                        handleSelect={handleSelect}
                                        defaultValue={inputs.placeService}
                                        list={places ? places : []}
                                        error={error}
                                        typeError={error === 'placeService' ? `Place of service ${ErrorText.isRequired}` : ''}
                                        renderValue={(i) => i?.name}
                                    />
                                </div>
                                <div style={{ width: '100%' }}>
                                    <SelectStaff
                                        clientStaffs={clientStaffs}
                                        currentStaff={currentStaff}
                                        disabled={!inputs?.authService}
                                        handleSelectStaff={selectStaff}
                                        handleRemoveStaff={handleRemoveStaff}
                                        typeError={
                                            error === 'staff' ? `Staff ${ErrorText.isRequired}` :
                                                !isDateGreater(tb?.expireDate, inputs?.startDate) && currentStaff ? `The staff TB expires on ${moment.utc(tb?.expireDate).format('MM/DD/YYYY')} Please enter an earlier date or adjust TB expiration.` :
                                                    !checkDefaultCredential && currentStaff ? 'Please make sure the staff member has both \'TB Clearance\' and \'DOJ/FBI Clearance\' credentials to proceed with this appointment.' :
                                                        ''}
                                    />
                                    {checkDefaultCredential ?
                                        inputs?.staff &&
                                        <div className={classes.informationWrapper}>
                                            <div className={classes.titleTextWrapper} style={{ marginBottom: 0 }}>
                                                <p className={classes.informationTitle}>Credentials:</p>
                                                <div className={classes.informationBody}>
                                                    {currentStaff?.credentials?.length ?
                                                        <p>
                                                            {currentStaff?.credentials?.map((i, j) => (
                                                                i?.name ? i?.name : i?.type
                                                            )).join(', ')}
                                                        </p>
                                                        :
                                                        <p>No Credentials</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ''
                                    }
                                    <SelectTypeAutocomplete
                                        disabled={!currentStaff?.id}
                                        loadType={GET_ALL_PAY_CODES}
                                        title={'Staff Paycode*'}
                                        name={'staffPayCode'}
                                        handleSelect={handleSelect}
                                        defaultValue={inputs?.staffPayCode}
                                        list={allPaycodes?.filter((data) => !data?.terminationDate) || []}
                                        error={error}
                                        typeError={error === 'staffPayCode' ? `Staff paycode ${ErrorText.isRequired}` : ''}
                                        renderValue={(i) => i?.payCodeTypeId?.name}
                                    />
                                    {!createMultiple &&
                                        <>
                                            <DateInput
                                                name={'startDate'}
                                                label={'Start Date*'}
                                                handleChange={handleChange}
                                                value={inputs?.startDate ? moment(inputs.startDate).format('MM/DD/YYYY') : inputs.startDate}
                                                typeError={error === 'startDate' && `Start date ${ErrorText.isRequired}`}
                                            />
                                            <div className={classes.timeInputs} style={{ gap: '16px' }}>
                                                <TimeInput
                                                    label={'Start Time*'}
                                                    name={'startTime'}
                                                    onChange={handleChange}
                                                    typeError={
                                                        error === 'startTime' ? `Start time ${ErrorText.isRequired}` :
                                                            backError?.error === apptOverlap ? ErrorText.overlappingError('Appointments') :
                                                                backError?.error === staffOverlap ? ErrorText.overlappingError('Appointments')
                                                                    : ''
                                                    }
                                                    defaultValue={
                                                        inputs?.startTime ? renderTimes(inputs.startTime) || inputs.startTime : null
                                                    }
                                                />
                                                <TimeInput
                                                    label={'End Time*'}
                                                    name={'endTime'}
                                                    onChange={handleChange}
                                                    typeError={
                                                        error === 'endTime' ? `End time ${ErrorText.isRequired}` :
                                                            error === ErrorText.timeError ? ErrorText.timeError
                                                                : ''
                                                    }
                                                    defaultValue={
                                                        inputs?.endTime ? renderTimes(inputs.endTime) || inputs.endTime : null
                                                    }
                                                />
                                            </div>
                                        </>
                                    }
                                    <RenderWarnings
                                        uniteSizeCorrect={uniteSizeCorrect}
                                        unitSizeCountIsCorrect={unitSizeCountIsCorrect}
                                        unitSize={unitSize}
                                        minUnit={minUnit}
                                        maxUnit={maxUnit}
                                        currentApptUnites={currentApptUnites}
                                        modalDate={modalDate}
                                        warnAuthorization={warnAuthorization}
                                        checkAvailableUnits={checkAvailableUnits}
                                        availableUnits={currentService?.availableUnits}
                                    />
                                </div>
                            </div>

                            <div>
                                <ScheduleLinks
                                    changeLinks={setLinks}
                                    item={modalDate}
                                    linkError={error}
                                    setLinkError={setError}
                                />
                                {RolePermission([PermissionList.APPT_SIGNATURE_TOGGLE_MANAGE?.code]) &&
                                    (modalDate?.status ? modalDate?.status === scheduleStatuses?.PENDING : true) &&
                                    <div className={classes.signatureStyle}>
                                        <ToolInfo text={'Require a signature to render this appointment.'} />
                                        <p>Require Signature</p>
                                        <CustomizedSwitch
                                            checked={requires?.requireSignature}
                                            handleClick={(e) => changeRequired(e, 'requireSignature')}
                                        />
                                    </div>
                                }
                                {RolePermission([PermissionList.APPT_NOTE_TOGGLE_MANAGE?.code]) &&
                                    (modalDate?.status ? modalDate?.status === scheduleStatuses?.PENDING : true) &&
                                    <div className={classes.signatureStyle}
                                         style={{ marginBottom: 0 }}
                                    >
                                        <ToolInfo text={'Require notes to render this appointment.'} />
                                        <p>Require Notes</p>
                                        <CustomizedSwitch
                                            checked={requires?.requireNote}
                                            handleClick={(e) => changeRequired(e, 'requireNote')}
                                        />
                                    </div>
                                }
                            </div>

                            {
                                backError?.error !== apptOverlap &&
                                backError?.error !== staffOverlap &&
                                <ErrMessage
                                    text={backError?.error}
                                />
                            }

                            <div className={classes.actionBtnBox}>
                                <CreateChancel
                                    loader={!!loader.length}
                                    create={
                                        createMultiple ? 'Continue' :
                                            modalDate ? 'Save' : 'Add'
                                    }
                                    chancel={'Cancel'}
                                    onCreate={handleCreate}
                                    onClose={handleOpenClose}
                                    buttonWidth="48%"
                                />
                            </div>
                        </div>
                        :
                        <div>
                            <Recur
                                setStep={() => setStep('first')}
                                date={apptInfo()}
                                multiple={createMultiple}
                                handleClose={handleOpenClose}
                                dateTime={date}
                                staffTb={tb}
                                modalDate={modalDate}
                                currentService={currentService}
                            />
                        </div>
                    }
                </>
            )}
        </div>
    );
};

import { DateRow, PriceRow, TextRow, SimpleTooltip } from 'components';
import { convertHours, Images, PermissionList, RolePermission } from 'utils';
import React from 'react';
import { Checkbox } from '@material-ui/core';

export const generateInvoiceHead = (handleSelect, fullList, selected) => {
    return [
        {
            renderView: <SimpleTooltip
                title={<p>Select All</p>}
                placement="top-start"
            >
                <Checkbox
                    onClick={(e) => handleSelect(e, '', 'all')}
                    name={'checkBox'}
                    style={{ padding: 0, color: 'white' }}
                    checked={fullList?.length === selected?.length}
                />
            </SimpleTooltip>, width: '50px',
        },
        { name: '', title: 'Service Code', icon: 'date', noSearch: true, custom: false },
        { name: '', title: 'Place of Service', noSearch: true, custom: false },
        { name: '', title: 'Service Date', noSearch: true, custom: false },
        { name: '', title: 'Client', noSearch: true, custom: false },
        { name: '', title: 'Hours', noSearch: true, custom: false },
        { name: '', title: 'Invoice Amount', noSearch: true, custom: false },
    ];
};

export const generateInvoiceBody = (handleSelect, fullList, selected, handleEdit) => {
    return [
        {
            button: (handle, item) => (
                <SimpleTooltip
                    title={<p>Select</p>}
                    placement="top-start"
                >
                    <Checkbox
                        onClick={(e) => handleSelect(e, item)}
                        name={'checkBox'}
                        style={{ padding: 0, color: '#0C66E4' }}
                        checked={selected?.includes(item?.id)}
                    />
                </SimpleTooltip>
            ), notClickable: true,
        },
        {
            rowText: (item) => <TextRow name={item?.serviceCode ? item?.serviceCode : 'N/A'} />,
        },

        { rowText: (item) => <TextRow name={item?.placeOfService} /> },
        { rowText: (item) => <DateRow date={item?.dateOfService} /> },
        { rowText: (item) => <TextRow name={item?.client} /> },
        { rowText: (item) => <div>{convertHours(item?.hours)}</div> },
        { rowText: (item) => <PriceRow info={item?.invoiceAmount} /> },
    ];
};

export const GENERATE_ACTION_TYPE = 'GET_INVOICES_FOR_GENERATE';


// Invoice Payment Table config
export const invoicePaymentHead = [
    { name: 'displayId', title: 'Invoice ID', custom: false, width: '90px' },
    { name: 'dateRange', title: 'Invoice Date', icon: 'date', disabled: true, custom: false, width: '90px' },
    { name: 'dateRange', title: 'DOS', icon: 'date', disabled: true, custom: false, },
    { name: '', rowName: 'firstName', title: 'Client', custom: false },
    { name: 'totalAMT', title: 'Total AMT', custom: false },
    { name: 'totalUsed', title: 'Pmt. AMT', custom: false, disabled: true },
    { name: 'balance', title: 'Balance', custom: false },
    { name: 'paymentType', title: 'Payment Type', custom: false, disabled: true, width: '100px' },
    // { name: 'paymentReference', title: 'Payment Ref.', custom: false, disabled: true, width: '100px' },
    {
        name: 'status', title: 'Status', disabled: true, width: '75px',
        filterList: [
            { name: 'All', id: 'All' },
            { name: 'Open', id: 'OPEN' },
            { name: 'Paid', id: 'PAID' },
            { name: 'Closed', id: 'CLOSED' },
        ],
    },
    RolePermission([PermissionList.INVOICE_PMT_PAY?.code]) &&
    { name: 'actions', title: 'Actions', custom: false, disabled: true, width: '200px' },
];

export const GET_ACTION_TYPE = 'GET_INVOICE_PAYMENTS';

export const postingInvoiceEnums = {
    COMPLETED: 'COMPLETED',
    PAID: 'PAID',
};

export const invoicesNotYet = {
    title: 'No Invoices',
    subTitle: 'There are no invoices.',
    image: Images.noPendingClaim,
}

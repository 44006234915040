import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MenuBar } from 'fragments';
import { useSelector } from 'react-redux';
import {
    AboutUs,
    ContactUs,
    ControlPanelPage,
    Features,
    GettingStartedPage,
    HelpCenterPage,
    Home,
    LoginPage,
    PrivacyPolicy,
    TermsAndConditions,
} from '../pages';
import { InactivityHandler } from 'utils';

export const RouterSwitcher = ({}) => {
    const { accessToken } = useSelector((state) => ({
        accessToken: state.auth.accessToken,
    }));
    const token = accessToken ? accessToken : localStorage.getItem('access-token');
    const link = window.location.search;

    useEffect(() => {
        if (link.search('token=') !== -1 || link.search('resetToken=') !== -1) {
            localStorage.clear();
            sessionStorage.clear();
        }
    }, []);

    return (
        <React.Fragment>
            {!token || link.search('resetToken=') !== -1 || link.search('token=') !== -1
                ? (
                    <>
                            <Switch>
                                <Route path={'/'} exact component={Home} />
                                <Route path={'/features'} exact component={Features} />
                                <Route path={'/about-us'} exact component={AboutUs} />
                                <Route path={'/contact-us'} component={ContactUs} />
                                <Route exact path={'/help-center'} component={HelpCenterPage} />
                                <Route path={'/help-center/getting-started'} component={GettingStartedPage} />
                                <Route path={'/help-center/control-panel'} component={ControlPanelPage} />
                                <Route path={'/terms-and-conditions'} component={TermsAndConditions} />
                                <Route path={'/privacy-policy'} component={PrivacyPolicy} />
                                <Route path='/login' exact component={LoginPage} />
                                <Route path='/forgot' exact component={LoginPage} />
                                <Route path='/resetPassword/:resetToken?' exact component={LoginPage} />
                                <Route path='/register/:token?' exact component={LoginPage} />
                                <Redirect to={'/'} />
                            </Switch>
                    </>
                ) : (
                    <div className='open-sans'>
                        <MenuBar />
                        <InactivityHandler/>
                    </div>
                )}
        </React.Fragment>
    );
};

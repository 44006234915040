import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CustomDateSelector, FullTable, MinLoader, SelectTypeAutocomplete, } from 'components';
import { Colors, Images, SaveParams, useWeekInterval } from 'utils';
import { ACTION_TYPE, reportBody, reportHead, reportNotYet } from '../../pages/report/constants';
import { reportsStyle } from './styles';
import { clientActions, reportActions } from 'store';
import axios from 'axios';

export const SignatureReports = ({}) => {
    const clientList = useSelector((state) => state.client.clientList);
    const signatureReports = useSelector((state) => state.reports.signatureReports);
    const classes = reportsStyle();
    const dispatch = useDispatch();
    let history = useHistory();
    const info = history?.location?.state;
    const { firstDate } = useWeekInterval();
    const token = localStorage.getItem('access-token');
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        dispatch(clientActions.getClients());
    }, []);

    useEffect(() => {
        const sendInfo = renderParams();
        dispatch(reportActions.getSignatureReports({ ...sendInfo }));
    }, [info]);

    const handleChange = (e) => {
        const sendInfo = {
            ...info,
        };
        if (e.target.value === 'All' || !e.target.value) {
            delete sendInfo[e.target.name];
        } else {
            sendInfo[e.target.name] = e.target.value;
        }
        SaveParams(history, { ...sendInfo });
    };

    const renderParams = () => {
        const sendInfo = {
            ...info,
            limit: 50,
            start: firstDate,
            end: firstDate,
        };
        sendInfo.skip = info?.skip ? info?.skip : 0;
        info?.firstDate ? sendInfo.start = info?.firstDate : delete sendInfo.start;
        info?.lastDate ? sendInfo.end = info?.lastDate : delete sendInfo.end;
        delete sendInfo.page;
        delete sendInfo.activeTab;
        return sendInfo;
    };

    const handleDownload = async () => {
        setLoader(true);
        const sendInfo = renderParams();
        axios({
            url: `/reports/appt/signature/pdf`,
            method: 'GET',
            responseType: 'blob',
            headers: { 'access-token': `${token}` },
            params: { ...sendInfo },
        }).then((response) => {
            setLoader(false);
            const url = window.URL.createObjectURL(new Blob([response?.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `SignatureReports.pdf`);
            document.body.appendChild(link);
            link.click();
        }).catch(() => setLoader(false));
    };

    return (
        <div className={classes.reportTableWrapper}>
            <div className={classes.reportWrapper}>
                <div className='space-between table-invoice-filter-section'>
                    <div className='flex-align-start' style={{gap:'24px'}}>
                        <SelectTypeAutocomplete
                            placeholder={info?.client ? '' : 'All'}
                            name={'client'}
                            handleSelect={handleChange}
                            defaultValue={info?.client}
                            list={clientList?.clients ?
                                [{ id: 'All', firstName: 'All' }, ...clientList?.clients]
                                :
                                [{ id: 'All', firstName: 'All' }]
                            }
                            renderValue={(i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}
                            uiType={'tableFilter'}
                            outLabel={'Client'}
                            noError={true}
                        />
                    <CustomDateSelector outLabel={'DOS'} />
                    </div>
                    <button onClick={handleDownload} className={classes.downloadPdf}>
                        {loader ?
                            <MinLoader style={{ margin: 0, height: '19px' }} position={'relative'}
                                       color={Colors.ThemeBlue} />
                            :
                            <>
                                <img src={Images.downloadPdf} alt='icon' />
                                Download PDF
                            </>
                        }
                    </button>
                </div>
                <FullTable
                    head={reportHead}
                    body={reportBody}
                    loadingType={ACTION_TYPE}
                    list={signatureReports?.signatures}
                    listCount={signatureReports?.count}
                    notYet={reportNotYet}
                    activeRowId={''}
                />
            </div>
        </div>
    );
};
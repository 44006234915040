import {
    GET_ACTIVE_PAYROLL_SUCCESS, GET_ACTIVE_PROCESS_PAYROLL, GET_ACTIVE_PROCESS_PAYROLL_SUCCESS,
    GET_PAYROLL_BY_ID_SUCCESS, GET_PAYROLL_DOCUMENT_SUCCESS,
    GET_PROCESSED_PAYROLL_SUCCESS,
} from './staffPayroll.types';

const initialState = {
    activePayroll: null,
    activeProcessPayroll: null,
    payrollById: null,
    processedPayroll: null,
    payrollDocuments: null,
};

export const staffPayrollReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Active Payroll */

        case  GET_ACTIVE_PAYROLL_SUCCESS:
            return {
                ...state,
                activePayroll: action?.payload,
            };

        case  GET_ACTIVE_PROCESS_PAYROLL_SUCCESS:
            return {
                ...state,
                activeProcessPayroll: action?.payload,
            };

        case  GET_PAYROLL_BY_ID_SUCCESS:
            return {
                ...state,
                payrollById: action?.payload,
            };

        /** End */


        /** Processed Payroll */

        case  GET_PROCESSED_PAYROLL_SUCCESS:
            return {
                ...state,
                processedPayroll: action?.payload,
            };

        /** End */

        case  GET_PAYROLL_DOCUMENT_SUCCESS:
            return {
                ...state,
                payrollDocuments: action?.payload,
            };

        default:
            return state;
    }
};

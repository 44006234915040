import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FullTable } from 'components';
import { INVOICE_TRANSACTIONS_TYPE, invoiceTransactionsBody, invoiceTransactionsHead } from './constants';
import { invoiceActions } from 'store';

export const InvoiceTransactions = () => {
    const dispatch = useDispatch();
    const invoiceTxns = useSelector((state) => state.invoice.invoiceTxns);
    const invoiceById = useSelector((state) => state.invoice.invoiceById);

    useEffect(async () => {
        if(invoiceById) {
            const billIds = await invoiceById?.bills?.map((bill) => bill.id);
            if(billIds?.length) {
                dispatch(invoiceActions.getInvoiceTransactions(billIds));
            }
        }
    }, [invoiceById]);

    return (
        <div>
            <FullTable
                head={invoiceTransactionsHead}
                body={invoiceTransactionsBody}
                loadingType={INVOICE_TRANSACTIONS_TYPE}
                list={invoiceTxns}
                noText={'transactions'}
                activeRowId={''}
            />
        </div>
    );
};
import {call, put, takeLatest} from "redux-saga/effects";
import {httpRequestsOnErrorsActions} from "../http_requests_on_errors";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {httpRequestsOnSuccessActions} from "../http_requests_on_success";
import {
    CREATE_PROCESS_PAYROLL,
    DELETE_PAYROLL, DELETE_PAYROLL_DOCUMENT, EDIT_PAYROLL_DOCUMENT,
    GET_ACTIVE_PAYROLL,
    GET_ACTIVE_PAYROLL_SUCCESS,
    GET_ACTIVE_PROCESS_PAYROLL,
    GET_ACTIVE_PROCESS_PAYROLL_SUCCESS,
    GET_PAYROLL_BY_ID,
    GET_PAYROLL_BY_ID_SUCCESS, GET_PAYROLL_DOCUMENT, GET_PAYROLL_DOCUMENT_SUCCESS,
    GET_PROCESSED_PAYROLL,
    GET_PROCESSED_PAYROLL_SUCCESS, RESOLVE_PAYROLL, UPLOAD_PAYROLL_DOCUMENT,
} from './staffPayroll.types';
import {authService} from "./staffPayroll.service";

/** Active Payroll */

function* getActivePayroll(action) {
    if(action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.getActivePayrollService, action?.payload?.params);
        yield put({
            type: GET_ACTIVE_PAYROLL_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* getActiveProcessPayroll(action) {
    if(action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.getActivePayrollService, action?.payload?.params);
        yield put({
            type: GET_ACTIVE_PROCESS_PAYROLL_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* createProcessPayroll(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.createProcessPayrollService, action?.payload?.params);
        // yield put({
        //     type: GET_ACTIVE_PAYROLL,
        //     payload: { params: action?.payload?.params, load:'noLoad'},
        // });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));

    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}
function* getPayrollById(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.getPayrollByIdService, action?.payload?.id);
        yield put({
            type: GET_PAYROLL_BY_ID_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));

    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* deletePayroll(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.deletePayrollService, action?.payload?.id);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* resolvePayroll(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.resolvePayrollService, action?.payload?.id, action?.payload?.params);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}


/** End */

/** Processed Payroll */

function* getProcessedPayroll(action) {
    if(action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.getProcessedPayrollService, action?.payload?.params);
        yield put({
            type: GET_PROCESSED_PAYROLL_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));

    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** End */



/** Payroll Documents */

function* uploadPayrollDocument(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.uploadDocumentService, action.payload.file, action.payload.id);
        yield put({
            type: GET_PAYROLL_DOCUMENT,
            payload: { id: action?.payload?.id, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editPayrollDocument(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.editDocumentService, action?.payload);
        yield put({
            type: GET_PAYROLL_DOCUMENT,
            payload: { id: action?.payload?.id, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* getPayrollDocument(action) {
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(authService.getDocumentService, action.payload.id);
        yield put({
            type: GET_PAYROLL_DOCUMENT_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* deletePayrollDocument(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.deleteDocumentService, action.payload?.payrollId, action.payload.id);
        yield put({
            type: GET_PAYROLL_DOCUMENT,
            payload: { id: action?.payload?.payrollId, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** End */
export const watchStaffPayroll = function* watchStaffPayrollSaga() {
    /** Active Payroll */
    yield takeLatest(GET_ACTIVE_PAYROLL, getActivePayroll);
    yield takeLatest(GET_ACTIVE_PROCESS_PAYROLL, getActiveProcessPayroll);
    yield takeLatest(CREATE_PROCESS_PAYROLL, createProcessPayroll);
    yield takeLatest(GET_PAYROLL_BY_ID, getPayrollById);
    yield takeLatest(DELETE_PAYROLL, deletePayroll);
    yield takeLatest(RESOLVE_PAYROLL, resolvePayroll);
    /** End */

    /** Processed Payroll */
    yield takeLatest(GET_PROCESSED_PAYROLL, getProcessedPayroll);
    /** End */

    /** Payroll Documents */
    yield takeLatest(UPLOAD_PAYROLL_DOCUMENT, uploadPayrollDocument);
    yield takeLatest(EDIT_PAYROLL_DOCUMENT, editPayrollDocument);
    yield takeLatest(GET_PAYROLL_DOCUMENT, getPayrollDocument);
    yield takeLatest(DELETE_PAYROLL_DOCUMENT, deletePayrollDocument);
    /** End */
};
import React from "react";
import { wrapperStyle } from "./styles";

export const ModalContentWrapper = ({
   titleContent,
   subtitleContent,
   content,
   children,
   wrapperClassName,
   textAlign,
    noDefaultStyles,
                                        contentStyles
}) => {
   const classes = wrapperStyle();

   const modalContentWrapperStyle = noDefaultStyles ? '' : `${classes.wrapperContainerStyle} ${wrapperClassName}`;

   return (
      <div className={modalContentWrapperStyle}>
         <div style={{ paddingBottom: "0", textAlign: textAlign }}>
            {titleContent && (
               <h2 className={classes.wrapperTitleStyle}>{titleContent}</h2>
            )}
            {subtitleContent && (
               <p className={classes.wrapperSubtitleStyle}>{subtitleContent}</p>
            )}
            {content}
         </div>
         <div style={{...contentStyles}}>{children}</div>
      </div>
   );
};

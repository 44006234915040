import { Images } from '../../utils';
import React from 'react';
import { SimpleTooltip } from './tooltip';

export const ToolInfo = ({ text, noTool }) => {
    return (
        <div>
            {noTool ?
                <img src={Images.toolInfoBlack} alt={'icon'} />
                :
                <SimpleTooltip title={<div>{text}</div>} placement="top-start">
                    <img src={Images.toolInfoBlack} alt={'icon'} />
                </SimpleTooltip>
            }
        </div>
    );
};
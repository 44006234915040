import { makeStyles } from '@material-ui/core/styles';
import { Colors, Shadow } from 'utils';

export const claimDetailsStyle = makeStyles(() => ({
    refreshClaimBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '36px',
        height: '36px',
        borderRadius: '4px',
        border: '1px solid #E4E7EC',
        background: '#FFF',
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    },
    claimDetailsContainerStyle: {
        width: '100%',
        backgroundColor: Colors.BackgroundWhite,
        borderRadius: '8px',
        boxShadow: Shadow.noteModalShadow,
        padding: '24px',
        '@media(max-width: 1280px)': { padding: '16px' },
    },
    claimDetailsStyle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '16px',
    },
    closeButnStyle: {
        border: 'none',
        outline: 'none',
        padding: '0 16px',
        height: '36px',
        borderRadius: '4px',
        backgroundColor: Colors.ThemeRed,
        fontSize: '14px',
        fontWeight: 600,
        color: Colors.BackgroundWhite,
        '&.closed': {
            backgroundColor: Colors.BackgroundWater,
            color: Colors.ThemeRed,
            cursor: 'default',
        },
    },
    submitClaimBtn: {
        border: 'none',
        outline: 'none',
        padding: '0 16px',
        height: '36px',
        borderRadius: '4px',
        backgroundColor: Colors.ThemeBlue,
        fontSize: '14px',
        fontWeight: 600,
        color: Colors.BackgroundWhite,
    },
    claimDetailsFirstPartStyle: {
        width: '100%',
        backgroundColor: Colors.BackgroundWhite,
        borderRadius: '8px',
        boxShadow: '0px 0px 6px #8A8A8A3D',
        marginBottom: '24px',
        padding: '16px',
        '@media(max-width: 1280px)': { padding: '8px' },
    },
    claimOutlineStyle: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px',
    },
    claimIdTextBoxStyle: {
        fontSize: '16px',
        fontWeight: 600,
        color: Colors.TextSecondary,
    },
    claimDetailsListStyleBox: {
        borderRadius: '8px',
        border: '1px solid #E4E7EC',
        background: '#FFF',
        padding: 16,

    },
    claimDetailsListStyle: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px 16px',
        width: '100%',
        '& li': {
            flexGrow: 1,
            width: '32%',
            maxWidth: '32%',
            padding: '9px 16px',
            '@media(max-width: 1280px)': { padding: '9px 8px' },
            backgroundColor: Colors.BackgroundCatskillWhite,
            borderRadius: '8px',
            '& > span': {
                fontSize: '14px',
                fontWeight: 600,
                color: Colors.TextSecondary,
                '& em': {
                    fontSize: 'inherit',
                    fontWeight: 500,
                    color: Colors.TextMiddleGray,
                    marginLeft: '8px',
                },
            },
        },
    },
    claimInfoBoxStyle: {
        // maxWidth: "728px",
        width: 'fit-content',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '8px 16px',
        backgroundColor: '#347AF01A',
        marginBottom: '24px',
        borderRadius: '4px',
        '& > img': { marginRight: '8px' },
    },
    claimInfoStyle: {
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: 500,
        color: Colors.TextPrimary,
    },
    claimDetailsSecondPartStyle: { width: '100%' },
    claimDetailsTitleBoxStyle: {
        fontSize: '18px',
        fontWeight: 700,
        color: Colors.TextSecondary,
        textTransform: 'capitalize',
    },
    closeClaimWrapperStyle: {
        '& > button': { backgroundColor: Colors.BackgroundWater },
        padding: 0,

    },
}));

import { Images, useGlobalTextStyles, useModal } from 'utils';
import { staffModalsStyle } from './styles';
import { CreateActiveTimesheet } from './createActiveTimesheet';
import { TIMESHEET_ENUMS } from './constants';


export const TimeSheetModalSelector = ({ fromStaff }) => {
    const globalStyle = useGlobalTextStyles();
    const classes = staffModalsStyle();
    const { open } = useModal();

    const handleOpen = (type) => {
        open(<CreateActiveTimesheet createType={type} fromStaff={fromStaff} />);
    };

    return (
        <div>
            <p className={globalStyle.modalTitle}>{'Add Timesheet'}</p>
            <p className={globalStyle.modalText}>{'Please select timesheet type.'}</p>
            <button className={classes.typeBtn} onClick={() => handleOpen(TIMESHEET_ENUMS.FIXED)}>
                <p>Fixed Pay</p>
                <img src={Images.east} alt="" />
            </button>

            <button className={classes.typeBtn} onClick={() => handleOpen(TIMESHEET_ENUMS.PAYCODE)}>
                <p> From Paycodes</p>
                <img src={Images.east} alt="" />
            </button>
        </div>
    );
};
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import { FindLoad, FindSuccess, Images } from 'utils';
import {authActions, httpRequestsOnSuccessActions} from "store";
import { CheckboxesTags, DeleteElement, NoItemsYet, NoItemText, SimpleModal } from 'components';
import {roleStyles} from "./styles";
import {RoleItem} from "./rollItem";

const ACTION_TYPE = 'REMOVE_ACCESS'

export const Role = ({accessList = [], sendItem, handleRemoveSelected, newList,}) => {
    const params = useParams();
    const dispatch = useDispatch();
    const classes = roleStyles();
    const [index, setIndex] = useState(null);
    const [item, setItem] = useState("");
    const [openDel, setOpen] = useState(false);
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccess(ACTION_TYPE);

    useEffect(() => {
        if (!!success?.length) {
            setItem("");
            setIndex(0);
            setOpen(false);
            handleRemoveSelected && handleRemoveSelected();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    const getRoleItemId = (item) => {
        setItem(item);
        setOpen((prevState) => !prevState);
    };

    const handleOpenCloseDel = () => {
        setItem("");
        setOpen((prevState) => !prevState);
    };

    const handleOpen = (item, j) => {
        setIndex(j);
        setItem(item);
        sendItem(item);
    };

    const addPermissions = (item) => {
        if (item?.length) {
            dispatch(authActions.assignAccess(params.id, item[item?.length - 1]?.id));
        }
    };

    const handleDelete = () => {
        dispatch(authActions.removeAccess(params.id, item.id));
    };

    return (
        <div className={classes.roleWrapper}>
            <CheckboxesTags
                handleChange={addPermissions}
                permissionsList={newList?.length ? newList : []}
                label={"Add Role"}
                placeholder={"Add Role"}
                notShowError={true}
            />
            <div className={classes.roleItemContainer}>
                {accessList?.roles?.length ? (
                    accessList?.roles.map((item, j) => (
                        <div key={j}>
                            <RoleItem
                                handleOpen={() => handleOpen(item, j)}
                                handleClick={() => getRoleItemId(item)}
                                roleItem={item.title}
                                roleDescription={item?.description}
                                active={index === j}
                                // handleEdit={() => open(<EditRole item={item}/>)}
                            />
                        </div>
                    ))
                ) : (
                    <NoItemsYet
                        text={'No Roles Assigned'}
                        subTitle={'Assign roles to define this staff member’s access and permissions.'}
                        image={Images.noAccess}
                    />
                )}
            </div>
            <SimpleModal
                openDefault={openDel}
                handleOpenClose={handleOpenCloseDel}
                content={
                    <DeleteElement
                        loader={!!loader.length}
                        text={`Are you sure you want to remove the ${item?.title} role from this staff`}
                        info={item?.title}
                        innerText={"Remove"}
                        handleDel={handleDelete}
                        handleClose={handleOpenCloseDel}
                    />
                }
            />
        </div>
    );
};

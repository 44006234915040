import React from 'react';
import {
    FilesComponent,
    Notes,
    SimpleTabs,
} from 'components';
import { TimeSheetDetailHeader, TimeSheetDetailsTab } from './core';
import { timeSheetDetailTabsLabels } from './constants';
import { PermissionList, RolePermission } from 'utils';
import { useSelector } from 'react-redux';
import { HistoryTab } from '../history';

export const TimeSheetDetail = ({}) => {
    const payrollById = useSelector((state) => state.staffPayroll.payrollById);
    const payrollDocuments = useSelector((state) => state.staffPayroll.payrollDocuments);

    const claimTabContent = [
        {
            tabComponent: <TimeSheetDetailsTab/>,
            tab: 'Details',
        },
        RolePermission([PermissionList.TIMESHEET_NOTE_READ?.code, PermissionList.TIMESHEET_NOTE_MANAGE?.code]) &&
        {
            tabComponent: (
                <Notes permissions={[PermissionList.TIMESHEET_NOTE_MANAGE?.code]}  onModel={'Timesheet'} id={payrollById?.id} />
            ),
            tab: 'Notes',
        },
        RolePermission([PermissionList.TIMESHEET_HISTORY_READ?.code]) &&
        {
            tabComponent:  <HistoryTab currentId={payrollById?.id} onModel={'Timesheet'} />,
            tab: 'History',
        },
        RolePermission([PermissionList.TIMESHEET_FILE_READ?.code, PermissionList.TIMESHEET_FILE_MANAGE?.code]) &&
        {
            tabComponent: (
                <FilesComponent
                    currentId={payrollById?.id}
                    onResource={'payroll'}
                    documents={payrollDocuments}
                    loadTable={'GET_PAYROLL_DOCUMENT'}
                    permissions={[PermissionList.TIMESHEET_FILE_MANAGE?.code]}
                />
            ),
            tab: 'Files',
        },

    ];


    return (
            <div className='tab-box-wrapper'>
                <TimeSheetDetailHeader/>
                <SimpleTabs
                    defaultTab={'Details'}
                    tabsLabels={timeSheetDetailTabsLabels}
                    tabsContent={claimTabContent}
                />
            </div>
    );
};

import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    AddButton,
    AddNotes, CustomDelete, DeleteWithIcon,
    EditWithIcon, SearchHistory, UploadModal,
} from 'components';
import { Images, PermissionList, RolePermission, useModal } from 'utils';
import { staffPayrollActions } from 'store';
import { CreateActiveTimesheet, MilageTimesheet } from '../../staff';
import { Resolve } from '../../../pages/payroll/fragments/resolve';
import { PAYROLL_ENUMS } from '../../../pages/payroll/constants';

const DELETE_ACTION_TYPE = 'DELETE_PAYROLL';
export const TimeSheetDetailHeader = ({}) => {
    const payrollById = useSelector((state) => state.staffPayroll.payrollById);
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const { open, close } = useModal();

    const handleOpenModal = () => {
        if (payrollById?.type === 'MILEAGE') {
            open(<MilageTimesheet info={payrollById} />);
        } else {
            open(<CreateActiveTimesheet info={payrollById} />);
        }
    };

    const handleOpenResolve = (e) => {
        e.preventDefault();
        e.stopPropagation();
        open(
            <Resolve
                id={payrollById?.id}
                afterSuccess={() => dispatch(staffPayrollActions.getPayrollById(payrollById?.id))}
                displayId={payrollById?.apptDisplayId}
            />,
        );
    };

    return (
        <Fragment>
            <div className="tabs-wrapper">
                <div className="space-between-flex-start" style={{ height: 36 }}>
                    <div className="detail-page-title-box">
                        <p>Timesheet ID: </p>
                        <span> {payrollById?.displayId}</span>
                        {payrollById?.resolve &&
                            <div className="detail-resolved-btn-box">
                                <img src={Images.greenMark} alt="greenMark" />
                                <p>Resolved</p>
                            </div>
                        }
                    </div>
                    <div className="flex-align-start" style={{ gap: 16 }}>
                        {payrollById?.flag && payrollById?.status === PAYROLL_ENUMS.PROCESSED &&
                            <button onClick={handleOpenResolve} className="resolve-btn-big">
                                Resolve
                            </button>
                        }
                        {(!info?.activeTab || info?.activeTab === 'Details') &&
                            <div className="flex-align-center" style={{ gap: 16 }}>
                                {(payrollById?.status ===  PAYROLL_ENUMS.PROCESSED ? true : !payrollById?.apptDisplayId) &&
                                    RolePermission([PermissionList.TIMESHEET_UPDATE?.code]) &&
                                    <EditWithIcon
                                        handleClick={handleOpenModal}
                                    />
                                }
                                {!payrollById?.apptDisplayId && payrollById?.status === PAYROLL_ENUMS.ACTIVE &&
                                    RolePermission([PermissionList.TIMESHEET_DELETE?.code]) &&
                                    <DeleteWithIcon handleClick={() => {
                                        open(<CustomDelete
                                            info="Delete Timesheet"
                                            text="Are you sure you want to delete this timesheet? This action is irreversible, and all data will be permanently lost."
                                            handleDel={() => dispatch(staffPayrollActions.deletePayroll(payrollById?.id))}
                                            afterSuccess={() => history.push('/activePayroll')}
                                            handleClose={() => close()}
                                            actionType={DELETE_ACTION_TYPE}
                                            body={
                                                <div className="deleting-client-info-box">
                                                    <p style={{ fontWeight: 600 }}>Timesheet ID:</p>
                                                    <p style={{ fontWeight: 400 }}>{payrollById?.displayId}</p>
                                                </div>
                                            }
                                        />);
                                    }} />
                                }
                            </div>
                        }
                        {
                            info?.activeTab === 'Notes' && RolePermission([PermissionList.TIMESHEET_NOTE_MANAGE?.code]) ? (
                                    <AddButton text="Add Note" handleClick={() => open(
                                        <AddNotes
                                            id={payrollById?.id}
                                            model={'Timesheet'}
                                        />,
                                    )} />
                                ) :
                                info?.activeTab === 'History' && RolePermission([PermissionList.TIMESHEET_HISTORY_READ?.code]) ? (
                                        <SearchHistory />
                                    )
                                    :
                                    info?.activeTab === 'Files' && RolePermission([PermissionList.TIMESHEET_FILE_MANAGE?.code]) ?
                                        <AddButton
                                            Icon={true}
                                            customIcon={Images.uploadCloud}
                                            text={'Upload Document'}
                                            handleClick={() => open(
                                                <UploadModal
                                                    currentId={payrollById?.id}
                                                    onResource={'payroll'}
                                                />,
                                            )}
                                        />
                                        : null
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

import axios from "axios";

export const authService = {
   /** Active Payroll */
   getActivePayrollService: (params) =>  axios.get("/payroll/timesheet/active", { auth: true, params:{ ...params} }),

   createProcessPayrollService: (params) =>  axios.post("/payroll/timesheet/process", null,{ auth: true, params: { ...params} }),

   getPayrollByIdService: (id) =>  axios.get(`/payroll/timesheet/${id}`,{ auth: true}),

   deletePayrollService: (id) =>  axios.delete(`/payroll/timesheet/${id}`,{ auth: true}),

   resolvePayrollService: (id, params) =>  axios.patch(`/payroll/timesheet/${id}/resolve`, null, { auth: true, params: { ...params}}),
   /** End */

   /** Processed Payroll */

   getProcessedPayrollService: (params) =>  axios.get("/payroll/timesheet/processed",{ auth: true, params:{ ...params} }),

   /** End */

   /** Payroll Documents */

   uploadDocumentService: (file, id ) => axios.post(`/payroll/timesheet/${id}/documents`, {...file}, { auth: true }),

   editDocumentService: ({id, dockId, file} ) => axios.patch(`/payroll/timesheet/${id}/documents/${dockId}`, {...file}, { auth: true }),

   getDocumentService: ( id ) => axios.get(`/payroll/timesheet/${id}/documents`, { auth: true }),

   deleteDocumentService: (clientId, id ) => axios.delete(`/payroll/timesheet/${clientId}/documents/${id}`, { auth: true }),


   /** End */


};

import React from "react";
import { Images } from "utils";
import {TextRow} from "components";
import { GET_PLACES } from '../../../store/system/system.type';

export const systemTypeHead = [
   { name: "name", title: "Name" },
   { name: "displayCode", title: "Display Code", custom: false },
   { name: "category", title: "Category", custom: false },
   { name: "action", title: "Action", custom: false, disabled: true, width: '100px' },
];

export const systemTypeBody = [
   { rowText:  (item) => <TextRow name={item?.name} textWidth={10}   /> },
   { rowText:  (item) => <TextRow name={item?.displayCode} textWidth={10}   />  },
   { rowText:  (item) => <TextRow name={item?.category} textWidth={10}   />  },
   {
      button: (cb) => (
         <div onClick={() => cb("edit")}>
            <img src={Images.edit} alt="edit" />
         </div>
      ),
      notClickable: true,
   },
];

export const ACTION_TYPE = "GET_SERVICES";

export const systemNotYet = {
   title: 'No Service Types Added',
   subTitle: 'It looks like there are currently no service types listed. Use the inputs above to add new service types.',
   image: Images.notFile,
}

export const credentialTypes = [
   {name:'Degree', id:'DEGREE'},
   {name:'Clearance', id:'CLEARANCES'},
   {name:'License', id:'LICENSE'},
]


export const placeOfServiceHead = [
   { name: "", title: "Name", noSearch: true, custom: false, },
   { name: "", title: "Code", custom: false, noSearch: true },
   { name: "", title: "Modifier", custom: false, noSearch: true },
   { name: "", title: "Action", custom: false, disabled: true,  noSearch: true, width: '50px' },
];

export const placeOfServiceBody = [
   { rowText:  (item) => <TextRow name={item?.name} /> },
   { rowText:  (item) => <TextRow name={item?.code}   />  },
   { rowText:  (item) => <TextRow name={item?.modifier}  />  },
   {
      button: (cb) => (
          <div onClick={() => cb("edit")}>
             <img src={Images.edit} alt="edit" />
          </div>
      ),
      notClickable: true,
   },
];

export const PLACE_OF_ACTION_TYPE = "GET_PLACES";


export const departNotYet = {
   title: 'No Departments Added',
   subTitle: 'Add departments to categorize your staff and manage your clinics more effectively.',
   image: Images.noFsYet,
}

export const jobTitleNotYet = {
   title: 'No Job Titles Added',
   subTitle: 'Add job titles to help classify and manage your staff roles.',
   image: Images.noRole,
}

export const placeOfNotYet = {
   title: 'No Places of Service Added',
   subTitle: 'Add locations to manage service types and categorize where services are provided.',
   image: Images.noFile,
}

export const credentialNotYet = {
   title: 'No Credentials Added',
   subTitle: 'Create credentials to track required qualifications and certifications for your staff.',
   image: Images.noCredential,
}
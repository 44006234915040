import { makeStyles } from '@material-ui/core/styles';
import { Colors } from 'utils';

export const generateClaimStyle = makeStyles(() => ({
    refreshTitle:{
        color: '#172B4D',
        fontSize: '20px',
        fontWeight: '700',
        lineHeight: '28px',
        padding: '24px',
    },
    generateClaimContainerStyle: {
        width: '100%',
        borderRadius: '8px',
        backgroundColor: Colors.BackgroundWhite,
        padding: '24px',
        '@media(max-width: 1280px)': { padding: '16px' },
    },
    generateClaimActionsStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginBottom: '24px',
    },
    mergeBillsBoxStyle: {
        display: 'flex',
        alignItems: 'center',
    },
    mergeBillsTitleStyle: {
        fontSize: '14px',
        fontWeight: 600,
        color: Colors.TextSecondary,
        marginRight: '8px',
    },
    tableAndPaginationBoxStyle: {
        minHeight: '500px',
        display: 'flex',
        flexDirection: 'column',
    },
    tableBoxStyle: { flexGrow: 1 },
    loaderContainerStyle: {
        minHeight: '400px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    notClaimedBillsFooterStyle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 0 0'
    },
    generateOrCancelButnStyle: {
        maxWidth: '540px',
        '@media(max-width: 1440px)': { maxWidth: '446px' },
        width: '100%',
        '& button': {
            width: '262px',
            '@media(max-width: 1440px)': { width: '215px' },
            height: '36px !important',
            fontSize: '14px !important',
            '&:first-of-type': {
                backgroundColor: `${Colors.BackgroundWater} !important`,
            },
        },
    },
    infoIcon: {
        marginLeft: '16px',
    },


    closeButtonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '-50px',
        marginBottom: '-30px',
    },

    closeBill: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        margin: '24 0',
        '& button': {
            width: '109px',
            height: '36px',
            background: '#D72525 0% 0% no-repeat padding-box',
            borderRadius: '18px',
            color: Colors.TextWhite,
            fontSize: '14px',
            fontWeight: '600',
            border: 'none',
        },
        '& button:hover': {
            background: '#ac0707',
        },
    },

    itemsSection: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '24px',
    },

    addButtonsWrapper: {
        display: 'flex',
        marginTop: '24px',
    },

    requestCorrection: {
        width: '260px',
        height: '48px',
        border: '1px solid #D0D5DD',
        background: '#FFF',
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        borderRadius: '4px',
        fontSize: '16px',
        fontWeight: '600',
        color: '#172B4D',
    },

    itemsWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        height: 'auto',
        maxHeight: '50px',
        padding: '16px',
        background: '#E6ECF3 0% 0% no-repeat padding-box',
        borderRadius: '8px',
    },

    billingHistory: {
        marginLeft: '50px',
    },

    paperWrapper: {
        width: '800px',
        height: '90vh',
        background: 'white',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },

    paperFooter: {
        height: '80px',
        padding: '10px 0',
        background: '#42526E',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        left: 0,
        bottom: 0,
    },
    draftBtnBox: {
        padding:'10px 16px',
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        color: '#FFF',
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '19px',
    },
}));

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { CreateChancel, FullTable, SimpleModal, SinglePagination } from 'components';
import { FindLoad, FindSuccessItem, FLAGGED_VALUES, PermissionList, RolePermission, useModal } from 'utils';
import { adminActions, appointmentActions, claimActions, httpRequestsOnSuccessActions, systemActions } from 'store';
import { generateClaimBody, generateClaimHead, ACTION_TYPE } from './constants';
import { GenerateClaimFilters } from './generateClaimFilters';
import { Service, scheduleModalsStyle } from '../../../schedule';
import { generateClaimStyle } from './styles';

const EDIT_ACTION = 'EDIT_APPOINTMENT';
const GENERATE_ACTION = 'GENERATE_CLAIM';
export const GenerateClaimFragment = ({}) => {
    const { appointments } = useSelector((state) => ({
        appointments: state.appointment.appointments,
    }));
    const classes = generateClaimStyle();
    const { close } = useModal();
    const dispatch = useDispatch();
    const generateClaimLoader = FindLoad(GENERATE_ACTION);
    const modalClasses = scheduleModalsStyle();
    const success = FindSuccessItem(EDIT_ACTION);
    const [selectedList, setSelectedList] = useState([]);
    const [item, setItem] = useState(null);
    const [filters, setFilters] = React.useState({});
    const info = filters

    useEffect(() => {
        if (success) {
            getAppt('noLoad');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(EDIT_ACTION));
            setItem(null);
        }
    }, [success]);

    useEffect(() => {
        getAppt();
    }, [filters]);

    const getAppt = (load) => {
        const filterInfo = {
            status: 'COMPLETED',
            type: 'SERVICE',
            billStatus: 'NOT_BILLED',
            ...info,
        };
        filterInfo.skip = info?.skip ? info?.skip : 0;
        filterInfo.limit = 50;

        if (info?.firstDate) {
            filterInfo.startDate =  moment.utc(info?.firstDate).format('YYYY-MM-DD');
            delete filterInfo.firstDate;
        } else {
            delete filterInfo.startDate;
        }
        if (info?.lastDate) {
            filterInfo.endDate = moment.utc(info?.lastDate).format('YYYY-MM-DD');
            delete filterInfo.lastDate
        } else {
            delete filterInfo.endDate;
        }

        if(info?.flag) {
            if(info?.flag === FLAGGED_VALUES.REMOVED_FROM_CLAIM) {
                filterInfo.removedFromClaimFlag = true;
            }else{
                filterInfo.incompleteUnitFlag = true;
            }
        }else{
            delete filterInfo.flag;
            delete filterInfo.removedFromClaimFlag;
            delete filterInfo.incompleteUnitFlag;
        }

        dispatch(appointmentActions.getAppointment(filterInfo, load));
    };

    const changePage = (number) => {
        const params = {
            ...filters,
        };
        params.page = number;
        setFilters(params);
    };

    const handleCloseModal = () => {
        setItem(null);
    };
    const handleCheck = (ev, item, name) => {
        ev.stopPropagation();
        ev.preventDefault();
        if (name === 'all') {
            let allList = appointments?.appts?.length === selectedList?.length ? [] : appointments?.appts?.map((i) => i?.id);
            setSelectedList(allList);
        } else {
            const list = [...selectedList];
            const uniqSet = new Set(list);

            if (!uniqSet.has(item?.id)) {
                list.push(item?.id);
            } else {
                list.indexOf(item?.id) !== -1 && list.splice(list.indexOf(item?.id), 1);
            }
            setSelectedList(list);
        }
    };
    const handleEdit = (item) => {
        setItem(item);
        dispatch(adminActions.getAllPayCodes(item.staff?._id));
        dispatch(systemActions.getPlaces());
    };

    return (
        <>
            <div className="generate-modal">
                <div>
                    <GenerateClaimFilters setFilters={setFilters} filters={filters} />
                    <FullTable
                        head={generateClaimHead(handleCheck, appointments?.appts, selectedList)}
                        body={generateClaimBody(handleCheck, appointments?.appts, selectedList, handleEdit)}
                        loadingType={ACTION_TYPE}
                        list={appointments?.appts}
                        noText={'appointments'}
                        generateTable={true}
                        height={'medium'}
                    />
                </div>

                <div className="space-between">
                    {!!appointments?.appts?.length ?
                        <SinglePagination page={filters?.page} count={appointments?.count} changePage={changePage} />
                        :
                        <div />
                    }
                    {RolePermission([PermissionList.CLAIMS_CREATE?.code]) &&
                        <CreateChancel
                            classes={classes.generateOrCancelButnStyle}
                            loader={!!generateClaimLoader.length}
                            create={'Prepare Claims'}
                            chancel={'Cancel'}
                            onCreate={() => dispatch(claimActions.generateClaim({ appts: selectedList }))}
                            onClose={close}
                            disabled={!selectedList.length}
                        />
                    }
                </div>
            </div>

            <SimpleModal
                handleOpenClose={handleCloseModal}
                openDefault={!!item}
                content={
                    <div className={modalClasses.bigModal}>
                        <Service
                            modalDate={item}
                            handleOpenClose={handleCloseModal}
                        />
                    </div>
                }
                secondary={true}
            />
        </>
    );
};

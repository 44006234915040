import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ValidationInput, CreateChancel, SelectTypeAutocomplete, DateInput } from 'components';
import { createClientStyle } from './styles';
import { ErrorText, FindErrorItem, FindLoad, FindSuccess, hooksForErrors, useModal } from 'utils';
import { clientActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { GET_FS_SOURCES } from './constants';

export const AddEnrollment = ({ info, activeEnrollments = [], type, params }) => {
    const { close } = useModal();
    const dispatch = useDispatch();
    const actionType = info ? 'EDIT_CLIENT_ENROLLMENT' : 'CREATE_CLIENT_ENROLLMENT';
    const titleType = type === 'PRIMARY' ? 'Primary' : 'Secondary';
    const classes = createClientStyle();
    const fSelect = useSelector((state) => state.fundingSource.fSelect.funders);
    const success = FindSuccess(actionType);
    const loader = FindLoad(actionType);
    const backError = FindErrorItem(actionType);
    const [error, setError] = useState('');
    // const enrollmentErrorText = hooksForErrors.getEnrollmentErrorText(error, backError);
    const [inputs, setInputs] = useState(
        info ?
            {
                ...info,
                funding: info.funderId,
                enrolmentId: info.enrolmentId,
                startDate: info.startDate && moment.utc(info.startDate).format('YYYY-MM-DD'),
            }
            : {},
    );

    const fundingSources = fSelect?.filter(function(array_el) {
        return (
            activeEnrollments?.active?.filter(function(anotherOne_el) {
                return anotherOne_el.funderId === array_el?.id;
            }).length === 0
        );
    });

    useEffect(() => {
        return () => {
            dispatch(httpRequestsOnErrorsActions.removeError(actionType));
        };
    }, []);

    useEffect(() => {
        if (!!success.length) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType));
        }
    }, [success]);

    function handleChange(e) {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        if (error === e.target.name || error === ErrorText.startDateError || !!backError) {
            setError('');
        }
        if (!!backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(backError.type));
        }
    }

    function handleCreate() {
        const startDateIsValid = new Date(inputs.startDate).getTime() <= new Date(new Date()).getTime();
        const enrollmentDataIsValid = !!inputs.funding && !!inputs?.enrolmentId && !!inputs.startDate && startDateIsValid;
        if (enrollmentDataIsValid) {
            const data = {
                type: type,
                enrolmentId: inputs?.enrolmentId,
                startDate: inputs?.startDate,
            };
            if (info) {
                delete data.type;
            }
            if (info) {
                dispatch(clientActions.editClientEnrollment(data, params.id, inputs?.funding, info.id));
            } else {
                dispatch(clientActions.createClientEnrollment(data, params.id, inputs?.funding));
            }
        } else {
            setError(
                !inputs.funding ? 'funding' :
                    !inputs.enrolmentId ? 'enrolmentId' :
                        !inputs.startDate ? 'startDate' : !startDateIsValid ?
                            ErrorText.startDateError : 'Input is not field',
            );
        }
    }

    return (
        <div className={classes.addEnrollment}>
            <div>
                <p className={'modal-header-title'}>{info ? 'Edit an Enrollment' : `Add ${titleType} Enrollment`}</p>
                <p className={classes.subTitle}>{info ? '' : `To add ${titleType} enrollment in the system, please fulfill the below fields.`}</p>
            </div>
            <div style={{ marginTop: '32px' }}>
                <SelectTypeAutocomplete
                    disabled={!!info}
                    loadType={GET_FS_SOURCES}
                    title={'Funding Source*'}
                    name={'funding'}
                    handleSelect={handleChange}
                    defaultValue={inputs.funding}
                    list={info ? fSelect : fundingSources ? fundingSources : []}
                    error={error}
                    typeError={error === 'funding' ? ErrorText.selectField : ''}
                    renderValue={(i) => i?.name}
                />
                <ValidationInput
                    variant={'outlined'}
                    onChange={handleChange}
                    value={inputs?.enrolmentId}
                    type={'text'}
                    label={'Enrollment ID*'}
                    name="enrolmentId"
                    typeError={error === 'enrolmentId' ? ErrorText.field : ''}
                    Length={20}
                />
                <DateInput
                    name={'startDate'}
                    label={'Enrollment Date*'}
                    handleChange={handleChange}
                    value={inputs?.startDate ? moment.utc(inputs.startDate).format('MM/DD/YYYY') : null}
                    max={moment.utc().format('YYYY-MM-DD')}
                    typeError={error === 'startDate' ? ErrorText.field :
                        error === ErrorText.startDateError ? ErrorText.startDateError : ''
                    }
                />
                {/*<ValidationInput*/}
                {/*    max={moment.utc().format('YYYY-MM-DD')}*/}
                {/*    variant={'outlined'}*/}
                {/*    onChange={handleChange}*/}
                {/*    value={inputs.startDate}*/}
                {/*    type={'date'}*/}
                {/*    label={'Enrollment Date*'}*/}
                {/*    name="startDate"*/}
                {/*    typeError={error === 'startDate' ? ErrorText.field :*/}
                {/*        error === ErrorText.startDateError ? ErrorText.startDateError : ''*/}
                {/*    }*/}
                {/*/>*/}
            </div>
            <div className={classes.clientModalBlock}>
                <CreateChancel
                    loader={!!loader.length}
                    create={info ? 'Save' : 'Add'}
                    chancel={'Cancel'}
                    onCreate={handleCreate}
                    onClose={close}
                    buttonWidth="224px"
                />
            </div>
        </div>
    );
};

import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { httpRequestsOnSuccessActions, invoiceActions, invoicePaymentActions } from 'store';
import {GET_ACTION_TYPE, invoicePaymentHead } from './constants';
import { InvoiceFilters } from './invoiceFilters';
import { FindSuccess, FindSuccessItem, useModal } from 'utils';
import { InvoicePaymentTable } from './invoicePaymentTable';

const GENERATE_ACTION_TYPE = 'GENERATE_INVOICE'
const PAY_IN_FULL_PAYMENT_INVOICE = 'PAY_IN_FULL_PAYMENT_INVOICE'
const EDIT_TYPE = 'EDIT_PAYMENT_INVOICE';

export const Invoices = () => {
    const invoicePaymentsData = useSelector((state) => state.invoicePayment.invoicePayments);
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const generateSuccess = FindSuccessItem(GENERATE_ACTION_TYPE)
    const payInFull = FindSuccessItem(PAY_IN_FULL_PAYMENT_INVOICE)
    const editSuccess = FindSuccessItem(EDIT_TYPE);
    const { close } = useModal();

    const renderParams = () => {
        const pushInfo = {
            ...info,
        };
        delete pushInfo.page;
        pushInfo.skip = info?.skip ? info?.skip : 0;
        pushInfo.limit = 50;

        if (!info?.client) {
            delete pushInfo.client;
        }
        if (!info?.status) {
            delete pushInfo.status;
        }

        if(info?.firstDate){
            pushInfo.from = info?.firstDate
            delete pushInfo.firstDate
        }else{
            delete pushInfo.from
        }
        
        if(info?.lastDate){
            pushInfo.to = info?.lastDate
            delete pushInfo.lastDate
        }else{
            delete pushInfo.to
        }

        return pushInfo;
    }

    const getInvoices = (load) => {
        dispatch(invoicePaymentActions.getInvoicePayments({ ...renderParams()}, load));
    }

    useEffect(() => {
        getInvoices()
    }, [info]);

    useEffect(() => {
        if(generateSuccess){
            getInvoices('noLoad')
            dispatch(httpRequestsOnSuccessActions.removeSuccess(GENERATE_ACTION_TYPE))
            close()
        } if(payInFull){
            getInvoices('noLoad')
            dispatch(httpRequestsOnSuccessActions.removeSuccess(PAY_IN_FULL_PAYMENT_INVOICE))
        }
        if(editSuccess){
            getInvoices('noLoad')
            dispatch(httpRequestsOnSuccessActions.removeSuccess(EDIT_TYPE));
        }
    }, [generateSuccess, payInFull, editSuccess]);

    return (
        <div>
            <InvoiceFilters/>
            <InvoicePaymentTable
                head={invoicePaymentHead}
                loadingType={GET_ACTION_TYPE}
                list={invoicePaymentsData?.invoices}
                listCount={invoicePaymentsData?.count}
                activeRowId={''}
            />
        </div>
    );
};

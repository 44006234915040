import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ErrMessage } from '../messages';
import moment from 'moment';
import { Images } from '../../utils';

export const DateInput = ({
                              handleChange,
                              value,
                              errorFalse,
                              typeError,
                              name,
                              disabled,
                              label,
                              min,
                              max,
                              fromSearch,type
                          }) => {

    const sendValue = (selectedValue) => {
        const converted = moment(selectedValue ? selectedValue : '');
        converted.hour(10);
        const testMoment = moment(converted).format('YYYY-MM-DD');
        const sendParams = {
            target: {
                name: name,
                value: selectedValue ? testMoment : '',
            },
        };
        handleChange(sendParams);
    };

    const ExampleCustomInput = forwardRef(
        ({ onClick, className }, ref) => {
            return <button className={`${className} space-between`} onClick={onClick} ref={ref}>
                    {value ? <p style={{color: '#51566D'}}>{value}</p> : <p style={{ color: 'rgba(75, 92, 104, 0.5)' }}>MM/DD/YYYY</p>}
                    <img src={Images.calendarToday} alt={'as'} />
                </button>
        },
    );

    return (
        <div className="date-input-box">
            <div>
                <p style={typeError ? { color: '#f44336' } : {}} className="date-input-title">{label}</p>
                <DatePicker
                    showYearDropdown
                    className={
                        type === 'filter' ? `date-picker-filter-box` :
                        fromSearch ? `date-picker-search-box ${typeError ? 'date-picker-error' : ''}` : `date-picker-box ${typeError ? 'date-picker-error' : ''}`
                    }
                    isClearable={!!value}
                    showIcon
                    disabled={disabled}
                    onChange={(date) => sendValue(date)}
                    selected={value}
                    customInput={<ExampleCustomInput className="example-custom-input" />}
                    minDate={min}
                    maxDate={max}
                    popperProps={{ strategy: "fixed" }}
                />
                {errorFalse ? null : (
                    <ErrMessage
                        style={{ marginBottom: '12px' }}
                        text={typeError}
                    />
                )}
            </div>
        </div>
    );
};
import { makeStyles } from '@material-ui/core/styles';
import { Colors, Images, Shadow } from 'utils';

export const inputsStyle = makeStyles(() => ({

    labelTitle: {
        fontSize: '14px',
        fontWeight: 600,
        color: Colors.BackgroundBlue,
        marginBottom: '8px',
    },
    noChipLoads: {
        background: 'white',
        width: '250px',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(163, 178, 189, 0.50) !important',

        },
        '& .MuiOutlinedInput-root': {
            height: '36px',
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-10px',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, 3px) scale(0.75) !important',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            background: 'none',
        },
        '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
            marginTop: '-10px',
            color:'#51566D',
        },
        '& .MuiAutocomplete-tag': {
            display: 'none',
        },
    },

    title: {
        color: '#347AF0',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '19px',
        marginBottom: '8px',
    },

    blueBorder: {
        borderColor: 'blue',
    },

    itemButton: {
        border: 'none',
        background: 'none',
        outline: 'none',
        marginTop: '2px',
        height: '18px',
        padding: 0,
    },

    smallDesk: {
        '@media (max-width: 1918px)': {
            display: 'block',
        },
        '@media (min-width: 1919px)': {
            display: 'none',
        },
    },

    bigDesk: {
        '@media (max-width: 1918px)': {
            display: 'none',
        },
        '@media (min-width: 1919px)': {
            display: 'block',
        },
    },

    radioItem: {
        fontSize: '14px',
        color: Colors.TextSecondary,

        '& .MuiSvgIcon-root': {
            color: Colors.ThemeBlue,
        },
    },

    inputTextFieldAutoHeight: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeBorder,
        },
        '& .MuiOutlinedInput-root': {},
        '& .MuiInputLabel-outlined': {},
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
        },
    },

    radioGroupWrapper: {
        '& .MuiFormControlLabel-root': {
            // background: 'red!important',
            margin: '0 !important',

        },

        '& .MuiFormControlLabel-root:hover': {
            background: '#EBF2FD!important',
        },
    },

    select: {
        '& select:focus': {
            outline: 'none!important',
            backgroundColor: 'white',
            border: 'none!important',
        },
        height: '36px',
        outline: 'none!important',
    },
    selectPlaceholder: {
        '& .MuiSelect-select:focus': {
            outline: 'none',
            backgroundColor: 'white',
        },
    },
    radio: {
        color: Colors.BackgroundBlue,
        '&:hover': {
            backgroundColor: 'white!important',
        },
        '&$checked': {
            color: `${Colors.BackgroundBlue}!important`,
            '&:hover': {
                backgroundColor: 'white!important',
            },
        },
    },
    radioInputLabel: {
        fontSize: 16,
        color: Colors.TextSecondary,
    },

    days: {
        fontSize: '16px',
        fontWeight: '600',
        color: '#4B5C68',
        marginLeft: '8px',
    },

    checked: {
        color: 'green',
    },
    searchfAddressDescriptionText: {
        fontSize: '16px',
        marginLeft: '8px',
        color: Colors.TextPrimary,
    },
    SignInInput: {
        width: '100%',
        '& .MuiFormLabel-root': {
            fontSize: '16px',
            color: Colors.TextPrimary,
        },

        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: Colors.ThemeRed,
        },

        '& .MuiMenu-paper': {
            position: 'relative',
            zIndex: '3000',
        },
    },
    changePasswordInput: {
        width: '100%',
        '& .MuiFormLabel-root': {
            fontSize: '16px',
            color: Colors.TextPrimary,
        },
        '& .MuiOutlinedInput-input':{
            padding : '0 20px'
        },
        '& .MuiOutlinedInput-root': {
            height: '44px',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
        },
        '& .MuiInputLabel-outlined':{
            transform: 'translate(14px, 15px) scale(1)',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: Colors.ThemeRed,
        },

        '& .MuiMenu-paper': {
            position: 'relative',
            zIndex: '3000',
        },
    },
    labelStyle: {
        fontSize: '14px',
        fontWeight: 600,
        color: Colors.BackgroundBlue,
        '&.withoutLabel': {
            minHeight: '15px',
            marginBottom: '8px',
        },
    },
    searchInputTitle: {
        display: 'flex',
        cursor: 'default',
        alignItems: 'center',
        '& span': {
            fontSize: '14px',
            lineHeight: '19px',
            fontWeight: '600',
            color: '#4B5C68',
            marginRight: '8px',
        },
        '& img': {
            width: '18px',
            height: '18px',
            cursor: 'pointer',
            filter: 'brightness(0) saturate(100%) invert(33%) sepia(27%) saturate(343%) hue-rotate(162deg) brightness(97%) contrast(90%)',
        },

    },
    searchInputTitleBlack: {
        display: 'flex',
        cursor: 'default',
        alignItems: 'center',
        '& span': {
            fontSize: '14px',
            lineHeight: '19px',
            fontWeight: '700',
            color: Colors.TextSecondary,
            marginRight: '8px',
        },
        '& img': {
            width: '18px',
            height: '18px',
            cursor: 'pointer',
        },
    },

    searchInput: {
        width: '100%',
        '@media (max-width: 1979px)': {
            marginBottom: '8px',
        },
        '@media (min-width: 1919px)': {
            marginBottom: '16px',
        },

        '& .MuiFormLabel-root': {
            fontSize: '16px',
            color: `${Colors.TextPrimary}`,
        },

        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: Colors.ThemeRed,
        },
    },

    actionStyle: {
        width: '100%',

        '@media (max-width: 1979px)': {
            marginBottom: '8px',
        },
        '@media (min-width: 1919px)': {
            marginBottom: '16px',
        },
        '& .MuiInput-underline:before': {
            borderBottom: 'none',
        },
    },

    PasswordInput: {
        '& .MuiInputBase-input::-webkit-input-placeholder': {
            fontSize: '16px',
            color: Colors.TextPrimary,
            opacity: 1,
        },
    },

    inputTextField: {
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
        '& .MuiOutlinedInput-root': {
            height: '48px',
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
            color: 'rgba(75, 92, 104, 0.7)',
            // color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
            color: '#0C66E4',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: 14,
            color: Colors.TextLightGray,
        },
    },

    inputTextFieldCheckboxes: {
        width: '100%',
        minHeight: '49px',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
        '& .MuiOutlinedInput-root': {
            minHeight: '49px',
            color: Colors.TextPrimary,
            padding: '0 9px !important',
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
            color:'rgba(75, 92, 104, 0.7)',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            color: Colors.ThemeBlue,
            transform: 'translate(14px, -2px) scale(0.75)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: 14,
            color: Colors.TextLightGray,
        },
    },

    inputTextFieldCheckboxAuto: {
        width: '100%',
        '& .MuiAutocomplete-input': {
            padding: '6.5px 4px !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
        '& .MuiAutocomplete-tag': {
            display: 'none',
        },
        '& .MuiOutlinedInput-root': {
            height: '48px',
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: 16,
            color: Colors.TextSecondary,
            opacity: 1,
        },
    },
    inputTextFieldDisabled: {
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundDisabled,
        },
        '& .MuiOutlinedInput-root': {
            height: '48px',
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundDisabled,
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: 14,
            color: Colors.TextLightGray,
        },
    },
    inputTextFieldSmall: {
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
        '& .MuiOutlinedInput-root': {
            height: '36px',
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: 14,
            color: Colors.TextLightGray,
        },
    },
    TextareaTextField: {
        width: '100%',
        resize: 'none',
        height: '128px!important',
        borderColor: Colors.BackgroundBlue,
        padding: '13px 0 10px 16px',
        borderRadius: '4px',
        overflow: 'unset!important',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        fontSize: '14px',
        lineHeight: '19px',
        color: Colors.TextSecondary,

        '&::-webkit-scrollbar': { display: 'none' },
        '&.error': { borderColor: Colors.ThemeRed },

        '&::placeholder': {
            fontSize: '14px',
            lineHeight: '19px',
            color: Colors.TextLightGray,
        },
    },

    // AutoComplete style **
    noChip: {
        '& .MuiAutocomplete-tag': {
            display: 'none',
        },

    },

    autocompleteStyle: {
        minHeight: '70px',
        '& fieldset': { borderColor: Colors.ThemeBlue },
        '& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd:hover fieldset':
            { borderColor: Colors.ThemeBlue },
        '& label': {
            color: '#4B5C6880 !important',
            top: '-5px',
            // '&.Mui-focused': { top: 0 },
        },
        '& input': { padding: '0 16px !important' },
        '& .MuiAutocomplete-endAdornment > button:first-of-type, button svg': {
            display: 'none',
        },
        '& .MuiAutocomplete-endAdornment > button:last-of-type': {
            position: 'absolute',
            top: '3px',
            right: 0,
        },
        '& .MuiAutocomplete-endAdornment > button:last-of-type span:first-of-type': {
            display: 'block',
            width: '18px',
            height: '18px',
            backgroundImage: `url(${Images.dropdownArrowFilledBlue})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
        },
        '& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd':
            { padding: 0, minHeight: '48px' },

    },

    serviceTypeAutocompleteStyle: {
        minHeight: '40px',
        marginRight: '16px',
        width: '500px',
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, 4px) scale(0.75) ',
        },

        '& fieldset': { borderColor: Colors.ThemeBlue },
        '& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd:hover fieldset':
            { borderColor: Colors.ThemeBlue },
        '& label': {
            color: '#4B5C6880 !important',
            top: '-10px',
            '&.Mui-focused': { top: '-10px' },
        },
        '& input': {
            padding: '0 16px !important',
            '&::placeholder': {
                color: 'black!important',
                opacity: 1,
            },
        },
        '& .MuiAutocomplete-endAdornment > button:first-of-type, button svg': {
            display: 'none',
        },
        '& .MuiAutocomplete-endAdornment > button:last-of-type': {
            position: 'absolute',
            top: '3px',
            right: 0,
        },
        '& .MuiAutocomplete-endAdornment > button:last-of-type span:first-of-type': {
            display: 'block',
            width: '18px',
            height: '18px',
            backgroundImage: `url(${Images.dropdownArrowFilledBlue})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
        },
        '& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd':
            { padding: 0, minHeight: '36px' },
    },

    autocompleteAutoCompleteStyle: {
        minHeight: '48px',

        '& .MuiChip-root': {
            background: '#EBF2FD',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#347AF0 !important',
        },
        '& MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeBlue,
        },
        '& fieldset': { borderColor: Colors.ThemeBlue },
        '& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd:hover fieldset':
            { borderColor: Colors.ThemeBlue },
        '& label': {
            color: '#51566D !important',
            top: '-5px',
            '&.Mui-focused': { top: 0 },
        },
        '& input': { padding: '0 16px !important' },
        // "& .MuiAutocomplete-endAdornment > button:first-of-type, button svg": {
        //    display: "none",
        // },
        '& .MuiAutocomplete-endAdornment > button:last-of-type': {
            position: 'absolute',
            top: '3px',
            right: 0,
        },
        '& .MuiAutocomplete-endAdornment > button:last-of-type span:first-of-type': {
            display: 'block',
            width: '18px',
            height: '18px',
            backgroundImage: `url(${Images.dropdownArrowFilledBlue})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
        },
        '& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd':
            { padding: 0, minHeight: '48px' },

        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336 !important',
        },

        // '& .MuiSvgIcon-root':{
        //     display:'none!important'
        // },

        '& .MuiAutocomplete-endAdornment': {
            '& .MuiSvgIcon-root': {
                display: 'none!important',
            },
        },
        // '& MuiIconButton-label':{
        //     '& .MuiSvgIcon-root':{
        //         display:'none!important'
        //     }
        // }
    },

    optionsStyle: {
        fontSize: '16px',
        fontWeight: 400,
        color: '#4B5C68',
        '& *, & *:hover': { backgroundColor: 'inherit' },
    },
    // end **

    // UserTextArea Style **
    userTextAreaStyle: {
        position: 'relative',
        width: '100%',
        marginTop: '8px',
        '& label': {
            position: 'absolute',
            top: '17px',
            left: '17px',
            cursor: 'text',
            fontSize: '16px',
            fontWeight: 400,
            color: '#51566D',
            transition: 'all 0.1s ease-in-out',
        },
        '&.filled label': {
            backgroundColor: Colors.BackgroundWhite,
            padding: '0 4px',
            top: '-4px',
            left: '8px',
            fontSize: '12px',
        },
        '& textarea': {
            resize: 'none',
            outline: 'none',
            border: `1px solid ${Colors.ThemeBlue}`,
            borderRadius: '4px',
            padding: '13px 16px',
            width: '100%',
            height: '96px',
            overflowY: 'auto',
            fontSize: '16px',
            fontWeight: 400,
            color: Colors.TextPrimary,
        },
        '&:focus-within textarea': { border: `2px solid ${Colors.ThemeBlue}` },
        '&.error textarea': { borderColor: Colors.ThemeRed },
    },
    errorAndCharsBoxStyle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&.hasMaxText': { marginBottom: '16px' },
        '& .maxCharacter': {
            width: '100%',
            paddingRight: '16px',
            textAlign: 'right',
            fontSize: '12px',
            fontWeight: 400,
            color: Colors.TextSecondary,
        },
    },
    // end **

    // SearchInput Style **
    searchInputBoxStyle: {
        width: '500px',
        backgroundColor: '#F2F4F8',
        borderRadius: '8px',
        marginRight: '35px',
        padding: '9px 16px 9px 38px',
        backgroundImage: `url(${Images.search})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '18px 18px',
        backgroundPosition: '16px 50%',
        cursor: 'text',
        '@media(max-width: 1280px)': {
            width: '450px',
            marginRight: '27px',
        },
        '& > input': {
            width: '100%',
            outline: 'none',
            border: 'none',
            backgroundColor: 'inherit',
            fontSize: '14px',
            fontWeight: 400,
            color: Colors.TextPrimary,
            '&::placeholder': {
                color: '#4B5C6899 !important',
                opacity: 1 /* Firefox */,
            },
            '&:-ms-input-placeholder': {
                /* Internet Explorer 10-11 */
                color: '#4B5C6899 !important',
            },
            '&::-ms-input-placeholder': {
                /* Microsoft Edge */
                color: '#4B5C6899 !important',
            },
        },
    },
    // end **

    // CustomCheckBoxInput Style **
    checkboxStyle: {
        display: 'flex',
        alignItems: 'center',
        transition: 'all 0.2s linear',
        cursor: 'pointer',
        padding: '15px 16px',
        '& input': { display: 'none' },
        '& input:checked + $inputCheckBoxStyle': {
            backgroundImage: `url(${Images.checkmarkWhite})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '13px 10px',
            backgroundPosition: 'center',
            backgroundColor: Colors.ThemeBlue,
        },
        '&.unique input:checked + $inputCheckBoxStyle': {
            backgroundImage: `url(${Images.checkmarkBlue})`,
            backgroundColor: Colors.BackgroundWhite,
        },
        '&:hover': { backgroundColor: Colors.BackgroundWater },
        '&.unique:hover': { backgroundColor: 'inherit' },
        '&.unique $inputCheckBoxStyle': { border: `1px solid ${Colors.BackgroundWhite}` },
    },
    inputCheckBoxStyle: {
        backgroundColor: 'inherit',
        transition: 'background-color 0.2s linear',
        width: '18px',
        height: '18px',
        border: `1px solid ${Colors.ThemeBlue}`,
        borderRadius: '2px',
    },
    inputLabelStyle: {
        fontSize: '16px',
        fontWeight: 400,
        color: Colors.TextPrimary,
        marginLeft: '16px',
    },
    // end **

    searchAddress: {
        height: '48px',
        border: `1px solid ${Colors.BackgroundBlue}`,
        borderRadius: '4px',
        padding: '18.5px 14px',
        fontSize: '16px',
        width: '100%',
        color: Colors.TextPrimary,
    },
    searchAddressError: {
        height: '48px',
        border: `1px solid ${Colors.ThemeRed}`,
        borderRadius: '4px',
        padding: '18.5px 14px',
        fontSize: '16px',
        width: '100%',
        '&::placeholder': { color: Colors.ThemeRed },
    },

    errorText: {
        color: '#F07379!important',
        fontSize: '12px!important',
        fontWeight: '600!important',
        minHeight: '20px !important',
        marginTop: '0 !important',
    },

    inputShrink: {
        background: 'white',
        padding: '0 6px',
    },

    valuesContainer: {
        backgroundColor: 'white',
        boxShadow: Shadow.modalShadow,
        height: 'auto',
        maxHeight: '300px',
        overflow: 'auto',
        position: 'absolute',
        zIndex: '10',
        width: '292px',
    },

    statusImg: {
        width: '12px',
        height: '12px',
        borderRadius: '24px',
        background: 'red',
    },

    // *Editable Payment Input Styles**
    editableInputBoxStyle: {
        maxWidth: '195px',
        width: '100%',
        height: '100%',
        padding: '9px 16px',
        '@media(max-width: 1280px)': { padding: '9px 8px' },
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 400,
        color: Colors.TextSecondary,
        borderBottom: '2px solid transparent',
        '&.active': {
            borderBottom: '2px solid #347AF0',
            backgroundColor: Colors.BackgroundWhite,
        },
    },
    editableInputStyle: {
        width: '100%',
        border: 'none',
        outline: 'none',
        fontSize: '14px',
        fontWeight: 400,
        color: Colors.TextSecondary,
        backgroundColor: 'inherit',
    },
    editIconStyle: {
        width: '18px',
        height: '18px',
        cursor: 'pointer',
    },
    // *end**

    symbolStyles: {
        marginBottom: '-32px',
        margin: '16px 6px 0',
        color: '#51566D',
    },
    tableSearchInput: {
        background: 'transparent',
        border: 'none',
        width: '100%',
        height: '32px',
        outline: 'none',
        borderBottom: '1px solid #347AF0',
    },

}));
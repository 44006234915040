import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loader, Role } from 'components';
import { FindLoad, Images } from 'utils';
import { authActions, roleActions } from 'store';
import { serviceSingleStyles } from './styles';

export const StaffAccess = ({ accessList = [] }) => {
    const { rolesList } = useSelector((state) => ({
        rolesList: state.roles.rolesList,
    }));
    const params = useParams();
    const classes = serviceSingleStyles();
    const dispatch = useDispatch();
    const [info, setInfo] = useState('');
    const getLoader = FindLoad('GET_ACCESS');

    useEffect(() => {
        setInfo('');
        dispatch(roleActions.getRole());
        dispatch(authActions.getAccess(params.id));
    }, []);

    const sendItem = (item) => {
        setInfo(item);
    };

    const handleRemoveSelected = () => {
        if (accessList && accessList.roles.length) {
            if (accessList && accessList.roles.length === 1) {
                setInfo('');
            } else {
                setInfo(accessList.roles[0]);
            }
        } else {
            setInfo('');
        }
    };

    const newList = accessList?.roles ? rolesList?.roles?.filter(function(array_el) {
        return (accessList?.roles?.filter(function(anotherOne_el) {
                return anotherOne_el.id === array_el.id;
            }).length === 0
        );
    }) : rolesList?.roles;

    if (getLoader?.length) {
        return <Loader />;
    }

    return (
        <div className={classes.staffAccessWrapper}>
            <Role
                newList={newList}
                rolesList={rolesList}
                accessList={accessList}
                sendItem={sendItem}
                handleRemoveSelected={handleRemoveSelected}
            />
            {!!accessList?.roles?.length &&
                <div className={classes.roleInformation}>
                    {info ? (
                        <>
                            <div className={classes.roleHeader}>
                                <div className={classes.cardIcon}>
                                    <img src={Images.address} alt="role" />
                                </div>
                                <p className={classes.roleTitle}>{info ? info.title : ''}</p>
                            </div>
                            <p className={classes.roleSubtitle}>Description</p>
                            <p className={classes.roleText}>{info?.description ? info.description : 'Not Set'}</p>
                            <p className={classes.roleSubtitle}>Permissions</p>
                            <div className={classes.permissionsList}>
                                {info &&
                                    info.permissions &&
                                    info.permissions.map((item, j) => (
                                        <div key={j} className={classes.rolePermissionContainer}>
                                            <img src={Images.roleManagementActive} alt="permission" />
                                            <p className={classes.rolePermissionName}>{item.title}</p>
                                        </div>
                                    ))}
                            </div>
                        </>
                    ) : (
                        <div className={classes.selectRole}>
                            <img src={Images.accessManagementIcon} alt="role" />
                            <p>Select Role</p>
                            <span>Choose a role on the left to see and manage its <br /> permissions.</span>
                        </div>
                    )}
                </div>
            }
        </div>
    );
};

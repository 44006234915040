import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileUploader } from 'react-drag-drop-files';
import { ErrorText, FindLoad, FindSuccessItem, Images, useModal } from 'utils';
import { clientActions, httpRequestsOnSuccessActions, UploadServices } from 'store';
import { ErrMessage, TextRow, CreateChancel, ValidationInput, DownloadLink, MinLoader } from 'components';

export const AuthFiles = ({ currentId }) => {
    const actionType = 'ADD_FILES_TO_CLIENT_AUTH';
    const editActionType = 'EDIT_FILE_NAME_OF_CLIENT_AUTH';
    const deleteActionType = 'REMOVE_FILES_FROM_CLIENT_AUTH';
    const clientsAuthorizations = useSelector((state) => state.client.clientsAuthorizations);
    const client = useSelector((state) => state.client.clientItemInfo);
    const { close } = useModal();
    const inputRes = useRef();
    const [uploaded, setUploaded] = useState([]);
    const [edit, setEdit] = useState('');
    const [deleteId, setDeleteId] = useState('');
    const [inputs, setInputs] = useState({});
    const [error, setError] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadLoader, setUploadLoader] = useState(false);
    const dispatch = useDispatch();
    const addLoader = FindLoad(actionType);
    const editLoader = FindLoad(editActionType);
    const deleteLoader = FindLoad(deleteActionType);
    const success = FindSuccessItem(actionType);
    const successEdit = FindSuccessItem(editActionType);
    const successDelete = FindSuccessItem(deleteActionType);

    useEffect(() => {
        if (clientsAuthorizations) {
            const active = clientsAuthorizations?.find((i) => i?.status === 'ACTIVE');
            setUploaded(active?.documents);
        }
    }, [clientsAuthorizations]);

    useEffect(() => {
        if (successDelete) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(successDelete));
            setDeleteId('');
        }
    }, [successDelete]);

    const handleUpload = (e) => {
        error === 'currentFile' && setError('');
        if (e && inputs.name) {

            const pos = e.type.slice(e.type.search('/'));
            const fileType = pos.substring(1);
            const info = {
                file: e,
                name: inputs?.name,
                format: fileType,

            };
            setUploadedFiles([info, ...uploadedFiles]);
            setInputs({});
        }
    };

    const handleSubmit = async () => {
        if (uploadedFiles?.length) {

            let formData = new FormData();
            uploadedFiles?.map((e) => {
                formData.append('files', e.file);
            });

            setUploadLoader(true);
            const res = await UploadServices.UploadManyImage(formData);

            const mixedList = res?.data?.map((file, index) => ({
                file: {
                    ...file,
                    format: uploadedFiles[index]?.format,
                },
                name: uploadedFiles[index]?.name,
                description: uploadedFiles[index]?.description || '',
            }));

            const sendList = {
                    documents: mixedList,
            };

            setUploadLoader(false);
            dispatch(clientActions.addFilesToClientAuth(currentId, sendList, client?.id));
        } else {
            setError(
                !inputs.name ? 'name' :
                    !uploadedFiles ? 'currentFile' : '',
            );
        }
    };

    const handleEdit = () => {
        if (edit?.name) {
            dispatch(clientActions.editFileNameOfClientAuth(currentId, edit?._id, edit?.name));
        } else {
            inputRes.current.focus();
        }
    };

    const removeStates = () => {
        setEdit('');
        setInputs({});
        setError('');
    };

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType));
            removeStates();
            close();
        }
    }, [success]);


    useEffect(() => {
        if (successEdit) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(editActionType));
            removeStates();
        }
    }, [successEdit]);

    function handleChange(e) {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        error === e.target.name && setError('');
    }


    const renderSize = (item) => {
        const bytes = item?.size;
        const KB = 1024;
        const MB = KB * 1024;

        if (bytes >= MB) {
            return (bytes / MB).toFixed(2) + ' MB';
        } else if (bytes >= KB) {
            return (bytes / KB).toFixed(2) + ' KB';
        } else {
            return bytes + ' bytes';
        }
    };

    const removeFile = (item, key) => {
        const updatedItems = uploadedFiles?.filter((item, k) => k !== key);
        setUploadedFiles(updatedItems);
    };

    return (
        <div style={{ width: 400 }}>
            <p className="modal-header-title">Upload Document</p>
            <p className="select-document-desc">Select a document to upload and add optional details.</p>
            <div className="modal-body-wrapper-full">
                <ValidationInput
                    onChange={handleChange}
                    value={inputs.name}
                    variant={'outlined'}
                    name={'name'}
                    type={'text'}
                    placeholder={'Name*'}
                    label={'Document Name*'}
                    typeError={error === 'name' ? ErrorText.field : ''}
                    Length={50}
                />
                <FileUploader
                    disabled={!inputs?.name}
                    handleChange={handleUpload} name="file"
                    style={!inputs?.name ? { opacity: '0.5' } : {}}
                >
                    <div
                        style={!inputs?.name ? { opacity: '0.5' } : {}}
                        // style={error === 'currentFile' ? { border: '2px dashed #F07379' } : {}}
                        className="drag-drop-component"
                    >
                        <img src={Images.uploadCloudBlack} alt="icon" />
                        <label className="custom-file-upload">
                            <input
                                onClick={(event) => (event.target.value = null)}
                                disabled={!inputs?.name}
                                onChange={handleUpload}
                                type="file"
                                id="file"
                            />
                            <i className="fa fa-cloud-upload" />
                            <div className="upload">
                                <p><span style={{ color: '#2E6DD8', fontWeight: 600 }}>Click to upload</span> or
                                    drag and drop</p>
                                <p style={{ fontSize: '12px', color: '#172B4D' }}>PDF, PNG, JPG and CSV (max.
                                    800x400px)</p>
                            </div>
                        </label>
                    </div>
                </FileUploader>

                <ErrMessage text={error === 'currentFile' ? 'Please select file' : ''} />
                {!!uploadedFiles?.length &&
                    uploadedFiles.map((i, j) => (
                        <div className="loaded-documents-box" key={j}>
                            <img src={Images.uploadedFile} alt="icon" />
                            <div className="loaded-document-info-box">
                                <p className="loaded-document-name">
                                    <TextRow name={i?.name} />
                                </p>
                                <div className="loaded-document-detail-box">
                                    <div className="flex-align-center" style={{ gap: '8px' }}>
                                        {i?.type && <p>{i?.file?.type?.replace('image/', '')}</p>}
                                        <p>{renderSize(i?.file)}</p>
                                    </div>
                                    <div className="loaded-circle" />
                                    <div className="flex-align-center" style={{ gap: '4px' }}>
                                        <img src={Images.complete} alt="icon" />
                                        <p>Completed</p>
                                    </div>
                                </div>
                                <div className="full-loaded">
                                    <div className="full-loaded-line" />
                                    <p>100%</p>
                                </div>
                            </div>
                            <button className="remove-button" onClick={() => removeFile(i, j)}>
                                <img src={Images.grayTrash} alt="icon" />
                            </button>
                        </div>),
                    )}

                {!!uploaded?.length &&
                    uploaded.map((i, j) => (
                        <div className="loaded-documents-box" key={j}>
                            {edit?._id === i?._id ?
                                <div className="flex-align-start"
                                     style={{ gap: 12, width: '100%', transition: 'all 0.2s' }}>
                                    <img src={Images.uploadedFile} alt="icon" />
                                    <div className="full-width">
                                        <div className="flex-align-center" style={{ width: '100%', gap: 8 }}>
                                            <div className="loaded-document-info-box">
                                                <div className="loaded-document-field">
                                                    <input
                                                        ref={inputRes}
                                                        name={'name'}
                                                        onChange={(e) => setEdit((prevState) => ({
                                                            ...prevState,
                                                            [e.target.name]: e.target.value,
                                                        }))}
                                                        type="text" value={edit?.name}
                                                    />
                                                </div>
                                            </div>
                                            <button
                                                onClick={() => handleEdit()}
                                                className="edit-action-btn"
                                            >
                                                {!!editLoader?.length ?
                                                    <MinLoader margin={'0'} color={'#475467'} small={true} />
                                                    :
                                                    <img src={Images.checkMarkBlack} alt="icon" />
                                                }
                                            </button>
                                            <button
                                                onClick={() => setEdit('')}
                                                className="edit-action-btn"
                                            >
                                                <img src={Images.remove} alt="icon" />
                                            </button>
                                        </div>
                                        <div className="loaded-document-detail-box" style={{ marginTop: 4 }}>
                                            <p>{i?.file?.format}</p>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="flex-align-start"
                                     style={{ transition: 'all 0.2s', gap: 8, width: '100%' }}>
                                    <img src={Images.uploadedFile} alt="icon" />
                                    <div className="loaded-document-info-box">
                                        <p className="loaded-document-name">
                                            <TextRow name={i?.name} />
                                        </p>
                                        <div className="loaded-document-detail-box">
                                            <div className="flex-align-center" style={{ gap: '8px' }}>
                                                <p>{i?.file?.format}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-align-center" style={{ gap: 10 }}>
                                        <button
                                            className="reset-btn"
                                            style={{ padding: 0 }}
                                            onClick={() => setEdit(i)}
                                        >
                                            <img src={Images.editDockBlack} alt="icon" style={{ fill: '#475467' }} />
                                        </button>
                                        <button
                                            className="reset-btn"
                                            style={{ padding: 0, width: '24px' }}
                                        >
                                            <DownloadLink
                                                iconStyles={{ width: 24, height: 24, margin: 0 }}
                                                file={i?.file}
                                                otherIcon={Images.downloadCloud}
                                                small={true}
                                                downloadLoadStyle={'0'}
                                                loadColor={'#475467'}
                                            />
                                        </button>
                                        <button
                                            className="reset-btn"
                                            style={{ padding: 0, width: '24px' }}
                                            onClick={() => {
                                                setDeleteId(i?._id);
                                                dispatch(clientActions.removeFilesFromClientAuth(currentId, i?._id));
                                            }}
                                        >
                                            {deleteId === i?._id && deleteLoader?.length ?
                                                <MinLoader margin={'0'} color={'#DE350B'} small={true} position={'relative'} />
                                                :
                                                <img src={Images.redTrash} alt="icon" />
                                            }
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>),
                    )}
            </div>
            <CreateChancel
                loader={uploadLoader || !!addLoader?.length}
                create={'Save'}
                chancel={'Cancel'}
                onCreate={handleSubmit}
                onClose={close}
                buttonWidth="100%"
                disabled={!uploadedFiles?.length}
            />
        </div>
    );
};
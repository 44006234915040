import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    CreateChancel,
    NumericFormatCustom,
    SelectAutocompleteMultiple,
    TextareaCustom,
    DateInputForm,
} from 'components';
import { FindLoad, FindSuccessItem, SaveParams, useModal } from 'utils';
import {
    adminActions,
    httpRequestsOnErrorsActions,
    httpRequestsOnSuccessActions,
    staffPayrollActions,
} from 'store';
import { useHistory } from 'react-router-dom';

export const MilageTimesheet = ({ info, fromStaff }) => {
    const { adminsList } = useSelector((state) => ({
        adminsList: state.admins.adminsList,
    }));
    const ACTION_TYPE = 'EDIT_TIMESHEET';
    const { close } = useModal();
    const dispatch = useDispatch();
    const success = FindSuccessItem(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const { handleSubmit, control, clearErrors, reset, setValue, watch } = useForm({});
    const history = useHistory();
    const pushInfo = history?.location?.state;

    useEffect(() => {
        if (!fromStaff) {
            dispatch(adminActions.getAdmins({ status: 'ACTIVE' }));
        }
    }, []);

    useEffect(() => {
        if (info) {
            const params = {
                timesheetDate: moment.utc(info.timesheetDate).format('YYYY-MM-DD'),
                staffId: info?.staffId,
                description: info?.description,
                qty: info?.qty,
            };
            reset(params);
        }
    }, [info]);

    useEffect(() => {
        if (success) {
            close();
            SaveParams(history, pushInfo);
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
        if (info && success) {
            close();
            if (fromStaff) {
                SaveParams(history, pushInfo);
            } else {
                dispatch(staffPayrollActions.getPayrollById(info?.id));
            }
        }
    }, [success]);

    useEffect(() => {
        return () => {
            clearErrors();
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    const handleCreate = (data) => {
        const sandedData = {
            ...data,
        };
        sandedData.qty = data?.qty ? data.qty : 0;
        dispatch(adminActions.editTimesheet(sandedData, info?.id));
    };

    return (
        <form
            style={{ width: 420 }}
            onSubmit={handleSubmit(handleCreate)}
        >
            <p className="modal-header-title"> Edit Milage Timesheet</p>
            <div className="modal-body-wrapper">
                {!fromStaff &&
                    <SelectAutocompleteMultiple
                        name="staffId"
                        label={'Staff Member*'}
                        control={control}
                        options={adminsList?.staffs}
                        renderValue={((i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`)}
                        rules={{ required: 'Staff member is required.' }}
                        loadType={'GET_ADMINS'}
                    />
                }
                <TextareaCustom
                    control={control}
                    name="description"
                    label="Description"
                    variant={'outlined'}
                    allowCharacter={true}
                    maxLength={100}
                    noError={true}
                />
                <NumericFormatCustom
                    name={'qty'}
                    label={'Miles*'}
                    // thousandSeparator={false}
                    // fixedDecimalScale={true}
                    // decimalScale={0}
                    control={control}
                    rules={{
                        required: 'Miles is required.',
                    }}
                />
                <DateInputForm
                    value={watch('timesheetDate')}
                    name="timesheetDate"
                    label={'Timesheet Date*'}
                    max={moment.utc().format('YYYY-MM-DD')}
                    control={control}
                    rules={{
                        required: 'Created date is required.',
                    }}
                />
            </div>
            <CreateChancel
                type="submit"
                loader={!!loader.length}
                create={info ? 'Save' : 'Add'}
                chancel={'Cancel'}
                onClose={close}
                buttonWidth="100%"
            />
        </form>
    );
};

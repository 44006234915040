import { useDispatch, useSelector } from 'react-redux';
import { credentialsStyles } from './styles';
import { Images, useModal } from 'utils';
import { CreateCredential } from './createCredential';
import { AddCircleBlue, NoItemsYet, NoItemText, TypeRow } from 'components';
import { systemActions } from 'store';
import { credentialNotYet } from '../constants';

export const CredentialsList = ({}) => {
    const classes = credentialsStyles();
    const { open } = useModal();
    const globalCredentials = useSelector((state) => state.system.credentials);
    const selectedCredential = useSelector((state) => state.system.selectedCredential);
    const dispatch = useDispatch();

    const handleOpen = (item) => {
        dispatch(systemActions.selectCredential(item));
    };

    const handleEditCredential = (event, item) => {
        event.preventDefault();
        event.stopPropagation();
        open(<CreateCredential item={item} />);
    };

    return (
        <div className={classes.credentialsListWrapper}>

            <div className={classes.credentialsListHeaderBox}>
                <div className={classes.credentialsListHeader}>
                    <p className={classes.credentialsListHeaderTitle}>Credentials</p>
                    <div>
                        <AddCircleBlue text={'Add Credential'} handleClick={() => open(<CreateCredential />)} />
                    </div>
                </div>
            </div>
            {globalCredentials?.length ?
                <div className={classes.credentialsListBody}>
                    {globalCredentials?.map((i) => (
                            <div
                                style={selectedCredential?.id === i?.id ? { background: 'rgba(235, 242, 253, 0.50)' } : {}}
                                className={classes.credentialItem} key={i?._id} onClick={() => handleOpen(i)}>
                                <div className="flex-align-center">
                                    <img src={Images.licenseIcon} alt="icon" />
                                    <p className={classes.licenseTitle}><b>{<TypeRow text={i?.type} />} -</b> {i?.name}
                                    </p>
                                </div>
                                <div className={classes.credentialActionBox}>
                                    <button onClick={(e) => handleEditCredential(e, i)}>
                                        <img src={Images.edit} alt="icon" />
                                    </button>
                                    <div>
                                        <img
                                            style={{ transition: '2s' }}
                                            src={selectedCredential?.id === i?.id ? Images.rightDropdownArrowBlue : Images.dropDown}
                                            alt="icon" />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                :
                <div style={{ position: 'relative' }}>
                    <NoItemsYet
                        text={credentialNotYet.title}
                        subTitle={credentialNotYet.subTitle}
                        image={credentialNotYet.image}
                    />
                </div>
            }
        </div>
    );
};
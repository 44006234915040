import React from 'react';
import { hooksForTable, Images, makeCapitalize, RolePermission, } from 'utils';

export const NoteInfo = ({ note, handleAction, permissions }) => {
    const checkPermissions = RolePermission([...permissions]);

    return (
        <>
            <div style={{ width: 400 }}>
                <p className="note-subject">{makeCapitalize(note?.subject)}</p>
                <div className="creator-information-box">
                    <div>
                        <span>{`By ${note?.user?.firstName ? note?.user?.firstName : ''} ${note?.user?.lastName ? note?.user?.lastName : ''}`}</span>
                        <span style={{ margin: '0 4px' }}>|</span>
                        <span>{hooksForTable.handleCreatedAtDate(note?.created)}</span>
                    </div>
                    <div>
                        {checkPermissions &&
                            <div className="flex-align-center" style={{ gap: '4px' }}>
                                <button onClick={() => handleAction('edit')} className="reset-btn">
                                    <img src={Images.edit} alt="edit" />
                                </button>
                                <button onClick={() => handleAction('deleteNote')} className="reset-btn">
                                    <img src={Images.remove} alt="delete" />
                                </button>
                            </div>
                        }
                    </div>
                </div>
                <div className="note-info-description">
                    <p>{note?.text}</p>
                </div>
            </div>
        </>
    );
};

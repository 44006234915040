import {
    DateRowStartEnd,
    TextRow,
    PriceRow,
    LinkRow,
    ApptFlags,
    SimpleTooltip,
} from 'components';
import { Checkbox } from '@material-ui/core';
import { Images, PermissionList, RolePermission } from 'utils';
import React from 'react';

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

export const pendingClaimHead = (handleSelect, fullList, selected) => {
    return [
        RolePermission([PermissionList.CLAIMS_UPDATE?.code]) &&
        { renderView: <SimpleTooltip
                title={<p>Select All</p>}
                placement="top-start"
            >
                <Checkbox
                    {...label}
                    onClick={(e) => handleSelect(e, '', 'all')}
                    name={'checkBox'}
                    style={{ padding: 0, color: '#347AF0' }}
                    checked={fullList?.length === selected?.length}
                />
            </SimpleTooltip>,
            width: '50px',
        },
        { name: 'displayId', title: 'Claim ID', custom: false, width: '150px' },
        { name: 'dateRange', title: 'DOS', icon: 'date', disabled: true, custom: false, width: '200px' },
        { name: 'funder', rowName: 'name', title: 'Funding Source' },
        { name: 'client', rowName: 'code', title: 'Client Code' },
        { name: 'totalAmount', title: 'Billed Amount', custom: false },
    ];
};

export const pendingClaimBody = (handleSelect, fullList, selected) => {
    return [
        RolePermission([PermissionList.CLAIMS_UPDATE?.code]) &&
        {
            button: (handle, item) => (
                <SimpleTooltip
                    title={<p>Select</p>}
                    placement="top-start"
                >
                    <Checkbox
                        {...label}
                        onClick={(e) => handleSelect(e, item)}
                        disabled={item?.incompleteUnitFlag || item?.updateFlag}
                        name={'checkBox'}
                        style={{ padding: 0, color: '#347AF0', opacity: item?.incompleteUnitFlag || item?.updateFlag ? 0.5 : 1 }}
                        checked={selected?.includes(item?.id)}
                    />
                </SimpleTooltip>
            ), notClickable: true,
        },
        { rowText: (item) => <ApptFlags id={item?.displayId} updateFlag={item?.updateFlag} incomplete={item?.incompleteUnitFlag} removed={item?.removedFromClaimFlag} /> },
        { rowText: (item) => <DateRowStartEnd start={item?.dos?.early} end={item?.dos?.latest} /> },
        { rowText: (item) => <TextRow name={item?.funderName} /> },
        { rowText: (item) => <LinkRow
                name={item?.clientCode ? item?.clientCode : 'N/A'}
                href={RolePermission([PermissionList.CLIENT_READ?.code]) ? `/client/${item?.clientId}` : ''}
                checkPermission={item?.clientCode ? RolePermission([PermissionList.CLIENT_READ?.code]) : null}
            />, notClickable: true,
        },
        { rowText: (item) => <PriceRow info={item?.billedAmount ? item?.billedAmount : 0} /> },
    ];
};

export const ACTION_TYPE = 'GET_CLAIMS';

export const pendingClaimNotYet = {
    title: 'No Pending Claims',
    subTitle: 'There are no claims waiting for submission or processing at this time.',
    image: Images.noPendingClaim,
}

export const billedClaimNotYet = {
    title: 'No Billed Claims',
    subTitle: 'There are no claims that have been billed. Once claims are submitted, they will be listed here.',
    image: Images.noPendingClaim,
}


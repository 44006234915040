import React, { useEffect, useState } from 'react';
import { AddModalButton, ValidationInput, CreateChancel, CustomNumericFormat, DateInput } from 'components';
import { PayrollSetupStyles } from '../styles';
import {
    ErrorText,
    FindErrorItem,
    FindLoad,
    FindSuccess, FindSuccessItem,
    hooksForErrors,
    isNotEmpty,
} from 'utils';
import { useDispatch } from 'react-redux';
import {
    httpRequestsOnErrorsActions,
    httpRequestsOnSuccessActions,
    payrollActions,
} from 'store';
import moment from 'moment';
import { overtimeBtn } from './constants';

export const MileageCompensation = ({
                                        marginTop,
                                        marginRight,
                                        maxWidth,
                                        editedData,
                                        handleClose,
                                        type,
                                    }) => {
    const classes = PayrollSetupStyles();
    const dispatch = useDispatch();
    const ACTION_TYPE = !!editedData ? 'EDIT_MILEAGE' : 'CREATE_MILEAGE';
    const success = FindSuccessItem(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const backError = FindErrorItem(ACTION_TYPE);
    const [error, setError] = useState('');
    const mileageCompensationErrorText = hooksForErrors.getMileageCompensationErrorText(error, backError);

    useEffect(() => {
        dispatch(payrollActions.getMileages());
    }, []);

    useEffect(() => {
        if (!!backError && type === 'modal') {
            setError(backError?.error);
        } else if (backError?.error === 'can not be two active mileage') {
            setError('canNot');
        } else if (backError?.error === 'Mileage overlapping') {
            setError('overlapping');
        }
    }, [backError]);

    useEffect(() => {
        if (success) {
            if (!!editedData) {
                handleClose();
                setError('');
                dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            } else {
                setInputs({});
                setError('');
                dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            }
            setInputs({});
        }
    }, [success]);

    const [inputs, setInputs] = useState(
        !!editedData
            ? {
                ...editedData,
                startDate: moment(editedData.startDate).format().substring(0, 10),
            }
            : {},
    );

    useEffect(() => {
        return () => {
            if (!!editedData) {
                dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
            } else {
                dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
            }
        };
    }, []);

    function handleChange(e) {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        if (error) {
            setError('');
        }
        if (!!backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(backError.type));
        }
    }

    function handleChangeMilage(value) {
        setInputs((prevState) => ({ ...prevState, ['compensation']: value?.value }));
        if (error) {
            setError('');
        }
        if (!!backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(backError.type));
        }
    }

    function handleSubmit() {
        const dataIsValid = isNotEmpty(inputs.compensation) && !!inputs.startDate;
        if (dataIsValid) {
            const data = {
                compensation: +inputs?.compensation,
                startDate: inputs.startDate ? new Date(inputs.startDate).toISOString() : '',
            };
            if (editedData) {
                dispatch(payrollActions.editMileage(inputs?.id, data));
            } else {
                dispatch(payrollActions.createMileage(data));
            }
        } else {
            const dataErrorText = !isNotEmpty(inputs.compensation)
                ? 'compensation'
                : !inputs.startDate
                    ? 'startDate'
                    : '';
            setError(dataErrorText);
        }
    }

    return (
        <div
            className={!editedData ? classes.payCodeType : {}}
            style={{
                maxWidth: maxWidth,
                marginRight: marginRight ? marginRight : 0,
                marginTop: marginTop ? marginTop : 0,
            }}
        >
            {!editedData && (
                <>
                    <h1 className={classes.modalTitle}>Add New Mileage Compensation</h1>
                </>
            )}
            <CustomNumericFormat
                prefix={'$'}
                name={'compensation'}
                label={'$ Mileage Compensation*'}
                value={inputs?.compensation ? inputs?.compensation  :''}
                handleChangeMiles={(e) => handleChangeMilage(e, 'compensation')}
                thousandSeparator={true}
                fixedDecimalScale={true}
                decimalScale={2}
                error={mileageCompensationErrorText}
                errorName={mileageCompensationErrorText ? 'compensation' : ''}
            />
            <DateInput
                name={'startDate'}
                label={'Start Date*'}
                handleChange={handleChange}
                value={inputs.startDate ? moment.utc(inputs.startDate).format('MM/DD/YYYY') : null}
                typeError={error === 'startDate' ? `Start date ${ErrorText.isRequired}` :
                    error === 'overlapping' ? 'Mileage overlapping' :
                        ''}
            />
            {!!editedData ? (
                <CreateChancel
                    loader={!!loader.length}
                    create={'Save'}
                    chancel={'Cancel'}
                    onClose={handleClose}
                    onCreate={handleSubmit}
                    buttonWidth={'192px'}
                    createButnMargin={'16px'}
                />
            ) : (
                <AddModalButton
                    loader={!!loader.length}
                    handleClick={handleSubmit}
                    text={'Add Mileage Compensation'}
                    styles={{
                        width: '100%',
                        height: '48px',
                    }}
                />
            )}
        </div>
    );
};

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    StaffGeneral,
    StaffCredentials,
    StaffEmployment,
    StaffAccess,
    StaffItemHeader,
    StaffTimesheet,
    PastStaffs,
} from './core';
import { FindLoad, PermissionList, RolePermission } from 'utils';
import {
    SimpleTabs,
    Loader,
    Notes,
    SimpleModal,
    FilesComponent,
} from 'components';
import { StaffService } from './core/staffService';
import { staffTabsLabels } from './core/constants';
import { CreateStaff } from '../createStaff';
import { StaffDetails } from '../createStaff';
import { HistoryTab } from '../../history';

export const StaffItem = ({}) => {
    const { staffGeneral, employments, activeEmployment, staffServices, serviceTypes, rolesList, accessList, staffDocuments } = useSelector((state) => ({
        staffGeneral: state.admins.adminInfoById,
        staffCredentialList: state.admins.credential,
        employments: state.admins.employments,
        activeEmployment: state.admins.activeEmployment,
        staffServices: state.admins.staffServices,
        serviceTypes: state.system.services,
        rolesList: state.roles.rolesList?.roles,
        accessList: state.auth.accessList,
        staffDocuments: state.admins.staffDocuments,
    }));
    const params = useParams();
    const [staffGeneralModal, setStaffGeneralModal] = useState('');
    const loaderItems = FindLoad('GET_ADMIN_BY_ID');


    function handleClose() {
        setStaffGeneralModal('');
    }

    const tabsContent = [
        RolePermission([PermissionList.STAFF_READ.code, PermissionList.STAFF_CREATE.code, PermissionList.STAFF_STATUS?.code]) &&
        {
            tabComponent: !!loaderItems.length ? (
                <Loader />
            ) : (
                <StaffGeneral
                    handleOpenModal={(type) => setStaffGeneralModal(type)}
                    staffGeneral={staffGeneral}
                    activeEmployment={activeEmployment}
                    pastEmployments={employments}
                />
            ),
            tab: 'General',
        },
        RolePermission([PermissionList.STAFF_PAYCODE_MANAGE?.code, PermissionList.STAFF_PAYCODE_READ?.code]) &&
        {
            tabComponent: (
                <StaffEmployment info={activeEmployment} />
            ),
            tab: 'Paycodes',
        },
        RolePermission([PermissionList.STAFF_TIMESHEET_READ?.code, PermissionList.STAFF_TIMESHEET_CREATE?.code]) &&
        {
            tabComponent: (
                <StaffTimesheet />
            ),
            tab: 'Timesheet',
        },
        RolePermission([PermissionList.STAFF_CREDENTIAL_MANAGE?.code, PermissionList.STAFF_CREDENTIAL_READ?.code]) &&
        {
            tabComponent: (
                <StaffCredentials />
            ),
            tab: 'Credentials',
        },
        RolePermission([PermissionList.STAFF_ROLES_MANAGE?.code]) &&
        {
            tabComponent: (
                <StaffAccess rolesList={rolesList} accessList={accessList} />
            ),
            tab: 'Access',
        },
        RolePermission([PermissionList.STAFF_SERVICE_MANAGE?.code, PermissionList.STAFF_SERVICE_READ?.code]) &&
        {
            tabComponent: (
                <StaffService
                    services={serviceTypes?.serviceTypes}
                    info={staffServices}
                    staffGeneral={staffGeneral}
                />
            ),
            tab: 'Services',
        },
        RolePermission([PermissionList.STAFF_NOTE_MANAGE?.code, PermissionList.STAFF_NOTE_READ?.code]) &&
        {
            tabComponent: (
                <Notes onModel={'Staff'} permissions={[PermissionList.STAFF_NOTE_MANAGE?.code]} id={staffGeneral?.id} />
            ),
            tab: 'Notes',
        },
        RolePermission([PermissionList.STAFF_HISTORY_READ?.code]) &&
        {
            tabComponent: <HistoryTab currentId={params?.id} onModel={'Staff'} />,
            tab: 'History',
        },
        RolePermission([PermissionList.STAFF_FILE_MANAGE?.code, PermissionList.STAFF_FILE_READ?.code]) &&
        {
            tabComponent: (
                <FilesComponent
                    currentId={params?.id}
                    onResource={'staff'}
                    documents={staffDocuments}
                    loadTable={'GET_STAFF_DOCUMENT'}
                    permissions={[PermissionList.STAFF_FILE_MANAGE?.code]}
                />
            ),
            tab: 'Files',
        },
    ];

    return (
        <>
            <div className="tab-box-wrapper">
                <StaffItemHeader />
                <SimpleTabs
                    defaultTab={
                        RolePermission([PermissionList.STAFF_READ?.code, PermissionList.STAFF_CREATE?.code, PermissionList.STAFF_STATUS?.code]) ? 'General' : ''
                    }
                    tabsLabels={staffTabsLabels}
                    tabsContent={tabsContent}
                />
            </div>

            <SimpleModal
                openDefault={!!staffGeneralModal}
                handleOpenClose={() => setStaffGeneralModal('')}
                content={
                    staffGeneralModal === 'general' ? (
                        <CreateStaff
                            staffGeneral={staffGeneral}
                            handleClose={handleClose}
                        />
                    ) : staffGeneralModal === 'other' ? (
                            <StaffDetails
                                staffGeneral={staffGeneral}
                                handleClose={handleClose}
                            />
                        ) :
                        staffGeneralModal === 'pastEmployment' ? (
                                <PastStaffs
                                    employments={employments}
                                    handleClose={handleClose}
                                />
                            ) :
                            // (
                            // <EmploymentModal
                            //     info={staffGeneralModal === 'employment' ? activeEmployment : ''}
                            //     handleClose={handleClose}
                            //     active={activeEmployment}
                            // />
                            // )
                            null
                }
            />
        </>
    );
};

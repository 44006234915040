import React from 'react';
import {
    DateRow,
    PriceRow,
    TextRow,
    UnitsRow,
    SimpleTooltip,
    ApptFlags, DisplayIdRow,
} from 'components';
import {
    formatAMPM,
    hooksForTable,
    Images,
    manageStatus,
    manageStatusCircle,
    PermissionList,
    RolePermission,
    useModal,
} from 'utils';
import { FormType1500 } from '../../../../pages/billing/claims/formType1500';
import moment from 'moment/moment';

export const claimDetailTabsLabels = [
    { label: 'Details' },
    { label: 'Appointments' },
    RolePermission([PermissionList.CLAIM_NOTE_MANAGE?.code]) &&
    { label: 'Notes' },
    RolePermission([PermissionList.CLAIM_HISTORY_READ?.code]) &&
    { label: 'History' },
    RolePermission([PermissionList.CLAIM_FILE_READ?.code]) &&
    { label: 'Files' },
];


export function getClaimDetails(claim) {
    const { open } = useModal();
    const {
        staffFullName,
        clientFullName,
        funderName,
        dos,
        createdDate,
        submittedDate,
        totalAmount,
        paidAmount,
        status,
    } = claim || {};

    const { handleCreatedAtDate } = hooksForTable;

    return [
        {
            detailText: 'Staff:',
            detail: staffFullName,
        },
        {
            detailText: 'DOS:',
            detail: `${handleCreatedAtDate(dos?.early)} - ${handleCreatedAtDate(dos?.latest)}`,
        },
        {
            detailText: 'Billed Amount:',
            detail: <PriceRow info={claim?.billedAmount} />,
        },


        {
            detailText: 'Client:',
            detail: clientFullName,
        },
        {
            detailText: 'Created Date:',
            detail: createdDate ? <DateRow date={createdDate} /> : 'N/A',
        },
        {
            detailText: 'Allowed Amount:',
            detail: <PriceRow info={claim?.allowedAmount} />,
        },


        {
            detailText: 'Founding Source:',
            detail: funderName,
        },
        {
            detailText: 'Submitted Date:',
            detail: submittedDate ? <DateRow date={submittedDate} /> : 'N/A',
        },
        {
            detailText: 'Paid Amount:',
            detail: <PriceRow info={paidAmount} />,
        },


        {
            detailText: 'Status:',
            detail: (
                <div className="flex-align-center">
                    <div>{manageStatusCircle(status)}</div>
                    <div>{manageStatus(status)}</div>
                </div>
            ),
        },
        {
            detailText: '1500 Form:',
            detail: (
                <button onClick={() => open(<FormType1500 item={claim} />)} className="download-1500">
                    <p>Download</p>
                    <img src={Images.downloadCloudBlue} alt="" />
                </button>
            ),
        },


        {
            detailText: 'Balance:',
            detail: <PriceRow info={claim?.remaining} />,
        },


        {
            detailText: 'Additional Claim Info:',
            detail: claim?.additionalClaimInfo,
            background: '#FCEBE7',
        },
        {
            detailText: 'Resubmission Code:',
            detail: claim?.resubmissionCode,
            background: '#FCEBE7',
        },
        {
            detailText: 'Original Ref. No:',
            detail: claim?.originalRefNumber,
            background: '#FCEBE7',
        },
    ];
}


export const claimDetailHead = () => (
    [
        { name: '', title: 'ID', noSearch: true, custom: false },
        { name: '', title: 'DOS', noSearch: true, custom: false },
        { name: '', title: 'CPT Code', noSearch: true, custom: false },
        { name: '', title: 'Units', noSearch: true, custom: false },
        { name: '', title: 'Billed', noSearch: true, custom: false },
        { name: '', title: 'Allowed', noSearch: true, custom: false },
        { name: '', title: 'Client Total', noSearch: true, custom: false },
        { name: '', title: 'Paid', noSearch: true, custom: false },
        { name: '', title: 'Balance', noSearch: true, custom: false },
    ]
);

export const claimDetailBody = (claim) => (
    [
        {
            rowText: (item) => claim?.status === PENDING_CLAIM ?
                <ApptFlags
                    id={item?.displayId ? item?.displayId : 'N/A'}
                    incomplete={item?.incompleteUnitFlag}
                    removed={item?.removedFromClaimFlag}
                    updateFlag={item?.updateFlag}
                />
                :
                <DisplayIdRow id={item?.displayId ? item?.displayId : 'N/A'} />,
        },
        {
            rowText: (item) => <div className="flex-align-center">
                <DateRow date={item?.dos} />
            </div>,
        },
        {
            rowText: (item) => <TextRow name={`${item?.cptCode} ${item?.modifier ? `(${item?.modifier})` : ''}`} />,
        },
        {
            rowText: (item) => <UnitsRow info={item?.totalUnits} />,
        },
        {
            rowText: (item) => <PriceRow info={item?.totalBilled} />,
        },
        {
            rowText: (item) => <PriceRow info={item?.allowedAMT} />,
        },
        {
            rowText: (item) => <PriceRow info={item?.clientTotal} />,
        },
        {
            rowText: (item) => <PriceRow info={item?.payorPaid} />,
        },
        {
            rowText: (item) => <PriceRow info={item?.payorBalance} />,
        },
    ]
);

export const PENDING_CLAIM = 'PENDING';
export const CLOSED_CLAIM = 'CLOSED';
export const SUBMITTED_CLAIM = 'SUBMITTED';
export const COMPLETED = 'COMPLETED';
export const OPEN = 'OPEN';
export const APPEAL = 'APPEAL';


export const claimAppointmentsHead = (claimById) => {
    return [
        { name: '', title: 'ID', noSearch: true, custom: false },
        { name: '', title: 'Date/Time', noSearch: true, custom: false },
        { name: '', title: 'Staff Member', noSearch: true, custom: false },
        { name: '', title: 'CPT Code (Mod.)', noSearch: true, custom: false },
        { name: '', title: 'Place of Service', noSearch: true, custom: false },
        { name: '', title: 'Units', noSearch: true, custom: false },
        RolePermission([PermissionList.REMOVE_CLAIM_APPT?.code]) && claimById?.status !== 'PENDING' &&
        { name: '', title: 'Action', noSearch: true, custom: false },
    ];
};

export const claimAppointmentsBody = (claimById) => {
    return [

        {
            rowText: (item) =>

                claimById?.status === 'PENDING' ?
                    <ApptFlags id={item?.displayId} incomplete={item?.incompleteUnitFlag}
                               removed={item?.removedFromClaimFlag} updateFlag={item?.updateFlag} singleAppt={true} />
                    :
                    <ApptFlags id={item?.displayId} />,
        },
        {
            rowText: (item) => <div>
                <p>{moment.utc(item?.startTime).format('MM/DD/YYYY')}</p>
                <p className="schedule-time">
                    {`${formatAMPM(item?.startTime)} - ${formatAMPM(item?.endTime)}`}
                </p>
            </div>,
        },
        { rowText: (item) => <TextRow name={item?.staffFullName} /> },
        { rowText: (item) => <TextRow name={item?.cptCode} /> },
        { rowText: (item) => <TextRow name={item?.placeOfService} /> },
        { rowText: (item) => <UnitsRow info={item?.units} /> },
        RolePermission([PermissionList.REMOVE_CLAIM_APPT?.code]) && claimById?.status !== 'PENDING' &&
        {
            button: (fn, item) => item?.status !== 'PENDING' && (
                <div>
                    {item?.removedFromClaimFlag ?
                        <SimpleTooltip
                            title={<p>{'Appointment was removed from claim'}</p>}
                            placement="bottom"
                            arrow
                        >
                            <p style={{ color: '#E90715' }}>Removed</p>
                        </SimpleTooltip>
                        :
                        <button className="remove-from-claim" onClick={fn}>Remove from Claim</button>
                    }
                </div>
            ),
        },
    ];
};

import { DateRow, SimpleTooltip, TextRow } from 'components';
import { Images } from '../../utils';
import notFile from '../../assets/images/icons/notYet/notFile.svg';

export const reportHead = [
    { name: 'client', title: 'Client Name', custom: false, noSearch:true },
    { name: 'staff', title: 'Staff Name', custom: false,  noSearch:true  },
    { name: '', title: 'Signature D/T', custom: false, disabled: true,  noSearch:true  },
    { name: '', title: 'Appointment D/T', custom: false, disabled: true,  noSearch:true  },
    { name: 'cptCode', title: 'CPT Code', custom: false,  noSearch:true  },
    { name: '', title: 'Signature Files', custom: false, disabled: true,  noSearch:true  },
    { name: '', title: 'Action', custom: false, disabled: true,  noSearch:true, width:'100px'  },
];

export const reportBody = [
    { rowText: (item) => <TextRow name={item?.client ? item?.client : ''} /> },
    { rowText: (item) => <TextRow name={item?.staff ? item?.staff : ''} /> },
    { rowText: (item) => <DateRow date={item?.signatureDate} /> },
    { rowText: (item) => <DateRow date={item?.apptDate} /> },
    { rowText: (item) => <TextRow name={item?.cptCode ? item?.cptCode : ''} /> },
    {
        rowText: (item) => <div className={'signature-file-wrapper'}>{item?.signature ?
            <img src={item?.signature} alt="icon" />
            :
            <p style={{color:'#475467'}}>No Signature</p>
        }
        </div>,
    },

    {
        rowText: (item) => (
            <SimpleTooltip
                title={item?.signature ? 'View Signature' : 'No Signature Available'}
                placement={'top-start'}
            >
                <button style={!item?.signature ? { opacity: '0.5' } : {}} className="reset-btn"
                        disabled={!item?.signature}>
                    <a href={item?.signature} target={'_blank'}>
                        <img src={Images.showEye} alt="icon" className='show-eye-blue-icon' />
                    </a>
                </button>
            </SimpleTooltip>
        ),
    },
];

export const ACTION_TYPE = 'GET_SIGNATURE_REPORTS';


export const tabsLabels = [
    {
        label: 'Signature',
    },

];


export const reportNotYet = {
    title: 'No Signature Reports Available',
    subTitle: 'It looks like there are currently no signature reports available. ',
    image: Images.notFile,
}
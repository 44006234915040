import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Address, CreateChancel, MaskInput, ValidationInput } from 'components';
import {
    ErrorText,
    FindErrorItem,
    FindLoad,
    FindSuccess,
    getPhoneErrorText, hooksForErrors, isNotEmpty,
    useModal,
} from 'utils';
import { createClientStyle } from '../createClient/styles';
import { clientActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { Checkbox } from '@material-ui/core';
import { modalsStyle } from '../../../components/modal/styles';

export const InsuredInfo = ({ client }) => {
    const classes = createClientStyle();
    const ACTION_TYPE = 'EDIT_CLIENT';
    const [error, setError] = useState('');
    const [inputs, setInputs] = useState({});
    const [enteredAddress, setEnteredAddress] = useState(null);
    const dispatch = useDispatch();
    const success = FindSuccess(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const { close } = useModal();
    const backError = FindErrorItem(ACTION_TYPE);
    const phoneErrorMsg = getPhoneErrorText(inputs?.phoneNumber);
    const phoneErrorText = hooksForErrors.getPhoneError(error, backError, phoneErrorMsg);
    const modalStyles = modalsStyle();

    useEffect(() => {
        if (client?.insuredInfo?.firstName) {
            setInputs({
                firstName: client?.insuredInfo?.firstName,
                middleName: client?.insuredInfo?.middleName,
                lastName: client?.insuredInfo?.lastName,
                phoneNumber: client?.insuredInfo?.phoneNumber,
            });
        }
    }, [client]);

    useEffect(() => {
        if (!!success.length) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(success[0].type));
        }
    }, [success]);

    const handleCreate = () => {

        const phoneIsValid = isNotEmpty(inputs.phoneNumber) && inputs.phoneNumber.trim().length >= 10 && !/[a-zA-Z]/g.test(inputs.phoneNumber);

        const isValid =
            isNotEmpty(inputs.firstName) &&
            isNotEmpty(inputs.lastName) &&
            isNotEmpty(inputs.phoneNumber) &&
            phoneIsValid &&
            isNotEmpty(enteredAddress?.address);

        if (isValid) {

            const data = {
                firstName: client.firstName,
                middleName: client?.middleName ? client.middleName : null,
                lastName: client.lastName,
                phoneNumber: client?.phoneNumber ? parseInt(client?.phoneNumber.replace(/[^0-9]/g, '')) + '' : null,
                gender: client.gender,
                birthday: client.birthday,
                ethnicity: client.ethnicity,
                language: client.language,
                familyLanguage: client.familyLanguage,
                status: 'ACTIVE',
                insuredInfo: {
                    firstName: inputs.firstName,
                    middleName: inputs?.middleName,
                    lastName: inputs?.lastName,
                    phoneNumber: inputs?.phoneNumber ? parseInt(inputs?.phoneNumber.replace(/[^0-9]/g, '')) + '' : null,
                    address: enteredAddress?.address,
                },
            };

            data.code = client?.code ? client?.code : null;
            data.registrationDate = client?.registrationDate ? client?.registrationDate : null;
            data.email = client?.email ? client?.email : null;
            data.renderingProvider = client?.renderingProviderId ? client?.renderingProviderId : null;
            dispatch(clientActions.editClient(data, client?.id));

        } else {
            let errMessage = !inputs.firstName ? 'firstName' :
                !inputs.lastName ? 'lastName' :
                    !inputs.phoneNumber ? 'phoneNumber' :
                        !phoneIsValid ? 'phoneNumber' :
                            !enteredAddress?.address ? 'enteredAddress' : '';
            setError(errMessage);
        }
    };

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        error === e.target.name && setError('');
    };

    const handleAddressChange = (selectedAddress) => {
        setEnteredAddress(selectedAddress);
        error === 'enteredAddress' && setError('');
        if (error === 'unableError') {
            setError('');
            dispatch(httpRequestsOnErrorsActions.removeError(backError.type));
        }
    };


    const handleCheck = () => {
        const params = {}

        client?.firstName ? params['firstName'] = client?.firstName : delete params['firstName']
        client?.firstName ? params['lastName'] = client?.lastName : delete params['lastName'];
        client?.middleName ? params['middleName'] = client?.middleName : delete params['middleName'];
        client?.phoneNumber ? params['phoneNumber'] = client?.phoneNumber : delete params['phoneNumber'];
        setInputs(params);
    };

    return (
        <div  style={{ width: 463 }}>
            <p className='modal-header-title'>{client?.insuredInfo?.firstName ? 'Edit Insured Info' : 'Add Insured Info'}</p>
            <div className='modal-body-wrapper'>
                <div className={classes.sameAsClient}>
                    <p>Same as Client</p>
                    <Checkbox
                        name='available'
                        className={modalStyles.customCheckbox}
                        onChange={handleCheck}
                    />
                </div>
                <ValidationInput
                    variant={'outlined'}
                    onChange={handleChange}
                    value={inputs.firstName}
                    label={'Insured Name*'}
                    name='firstName'
                    typeError={error === 'firstName' ? `Insured Name ${ErrorText.isRequired}` : ''}
                    Length={15}
                />
                <ValidationInput
                    variant={'outlined'}
                    onChange={handleChange}
                    value={inputs.middleName}
                    label={'Insured Middle Name'}
                    name='middleName'
                    typeError={error === 'middleName' ? ErrorText.field : ''}
                    Length={15}
                />
                <ValidationInput
                    variant={'outlined'}
                    onChange={handleChange}
                    value={inputs.lastName}
                    label={'Insured Last Name*'}
                    name='lastName'
                    typeError={error === 'lastName' ? `Insured Last Name ${ErrorText.isRequired}` : ''}
                    Length={15}
                />
                <MaskInput
                    name='phoneNumber'
                    label='Insured Phone Number*'
                    value={inputs.phoneNumber}
                    handleChange={handleChange}
                    error={phoneErrorText}
                />
                <Address
                    placeholder={'Street Address*'}
                    oneInput={true}
                    noUnit={true}
                    errMessage={
                        error === 'enteredAddress' ? `Street address ${ErrorText.isRequired}`  :
                            error === 'unableError' ? 'Unable to verify the address' :
                                ''
                    }
                    setAddress={handleAddressChange}
                    defaultValue={client?.insuredInfo?.firstName ? client?.insuredInfo?.address : ''}

                />
            </div>
            <div className={classes.createFoundingSourceBodyFlex}>
                <CreateChancel
                    loader={!!loader.length}
                    create={client?.insuredInfo?.firstName ? 'Save' : 'Add'}
                    chancel={'Cancel'}
                    onCreate={handleCreate}
                    onClose={() => close()}
                    buttonWidth='224px'
                />
            </div>
        </div>
    );
};
import {
    convertHours,
    formatAMPM,
    hooksForTable,
    manageStatus,
    manageStatusCircle,
    PermissionList,
    RolePermission,
} from 'utils';
import React from 'react';
import { DownloadInvoicePdf } from './downloadInvoicePdf';
import {
    DateRow,
    DateRowStartEnd,
    DisplayIdRow,
    PriceRow,
    TextRow,
} from 'components';
import moment from 'moment/moment';

export function getInvoiceDetails(invoice) {
    const {
        dateRange,
        pdfDocument,
        client,
        status,
        totalHours,
        totalAMT,
        invoiceDate,
        term,
        paidAMT,
        dueDate,
    } = invoice || {};
    const { addSignToValueFromStart, getValueByFixedNumber, handleCreatedAtDate } = hooksForTable;
    const early = handleCreatedAtDate(dateRange?.early);
    const latest = handleCreatedAtDate(dateRange?.latest);
    const dueAmount = totalAMT - paidAMT;

    return [
        {
            detailText: 'Client:',
            detail: !!client ? <TextRow
                name={`${client?.clientCode ? `(${client?.clientCode})` : ''} ${client?.clientFullName}`} /> : 'N/A',
        },
        {
            detailText: 'Total Hours:',
            detail: convertHours(totalHours ? totalHours : 0),
        },
        {
            detailText: 'Invoice Date:',
            detail: <DateRow date={invoiceDate} />,
        },
        {
            detailText: 'DOS:',
            detail: <DateRowStartEnd start={early} end={latest} />,
        },
        {
            detailText: 'Total Amount:',
            detail: addSignToValueFromStart(getValueByFixedNumber(totalAMT)),
        },
        {
            detailText: 'Term:',
            detail: '30',
        },
        {
            detailText: 'Status:',
            detail: <div className="flex-align-center">
                <p>{manageStatusCircle(status)}</p>
                <p>{manageStatus(status)}</p>
            </div>,
        },
        {
            detailText: 'Paid AMT:',
            detail: <PriceRow info={paidAMT} />,
        },
        {
            detailText: 'Due Date:',
            detail: <DateRow date={dueDate} />,
        },
        {
            detailText: 'PDF Document:',
            detail: !!pdfDocument || !!'file_pdf.pdf' ? <DownloadInvoicePdf /> : null,
        },
        {
            detailText: 'AMT Due:',
            detail: <PriceRow info={dueAmount} />,
        },
    ];
}

export const invoiceDetailHead = (
    [
        { name: '', title: 'ID', noSearch: true, custom: false },
        { name: '', title: 'Dos', noSearch: true, custom: false },
        { name: '', title: 'Service Code', noSearch: true, custom: false },
        { name: '', title: 'Description', noSearch: true, custom: false },
        { name: '', title: 'Hours', noSearch: true, custom: false },
        { name: '', title: 'Total Billed', noSearch: true, custom: false },
        { name: '', title: 'Copay', noSearch: true, custom: false },
        { name: '', title: 'Coins.', noSearch: true, custom: false },
        { name: '', title: 'Deduct.', noSearch: true, custom: false },
        { name: '', title: 'AMT Due', noSearch: true, custom: false },
    ]
);

export const invoiceDetailBody = (
    [
        { rowText: (item) => <DisplayIdRow id={item?.displayId ? item?.displayId : 'N/A'}  /> },
        { rowText: (item) => <DateRow date={item?.dos} /> },
        { rowText: (item) => <TextRow name={item?.cptCode} /> },
        { rowText: (item) => <TextRow name={renderDInvoiceBillDesc(item)} /> },
        { rowText: (item) => item?.hours ? convertHours(item?.hours) : 'N/A' },
        { rowText: (item) => <PriceRow info={item?.totalBilled} /> },
        { rowText: (item) => <PriceRow info={item?.copay} /> },
        { rowText: (item) => <PriceRow info={item?.coIns} /> },
        { rowText: (item) => <PriceRow info={item?.deductible} /> },
        { rowText: (item) => <PriceRow info={item?.clientBalance} /> },
    ]
);


const renderDInvoiceBillDesc = (item) => {

    if (item?.appts?.length) {
        return item?.appts?.map((item) =>
            `${item?.staffFullName} (${moment(item?.startTime).format('LT')} - ${moment(item?.endTime).format('LT')})`,
        ).join(', ');
    } else {
        return 'N/A';
    }
};

export const invoiceDetailTabsLabels = [
    { label: 'Details' },
    { label: 'Appointments' },
    { label: 'Transactions' },
    RolePermission([PermissionList.INVOICE_NOTE_READ?.code, PermissionList.INVOICE_NOTE_MANAGE?.code]) &&
    { label: 'Notes' },
    RolePermission([PermissionList.INVOICE_HISTORY_READ?.code]) &&
    { label: 'History' },
    RolePermission([PermissionList.INVOICE_FILE_MANAGE?.code, PermissionList.INVOICE_FILE_READ?.code]) &&
    { label: 'Files' },
];


export const invoiceAppointmentsHead = [
    { name: '', title: 'ID', noSearch: true, custom: false },
    { name: '', title: 'Date', noSearch: true, custom: false },
    { name: '', title: 'Time', noSearch: true, custom: false },
    { name: '', title: 'Staff Member', noSearch: true, custom: false },
    // { name: '', title: 'CPT Code (Mod.)', noSearch: true, custom: false },
    // { name: '', title: 'Place of Service', noSearch: true, custom: false },
    // { name: '', title: 'Units', noSearch: true, custom: false },
];

export const invoiceAppointmentsBody = [
    {
        rowText: (item) => item?.displayId,
    },
    {
        rowText: (item) => <div>
            <p>{moment.utc(item?.startTime).format('MM/DD/YYYY')}</p>
        </div>,
    },
    {
        rowText: (item) => <p className="schedule-time">
            {`${formatAMPM(item?.startTime)} - ${formatAMPM(item?.endTime)}`}
        </p>,
    },
    { rowText: (item) => <TextRow name={item?.staffFullName} /> },
    // { rowText: (item) => <TextRow name={item?.cptCode} /> },
    // { rowText: (item) => <TextRow name={item?.placeOfService} /> },
    // { rowText: (item) => <UnitsRow info={item?.units} /> },
];

export const invoiceTransactionsHead = [
    { name: '', title: 'Trans. ID', noSearch: true, custom: false },
    { name: '', title: 'Created Date', noSearch: true, custom: false },
    { name: '', title: 'Voided Date', noSearch: true, custom: false },
    { name: '', title: 'Total AMT', noSearch: true, custom: false },
    { name: '', title: 'Paid AMT', noSearch: true, custom: false },
    { name: '', title: 'AMT Due', noSearch: true, custom: false },
    { name: '', title: 'Status', noSearch: true, custom: false },
];

export const invoiceTransactionsBody = [
    { rowText: (item) => <DisplayIdRow id={item?.displayId} /> },
    { rowText: (item) => <DateRow date={item?.createdDate} /> },
    { rowText: (item) => item?.voidedDate ? <DateRow date={item?.voidedDate} /> : 'N/A' },
    { rowText: (item) => <PriceRow info={item?.totalAMT} /> },
    { rowText: (item) => <PriceRow info={item?.paidAMT} /> },
    { rowText: (item) => <PriceRow info={item?.amtDue} /> },
    {
        rowText: (item) => <p className={item?.status === 'ACTIVE' ? 'active-status' : 'inactive-status'}>
            {item?.status === 'ACTIVE' ? 'Active' : 'Voided'}
        </p>,
    },
];

export const INVOICE_TRANSACTIONS_TYPE = 'GET_INVOICE_TRANSACTION';

export const invoiceEnums = {
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
};
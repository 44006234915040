import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TimeSheetDetail } from 'fragments';
import { staffPayrollActions } from 'store';
import { FindLoad, PermissionList, RolePermission } from 'utils';
import { Loader } from 'components';

const ACTION_TYPE = 'GET_PAYROLL_BY_ID';
export const TimeSheetDetailPage = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);

    useEffect(() => {
        dispatch(staffPayrollActions.getPayrollById(params?.id));

        if (RolePermission([PermissionList.TIMESHEET_FILE_READ?.code, PermissionList.TIMESHEET_FILE_MANAGE?.code])) {
            dispatch(staffPayrollActions.getPayrollDocument(params.id));
        }
    }, []);

    if (loader?.length) {
        return <Loader />;
    }

    return (
        <div>
            <TimeSheetDetail />
        </div>
    );
};
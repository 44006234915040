import { makeStyles } from '@material-ui/core/styles';
import { Backgrounds as Background, Backgrounds, Colors } from 'utils';

export const paginationStyle = makeStyles(() => ({
    paginationWrapper: {
        width: '100%',
        margin: '0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: '1px solid #E4E7EC',
        background: '#FFF',
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        height: '68px',
        borderRadius: '8px',
        padding: '16px',
        marginTop: '4px',
        '@media(min-width: 1919px)': {
            padding: '16px 32px',
        },

        '& p': {
            fontSize: '14px',
            fontWeight: '400',
            color: '#667185',
        },

        '& .MuiPaginationItem-textPrimary.Mui-selected': {
            background: '#EAF1FD',
            borderRadius: '4px',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '19px',
            color: '#0C66E4',
        },

        '& .MuiPaginationItem-page': {
            background: 'none',
            border: 'none',
            borderRadius: '4px',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '19px',
            color: '#98A2B3',
        },

        '& .MuiPaginationItem-outlined': {
            background: Backgrounds.whiteModal,
            border: '1px solid #DDE3F0',
            borderRadius: '4px',
            textAlign: 'center',
            fontWeight: '600',
            fontSize: '14px',
            color: '#545F7E',
        },

        '& .MuiPaginationItem-page.Mui-selected:hover': {
            background: '#EAF1FD',
            color: '#0C66E4',
        },
        '& .MuiPaginationItem-icon': {
            color: '#344054',
        },
    },


    singlePaginationWrapper: {
        width: '100%',
        margin: '0',

        '& .MuiPaginationItem-textPrimary.Mui-selected': {
            background: '#EAF1FD',
            borderRadius: '4px',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '19px',
            color: '#0C66E4',
        },

        '& .MuiPaginationItem-page': {
            background: 'none',
            border: 'none',
            borderRadius: '4px',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '19px',
            color: '#98A2B3',
        },

        '& .MuiPaginationItem-outlined': {
            background: Backgrounds.whiteModal,
            border: '1px solid #DDE3F0',
            borderRadius: '4px',
            textAlign: 'center',
            fontWeight: '600',
            fontSize: '14px',
            color: '#545F7E',
        },

        '& .MuiPaginationItem-page.Mui-selected:hover': {
            background: '#EAF1FD',
            color: '#0C66E4',
        },
        '& .MuiPaginationItem-icon': {
            color: '#344054',
        },
    },

    selectPageBox:{
        width: '64px',
        height: '36px',
        borderRadius: '6px',
        border: '1px solid #D0D5DD',
        padding: '0 8px',
        display:'flex',
        alignItems:'center',

        '& img':{
            width: '20px',
            height: '32px',
        }
    },
    selectPage: {
        cursor: 'pointer',
        background: '#FFF',
        color: '#98A2B3',
        outline: 'none',
        width: '100%',
        height: '100%',
        border: 'none',
    },
}));

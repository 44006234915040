



export const CloseActionBtn = ({ handleClick, text }) => {
    return(
        <button onClick={handleClick} className='close-action-btn'>
            {text}
        </button>
    )
}
import {makeStyles} from "@material-ui/core/styles";
import { Backgrounds, Colors, Shadow } from 'utils';

export const receivableBillTableStyle = makeStyles(() => ({
    recBillTableStyle: {
        width: "100%",
        padding: "16px 24px",

        // boxShadow: Shadow.modalShadow,
        // borderRadius: "0 0 8px 8px",
        "@media(min-width: 1919px)": {
            padding: "40px",
        },
    },
    bodyWrapper:{
        maxHeight:'250px',
        height:'auto',
        overflow:'auto'
    },
    recBillContainerStyle: {width: "100%"},
    recBillTitleStyle: {
        fontSize: "24px",
        fontWeight: 700,
        color: Colors.TextSecondary,
        display: 'flex',
        justifyContent: 'center',
        margin: '10px 0 40px'
    },
    billTotals: {
        fontSize: "18px",
        fontWeight: 700,
        color: Colors.TextSecondary,
        margin: '40px 0 16px',
    },
    receivableBillContainerStyle: {
        width: "100%",
        marginTop: "25px",
    },
    buttonsTabWrapper: {
        width: '100%',
        display: 'flex',
        borderBottom: '4px solid #EBF2FD',
        borderRadius: '4px',

        '& button': {
            background: 'transparent',
            border: 'none',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '19px',
            color: '#4B5C68',
            padding:'0 24px 12px',
            marginBottom:'-4px'
        }
    },
    tableTheadStyle: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: `${Colors.BackgroundWhite}`,
        borderRadius: "8px",
    },
    thStyle: {
        maxWidth: "195px",
        width: "100%",
        padding: "9px 16px",
        "@media(max-width: 1280px)": { padding: "9px 8px" },
        "& span": {
            fontSize: "14px",
            fontWeight: 700,
            color: `${Colors.TextSecondary}`,
        },
    },
    thMaxStyle: {
        width: "100%",
        padding: "9px 16px",
        "@media(max-width: 1280px)": { padding: "9px 8px" },
        "& span": {
            fontSize: "14px",
            fontWeight: 700,
            color: `${Colors.TextSecondary}`,
        },
    },
    tbodyContainerStyle: {
        width: "100%",
        borderRadius: "8px",
        overflow: "hidden",
        marginTop: "4px",
    },
    tbodyRowStyle: {
        display: "flex",
        justifyContent: "space-between",
        background: Backgrounds.catskillWhite,
        cursor: "default",
    },
    tdStyle: {
        display: "flex",
        alignItems: "center",
        maxWidth: "195px",
        width: "100%",
        fontSize: "14px",
        fontWeight: 400,
        color: Colors.TextPrimary,
        padding: "9px 16px",
        "@media(max-width: 1280px)": { padding: "9px 8px" },
    },
    tdMaxStyle: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        fontSize: "14px",
        fontWeight: 400,
        color: Colors.TextPrimary,
        padding: "9px 16px",
        "@media(max-width: 1280px)": { padding: "9px 8px" },
    },
    linksWrapper:{
        display:'flex',
        alignItems:'center',
    },
    linksButtonWrapper:{
        display:'flex',
        alignItems:'center',

        '& button':{
            background:'transparent',
            border:'none',
            color:'#347AF0',
            fontWeight:400,
            fontSize:'14px',
            lineHeight:'19px',
            textDecoration:'underline',
        },
        '& img':{
            margin:'0 8px'
        }
    }
}));

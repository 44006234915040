import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Drawer } from '@material-ui/core';
import { FullTable, SimpleModal, SimpleTooltip } from 'components';
import { fundingSourceActions, httpRequestsOnSuccessActions, systemActions } from 'store';
import {
    ACTION_TYPE,
    fundingSourceServiceBody,
    fundingSourceServiceHead, serviceNotYet,
} from './constants';
import { FundingSourceSinglePTModifiers, fundingSourceSingleStyles } from '.';
import { FundingSourceServiceAdd } from './modals';
import { SaveParams, useModal } from 'utils';


export const FundingSourceServices = ({ services = [] }) => {
    const classes = fundingSourceSingleStyles();
    const globalCredentials = useSelector((state) => state.system.credentials);
    const fsSource = useSelector((state) => state.fundingSource.fundingSourceItem);
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const [drawerPosition, setDrawerPosition] = useState({ right: false });
    const [service, setService] = useState({});
    const checkActive = typeof (info?.active) === 'boolean' ? info?.active : true;
    const { open } = useModal();

    useEffect(() => {
        dispatch(systemActions.getServices());
        dispatch(systemActions.getCredentialGlobal());
    }, []);

    const renderParams = () => {
        const sendInfo = {
            ...info,
        };
        sendInfo.active = (typeof (info?.active) === 'boolean' ? info?.active : true);
        delete sendInfo.page;
        delete sendInfo.activeTab;
        delete sendInfo.limit;
        delete sendInfo.skip;
        return sendInfo;
    };

    const getServices = () => {
        dispatch(fundingSourceActions.getFoundingSourceServiceById(fsSource?.id, { ...renderParams() }));
    };

    useEffect(() => {
        getServices();
    }, [info]);

    useEffect(() => {
        if (services && service) {
            const newList = services.find((i) => i.id === service.id);
            setService(newList);
        }
    }, [services]);

    const toggleDrawer = (anchor, open) => {
        setDrawerPosition({ ...drawerPosition, [anchor]: open });
    };

    const handleClickButton = (item, name) => {
        if (name === 'changeStatus') {
            const sendInfo = {
                ...info,
            };
            sendInfo.active = (typeof (info?.active) === 'boolean' ? info?.active : true);
            delete sendInfo.page;
            delete sendInfo.activeTab;
            delete sendInfo.limit;
            delete sendInfo.skip;

            dispatch(httpRequestsOnSuccessActions.appendSuccess('MINI_LOAD', item?.id));

            dispatch(fundingSourceActions.editFoundingSourceServiceStatus(item?.id, true, fsSource?.id, sendInfo));
        } else {
            setService(item);
            if (name === 'editFundingSourseService') {
                open(<FundingSourceServiceAdd info={item}/>)
            } else if (name === 'openModifiersDrawer') {
                toggleDrawer('right', true);
            }
        }
    };

    const changeType = (type) => {
        let params = {
            ...info,
            active: type,
        };
        SaveParams(history, { ...params });
    };

    return (
        <>
            <div className={classes.buttonsAndInfo}>
                <div className={classes.buttonsTabWrapper}>
                    <button
                        onClick={() => changeType(true)}
                        className={
                            checkActive ? classes.activeBtn : classes.passiveBtn}
                    >
                        Active
                    </button>
                    <button
                        onClick={() => changeType(false)}
                        className={info?.active === false ? classes.activeBtn : classes.passiveBtn}
                    >
                        Inactive
                    </button>
                </div>
            </div>

            <FullTable
                head={fundingSourceServiceHead}
                body={fundingSourceServiceBody}
                loadingType={ACTION_TYPE}
                list={services}
                handleClickButton={handleClickButton}
                notYet={serviceNotYet}
            />

            <Drawer
                className={classes.modifiersDrawerStyle}
                anchor={'right'}
                open={drawerPosition.right}
                onClose={() => toggleDrawer('right', false)}
            >
                <FundingSourceSinglePTModifiers
                    globalCredentials={globalCredentials}
                    title={service?.serviceTypeId?.name ? service?.serviceTypeId?.name : ''}
                    currentService={service}
                    onClose={() => toggleDrawer('right', false)}
                />
            </Drawer>
        </>
    );
};

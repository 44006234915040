import React from 'react';
import { PermissionList, RolePermission } from 'utils';

// Invoice Payment Table config
export const invoicePaymentHead = [
    { name: 'displayId', title: 'Invoice ID', custom: false, width: '220px' },
    { name: '', rowName: 'firstName', title: 'Client', custom: false },
    { name: 'totalAMT', title: 'Total AMT', custom: false },
    { name: 'totalUsed', title: 'Payment AMT', custom: false, disabled: true },
    { name: 'balance', title: 'Balance', custom: false },
    { name: 'paymentType', title: 'Payment Type', custom: false, disabled: true },
    { name: 'paymentReference', title: 'Payment Ref.', custom: false, disabled: true },
    { name: 'status', title: 'Status', custom: false, disabled: true },
    RolePermission([PermissionList.INVOICE_PMT_PAY?.code]) &&
    { name: 'actions', title: 'Actions', custom: false, disabled: true },
];

export const ACTION_TYPE = 'GET_INVOICE_PAYMENTS';

import React from 'react';
import { CustomDateSelector } from '../inputs';

export const SearchHistory = ({ }) => {

    return (
        <div className="flex-align-center" style={{ gap: '16px' }}>
            <CustomDateSelector  buttonStyles={{margin: 0}}/>
        </div>
    );
};
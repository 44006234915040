import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { Loader, SimpleModal, TextRow } from 'components';
import { appointmentActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { CalendarInterval, ScheduleDetailsCard } from './common';
import { getBorderColorAndText } from './constants';
import { FindLoad, FindSuccess, formatAMPM, PermissionList, RolePermission, useModal } from 'utils';
import { getDisplayFromFullType, SERVICE } from '../constants';
import { MoreAppts } from './modals/moreAppts';

const DragAndDropCalendar = withDragAndDrop(Calendar);
const APPT_STATUS = 'SET_APPOINTMENT_STATUS';

export const Selectable = ({ handleOpenClose, openCloseRecur, handleEdit, handleOpen }) => {
    const { appointments } = useSelector((state) => ({
        appointments: state.appointment.appointments,
    }));
    const dispatch = useDispatch();
    const [openDetails, openOpenDetails] = useState(false);
    let history = useHistory();
    const info = history?.location?.state;
    const localizer = momentLocalizer(moment);
    const setStatus = FindSuccess(APPT_STATUS);
    const date1 = new Date(info?.firstDate);
    const date2 = new Date(info?.lastDate);
    const differenceInTime = date2 - date1;
    const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
    const [appointmentById, setAppointmentById] = useState(null);
    const { open } = useModal();

    moment.locale('es-es', {
        week: {
            dow: 0,
        },
    });

    // moment.locale('es-es', {
    //     week: {
    //         dow: 1,
    //     },
    // });

    const handleReturnColor = (i) => {
        const { color: borderColor, background } = getBorderColorAndText(i?.status, i?.type);
        return { borderColor, background };
    };

    const renderTime = (evDate, time) => {
        return new Date(`${evDate.slice(0, 10)}${time.slice(10, 16)}`);
    };

    const events = appointments?.appts?.map((i) => {
        return {
            id: i?.id,
            title: getDisplayFromFullType(i.type),
            start: renderTime(i.startDate, i.startTime),
            end: renderTime(i.startDate, i.endTime),
            originalStart: i.startTime,
            originalEnd: i.endTime,
            borderColor: handleReturnColor(i)?.borderColor,
            background: handleReturnColor(i)?.background,
            staffName: i?.staff,
            client: i?.client,
            clientCode: i?.type === SERVICE ? i?.client?.code : null,
            serviceCptCode: i?.type === SERVICE ? i?.authorizedService?.service?.cptCode : null,
            status: i?.status,
            eventType: i?.type,
        };
    });

    const EventComponent = ({ event }) => {
        const staffName = event?.staffName?.firstName;
        const staffLastName = event?.staffName?.lastName ? event?.staffName?.lastName.slice(0, 1) : null;

        return (
            <div className="calendar-card-staff">
                <p>
                        <span style={{ fontWeight: 600 }}>{
                            `${staffName}${staffLastName ? `${staffLastName}.` : ''} ${event?.clientCode ? `- ${event?.clientCode},` : ''} `
                        }</span>

                    {event?.serviceCptCode &&
                        <span style={{ fontWeight: 400 }}>{event?.serviceCptCode}</span>
                    }
                </p>
            </div>
        );
    };

    const handleOpenCloseModal = (date) => {
        const current = appointments?.appts?.find((i) => i?.id === date?.id);
        setAppointmentById(current);
        openOpenDetails((prevState) => !prevState);
    };

    useEffect(() => {
        if (appointments && appointmentById) {
            const current = appointments?.appts?.find((i) => i?.id === appointmentById?.id);
            setAppointmentById(current);
        }
    }, [appointments]);

    // const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    //
    //     if (RolePermission([
    //         PermissionList.APPT_SERVICE_CREATE?.code,
    //         PermissionList.APPT_CONNECTED_CREATE?.code,
    //         PermissionList.APPT_DRIVE_CREATE?.code,
    //         PermissionList.APPT_BREAK_CREATE?.code,
    //         PermissionList.APPT_PTO_CREATE?.code,
    //         PermissionList.APPT_STAFF_CREATE?.code,
    //         PermissionList.APPT_SICK_TIME_CREATE?.code,
    //         PermissionList.APPT_SELF_CREATE?.code,
    //     ])) {
    //         const startDate = start;
    //         const endDate = end;
    //
    //         if (event?.status !== 'CANCELLED' && event?.status !== 'COMPLETED' && event?.status !== 'RENDERED') {
    //             const filteredDate = appointments?.appts?.find((i) => i?.id === event?.id);
    //
    //             const date = {
    //                 ...filteredDate,
    //             };
    //
    //             const editDate = {
    //                 ...date,
    //             };
    //             editDate['startDate'] = startDate;
    //             editDate['startTime'] = new Date(startDate + 'Z');
    //             editDate['endTime'] = new Date(endDate + 'Z');
    //             editDate['staff'] = date?.staff?._id ? date?.staff?._id : date.staff;
    //             editDate['client'] = filteredDate?.client?._id ? filteredDate?.client?._id : null;
    //             date.miles ? (editDate['miles'] = date.miles ? +date.miles : '') : '';
    //             date.staffPayCode ? editDate['staffPayCode'] = date.staffPayCode?._id : '';
    //
    //             // const changeReducer = {
    //             //     ...filteredDate,
    //             // };
    //             // changeReducer['startDate'] = startDate;
    //             // changeReducer['startTime'] = startDate;
    //             // changeReducer['endTime'] = endDate;
    //
    //
    //             if (date?.type !== 'SERVICE') {
    //                 delete editDate.authorizedService;
    //                 delete editDate.placeService;
    //
    //             } else {
    //                 editDate.authorizedService ? editDate.authorizedService = filteredDate?.authorizedService?._id : '';
    //                 editDate.placeService ? editDate.placeService = filteredDate?.placeService?._id : '';
    //                 editDate.client ? editDate.client = filteredDate?.client?._id : '';
    //                 editDate.chargeRateId = editDate.chargeRate;
    //                 editDate.staffPayCode ? editDate.staffPayCode = filteredDate?.staffPayCode?._id : delete editDate.staffPayCode;
    //                 delete editDate.chargeRate;
    //             }
    //
    //             if (date?.status === 'PENDING') {
    //                 dispatch(appointmentActions.editAppointmentPosition(editDate, filteredDate?.id));
    //                 dispatch(appointmentActions.editAppointment(editDate, filteredDate?.id));
    //             }
    //         } else {
    //             dispatch(httpRequestsOnErrorsActions.appendError('CANT_CHANGE_APPOINTMENT'));
    //         }
    //
    //     }
    // };

    const handleSelect = ({ action, start, end }) => {
        if (RolePermission([
            PermissionList.APPT_SERVICE_CREATE?.code,
            PermissionList.APPT_CONNECTED_CREATE?.code,
            PermissionList.APPT_DRIVE_CREATE?.code,
            PermissionList.APPT_BREAK_CREATE?.code,
            PermissionList.APPT_PTO_CREATE?.code,
            PermissionList.APPT_STAFF_CREATE?.code,
            PermissionList.APPT_SICK_TIME_CREATE?.code,
            PermissionList.APPT_SELF_CREATE?.code,
        ]) && action === 'select') {
            const date = {
                startDate: new Date(start).toString(),
                startTime: moment(start).format(),
                endTime: moment(end).format(),
            };
            handleOpenClose('', date);
        }
    };

    const CustomToolbar = (toolbar) => {
        // const goToBack = () => {
        //     toolbar.date.setDate(toolbar.date.getDate() - 7);
        //     toolbar.onNavigate('next');
        // };
        //
        // const goToNext = () => {
        //     toolbar.date.setDate(toolbar.date.getDate() + 7);
        //     toolbar.onNavigate('next');
        // };


        return (
            <div>
                {/*<Filters*/}
                {/*    fullView={true}*/}
                {/*    // goToBack={goToBack}*/}
                {/*    // goToNext={goToNext}*/}
                {/*    handleOpen={handleOpen}*/}
                {/*/>*/}

                <CalendarInterval
                    // handleSelect={setSlot}
                    // selected={slot}
                />

            </div>
        );
    };

    const ResetToolbar = (toolbar) => {
        // const goToBack = () => {
        //     toolbar.date.setDate(toolbar.date.getDate() - 7);
        //     toolbar.onNavigate('next');
        // };
        //
        // const goToNext = () => {
        //     toolbar.date.setDate(toolbar.date.getDate() + 7);
        //     toolbar.onNavigate('next');
        // };

        return (
            <div />
        );
    };

    // const renderTimeDate = (time) => {
    //     return new Date(time + 'Z');
    // };

    // const formats = {
    //     timeGutterFormat: (time) => formatAMPM(renderTimeDate(time).toISOString()),
    //     // eventTimeRangeFormat: ({ start, end }, culture, localizer) => formatAMPM(renderTimeDate(start).toISOString()) + ' - ' + formatAMPM(renderTimeDate(end).toISOString() ) ,
    //     eventTimeRangeFormat: ({ start, end, }) => formatAMPM(renderTimeDate(start).toISOString()) + ' - ' + formatAMPM(renderTimeDate(end).toISOString()),
    //     dayRangeHeaderFormat: ({ start, end }, culture, localizer) => localizer.format(start, 'MMMM DD', culture),
    // };


    useEffect(() => {
        if (setStatus?.length) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(APPT_STATUS));
            // handleOpenCloseModal();
            openOpenDetails(false)
        }
    }, [setStatus]);

    const loader = FindLoad('GET_APPOINTMENT');


    const { scrollToTime } = useMemo(
        () => ({
            scrollToTime: new Date(1972, 0, 1, 7),
        }),
        [],
    );

    const handleNavigate = (date, view, action) => {
        history.push({
            state: {
                ...info,
                firstDate: date,
                lastDate: date,
            },
        });
    };

    const handleEditAppt = (item) => {
        handleEdit(item);
        setAppointmentById(null);
        openOpenDetails(false);
    };

    const renderStyles = (item) => {
        return {
            borderLeft: `4px solid ${item?.borderColor}`,
            background: item?.background,
            borderRadius: '4px',
            minHeight: '27px',
        };
    };

    const handleShowMore = (events, date) => {
        open(<MoreAppts appts={events} day={date} handleOpenDetail={(id) => handleOpenCloseModal({ id: id })} />);
    };

    if (loader?.length) {
        return <Loader />;
    }

    return (
        <div className="drag-and-drop-calendar">
            <>
                {differenceInDays === 0 || differenceInDays === 1 ?
                    <DragAndDropCalendar
                        date={info?.firstDate ? new Date(info?.firstDate ? info?.firstDate : '') : ''}
                        components={{
                            toolbar: ResetToolbar,
                            event: EventComponent,
                        }}
                        defaultView={Views.DAY}
                        selectable
                        resizable
                        // onEventDrop={moveEvent}
                        localizer={localizer}
                        events={events || []}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 900 }}
                        onSelectEvent={(i) => handleOpenCloseModal(i)}
                        onSelectSlot={(e, j) => handleSelect(e, j)}
                        onNavigate={handleNavigate}
                        eventPropGetter={(myEventsList) => {
                            return {
                                style: renderStyles(myEventsList),
                            };
                        }}
                    />
                    :

                    differenceInDays > 7 ?

                        <Calendar
                            date={info?.firstDate ? new Date(info?.firstDate ? info?.firstDate : '') : ''}
                            components={{
                                toolbar: ResetToolbar,
                                event: EventComponent,
                            }}
                            localizer={localizer}
                            events={events || []}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: 900 }}
                            onSelectEvent={(i) => handleOpenCloseModal(i)}
                            popup
                            onShowMore={handleShowMore}
                            eventPropGetter={(myEventsList) => {
                                return {
                                    style: renderStyles(myEventsList),
                                };
                            }}

                        />
                        :
                        <DragAndDropCalendar
                            date={info?.firstDate ? new Date(info?.firstDate ? info?.firstDate : '') : ''}
                            components={{
                                toolbar: CustomToolbar,
                                event: EventComponent,
                            }}
                            // onEventDrop={moveEvent}
                            resizable
                            selectable
                            localizer={localizer}
                            events={events || []}
                            defaultView={Views.WEEK}
                            onSelectEvent={(i) => handleOpenCloseModal(i)}
                            onSelectSlot={(e, j) => handleSelect(e, j)}
                            step={info?.slot ? info?.slot : 30}
                            style={{ height: '90vh' }}
                            scrollToTime={scrollToTime}
                            onNavigate={handleNavigate}
                            eventPropGetter={(myEventsList) => {
                                return {
                                    style: renderStyles(myEventsList),
                                };
                            }}
                        />
                }
            </>

            <SimpleModal
                handleOpenClose={handleOpenCloseModal}
                openDefault={openDetails}
                content={
                    <ScheduleDetailsCard
                        openCloseRecur={openCloseRecur}
                        closeDetail={() => openOpenDetails(false)}
                        handleEdit={handleEditAppt}
                        appointmentById={appointmentById}
                    />
                }
                secondary={true}
                calendarView={true}
            />
        </div>
    );
};

import {
    GET_INVOICES_SUCCESS,
    GET_INVOICE_BY_ID_SUCCESS,
    GET_INVOICES_FOR_GENERATE_SUCCESS,
    GET_INVOICE_DOCUMENT_SUCCESS, GET_INVOICE_TRANSACTION_SUCCESS, REMOVE_INVOICE_TRANSACTION,
} from './invoice.type';

const initialState = {
    invoices: [],
    invoiceById: {},
    billForGenerate: null,
    invoiceDocuments: [],
    invoiceTxns: [],
};

export const invoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INVOICES_SUCCESS:
            return {
                ...state,
                invoices: action.payload.invoices,
            };

        case GET_INVOICE_BY_ID_SUCCESS:
            return {
                ...state,
                invoiceById: action.payload.invoiceById,
            };

        case GET_INVOICES_FOR_GENERATE_SUCCESS:
            return {
                ...state,
                billForGenerate: action.payload,
            };

        case GET_INVOICE_DOCUMENT_SUCCESS:
            return {
                ...state,
                invoiceDocuments: action.payload,
            };

        case GET_INVOICE_TRANSACTION_SUCCESS:
            return {
                ...state,
                invoiceTxns: action.payload,
            };

        case REMOVE_INVOICE_TRANSACTION:
            return {
                ...state,
                invoiceTxns: [],
            };
        default:
            return state;
    }
};

import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { Images, SaveParams } from 'utils';
import { Popper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { enGB } from 'date-fns/locale';

export const CustomDateSelector = ({ outLabel, handleGetDates, filters, buttonStyles }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    let history = useHistory();
    const info = filters ? filters : history?.location?.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const [state, setState] = useState([
        {
            startDate: moment().format(),
            endDate: moment().format(),
            key: 'selection',
        },
    ]);

    const customLocale = {
        ...enGB,
        options: {
            ...enGB.options,
            weekStartsOn: 1,
        },
    };

    useEffect(() => {
        if (info?.firstDate && info?.lastDate) {
            setState([{
                startDate: moment(info?.firstDate).format(),
                endDate: moment(info?.lastDate).format(),
                key: 'selection',
            }]);
        }
    }, [info]);
    const handleDateChange = (item) => {
        const startDate = moment(item?.selection?.startDate);
        const endDate = moment(item?.selection?.endDate);

        setState([{
            startDate: startDate.format(),
            endDate: endDate.format(),
            key: 'selection',
        }]);

        const params = {
            ...info,
        };
        params.firstDate = startDate.format('YYYY-MM-DD');
        params.lastDate = endDate.format('YYYY-MM-DD');

        if (handleGetDates) {
            handleGetDates(params);
        } else {
            SaveParams(history, params);
        }
    };

    const handleClear = () => {
        setState([{
            startDate: moment().format(),
            endDate: moment().format(),
            key: 'selection',
        }]);

        const params = {
            ...info,
        };
        delete params.firstDate;
        delete params.lastDate;

        if (handleGetDates) {
            handleGetDates(params);
        } else {
            SaveParams(history, params);
        }
    };

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    return (
        <div className="date-selector-box">
            {outLabel && <p className="date-selector-label">{outLabel}</p>}
            <button style={{ ...buttonStyles }} className="date-range-selector" aria-describedby={id} type="button"
                    onClick={handleClick}>
                <p style={ { color: info?.firstDate ? '#51566D' : 'rgba(75, 92, 104, 0.50)'}}>
                    {info?.firstDate ? moment(info?.firstDate).format('MM/DD/YYYY') : 'Start Date'}
                </p>
                <img src={Images.arrowRight} alt="icon" />
                <p style={{ color: info?.lastDate ? '#51566D' : 'rgba(75, 92, 104, 0.50)' }}>
                    {info?.lastDate ? moment(info?.lastDate).format('MM/DD/YYYY') : 'End Date'}
                </p>
                <img src={Images.calendarToday} alt="icon" />
            </button>

            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                className={'custom-date-selector-box'}
                placement="bottom-start"
                style={{ position: 'absolute', zIndex: 1400 }}
            >
                <DateRangePicker
                    showDateDisplay={false}
                    ranges={state}
                    onChange={handleDateChange}
                    locale={customLocale}
                />
                <button className="clear-bnt" onClick={handleClear}>Clear</button>
            </Popper>
            {open && <div className="beck-drop" onClick={() => setAnchorEl(null)} />}
        </div>
    );
};

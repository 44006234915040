import {
   DELETE_INVOICE,
   EDIT_INVOICE,
   GENERATE_INVOICE,
   GET_INVOICES,
   GET_INVOICE_BY_ID,
   GET_INVOICES_FOR_GENERATE,
   UPLOAD_INVOICE_DOCUMENT,
   DELETE_INVOICE_DOCUMENT,
   GET_INVOICE_DOCUMENT,
   EDIT_INVOICE_DOCUMENT, GET_INVOICE_TRANSACTION, REMOVE_INVOICE_TRANSACTION, CLOSE_INVOICE,
} from './invoice.type';

export const getInvoices = (data) => {
   return {
      type: GET_INVOICES,
      payload: { data },
   };
};

export const getInvoiceById = (id) => {
   return {
      type: GET_INVOICE_BY_ID,
      payload: { id },
   };
};

export const generateInvoice = (body) => {
   return {
      type: GENERATE_INVOICE,
      payload: { body },
   };
};

export const editInvoice = (id, body) => {
   return {
      type: EDIT_INVOICE,
      payload: { id, body },
   };
};

export const deleteInvoice = (id) => {
   return {
      type: DELETE_INVOICE,
      payload: { id },
   };
};
export const getInvoiceTransactions = (ids) => {
   return {
      type: GET_INVOICE_TRANSACTION,
      payload: { ids },
   };
};
export const removeInvoiceTransactions = () => {
   return {
      type: REMOVE_INVOICE_TRANSACTION,
   };
};
export const closeInvoice = ( id, comment ) => {
   return {
      type: CLOSE_INVOICE,
      payload: { id, comment }
   };
};

/**Get Invoices for generate  */

export const getInvoiceForGenerate = (params) => {
   return {
      type: GET_INVOICES_FOR_GENERATE,
      payload: { params },
   };
};


/** Invoice Documents */
export const uploadInvoiceDocument = (file, id) => {
   return {
      type: UPLOAD_INVOICE_DOCUMENT,
      payload: {file, id}
   }
}

export const editInvoiceDocument = (id, dockId, file) => {
   return {
      type: EDIT_INVOICE_DOCUMENT,
      payload: {id, dockId, file}
   }
}

export const getInvoiceDocument = (id) => {
   return {
      type: GET_INVOICE_DOCUMENT,
      payload: {id}
   }
}

export const deleteInvoiceDocument = (invoiceId, id) => {
   return {
      type: DELETE_INVOICE_DOCUMENT,
      payload: {invoiceId, id}
   }
}

/** End */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDelByNote, FullTable } from 'components';
import { claimAppointmentsBody, claimAppointmentsHead } from './constants';
import { claimActions } from 'store';
import { useModal } from 'utils';

const DELETE_ACTION_TYPE = 'REMOVE_APPT_FROM_CLAIM';
export const ClaimAppointments = () => {
    const { open } = useModal();
    const dispatch = useDispatch();
    const claimById = useSelector((state) => state.claim.claimById);

    const handleClick = (item ) => {
        if (!item?.removedFromClaimFlag) {
            open(<CustomDelByNote
                info="Remove Appointment from Claim"
                text="Are you sure you want to remove this appointment from the claim? Please provide a reason for removing this appointment.."
                handleDel={(comment) => {
                    dispatch(claimActions.removeFromClaim(item?.id, comment, claimById?.id));
                }}
                innerText={'Remove'}
                inputLabel={'Reason for removing*'}
                actionType={DELETE_ACTION_TYPE}
                body={
                    <div>
                        <div className="deleting-client-info-box" style={{ alignItems: 'flex-start' }}>
                            <p style={{ fontWeight: 600 }}>Claim ID:</p>
                            <p style={{ fontWeight: 400 }}>{claimById?.displayId}</p>
                        </div>
                    </div>
                }
            />);
        }
    };

    return (
        <div>
            <FullTable
                head={claimAppointmentsHead(claimById)}
                body={claimAppointmentsBody(claimById)}
                list={claimById?.appts}
                noText={'appointments'}
                activeRowId={''}
                handleClickButton={handleClick}
            />
        </div>
    );
};
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SpeedDial } from '@material-ui/lab';
import { CustomBreadcrumbs } from 'components';
import { MenuControlPanel } from './fragments';
import { gettingStartedMenu } from './fragments/constants';
import { useScrollTop, useWidth } from 'utils';

export const GettingStartedPage = () => {
    const [currentMenuItem, setCurrentMenuItem] = useState({
        menu: '',
    });
    const [open, setOpen] = useState(false);

    const history = useHistory();
    const contentRef = useRef(null);
    const scrollTop = useScrollTop(contentRef);
    const width = useWidth();


    useEffect(() => {
        contentRef.current?.scrollTo(0, 0);
    }, []);


    useEffect(() => {
        gettingStartedMenu.forEach((item) => {
            const element = document.getElementById(item.hash);

            if (element?.offsetTop - 121 - scrollTop < 40 && element?.offsetTop) {
                changingMenu('menu', item?.title);
            }
        });
    }, [scrollTop]);


    const changingMenu = (path, value) => {
        setCurrentMenuItem(prev => ({
            ...prev,
            [path]: value,
        }));
    };


    const images = {
        desktop: {},
        tablet: {},
        mobile: {},
    };


    const renderImage = (src) => {
        if (width > 1240) {
            return images.desktop[src];
        } else if (width > 767) {
            return images.tablet[src];
        } else {
            return images.mobile[src];
        }
    };


    return (
        <div className='getting-started-page gt-walsheim'>
            {width > 767 &&
                <MenuControlPanel
                    currentMenuItem={currentMenuItem}
                    changingMenu={() => {
                    }}
                    menu={gettingStartedMenu}
                />
            }

            <div className='started-content' ref={contentRef}>
                <CustomBreadcrumbs
                    className={'getting-started-breadcrumbs'}
                    parent={'Help Center'}
                    child={'Getting Started'}
                    parentLink={'/helpCenter'}
                />

                {width < 768 &&
                    <div className='content-header' onClick={() => {
                        history.push('/helpCenter');
                    }}>
                        <div className='icon-help-text'>
                            {/*<Icons.bookHelp className='MenuControlPanel-header-icon' />*/}
                            <p className='text-help'>
                                Help
                            </p>
                        </div>

                        <p className='text-description'>
                            Articles for Control Panel
                        </p>

                        <SpeedDial
                            ariaLabel='SpeedDial basic example'
                            sx={{ position: 'absolute', bottom: 16, right: 16 }}
                            // icon={<MoreHorizIcon style={{ color: open ? 'white' : 'white', fontSize: '32px' }} />}
                            // openIcon={<MoreHorizIcon />}
                            onClose={(e) => {
                                e.stopPropagation();
                                setOpen(false);
                            }
                            }
                            onOpen={(e) => {
                                e.stopPropagation();
                                setOpen(true);
                            }}
                            open={open}
                        >
                            {open &&
                                <MenuControlPanel
                                    currentMenuItem={currentMenuItem}
                                    changingMenu={changingMenu}
                                    menu={gettingStartedMenu}
                                />
                            }
                        </SpeedDial>
                    </div>
                }


                <div className='getting-started-sub-wrapper' id='gettingStarted'>
                    <h3 className='getting-title'>
                        Getting Started
                    </h3>

                    <p className='getting-description getting-description_padding' style={{ maxWidth: '661px' }}>
                        To begin, visit the MenuMango homepage and look for the
                    </p>
                </div>


                <div className='getting-started-resource'>
                    <div>
                        <p className='description' style={{ fontSize: '11px', textAlign: 'center' }}>
                            FEEDBACK
                        </p>

                        <p className='title '>
                            Was this resource helpful?
                        </p>

                        <div className='feedback-svg-wrapper'>
                            <div className='feedback-svg-block'>
                                👍
                            </div>

                            <div className='feedback-svg-line' />

                            <div className='feedback-svg-block'>
                                👎
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};


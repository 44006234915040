export const ToastSuccess = (success) => {
   if (success) {
      return success === "CREATE_PAY_CODE_GLOBAL"
         ? "Successfully added paycode type"
         : success === "EDIT_PAY_CODE_BY_ID_GLOBAL"
         ? "Successfully updated paycode type"
         : success === "CREATE_OVERTIME_SETTINGS_GLOBAL"
         ? "Successfully added overtime settings"
         : success === "EDIT_OVERTIME_SETTINGS_BY_ID_GLOBAL"
         ? "Successfully updated overtime settings"
         : success === "DELETE_OVERTIME_SETTINGS_BY_ID_GLOBAL"
         ? "Successfully deleted overtime settings"
         : success === "DELETE_SERVICE_BY_ID_GLOBAL"
         ? "Successfully deleted service settings"
         : success === "DELETE_CREDENTIAL_BY_ID_GLOBAL"
         ? "Successfully deleted credential"
         : success === "DELETE_DEPARTMENT_BY_ID_GLOBAL"
         ? "Successfully deleted department"
         : success === "EDIT_CREDENTIAL_BY_ID_GLOBAL"
         ? "Successfully updated credential"
         : success === "DELETE_JOB_BY_ID_GLOBAL"
         ? "Successfully deleted job title"
         : success === "EDIT_JOB_BY_ID_GLOBAL"
         ? "Successfully updated job title"
         :
                                                      success === "EDIT_SERVICE_BY_ID_GLOBAL" ? "Service type updated" :
                                                      success === "CREATE_SERVICE_GLOBAL" ? "Service type added" :
                                                      success === "EDIT_DEPARTMENT_BY_ID_GLOBAL" ? "Successfully updated department" :
                                                          success === "CREATE_CREDENTIAL" ? "Successfully added credential" :
                                                          success === "CREATE_CREDENTIAL_GLOBAL" ? "Successfully added credential" :



                                                          success === "CREATE_DEPARTMENT_GLOBAL" ? "Successfully added department" :
                                                              success === "CREATE_JOB_GLOBAL" ? "Successfully added job title" :
                                                                  success === "EDIT_CREDENTIAL_BY_ID" ? "Successfully updated credential" :
                                                                      success === "DELETE_CREDENTIAL_BY_ID" ? "Successfully deleted credential" :
                                                                          success === "CREATE_GLOBAL_NOTE"
         ? "Successfully added note"
         : success === "EDIT_GLOBAL_NOTE"
         ? "Successfully updated note"
         : success === "DELETE_GLOBAL_NOTE"
         ? "Successfully delete note"
         : success === "CREATE_ADMIN"
         ? "Successfully added staff"
         : success === "EDIT_ADMIN_BY_ID"
         ? "Successfully updated staff"
         : success === "CREATE_AVAILABILITY_SCHEDULE_GLOBAL"
         ? "Successfully updated availability schedule"
         : success === "EDIT_FUNDING_SOURCE"
         ? "Successfully updated Funding Source"
         : success === "CREATE_FUNDING_SOURCE"
         ? "Successfully create Funding Source"
         : success === "CREATE_FUNDING_SOURCE_SERVICE_BY_ID"
         ? "Successfully added service"
         : success === "EDIT_FUNDING_SOURCE_SERVICE"
         ? "Successfully updated Service"
         : success === "CREATE_CLIENT"
         ? "Successfully added client"
         : success === "DELETE_CLIENT"
         ? "Successfully deleted client"
         : success === "EDIT_CLIENT"
         ? "Successfully updated client"
         : success === "EDIT_CLIENT_CONTACT"
         ? "Successfully updated contact"
         : success === "CREATE_CLIENT_CONTACT"
         ? "Successfully added contact"
         : success === "DELETE_CLIENT_CONTACT"
         ? "Successfully deleted contact"
         : success === "EDIT_CLIENT_ENROLLMENT"
         ? "Successfully updated enrollment"
         : success === "DELETE_CLIENT_ENROLLMENT"
         ? "Successfully deleted enrollment"
         : success === "CREATE_CLIENT_ENROLLMENT"
         ? "Successfully added enrollment"
         : success === "EDIT_CLIENT_AUTHORIZATION"
         ? "Successfully updated authorization"
         : success === "CREATE_CLIENT_AUTHORIZATION"
         ? "Successfully added authorization"
         : success === "EDIT_CLIENT_AUTHORIZATION_SERV"
         ? "Successfully updated service"
         : success === "CREATE_CLIENT_AUTHORIZATION_SERV"
         ? "Successfully added service"
         : success === "DELETE_CLIENT_AUTHORIZATION"
         ? "Successfully deleted authorization"
         : success === "DELETE_CLIENT_AUTHORIZATION_SERV"
         ? "Successfully deleted Service"
         : success === "DELETE_CLIENT_AUTHORIZATION_SERV"
         ? "Successfully deleted Service"
         : success === "CREATE_EMPLOYMENT"
         ? "Successfully added employment"
         : success === "EDIT_EMPLOYMENT"
         ? "Successfully updated employment"
         : success === "CREATE_PAY_CODE"
         ? "Successfully added pay code"
         : success === "EDIT_PAY_CODE"
         ? "Successfully updated paycode"
         : success === "DELETE_STAFF_SERVICE"
         ? "Successfully deleted service"
         : success === "CREATE_STAFF_SERVICE"
         ? "Successfully added service"
         : success === "CREATE_ROLE"
         ? "Role Was added"
         : success === "DELETE_ROLE"
         ? "Role Was Deleted"
         : success === "CREATE_MILEAGE"
         ? "Successfully added mileage"
         : success === "EDIT_MILEAGE"
         ? "Successfully updated Mileage"
         : success === "DELETE_MILEAGE"
         ? "Successfully deleted Mileage"
         : success === "IS_CLINICIAN"
         ? "Successfully changed Clinician"
         : success === "SET_STATUS" || success === "CHANGE_FUNDING_SOURCE_STATUS"
         ? "Successfully changed Status"
         : success === "CREATE_PLACE_GLOBAL"
         ? "Successfully added place of service"
         : success === "CREATE_TIMESHEET"
         ? "Successfully added timesheet"
         : success === "EDIT_PLACE_BY_ID_GLOBAL"
         ? "Successfully updated place of service"
         : success === "DELETE_PLACE_BY_ID_GLOBAL"
         ? "Successfully deleted place of service"
         : success === "CREATE_UPLOAD"
         ? "Successfully uploaded file"
         : success === "DELETE_UPLOADS"
         ? "Successfully deleted File"
         : success === "APPOINTMENT_REPEAT"
         ? "Appointment repeat was updated"
         : success === "DELETE_ROLE"
         ? "Role was deleted"
         : success === "DELETE_ROLE_PERMISSION"
         ? "Role Permission was deleted"
         : success === "ASSIGN_ACCESS"
         ? "Access was assigned"
         : success === "REMOVE_ACCESS"
         ? "Access was deleted"
         : success === "CHANGE_PASSWORD_REQUEST"
         ? "Password was updated"
         : success === "EDIT_TIMESHEET"
         ? "Timesheet was updated"
         : success === "SET_APPOINTMENT_STATUS"
         ? "Appointment status was updated"
         : success === "CHANGE_FUNDING_MODIFIER_STATUS"
         ? "Modifier status was updated"
         : success === "CREATE_FUNDING_MODIFIER"
         ? "Modifier was added"
         : success === "EDIT_FUNDING_MODIFIER"
         ? "Modifier was updated"
         : success === "TERMINATE_CLIENT_ENROLLMENT"
         ? "Enrollment was terminated"
         : success === "CHANGE_ADMIN_STATUS"
         ? "Staff status was updated"
         : success === "EDIT_CLIENT_STATUS" ? "Client status was updated" : success === "CHANGE_ENROLLMENT_TYPE"
         ? "Enrolment type was updated"
         : success === 'CANCEL_APPT' ? 'Appointments was cancelled' :  success === 'REINVITE_ADMIN' ? 'Staff was Reinvited' : success === 'CHANGE_CALENDAR_STATUS' ? 'Calendar status was updated' :
            success === 'GET_RECOVERY_LINK' ? 'Recovery link was sent' :
            success === 'CREATE_ADMIN_DETAILS' ? 'Staff details was updated' :
            success === 'APPOINTMENT_FROM_TEMPLATE' ? 'Appointments was created' :
            success === 'CREATE_PROCESS_PAYROLL' ? 'All selected payrolls have been successfully processed.' :
                                                                                                                                                                                                                                                                                                                                false;
   }
};

import React from 'react';
import { Images } from 'utils';

export const DetailMessageBox = ({ text  }) => {

    return (
        <div className='info-box-style'>
            <img src={Images.claimInfo} alt="" />
            <p>{text}</p>
        </div>
    );
}
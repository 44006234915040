import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AddButton, Loader, NoItemsYet } from 'components';
import { AccessManagement, AddRoleModal } from 'fragments';
import { FindLoad, FindSuccessItem, Images, PermissionList, RolePermission, useModal } from 'utils';
import {
    httpRequestsOnSuccessActions,
    permissionsActions,
    roleActions,
} from 'store';

const CREATE_ACTION = 'CREATE_ROLE';
const GET_ROLE = 'GET_ROLE';

export const Management = ({}) => {
    const { permissionsList, rolesList, role } = useSelector((state) => ({
        permissionsList: state.permissions.permissionsList,
        rolesList: state.roles.rolesList,
        role: state.roles.role,
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const createSuccess = FindSuccessItem('CREATE_ROLE');
    const { open } = useModal();
    const { roles, count } = rolesList || {};
    const loader = FindLoad(GET_ROLE);

    useEffect(() => {
        dispatch(roleActions.removeRole());
        dispatch(permissionsActions.getPermissions());
    }, []);


    const getRoles = () => {
        const infoItem = {};
        infoItem.skip = info?.skip ? info?.skip : 0;
        infoItem.limit = 50;
        info?.sortType ? infoItem.sortType = info.sortType : '';
        dispatch(roleActions.getRole({ ...infoItem }));
    };

    useEffect(() => {
        getRoles();
    }, [info]);

    useEffect(() => {
        if (createSuccess) {
            getRoles();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(CREATE_ACTION));
        }
    }, [createSuccess]);

    if (loader?.length) {
        return <Loader />;
    }

    return (
        <div>
            <div className="flex-end">
                {RolePermission([PermissionList.ROLES_CREATE?.code]) &&
                    <AddButton
                        text={'Add Role'}
                        handleClick={() => open(<AddRoleModal />)}
                    />
                }
            </div>

            {rolesList?.count > 0 ?
                <AccessManagement
                    afterSuccess={getRoles}
                    info={info}
                    rolesList={roles}
                    permissionsList={permissionsList}
                    role={role}
                    rolesCount={count}
                />
                :
                <div style={{ position: 'relative' }}>
                    <NoItemsYet
                        text={'No Roles or permissions yet'}
                        subTitle={'You haven\'t added any roles or permissions yet. Click the button below to get started.'}
                        image={Images.noFsYet}
                    />
                </div>
            }
        </div>
    );
};